import React from "react";
import { StyleSheet, Document, Page, View, Text } from "@react-pdf/renderer";
import { getDefaultStyles } from "../PdfStyles";
import { Header } from "../componentParts/Header";
import { Footer } from "../componentParts/Footer";
import { ComponentProps } from "../PdfPage";
import { Wrapper } from "../Wrapper";
import { GiftCards } from "../componentParts/GiftCards";
import { PrePayments } from "../componentParts/PrePayments";
import { Subscriptions } from "../componentParts/Subscriptions";
import { FirstCell } from "../componentParts/FirstCell";
import { useTranslation } from "react-i18next";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

function getLabels(t: any) {
  return {
    former: t("Former acquirer"),
  };
}

const Doc = (props: ComponentProps) => {
  const { t } = useTranslation();
  const { show } = props;
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  const labels = getLabels(t);

  return (
    <Document
      creator="Worldline"
      producer="Worldline"
      language={props.language.toString()}
    >
      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />
        <PrePayments {...props} />
        <View style={styles.divider} />
        <Subscriptions {...props} />
        <View style={styles.divider} />
        <GiftCards {...props} />
        <View style={styles.divider} />
        <View style={styles.sectionHeader}>
          <Text>{labels.former}</Text>
        </View>
        <View>
          <FirstCell label="&nbsp;" show={show}>
            Swedbank/Babs
          </FirstCell>
        </View>
        <Footer {...props} />
      </Page>
    </Document>
  );
};

export const SalesData: React.FunctionComponent<ComponentProps> = (props) => (
  <Wrapper {...props} name="sales-data">
    <Doc {...props} />
  </Wrapper>
);
