import { useParams } from "react-router";
import { AssociateId } from "../data/models/CommonTypes";

export type AssociateIdParams = {
  associateId: AssociateId;
};

export function useAssociateId() {
  const { associateId } = useParams<AssociateIdParams>();
  return associateId as AssociateId;
}
