import { Review } from "./Review";
import { useContractId } from "../../../hooks/useContractId";
import { Page } from "../../Page";
import { ErrorBoundary } from "../../../components/errors/ErrorBoundary";
import "./ReviewPage.scss";

export const REVIEW_ROUTE = "/review/:contractId";

export function ReviewPage() {
  const contractId = useContractId();

  if (!contractId) {
    return <div>No Contract Id present, can not load contract information</div>;
  }

  return (
    <Page title="Review status" className="merchant-status-page">
      <section>
        <article>
          <ErrorBoundary>
            <Review />
          </ErrorBoundary>
        </article>
      </section>
    </Page>
  );
}
