import React from "react";
import { Notifier } from "../../../components/notifier/Notifier";
import { DeleteContract } from "./DeleteContract";
import { PrimaryContact } from "./primaryContact/PrimaryContact";
import { SendForConfirmation } from "./SendForConfirmation";
import { Stores } from "./stores/Stores";
import { Uploads } from "./uploads/Uploads";
import { Offering } from "./offering/Offering";
import { CompanyData } from "./companyData/CompanyData";
import { FinancialData } from "./financialData/FinancialData";
import { SalesData } from "./salesData/SalesData";
import { ReadOnly } from "./readOnly/ReadOnly";
import { Notes } from "./notes/Notes";
import { useRecoilValue } from "recoil";
import { disabledState } from "../../../state/contractState";

export const LinkAnchors = {
  PRIMARY_CONTACT: { name: "Primary contact", anchor: "primary-contact" },
  COMPANY_DATA: { name: "Company data", anchor: "company-data" },
  FINANCIAL_DATA: {
    name: "Business Purpose",
    anchor: "financial-data",
  },
  SALES_DATA: {
    name: "Sales data",
    anchor: "sales-data",
  },
  OFFERING: { name: "Offering", anchor: "offering" },
  PRICING: {
    name: "Pricing",
    anchor: "pricing",
  },
  STORES: {
    name: "Locations",
    ecomName: "Ecom settings",
    anchor: "stores",
  },
  DOCUMENT_COLLECTION: {
    name: "Company Identification",
    anchor: "document-collection",
  },
  NOTES: {
    name: "Notes",
    anchor: "notes",
  },
  UPLOADS: { name: "Documents", anchor: "documents" },
};

export const ContractEdit = () => {
  const disabled = useRecoilValue(disabledState);

  return (
    <div className="contract-edit">
      <Notifier />

      <ReadOnly />

      <hr />
      <hr />

      <PrimaryContact />

      <hr />
      <hr />

      <CompanyData />

      <hr />
      <hr />

      <FinancialData />

      <hr />
      <hr />

      <SalesData />

      <hr />
      <hr />

      <Offering />

      <hr />
      <hr />

      <Stores />

      <hr />
      <hr />

      <Uploads />

      <hr />
      <hr />

      <Notes disabled={disabled} />

      <hr />
      <hr />

      <SendForConfirmation />

      <DeleteContract />
    </div>
  );
};
