import { BaseValidator } from "./BaseValidator";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";

export const RequiredValidatorName = "RequiredValidator";

export class RequiredValidator extends BaseValidator {
  name = RequiredValidatorName;

  validate(value: any): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (typeof value !== "undefined" && value.toString().length > 0) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: true, // only show this error on submit
        });
      }
    });
  }
}
