import { motion, Variants } from "framer-motion";
import "./SpinnerMotion.scss";

const DURATION = 0.075;

const svgVariants: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: DURATION,
      staggerDirection: 1,
    },
  },
};

const childVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  show: (custom: number) => ({
    opacity: [null, 1, 1, 0],
    scale: [null, 1, 1, 0.75],
    x: [custom * -25, 0, 0, custom * -10],
    transition: {
      duration: DURATION * 30,
      repeat: Infinity,
      repeatDelay: DURATION * 5,
      times: [0, 0.25, 0.85, 1],
    },
  }),
};

const childVariantsIn: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  show: (custom: number) => ({
    opacity: [null, 1],
    scale: [null, 1],
    x: [custom * -25, 0],
    transition: {
      duration: DURATION * 10,
      times: [0, 1],
    },
  }),
};

interface Props {
  /**
   * Size of the spinner.
   * @default medium - 72px
   */
  size?: "mini" | "small" | "medium" | "large" | "huge";
  /**
   * Display "Loading" below the spinner.
   * @default false
   */
  withText?: boolean;
  once?: boolean;
}

const sizes: Record<"mini" | "small" | "medium" | "large" | "huge", number> = {
  mini: 40,
  small: 48,
  medium: 72,
  large: 128,
  huge: 180,
};

export const SpinnerMotion: React.FunctionComponent<Props> = ({
  size = "medium",
  withText = false,
  once = false,
}) => {
  const childVariant = once ? childVariantsIn : childVariants;

  return (
    <div className="spinner-motion">
      <svg
        width={sizes[size]}
        viewBox="0 0 532 263"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <motion.g
          id="g12"
          transform="translate(266.168011, 131.141476) scale(-1, 1) rotate(-180.000000) translate(-266.168011, -131.141476) translate(0.896625, 0.001333)"
          fill="#55C4A4"
          fillRule="nonzero"
          variants={svgVariants}
          initial="hidden"
          animate="show"
        >
          <motion.path
            variants={childVariant}
            custom={1}
            d="M313.501159,57.7186354 C307.841728,87.4606448 299.879607,116.195632 289.728308,143.510087 C280.45268,166.731589 289.728308,143.510087 280.45268,166.731589 C267.544638,196.733057 251.89607,224.748048 233.65292,250.165215 C230.685368,254.305193 227.652951,258.326794 224.588102,262.280287 C210.480066,260.107326 196.745001,256.810586 183.463989,252.508446 C215.312474,215.670257 241.274503,168.985632 259.0636,115.741581 C268.371661,84.4590386 259.0636,115.741581 268.371661,84.4590386 C275.393246,57.6002577 280.420247,29.3629432 283.17699,0.114877244 L321.67409,0.114877244 C313.501159,57.7186354 321.67409,0.114877244 313.501159,57.7186354 Z"
            id="1-1"
          ></motion.path>
          <motion.path
            variants={childVariant}
            custom={2}
            d="M242.762605,55.0367444 C230.227534,112.172667 207.703325,164.614019 176.373756,208.215958 C168.362987,219.379144 159.930598,229.75909 151.109021,239.334717 C139.271244,233.662313 127.919951,227.141806 117.152439,219.846168 C167.454883,168.911295 203.097944,90.4398058 213.184378,0 L253.027417,0 C249.866129,25.9897623 248.853569,30.8983276 242.762605,55.0367444 Z"
            id="1-2"
          ></motion.path>
          <motion.path
            variants={childVariant}
            custom={3}
            d="M90.4448172,199.607311 C80.918643,191.244628 71.9823249,182.210983 63.7243411,172.575686 C103.731323,132.415341 132.575281,71.10571 142.293158,0.114625125 L180.766515,0.114625125 C173.009909,62.672244 151.539151,120.432417 118.389245,166.498695 C109.659376,178.651202 100.295412,189.691832 90.4448172,199.607311"
            id="1-3"
          ></motion.path>
          <motion.path
            variants={childVariant}
            custom={4}
            d="M43.5652432,145.783231 C36.1801138,134.555258 29.5964713,122.737619 23.9574383,110.407602 C46.8570644,81.8574932 63.7026019,43.5850618 71.3882889,0.114558507 L110.002783,0.114558507 C102.703528,46.8182028 85.8723024,89.8566188 60.7685873,124.674069 C55.3156138,132.236671 49.5620828,139.274012 43.5652432,145.783231"
            id="1-4"
          ></motion.path>
          <motion.path
            variants={childVariant}
            custom={5}
            d="M0,0.114596263 L38.7391852,0.114596263 C33.1614085,26.6009879 23.4488621,51.2368619 10.0177979,72.5628388 C3.49651671,49.5357787 0,25.2327676 0,0.114596263"
            id="1-5"
          ></motion.path>
          <motion.path
            variants={childVariant}
            custom={-1}
            d="M290.097822,143.511419 C310.189611,188.434972 335.113807,223.156956 358.092068,248.820608 C351.897505,251.123299 345.605645,253.202207 339.216489,255.04436 C319.481455,232.121235 298.741021,202.880304 280.822194,166.732922 C271.525497,147.978832 264.395803,130.982163 259.433115,115.742913 C252.941394,96.3518721 247.390071,76.1552191 242.779147,55.1529543 C236.990193,28.7848948 236.990193,28.7848948 234.022435,1.40687462e-12 L253.043959,0.116209941 C256.027727,30.7668093 262.468973,62.6062776 268.741175,84.4603713"
            id="5"
          ></motion.path>
          <motion.path
            variants={childVariant}
            custom={-2}
            d="M404.879068,225.701556 C402.47908,223.194542 400.186783,220.326231 397.760185,218.067001 C355.030226,178.28421 306.588346,79.8113799 303.335086,0.114877244 L321.67409,0.114877244 C333.041599,99.0324773 375.154896,165.625649 411.365521,205.817334 C414.333073,209.117317 417.333058,212.279463 420.333042,215.36215 C415.306041,218.983213 410.165527,222.430762 404.879068,225.701556 Z"
            id="4"
          ></motion.path>
          <motion.path
            variants={childVariant}
            custom={-3}
            d="M470.297974,168.446613 C466.382102,173.210097 462.301004,177.831487 458.054679,182.30913 C417.177602,140.432432 383.008729,79.6868496 372.335086,0.114680237 L390.82395,0.114680237 C401.745432,77.3555013 435.302967,129.851317 464.283724,162.00773 C466.266444,164.220115 468.282209,166.353191 470.297974,168.446613"
            id="3"
          ></motion.path>
          <motion.path
            variants={childVariant}
            custom={-4}
            d="M441.776746,0.115245377 L460.356932,0.115245377 C469.119274,47.9924798 488.821977,83.0852398 508.005307,107.281366 C505.274405,113.451864 502.325701,119.500058 499.159195,125.410869 C472.503582,94.2953409 450.740136,52.5215885 441.776746,0.115245377"
            id="2"
          ></motion.path>
          <motion.path
            variants={childVariant}
            custom={-5}
            d="M511.843034,0.114813901 L530.542773,0.114813901 C530.542773,14.7173335 529.360314,29.0355562 527.079858,42.9970142 C520.745258,30.0627749 515.525548,15.7705663 511.843034,0.114813901 Z"
            id="1"
          ></motion.path>
        </motion.g>
      </svg>
      {withText && (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
          }}
        >
          {"Loading"}
        </motion.span>
      )}
    </div>
  );
};
