import { useParams } from "react-router";
import { ContractId } from "../data/models/CommonTypes";

export type ContractIdParams = {
  contractId: ContractId;
};

export function useContractId() {
  const { contractId } = useParams<ContractIdParams>();
  return contractId as ContractId;
}
