import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Textarea } from "../../../../components/interactions/Input/Textarea";
import { T } from "../../../../components/translation/T";
import { dataDocuments } from "../../../../data/dataDocuments";
import { Status } from "../../../../data/types";
import { useContractId } from "../../../../hooks/useContractId";
import { contractDocumentsState } from "../../../../state/contractDocumentsState";
import { contractState, disabledState } from "../../../../state/contractState";
import "./AddCustomDoc.scss";

export const AddCustomDoc: React.FunctionComponent = () => {
  const contractId = useContractId();
  const contract = useRecoilValue(contractState);
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [value, setValue] = useState<string>("");
  const setDocs = useSetRecoilState(contractDocumentsState);
  const queryClient = useQueryClient();
  const addCustomDoc = () => setOpen(true);
  const disabled = useRecoilValue(disabledState);

  function onChange(inputValue: string) {
    setValue(inputValue);
  }

  const save = () => {
    if (!value) {
      setMessage(
        "You need to provide the merchant with a description of the document."
      );
      setStatus(Status.ERROR);

      setTimeout(() => {
        setMessage("");
        setStatus(Status.DEFAULT);
      }, 4000);
      return;
    }

    setMessage("");
    setStatus(Status.PENDING);

    dataDocuments
      .saveCustomDocument(contract.contractId, value)
      .then((docs) => {
        setDocs(docs);
        setOpen(false);
        setValue("");
        setStatus(Status.DEFAULT);
        queryClient.invalidateQueries({
          queryKey: dataDocuments.getDocumentsKey(contractId),
        });
      })
      .catch((err) => {
        setMessage("Something went wrong. We couldn't save the document");
        setStatus(Status.ERROR);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  };

  const buttonStatus = disabled ? Status.DISABLED : Status.DEFAULT;

  return (
    <div className="add-custom-docs m-top-40">
      <AnimateHeight name={open.toString()}>
        {open ? (
          <div>
            <p>
              <strong>
                <T>Add custom document</T>
              </strong>
            </p>
            <div className="custom-doc-input">
              <Textarea
                message={message}
                status={disabled ? Status.DISABLED : status}
                name="upload-file-desc"
                value={value}
                onChange={onChange}
                label="Document description"
              />
              <Button onClick={save} status={status}>
                <T>Save document</T>
              </Button>
            </div>
          </div>
        ) : (
          <Button block onClick={addCustomDoc} status={buttonStatus}>
            <T>Add custom document</T>
          </Button>
        )}
      </AnimateHeight>
    </div>
  );
};
