import React from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../PdfStyles";
import { ComponentProps } from "../PdfPage";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({
    top: {
      borderTop: "1px solid #222",
      padding: "6px 0 0 0",
      position: "absolute",
      bottom: 20,
      left: 30,
      width: "100%",
    },
    text: {
      fontSize: 8,
      margin: "0 0 2px 0",
    },
  });
}

function getLabels(t: any) {
  return {
    Address: t(
      "Bambora AB P.O. Box 17026, SE-104 62 Stockholm; Visitor address: Hammarby kaj 10D, 120 32 Stockholm, Sweden"
    ),
    Email: t("E-mail: support@worldline.com"),
  };
}

export const Footer: React.FunctionComponent<ComponentProps> = ({ show }) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };
  return (
    <View style={styles.top}>
      <Text style={styles.text}>{labels.Address}</Text>
      <Text style={styles.text}>{labels.Email}</Text>
    </View>
  );
};
