export type ContractId = string & { isContractId: true };
export type SigneeId = number & { isSigneeId: true };
export type BeneficialOwnerId = number & { isOwnerId: true };
export type TerminalId = number & { isTerminalId: true };
export type StoreId = number & { isStoreId: true };
export type UserId = string & { isUserId: true };
export type TeamId = string & { isTeamId: true };
export type Cas = number & { isCasId: true };
export type LinkId = string & { isLinkId: true };
export type UTCDate = string & { isUTCDate: true };
export type DocumentId = number & { isDocumentId: true };
export type AssociateId = string & { isAssociateId: true };
export type CorrelationId = string & { isCorrelationId: true };

export type SwedishCompanyRegistrationId = string & {
  isSwedishCompanyRegistrationId: true;
};
export type NorweiganCompanyRegistrationId = string & {
  isNorweiganCompanyRegistrationId: true;
};
export type DanishCompanyRegistrationId = string & {
  isDanishCompanyRegistrationId: true;
};
export type FinnishCompanyRegistrationId = string & {
  isFinnishCompanyRegistrationId: true;
};

export type CompanyRegistrationId =
  | SwedishCompanyRegistrationId
  | NorweiganCompanyRegistrationId
  | DanishCompanyRegistrationId
  | FinnishCompanyRegistrationId;

//Vet inte riktigt var jag ska lägga den här
export const ONGOING_RESPONSE = "ONGOING_RESPONSE";

export const DEFAULT_CAS = 0;
