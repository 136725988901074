import { data } from "./data";
import { Cas, ContractId } from "./models/CommonTypes";

const BASE_PATH = "/api/sales";

export enum Acquirer {
  WORLDLINE = "Worldline",
  SWEDBANK_BABS = "Swedbank/Babs",
  ELAVON = "Elavon",
  NETS = "NETS",
  HANDELSBANKEN = "Handelsbanken",
  OTHER = "Other",
}

export enum PrepaidOptions {
  ZERO = "0",
  ONE__THREE = "1-3",
  FOUR__SEVEN = "4-7",
  EIGHT__FOURTEEN = "8-14",
  FIFTEEN_THIRTY = "15-30",
  THIRTY_ONE__FORTY_FIVE = "31-45",
  FORTY_SIX__SIXTY = "46-60",
  SIXTY_ONE__NINETY = "61-90",
  NINETY_ONE__ONE_HUNDRED_TWENTY = "91-120",
  ONE_HUNDRED_TWENTY_ONE__ONE_HUNDRED_EIGHTY = "121-180",
  ONE_HUNDRED_EIGHTY_ONE__TWO_HUNDRED_SEVENTY = "181-270",
  GREATER_THAN_TWO_HUNDRED_SEVENTY = ">270",
}

export enum MembershipOptions {
  ZERO__ONE = "ZERO__ONE",
  THREE = "THREE",
  YEAR = "YEAR",
  OTHER = "OTHER",
}

export const MEMBERSHIP_OPTIONS_LABEL_MAPPING = {
  [MembershipOptions.ZERO__ONE]: "0-1 months",
  [MembershipOptions.THREE]: "1-3 months",
  [MembershipOptions.YEAR]: "3-12 months",
  [MembershipOptions.OTHER]: "More than one year",
};

export interface SubscriptionAndMembershipData {
  // försäljningsandel, hur stor andel av företagets försäljning är medlemsskap
  salesAmount?: number;
  // in months
  [MembershipOptions.ZERO__ONE]?: number;
  [MembershipOptions.THREE]?: number;
  [MembershipOptions.YEAR]?: number;
  [MembershipOptions.OTHER]?: number;
}

export interface PrepaidData {
  // in days
  [PrepaidOptions.ZERO]?: number;
  [PrepaidOptions.ONE__THREE]?: number;
  [PrepaidOptions.FOUR__SEVEN]?: number;
  [PrepaidOptions.EIGHT__FOURTEEN]?: number;
  [PrepaidOptions.FIFTEEN_THIRTY]?: number;
  [PrepaidOptions.THIRTY_ONE__FORTY_FIVE]?: number;
  [PrepaidOptions.FORTY_SIX__SIXTY]?: number;
  [PrepaidOptions.SIXTY_ONE__NINETY]?: number;
  [PrepaidOptions.NINETY_ONE__ONE_HUNDRED_TWENTY]?: number;
  [PrepaidOptions.ONE_HUNDRED_TWENTY_ONE__ONE_HUNDRED_EIGHTY]?: number;
  [PrepaidOptions.ONE_HUNDRED_EIGHTY_ONE__TWO_HUNDRED_SEVENTY]?: number;
  [PrepaidOptions.GREATER_THAN_TWO_HUNDRED_SEVENTY]?: number;
}

export interface GiftCardsData {
  // försäljningsandel, hur stor andel av företagets försäljning är giftcards
  salesAmount?: number;
  // giltighetstid, hur länge är korten giltiga
  validityPeriod?: string;
}

export interface BusinessRisk {
  cas: Cas;
  hasFormerAcquirer?: boolean; // !(BAMBORA_DEVICE || BAMBORA_DEVICE_SHORT_TERM)
  formerAcquirer?: string; // !(BAMBORA_DEVICE || BAMBORA_DEVICE_SHORT_TERM)
  externalAcquirer?: string; // BAMBORA_DEVICE || BAMBORA_DEVICE_SHORT_TERM
  externalAcquirerMid?: string; // BAMBORA_DEVICE || BAMBORA_DEVICE_SHORT_TERM
  pspPartner?: string;
  pspPartnerTerminalModel?: string;
  hasPrepaid?: boolean;
  prepaidOptions?: PrepaidData;
  hasSubscriptionAndMembership?: boolean;
  subscriptionAndMembership?: SubscriptionAndMembershipData;
  hasGiftCards?: boolean;
  giftCards?: GiftCardsData;
}

export const dataBusinessRisk = {
  getBusinessRisk: (contractId: ContractId) =>
    data.get<BusinessRisk>(`${BASE_PATH}/${contractId}/business-risk`),

  fetchBusinessRiskData: (contractId: ContractId) => ({
    queryKey: dataBusinessRisk.getBusinessRiskKey(contractId),
    queryFn: () => dataBusinessRisk.getBusinessRisk(contractId),
  }),

  getBusinessRiskKey: (contractId: ContractId) => {
    return ["business-risk", contractId];
  },

  saveBusinessRisk: (contractId: ContractId, businessRisk: BusinessRisk) =>
    data.post<BusinessRisk>(
      `${BASE_PATH}/${contractId}/business-risk`,
      businessRisk
    ),
};
