import { RequiredValidator } from "../../../../../../components/form/validators/RequiredValidator";
import { T } from "../../../../../../components/translation/T";
import { FC, useMemo, useRef } from "react";
import { ButtonPaneCollapse } from "../../ButtonPaneCollapse/ButtonPaneCollapse";
import { TextInput } from "../../../../../../components/form/TextInput";
import { id } from "../../../../../../components/utils";
import { Acquirer as AcquirerEnum } from "../../../../../../data/dataBusinessRisk";
import { useTranslation } from "react-i18next";

interface Props {
  acquirer?: string;
  onChangeAcquirer: (value: string) => void;
  hasPreviousAcquirer?: boolean;
  setHasPreviousAcquirer: (value: boolean | undefined) => void;
  disabled?: boolean;
}

export const Acquirer: FC<Props> = ({
  acquirer = undefined,
  onChangeAcquirer,
  hasPreviousAcquirer,
  setHasPreviousAcquirer,
  disabled,
}) => {
  const { t } = useTranslation();
  const identifier = useRef<string>(id());
  const list = useMemo(
    () =>
      Object.values(AcquirerEnum).map((opt) => (
        <option value={opt} key={opt}>
          {opt}
        </option>
      )),
    []
  );

  return (
    <ButtonPaneCollapse
      title={t("Former acquirer") || "Former acquirer"}
      label="Has your company previously had a merchant agreement with any other acquirer?"
      value={hasPreviousAcquirer}
      expanded={hasPreviousAcquirer}
      className="m-top-20"
      onChange={setHasPreviousAcquirer}
      small
      disabled={disabled}
      buttons={[
        {
          text: "Yes",
          active: hasPreviousAcquirer === true,
          data: true,
        },
        {
          text: "No",
          active: hasPreviousAcquirer === false,
          data: false,
        },
      ]}
      validators={[new RequiredValidator("Required to answer")]}
    >
      <div style={{ padding: "20px 20px 0 20px" }}>
        <TextInput
          label={<T>Acquirer</T>}
          onChange={onChangeAcquirer}
          name="Acquirer"
          disabled={disabled}
          value={acquirer}
          list={identifier.current}
          validators={
            hasPreviousAcquirer
              ? [new RequiredValidator("Required to answer")]
              : []
          }
        />
        <datalist id={identifier.current}>{list}</datalist>
      </div>
    </ButtonPaneCollapse>
  );
};
