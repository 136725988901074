import React from "react";
import styles from "./TrackingRow.module.scss";
import {
  CommunicationEvent,
  CommunicationTrackingLog,
} from "../../data/models/ContractTypes";
import {
  MdOutlineMarkEmailRead,
  MdSms,
  MdRemoveRedEye,
  MdRestore,
  MdEventNote,
} from "react-icons/md";
import { Flag } from "../flags/Flag";
import { getIntlDate } from "../utils";
import { Trans } from "react-i18next";
import { T } from "../translation/T";
import cx from "classnames";

interface Props {
  log: CommunicationTrackingLog;
}

function isEmail(log: CommunicationTrackingLog) {
  if (!log.communicationRecipient) {
    return false;
  }

  return log.communicationRecipient?.indexOf("@") > -1;
}

function getEventType(log: CommunicationTrackingLog) {
  switch (log.event) {
    case CommunicationEvent.SENT:
      return isEmail(log) ? (
        <T>Application sent to merchant by email</T>
      ) : (
        <T>Application sent to merchant by sms</T>
      );
    case CommunicationEvent.CONTRACT_VIEWED: {
      return isEmail(log) ? (
        <T>Merchant has viewed application (text message was used)</T>
      ) : (
        <T>Merchant has viewed application (email link was used)</T>
      );
    }

    case CommunicationEvent.DELIVERED: {
      if (isEmail(log)) {
        return <T>Email was delivered</T>;
      }
      return <T>Text message was delivered</T>;
    }
    case CommunicationEvent.REMINDER:
      if (log.salesUserName) {
        return (
          <T
            id="Merchant was reminded by {{name}}"
            options={{ name: log.salesUserName }}
          />
        );
      }
      return "Merchant was reminded";
    case CommunicationEvent.REVOKED:
      return <T>Application was revoked. Link is no longer available</T>;
    case CommunicationEvent.DELIVERY_FAILED:
      if (isEmail(log)) {
        return (
          <Trans>
            Email was <b>NOT</b> delivered. Please check email address
          </Trans>
        );
      }
      return (
        <Trans>
          Text message was <b>NOT</b> delivered. Please check phone number
        </Trans>
      );
    case CommunicationEvent.MESSAGE_VIEWED:
      if (isEmail(log)) {
        return <T>Email was viewed</T>;
      }
      return <T>Text message was reviewed</T>;

    default:
      break;
  }
}

function toDisplay(log: CommunicationTrackingLog) {
  switch (log.event) {
    case CommunicationEvent.SENT:
      if (isEmail(log)) {
        return <MdOutlineMarkEmailRead title="Sent" />;
      }
      return <MdSms />;

    case CommunicationEvent.CONTRACT_VIEWED:
      return <MdRemoveRedEye title="Viewed Contract" />;
    case CommunicationEvent.REMINDER:
      return <MdRestore title="Reminder" />;
  }
  return <MdEventNote />;
}

function wasCommunicatedToday(log: CommunicationTrackingLog) {
  const now = new Date();
  const startOfDate = new Date(now.setUTCHours(0, 0, 0, 0));
  return startOfDate < new Date(log.created);
}

export const TrackingRow: React.FunctionComponent<Props> = ({ log }) => {
  return (
    <li
      className={cx(styles.row, {
        [styles.today]: wasCommunicatedToday(log),
      })}
    >
      <div className="columns">
        <div className={styles.top}>
          {toDisplay(log)}{" "}
          <i>
            {getIntlDate(log.created, {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </i>
        </div>
        <div className="text-right">
          {log.merchantIp ? (
            <>
              ip: {log.merchantIp.ip}{" "}
              {log.merchantIp.countryCode ? (
                <Flag
                  country={log.merchantIp.countryCode}
                  height={14}
                  rounded
                />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.info}>{getEventType(log)}</div>
    </li>
  );
};
