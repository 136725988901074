import React from "react";
import { AnimateHeightMotion } from "../../../../../components/animate/AnimateHeightMotion";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { ButtonPaneWithLabel } from "../../../../../components/interactions/Buttons/ButtonPaneInput";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";
import { T } from "../../../../../components/translation/T";

interface Props {
  regulatedEntity?: boolean;
  onRegulatedEntityChange: (value: boolean | undefined) => void;
  disabled?: boolean;
}

export const RegulatedCompanyFields: React.FunctionComponent<Props> = ({
  onRegulatedEntityChange,
  regulatedEntity,
  disabled,
}) => {
  return (
    <div>
      <ButtonPaneWithLabel
        label="Is the company supervised by a regulatory authority?"
        value={regulatedEntity}
        className="m-top-20"
        onChange={(value) => {
          onRegulatedEntityChange(value);
        }}
        small
        buttons={[
          {
            text: "Yes",
            active: regulatedEntity === true,
            data: true,
          },
          {
            text: "No",
            active: regulatedEntity === false,
            data: false,
          },
        ]}
        validators={[new RequiredValidator("Required to answer")]}
        disabled={disabled}
      />
      <AnimateHeightMotion presence>
        {regulatedEntity && (
          <ErrorBox relative>
            <T>
              There is currently no support for companies supervised by a
              regulatory authority in Wlx. This company will have to be handled
              manually or through a different onboarding process
            </T>
          </ErrorBox>
        )}
      </AnimateHeightMotion>
    </div>
  );
};
