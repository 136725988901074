import { API, ServerError } from "../network/API";
import { getRecoil, setRecoil } from "recoil-nexus";
import { contractAuthState } from "../state/contractAuthState";

export const UNAUTHORIZED_STATUS_CODE = 401;

function actAndDelagate<T>(err: ServerError<T>) {
  if (err.status === UNAUTHORIZED_STATUS_CODE) {
    const state = getRecoil(contractAuthState);
    setRecoil(contractAuthState, {
      ...state,
      authorized: false,
    });
  }

  return Promise.reject(err);
}

export const data = {
  get: <T>(url: string, options?: RequestInit) => {
    return API.get<T>(url, options).catch(actAndDelagate);
  },

  post: <T>(url: string, body?: any) => {
    return API.post<T>(url, body).catch(actAndDelagate);
  },

  delete: <T>(url: string, body?: any) => {
    return API.delete<T>(url, body).catch(actAndDelagate);
  },
};
