import { RequiredValidator } from "../../../../../../components/form/validators/RequiredValidator";
import { T } from "../../../../../../components/translation/T";
import { FC, useMemo, useRef } from "react";
import { TextInput } from "../../../../../../components/form/TextInput";
import { id } from "../../../../../../components/utils";
import {
  Acquirer as AcquirerEnum,
  BusinessRisk,
} from "../../../../../../data/dataBusinessRisk";

interface Props {
  businessRisk: BusinessRisk;
  onChange: (value: string, name: string) => void;
  disabled?: boolean;
}

export const PspAcquirer: FC<Props> = ({
  businessRisk,
  onChange,
  disabled,
}) => {
  const identifier = useRef<string>(id());
  const list = useMemo(
    () =>
      Object.values(AcquirerEnum).map((opt) => (
        <option value={opt} key={opt}>
          {opt}
        </option>
      )),
    []
  );

  return (
    <div>
      <TextInput
        label={<T>PSP Acquirer</T>}
        onChange={onChange}
        name="pspAcquirer"
        disabled={true}
        value={businessRisk.externalAcquirer}
        list={identifier.current}
        validators={[new RequiredValidator("Required to answer")]}
      />
      <datalist id={identifier.current}>{list}</datalist>

      <TextInput
        label={<T>PSP Acquirer MID</T>}
        onChange={onChange}
        name="externalAcquirerMid"
        disabled={disabled}
        value={businessRisk.externalAcquirerMid}
        validators={[new RequiredValidator("Required to answer")]}
      />
    </div>
  );
};
