import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../PdfStyles";
import { FirstCell } from "./FirstCell";
import { Cell } from "./Cell";
import { ComponentProps } from "../PdfPage";
import { PrepaidOptions } from "../../../../data/dataBusinessRisk";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({
    info: {
      margin: "4px 0 10px 0",
    },
  });
}

function getLabels(t: any) {
  return {
    prePayments: t("Pre-payments"),
    info: t("Average number of days from payment to delivery"),
    days: t("days"),
  };
}

export const PrePayments: React.FunctionComponent<ComponentProps> = ({
  show = true,
}) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  return (
    <>
      <View style={styles.sectionHeader}>
        <Text>{labels.prePayments}</Text>
      </View>

      <Text style={styles.info}>{labels.info}</Text>

      <View style={styles.split}>
        <View style={styles.quarterColumn}>
          <FirstCell
            label={`${PrepaidOptions.ZERO} ${labels.days}`}
            show={show}
          >
            10 %
          </FirstCell>
        </View>
        <View style={styles.quarterColumn}>
          <Cell
            label={`${PrepaidOptions.ONE__THREE} ${labels.days}`}
            show={show}
          >
            10 %
          </Cell>
        </View>
        <View style={styles.quarterColumn}>
          <Cell
            label={`${PrepaidOptions.FOUR__SEVEN} ${labels.days}`}
            show={show}
          >
            10 %
          </Cell>
        </View>
        <View style={styles.quarterColumn}>
          <Cell
            label={`${PrepaidOptions.EIGHT__FOURTEEN} ${labels.days}`}
            show={show}
          >
            10 %
          </Cell>
        </View>
      </View>
      <View style={styles.split}>
        <View style={styles.quarterColumn}>
          <FirstCell
            label={`${PrepaidOptions.FIFTEEN_THIRTY} ${labels.days}`}
            show={show}
          >
            10 %
          </FirstCell>
        </View>
        <View style={styles.quarterColumn}>
          <Cell
            label={`${PrepaidOptions.THIRTY_ONE__FORTY_FIVE} ${labels.days}`}
            show={show}
          >
            10 %
          </Cell>
        </View>
        <View style={styles.quarterColumn}>
          <Cell
            label={`${PrepaidOptions.FORTY_SIX__SIXTY} ${labels.days}`}
            show={show}
          >
            10 %
          </Cell>
        </View>
        <View style={styles.quarterColumn}>
          <Cell
            label={`${PrepaidOptions.SIXTY_ONE__NINETY} ${labels.days}`}
            show={show}
          >
            10 %
          </Cell>
        </View>
      </View>
      <View style={styles.split}>
        <View style={styles.quarterColumn}>
          <FirstCell
            label={`${PrepaidOptions.NINETY_ONE__ONE_HUNDRED_TWENTY} ${labels.days}`}
            show={show}
          >
            10 %
          </FirstCell>
        </View>
        <View style={styles.quarterColumn}>
          <Cell
            label={`${PrepaidOptions.ONE_HUNDRED_TWENTY_ONE__ONE_HUNDRED_EIGHTY} ${labels.days}`}
            show={show}
          >
            10 %
          </Cell>
        </View>
        <View style={styles.quarterColumn}>
          <Cell
            label={`${PrepaidOptions.ONE_HUNDRED_EIGHTY_ONE__TWO_HUNDRED_SEVENTY} ${labels.days}`}
            show={show}
          >
            10 %
          </Cell>
        </View>
        <View style={styles.quarterColumn}>
          <Cell
            label={`${PrepaidOptions.GREATER_THAN_TWO_HUNDRED_SEVENTY} ${labels.days}`}
            show={show}
          >
            5 %
          </Cell>
        </View>
      </View>
    </>
  );
};
