import styles from "../PricePlan.module.scss";
import { FunctionComponent } from "react";
// components
import { T } from "../../translation/T";
// types
import { Currency, Language } from "../../../i18n";
import { PriceData, UnitType } from "../../../data/dataPricing";
import { getIntlCost, getIntlPercent } from "../../utils";
// import { PriceData, UnitType } from "../../../data/models/PricingTypes";

interface Props {
  prices: PriceData[];
  currency: Currency;
  language: Language;
}

export const PricePlanBody: React.FunctionComponent<Props> = ({
  prices,
  currency,
  language,
}) => {
  return (
    <table>
      <tbody>
        {prices.map(
          ({ displayLabel, label, value, unitType = null }, index) => (
            <TableRow
              key={index}
              label={displayLabel || label}
              value={value}
              currency={currency}
              unitType={unitType}
              language={language}
            />
          )
        )}
      </tbody>
    </table>
  );
};

interface TableRowProps {
  label: string;
  value: number;
  currency: Currency;
  unitType: UnitType | null;
  language: Language;
}

const TableRow: FunctionComponent<TableRowProps> = ({
  label,
  value,
  currency,
  unitType = null,
  language,
}) => {
  return (
    <tr className={styles.row}>
      <td className={styles.tdLabel}>
        <div className={styles.label}>
          <T>Description</T>
        </div>
        <div className={styles.displayValue}>{label}</div>
      </td>
      <td className={styles.tdValue}>
        <div className={styles.label}>
          <T>Price</T>
        </div>
        <div className={styles.displayValue}>
          {unitType === UnitType.CURRENCY
            ? getIntlCost(language, value, currency, 2)
            : getIntlPercent(language, value, 2)}
        </div>
      </td>
    </tr>
  );
};
