import React, { FunctionComponent, RefObject, useRef } from "react";
import { useValidation, ValidationProps } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import { RequiredValidatorName } from "./validators/RequiredValidator";
import { Status } from "../../data/types";
import { Number, Props as InputProps } from "../interactions/Number/Number";

interface Props
  extends ValidationProps,
    Omit<InputProps, "status" | "required"> {
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
  pattern?: string;
  suffix?: string;
  prefix?: string;
}

export const NumberInput: FunctionComponent<Props> = ({
  className,
  name,
  label = null,
  value,
  onChange,
  onBlur,
  hint = null,
  placeholder = "",
  autoFocus,
  type,
  attributes = {},
  list,
  autocomplete,
  message,
  validators = [],
  forceValidation = false,
  disabled = false,
  scrollToRef,
  pattern,
  suffix,
  prefix,
  min,
  max,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const innerValidators = disabled ? [] : validators;
  const inputId = useRef("text_" + Math.random().toString(36).substring(2, 9));
  const [validity, errorMessages, resetValidation, status] =
    useValidation<number>(value, innerValidators, forceValidation);
  useForm(
    inputId.current,
    validity,
    value,
    resetValidation,
    scrollToRef ?? ref
  );

  const isRequired = innerValidators.some(
    (validator) => validator.name === RequiredValidatorName
  );

  const errors = errorMessages.length > 0 ? errorMessages[0] : null;

  return (
    <Number
      className={className}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      hint={hint}
      placeholder={placeholder}
      status={disabled ? Status.DISABLED : status}
      autoFocus={autoFocus}
      type={type}
      list={list}
      autocomplete={autocomplete}
      required={isRequired}
      message={status === Status.ERROR ? errors : message}
      attributes={attributes}
      pattern={pattern}
      suffix={suffix}
      prefix={prefix}
      min={min}
      max={max}
    />
  );
};
