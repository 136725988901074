const CACHE_TIME = 1000 * 60 * 5; // 5 minutes

interface cacheData {
  [key: string]: { promise: Promise<any>; timeout: number };
}

declare global {
  interface Window {
    cc: any;
  }
}

class Cache {
  private promises: cacheData = {};

  delete(key: string) {
    Object.keys(this.promises)
      .filter((url) => url.startsWith(key))
      .forEach((url) => delete this.promises[url]);
  }

  clear() {
    this.promises = {};
  }

  get(key: string) {
    if (
      this.promises[key] &&
      this.promises[key].timeout > new Date().getTime()
    ) {
      return this.promises[key].promise;
    } else {
      this.delete(key);
      return;
    }
  }

  set(key: string, promise: Promise<any>) {
    this.promises[key] = {
      promise,
      timeout: new Date().getTime() + CACHE_TIME,
    };
    return promise;
  }
}

export const cache = new Cache();
window.cc = cache;
