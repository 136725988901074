import React from "react";
import cx from "classnames";
import "./Pending.scss";

interface Props {
  className?: string;
}

export const Pending: React.FC<Props> = ({ className }) => (
  <div className={cx("icon", "icon-pending", className)}>
    <span />
    <span />
    <span />
  </div>
);
