import React, {
  useCallback,
  ReactNode,
  useEffect,
  useRef,
  useImperativeHandle,
} from "react";
import cx from "classnames";
import { Menu } from "../icons/Menu";
import { Button } from "../interactions/Buttons/Button";
import { useLocation } from "react-router-dom";
import { Close } from "../icons/Close";
import "./MenuWrapper.scss";

interface Props {
  children: ReactNode;
  icon?: ReactNode;
  menuOpen: boolean;
  toggleMenu: (open: boolean) => void;
  menuComponent: ReactNode;
  padded?: boolean;
}

export const MenuWrapper = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      icon = <Menu />,
      menuOpen,
      toggleMenu,
      menuComponent,
      padded = false,
    },
    ref
  ) => {
    const { pathname } = useLocation();
    const onOpen = useCallback(() => toggleMenu(true), [toggleMenu]);
    const onClose = useCallback(() => toggleMenu(false), [toggleMenu]);
    const innerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => innerRef.current as HTMLDivElement);

    useEffect(() => {
      toggleMenu(false);
    }, [pathname, toggleMenu]);

    return (
      <div
        className={cx("menu-wrapper", {
          "is-open": menuOpen,
          "is-padded": padded,
        })}
      >
        <div id="menu-wrapper-backdrop" onClick={onClose} />

        <div className="menu-wrapper-menu">
          {menuComponent}
          <div className="menu-wrapper-button">
            {menuOpen ? (
              <Button action onClick={onClose}>
                <Close />
              </Button>
            ) : (
              <Button action onClick={onOpen}>
                {icon}
              </Button>
            )}
          </div>
        </div>
        <div className="menu-wrapper-content" ref={innerRef}>
          {children}
        </div>
      </div>
    );
  }
);
