import React, { useState, useCallback, useRef } from "react";
import {
  EcomStore as EcomStoreInterface,
  EcomType,
  ProductType,
} from "../../../../../data/models/ContractTypes";
import { Form } from "../../../../../components/form/Form";
import { Checkboxes } from "../../../../../components/interactions/Checkboxes/Checkboxes";
import { useTranslation } from "react-i18next";
import { AnimateHeight } from "../../../../../components/animate/AnimateHeight";
import { TextInput } from "../../../../../components/form/TextInput";
import { EmailBackendValidator } from "../../../../../components/form/validators/EmailBackendValidator";
import { EmailValidator } from "../../../../../components/form/validators/EmailValidator";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { useRecoilValue } from "recoil";
import {
  contractState,
  disabledState,
} from "../../../../../state/contractState";
import { NumberInput } from "../../../../../components/form/NumberInput";
import { MinValidator } from "../../../../../components/form/validators/MinValidator";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import { FormName } from "../../menus/ContractEditMenu";
import { HiddenInput } from "../../../../../components/form/HiddenInput";
import { CurrencyByCountry } from "../../../../../i18n";
import { useContractId } from "../../../../../hooks/useContractId";
import { ecomStoreQueue } from "../../../../../data/queues/dataQueues";
import { dataStores } from "../../../../../data/dataStores";
import { EcomSelection } from "./eCom/EcomSelection";
import { Products } from "../../../../../components/products/Products";
import {
  SaveProps,
  UpdateWrapper,
} from "../../../../../components/errors/UpdateWrapper";
import { useSuspenseQuery } from "@tanstack/react-query";
import { InfoBox } from "../../../../../components/boxes/InfoBox";
import { T } from "../../../../../components/translation/T";
import { WebsiteValidator } from "../../../../../components/form/validators/WebsiteValidator";

interface Props {}

function addPrefix(url: string) {
  if (!url) {
    return "";
  }

  if (url.startsWith("https://")) {
    return url;
  }

  if (url.startsWith("http://")) {
    return url;
  }

  return `https://${url}`;
}

function stripPrefix(url: string) {
  if (!url) {
    return;
  }

  if (url.startsWith("https://")) {
    return url.substring("https://".length, url.length);
  }

  if (url.startsWith("http://")) {
    return url.substring("http://".length, url.length);
  }

  return url;
}

export const EcomStore: React.FunctionComponent<Props> = () => {
  const contractId = useContractId();

  return (
    <UpdateWrapper
      contractId={contractId}
      dataQueue={ecomStoreQueue}
      queryKey={dataStores.getEcomStoresKey(contractId)}
    >
      {(trySave, isSaving, data) => (
        <EcomStoreInner isSaving={isSaving} data={data} trySave={trySave} />
      )}
    </UpdateWrapper>
  );
};

export const EcomStoreInner: React.FunctionComponent<
  Props & SaveProps<EcomStoreInterface>
> = ({ trySave }) => {
  const contract = useRecoilValue(contractState);
  const disabled = useRecoilValue(disabledState);
  const contractId = useContractId();

  const { data: store } = useSuspenseQuery(
    dataStores.fetchEcomStore(contractId)
  );

  const [ecomStore, setEcomStore] = useState<EcomStoreInterface>(store);
  const [enableInvoicePayments, setEnableInvoicePayments] = useState<boolean>();
  const { t } = useTranslation();
  const ref = useRef<HTMLFormElement>(null);

  const onSettingsChange = useCallback((value: any, name: string) => {
    setEcomStore((prev) => ({
      ...prev,
      invoice: {
        ...prev.invoice,
        [name]: value,
      },
    }));
  }, []);

  const onUpdate = useCallback(
    (type: EcomType) => {
      setEcomStore((prev) => {
        const update = { ...prev, type };
        trySave(update);
        return update;
      });
    },
    [trySave]
  );

  return (
    <div>
      <Form
        onSaveTrigger={(_, form) => {
          const realErrors = hasRealErrors(form);
          if (!realErrors) {
            trySave(ecomStore);
          }
        }}
        ref={ref}
        name={FormName.STORE}
      >
        <EcomSelection
          selectedEcomType={store.type}
          onUpdate={onUpdate}
          disabled={disabled}
        />

        <div className="m-top-10">
          <HiddenInput
            label=""
            value={!!store.type ? true : undefined}
            scrollToRef={ref}
            validators={[
              new RequiredValidator("You need to select either App or Web"),
            ]}
          />
        </div>

        <div className="m-top-40">
          <InfoBox relative>
            <T>Merchant website is required for all Ecom products</T>
          </InfoBox>

          <div className="m-top-10">
            <TextInput
              value={stripPrefix(ecomStore.url)}
              placeholder="example.com"
              label="Merchant website"
              onChange={(value) => {
                setEcomStore((prev) => ({
                  ...prev,
                  url: addPrefix(value),
                }));
              }}
              validators={[
                new RequiredValidator("Required to answer"),
                new WebsiteValidator("Website format is invalid", true),
              ]}
              disabled={disabled}
              preIcon={{
                icon: "https://",
                width: 85,
              }}
            />
          </div>
        </div>

        <div className="m-top-40">
          <Products productType={contract.productType}>
            <Products.Product products={[ProductType.CHECKOUT]}>
              <Checkboxes
                name="enableInvoicePayments"
                label={t("Enable invoice payments in Worldline Checkout")}
                alternatives={[
                  {
                    value: "yes",
                    text: t("Yes"),
                  },
                ]}
                values={enableInvoicePayments ? ["yes"] : []}
                onChange={() => setEnableInvoicePayments((prev) => !prev)}
                disabled={disabled || !ecomStore.type}
              />

              <HiddenInput
                label=""
                value={
                  enableInvoicePayments === undefined
                    ? true
                    : enableInvoicePayments
                }
                scrollToRef={ref}
                validators={[new RequiredValidator("Placeholder")]}
              />
            </Products.Product>
          </Products>
        </div>

        <AnimateHeight name={enableInvoicePayments ? "yes" : "no"}>
          <div>
            {enableInvoicePayments && (
              <>
                <TextInput
                  onChange={onSettingsChange}
                  name="senderName"
                  label="Company name on invoice"
                  value={ecomStore.invoice?.senderName}
                  validators={[
                    new RequiredValidator("Company name is required"),
                  ]}
                  disabled={disabled}
                />
                <TextInput
                  onChange={onSettingsChange}
                  name="contactEmail"
                  label="Email address to company support"
                  value={ecomStore.invoice?.contactEmail}
                  validators={[
                    new RequiredValidator("Email is required"),
                    new EmailValidator("Email is not valid"),
                    new EmailBackendValidator("Email is not valid"),
                  ]}
                  disabled={disabled}
                />

                <div className="m-top-20">
                  <NumberInput
                    onChange={onSettingsChange}
                    name="totalTurnover"
                    label="Total turnover (for invoice payments)"
                    value={ecomStore.invoice?.totalTurnover}
                    suffix={` ${CurrencyByCountry[contract.country]}`}
                    validators={[
                      new RequiredValidator("Total turnover is required"),
                      new MinValidator(
                        0,
                        "Total turnover must be a positive value"
                      ),
                    ]}
                    disabled={disabled}
                  />
                </div>
                <div className="m-top-20">
                  <NumberInput
                    onChange={onSettingsChange}
                    name="averageTurnover"
                    label="Average turnover (for invoice payments)"
                    value={ecomStore.invoice?.averageTurnover}
                    suffix={` ${CurrencyByCountry[contract.country]}`}
                    validators={[
                      new RequiredValidator("Average turnover is required"),
                      new MinValidator(
                        0,
                        "Average turnover must be a positive value"
                      ),
                    ]}
                    disabled={disabled}
                  />
                </div>
              </>
            )}
          </div>
        </AnimateHeight>
      </Form>
    </div>
  );
};
