import { ServerError } from "../../network/API";
import { dataAssociates } from "../dataAssociates";
import { Associate } from "../models/AssociateTypes";
import {
  AssociateId,
  Cas,
  ContractId,
  DEFAULT_CAS,
  ONGOING_RESPONSE,
} from "../models/CommonTypes";

interface AssociateCas {
  associate: Cas;
}

interface AssociateQueueProps {
  contractId: ContractId;
  associate: Associate;
  resolve: (data: Associate[]) => void;
  reject: (error: ServerError<Associate[]> | typeof ONGOING_RESPONSE) => void;
}

class CasStorage {
  storage = new Map<AssociateId, AssociateCas>();

  getCas(associate: Associate): AssociateCas {
    const associatedCas = this.storage.get(associate.associateId);
    return {
      associate: (associate.cas as Cas) || (DEFAULT_CAS as Cas),
      ...associatedCas,
    };
  }

  setCas(associate: Associate) {
    this.storage.set(associate.associateId, {
      associate: associate.cas,
    });
  }

  setCasByResponse(associates: Associate[]) {
    associates.forEach((associate) => this.setCas(associate));
  }
}

class Associates {
  private queue: AssociateQueueProps[] = [];
  private isRequesting: AssociateId | null = null;
  private casStorage = new CasStorage();

  private isInQueue(associate: Associate) {
    return !!this.queue.find(
      (associateInQueue) =>
        associateInQueue.associate.associateId === associate.associateId
    );
  }

  public saveAssociate(contractId: ContractId, associate: Associate) {
    return new Promise<
      Associate[] | ServerError<Associate[]> | typeof ONGOING_RESPONSE
    >((resolve, reject) => {
      if (this.isRequesting) {
        this.queue = this.queue.filter(
          (associateInQueue) =>
            associateInQueue.associate.associateId !== associate.associateId
        );

        this.queue.push({
          contractId,
          associate,
          resolve,
          reject,
        });
        return;
      }

      if (!this.queue.length) {
        this.postAssociate(contractId, associate, resolve, reject);
        return;
      }

      const next = this.queue.pop();
      if (next) {
        this.postAssociate(
          next.contractId,
          next.associate,
          next.resolve,
          next.reject
        );
      }
    });
  }

  private postAssociate(
    contractId: ContractId,
    associate: Associate,
    resolve: (data: Associate[]) => void,
    reject: (error: ServerError<Associate[]> | typeof ONGOING_RESPONSE) => void
  ) {
    this.isRequesting = associate.associateId;

    getPromise(contractId, associate, this.casStorage.getCas(associate))
      .then((response) => {
        this.casStorage.setCasByResponse(response);
        const hasRecentUpdate = this.isInQueue(associate);
        this.isRequesting = null;

        const next = this.queue.pop();
        if (next) {
          this.postAssociate(
            next.contractId,
            next.associate,
            next.resolve,
            next.reject
          );
        }

        hasRecentUpdate ? reject(ONGOING_RESPONSE) : resolve(response);
      })
      .catch((err) => {
        const hasRecentUpdate = this.isInQueue(associate);
        this.isRequesting = null;

        const next = this.queue.pop();
        if (next) {
          this.postAssociate(
            next.contractId,
            next.associate,
            next.resolve,
            next.reject
          );
        }

        hasRecentUpdate ? reject(ONGOING_RESPONSE) : reject(err);
      });
  }

  public deleteAssociate(contractId: ContractId, associateId: AssociateId) {
    return dataAssociates.removeAssociate(contractId, associateId);
  }
}

function getPromise(
  contractId: ContractId,
  associate: Associate,
  associateCas: AssociateCas
): Promise<Associate[]> {
  return dataAssociates.saveAssociate(contractId, {
    ...associate,
    cas: associateCas.associate,
  });

  // if (saveType === SaveType.OWNER && associate.owner) {
  //   return dataAssociates.updateAssociateOwner(
  //     contractId,
  //     associate,
  //     associateCas.owner
  //   );
  // }

  // if (saveType === SaveType.ID && associate.identity) {
  //   return dataAssociates.updateAssociateIdentity(
  //     contractId,
  //     associate,
  //     associateCas.id
  //   );
  // }

  // if (saveType === SaveType.ROLES) {
  //   return dataAssociates.updateAssociateRoles(
  //     contractId,
  //     associate,
  //     associateCas.associate
  //   );
  // }

  // return Promise.resolve(associate);
}

export const associateQueue = new Associates();
