import { useMemo } from "react";
import { PHONE_COUNTRY_CODES } from "../form/validators/PhoneCountryCodes";
import {
  PhoneValidatorResponseCountry,
  PhoneValidatorResponseName,
  getCountryFromPhoneNumber,
} from "../form/validators/PhoneToCountryCode";
import { Flag } from "../flags/Flag";
import { useTranslation } from "react-i18next";
import { getCountryDisplayName } from "../translation/i18nUtils";
import styles from "./PhoneNumberCountry.module.scss";

interface Props {
  phoneNumber?: string;
}

const MIN_NUMBER_OF_CHARS = 3;

export const PhoneNumberCountry = ({ phoneNumber }: Props) => {
  const { i18n } = useTranslation();

  const country = useMemo(() => {
    if (!phoneNumber) {
      return;
    }

    if (phoneNumber.length < MIN_NUMBER_OF_CHARS) {
      return;
    }

    const searchItem = getCountryFromPhoneNumber(phoneNumber);

    if (!searchItem.valid) {
      return getCountryNameFromPhoneNumber(
        phoneNumber,
        Math.max(phoneNumber.length, 5)
      );
    }

    const phoneCountry =
      (searchItem as PhoneValidatorResponseCountry).country ||
      (searchItem as PhoneValidatorResponseName).name;

    return (
      <div className={styles.flag}>
        <Flag rounded country={phoneCountry} height={20} />{" "}
        {getCountryDisplayName(phoneCountry, i18n.language)}
      </div>
    );
  }, [phoneNumber, i18n]);

  return <>{country}</>;
};

const getCountryNameFromPhoneNumber = (phoneNumber: string, length: number) => {
  for (let i = length; i >= 1; i--) {
    const code = phoneNumber.substring(0, i);
    const country: string | undefined = PHONE_COUNTRY_CODES[code];
    if (country) {
      return country;
    }
  }
};
