import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router";
import { Beacon } from "../../../components/beacon/Beacon";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Edit } from "../../../components/icons/Edit";
import { Pending } from "../../../components/icons/Pending";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Input } from "../../../components/interactions/Input/Input";
import { Overlay } from "../../../components/overlay/Overlay";
import { Table } from "../../../components/table/Table";
import {
  AdminMerchantCategory,
  dataMccAdministration,
} from "../../../data/dataMccAdministration";
import { MccRiskLevel, Validity } from "../../../data/models/ContractTypes";
import { Status } from "../../../data/types";
import { Subpage } from "../../Subpage";
import { AddMccModal } from "./AddMccModal";
import { MCC_EDIT_PAGE } from "./EditMccPage";
import "./mcc.scss";

export const MCC_ADMIN_PAGE = "/manager/mcc";

export const MccAdministrationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [codes, setCodes] = useState<AdminMerchantCategory[]>([]);
  const [mccFilter, setMccFilter] = useState<string>("");
  const [sortField, setSortField] = useState("code");
  const [sortDirection, setSortDirection] = useState<
    "ascending" | "descending"
  >("ascending");

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleUploadClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const onNavigate = useCallback(
    (data: any) => {
      navigate(generatePath(MCC_EDIT_PAGE, { id: data.id }));
    },
    [navigate]
  );

  const [loading, setLoading] = useState(false);
  const updateData = useCallback(() => {
    setLoading(true);
    dataMccAdministration
      .loadAll()
      .then(setCodes)
      .finally(() => setLoading(false));
  }, [setCodes, setLoading]);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const sortField = search.get("sortField");
    const sortDirection = search.get("sortDirection");

    if (sortField !== null) {
      setSortField(sortField);
    }
    if (
      sortDirection !== null &&
      (sortDirection === "ascending" || sortDirection === "descending")
    ) {
      setSortDirection(sortDirection);
    }
    updateData();
  }, [updateData, setSortDirection, setSortField, location]);

  const prettyPrint = (riskLevel: MccRiskLevel) => {
    switch (riskLevel) {
      case MccRiskLevel.NONE:
        return <span>None</span>;
      case MccRiskLevel.NOT_ALLOWED:
        return (
          <span>
            <Beacon mini validity={Validity.MISSING} />
            Not Allowed
          </span>
        );
      case MccRiskLevel.RISK_APPROVAL:
        return (
          <span>
            <Beacon mini validity={Validity.PARTIAL} />
            Risk Approval
          </span>
        );
    }
  };

  const onSortChange = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const property = (ev.target as HTMLButtonElement).dataset.id || "name";
      setSortField(property);

      const search = new URLSearchParams(location.search);
      search.set("sortField", property);

      const direction =
        sortDirection === "ascending" ? "descending" : "ascending";
      search.set("sortDirection", direction);
      setSortDirection(direction);
      navigate({
        pathname: location.pathname,
        search: "?" + search.toString(),
      });
    },
    [navigate, location, sortDirection]
  );

  const isSorted = (property: string) => {
    if (sortField === property) {
      return sortDirection;
    }
    return undefined;
  };

  return (
    <Subpage title="MCC Administration" className="mcc-admin">
      <Overlay open={open} onClose={onClose}>
        <AddMccModal onSave={updateData} onClose={onClose} />
      </Overlay>

      <section>
        <article>
          <FieldSet header="MCC Administration">
            <div className="tablet-columns">
              <div className="column">
                <Input
                  name="mccFilter"
                  value={mccFilter}
                  placeholder="Filter MCC"
                  status={loading ? Status.DISABLED : Status.DEFAULT}
                  onChange={(value) => {
                    setMccFilter(value);
                  }}
                />
              </div>
              <div className="tablet-columns buttons">
                <div className="column m-bottom-20">
                  <Button
                    block
                    onClick={onOpen}
                    status={loading ? Status.DISABLED : Status.DEFAULT}
                  >
                    Add MCC
                  </Button>
                </div>
                <div className="column m-bottom-20">
                  <label htmlFor="document">
                    <Button
                      block
                      ghost
                      onClick={handleUploadClick}
                      status={loading ? Status.DISABLED : Status.DEFAULT}
                    >
                      Upload Excel
                    </Button>
                  </label>
                  <input
                    type="file"
                    onChange={(event) => {
                      if (!event.target.files) {
                        return;
                      }
                      const selectedFile = event.target.files[0];
                      setLoading(true);

                      const formData = new FormData();

                      formData.append(
                        "document",
                        selectedFile,
                        selectedFile.name
                      );

                      dataMccAdministration
                        .uploadExcel(formData)
                        .then(() => {
                          updateData();
                        })
                        .finally(() => setLoading(false));
                    }}
                    ref={hiddenFileInput}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: "none" }}
                    id="document"
                  />
                </div>
              </div>
            </div>
          </FieldSet>
        </article>
      </section>

      <section>
        <article>
          <hr />
          <Table>
            <thead>
              <tr>
                <th />
                <th>
                  <button
                    className={isSorted("code")}
                    onClick={onSortChange}
                    data-id="code"
                  >
                    MCC
                  </button>
                </th>
                <th>
                  <button
                    className={isSorted("name")}
                    onClick={onSortChange}
                    data-id="name"
                  >
                    Name
                  </button>
                </th>
                <th>
                  <button
                    className={isSorted("faceToFace")}
                    onClick={onSortChange}
                    data-id="faceToFace"
                  >
                    Face to Face
                  </button>
                </th>
                <th>
                  <button
                    className={isSorted("cardNotPresent")}
                    onClick={onSortChange}
                    data-id="cardNotPresent"
                  >
                    Card not present
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {codes
                .filter((row) => {
                  if (mccFilter.length > 0) {
                    return (
                      row.code.indexOf(mccFilter) > -1 ||
                      row.name.indexOf(mccFilter) > -1 ||
                      row.category.indexOf(mccFilter) > -1
                    );
                  }
                  return true;
                })
                .sort((r1, r2) => {
                  if (sortField.length === 0) {
                    return 0;
                  }

                  const val1 = r1[
                    sortField as keyof AdminMerchantCategory
                  ] as string;
                  const val2 = r2[
                    sortField as keyof AdminMerchantCategory
                  ] as string;

                  if (val1 > val2) {
                    return 1 * (sortDirection === "descending" ? -1 : 1);
                  }
                  if (val1 < val2) {
                    return -1 * (sortDirection === "descending" ? -1 : 1);
                  }
                  return 0;
                })
                .map((row) => {
                  const className = cx({
                    error:
                      row.faceToFace === MccRiskLevel.NOT_ALLOWED &&
                      row.cardNotPresent === MccRiskLevel.NOT_ALLOWED,
                    warning:
                      row.faceToFace === MccRiskLevel.RISK_APPROVAL &&
                      row.cardNotPresent === MccRiskLevel.RISK_APPROVAL,
                  });

                  return (
                    <tr key={row.id} className={className}>
                      <td>
                        <Button
                          action
                          className="mini"
                          data={{ id: row.id }}
                          onClick={onNavigate}
                        >
                          <Edit />
                        </Button>
                      </td>
                      <td>{row.code}</td>
                      <td>{row.name}</td>
                      <td
                        className={cx({
                          error: row.faceToFace === MccRiskLevel.NOT_ALLOWED,
                          warning:
                            row.faceToFace === MccRiskLevel.RISK_APPROVAL,
                        })}
                      >
                        {prettyPrint(row.faceToFace)}
                      </td>
                      <td
                        className={cx({
                          error:
                            row.cardNotPresent === MccRiskLevel.NOT_ALLOWED,
                          warning:
                            row.cardNotPresent === MccRiskLevel.RISK_APPROVAL,
                        })}
                      >
                        {prettyPrint(row.cardNotPresent)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {loading ? (
            <div className="center">
              <Pending />
            </div>
          ) : codes.length === 0 ? (
            "No codes available"
          ) : null}
          <hr />
        </article>
      </section>
    </Subpage>
  );
};
