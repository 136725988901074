import React from "react";
import cx from "classnames";
import {
  AssociateTracking,
  BankAccount,
  Contract,
  ContractDocument,
  EcomStore,
} from "../../../../../data/models/ContractTypes";
import {
  TIMELINE_START,
  TIMELINE_CREATED,
  TIMELINE_CONFIRMED_BY_MERCHANT,
  TIMELINE_BANK_ACCOUNT,
  TIMELINE_DOCUMENT,
  getTimelineDocsStart,
} from "../Timeline";
import { Associate } from "../../../../../data/models/AssociateTypes";

interface Props {
  active: boolean;
  primaryTracking?: AssociateTracking;
  contract: Contract;
  bankAccount?: BankAccount;
  documents: ContractDocument[];
  ecomStore?: EcomStore;
  associates: Associate[];
}

export const IndicatorDConfirmedByMerchant: React.FunctionComponent<Props> = ({
  active,
  primaryTracking,
  contract,
  bankAccount,
  documents,
  ecomStore,
  associates,
}) => {
  const start = TIMELINE_START + TIMELINE_CREATED;
  const confirmedByMerchant = getTimelineDocsStart(
    contract,
    associates,
    documents,
    primaryTracking,
    ecomStore,
    bankAccount
  );

  const startOfAssociates =
    confirmedByMerchant +
    TIMELINE_CONFIRMED_BY_MERCHANT +
    TIMELINE_BANK_ACCOUNT +
    TIMELINE_DOCUMENT * documents.length;

  return (
    <>
      <line
        x1="10"
        x2="10"
        y1={start}
        y2={confirmedByMerchant}
        className={cx({ active })}
      />

      <line
        x1="10"
        x2="10"
        y1={confirmedByMerchant}
        y2={startOfAssociates}
        className={cx({ active })}
      />

      <circle
        r="4"
        cx="10"
        cy={confirmedByMerchant}
        className={cx({ active })}
      />
    </>
  );
};
