import React from "react";
import { TIMELINE_CREATED } from "../Timeline";

interface Props {
  createdDate: string;
}

export const EventACreated: React.FunctionComponent<Props> = ({
  createdDate,
}) => {
  return (
    <li
      className="active"
      style={{
        height: TIMELINE_CREATED,
      }}
    >
      <div className="event">
        <div className="timeline-date">{createdDate}</div>
        <b>Contract created</b>
      </div>
    </li>
  );
};
