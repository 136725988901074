import React from "react";
import cx from "classnames";
import { ReactNode } from "react";
import { motion } from "framer-motion";
import "./Collapsible.scss";

interface Props {
  children: ReactNode;
  className?: string;
  collapsed: boolean;
  collapsedHeight?: number;
}

export const Collapsible: React.FunctionComponent<Props> = ({
  children,
  className,
  collapsed,
  collapsedHeight = 150,
}) => {
  return (
    <motion.div
      className={cx("collapsible", className, {
        "is-collapsed": collapsed,
      })}
      animate={{ height: collapsed ? collapsedHeight : "auto" }}
      transition={{ type: "spring", duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};
