export function getCardLabels(t: any) {
  return {
    debitDomestic: t("Debit domestic"),
    debitEEA: t("Debit EEA"),
    debitOutsideEEA: t("Debit outside EEA"),
    creditDomestic: t("Credit domestic"),
    creditEEA: t("Credit EEA"),
    creditOutsideEEA: t("Credit outside EEA"),
    commercialDomestic: t("Commercial cards domestic"),
    commercialEEA: t("Commercial cards EEA"),
    commercialOutsideEEA: t("Commercial cards outside EEA"),
    corporationCard: t("Corporation cards"),
    allOther: t("All other cards"),
    monthlyCost: t("Monthly costs"),
    monthlyFeePerTerminal: t("Monthly fee/terminal"),
    transactionFee: t("Transactional fee"),
    initialFee: t("Initial fee"),
    additionalMonths: t("Additional months"),
    monthlyFeeWireless: t("Monthly fee/wireless terminal"),
    monthlyFeeWirelessIntegrated: t("Monthly fee/wireless integrated terminal"),
    monthlyFeeWiredIntegrated: t("Monthly fee/wired integrated terminal"),
    shippingPerTerminal: t("Shipping cost/terminal"),
  };
}
