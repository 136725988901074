import { Country } from "../i18n";
import { data } from "./data";

const BASE_PATH = "/api/sales/location";

export interface LocationSuggestionResult {
  text: string;
}

export interface LocationLookupResult {
  postalCode?: string;
  street?: string;
  city?: string;
  countryCode?: Country;
  streetNumber?: string;
}

export const dataLocationLookup = {
  getSuggestions: (text: string) =>
    data.post<LocationSuggestionResult[]>(`${BASE_PATH}/suggestions`, {
      text,
    }),

  getLocation: (text: string) =>
    data.post<LocationLookupResult>(`${BASE_PATH}/lookup`, {
      text,
    }),
};
