import React, { useState, useCallback, useRef } from "react";
import cx from "classnames";
import { Status } from "../../../data/types";
import { API } from "../../../network/API";
import { useIsMountedRef } from "../../../hooks/useIsMounted";
import { Error } from "../../../components/icons/Error";
import { Visible } from "../../../components/visible/Visible";
import { useTranslation } from "react-i18next";
import {
  Progress,
  ProgressInterface,
} from "../../../components/fileUpload/Progress";
import "./UploadTranslations.scss";
import { id } from "../../../components/utils";

const UPLOAD_URL = "/api/admin/internal/translations/excel/upload";

export const UploadTranslations: React.FunctionComponent = () => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const identifier = useRef<string>(id());
  const fileElemRef = useRef<HTMLInputElement>(null);
  const mounted = useIsMountedRef();
  const [name, setName] = useState<string>("");
  const { i18n } = useTranslation();

  const [progress, setProgress] = useState<ProgressInterface>({
    progress: 0,
    name: "",
  });

  const upload = useCallback(
    (targetFile: File) => {
      if (!targetFile) {
        return;
      }

      setStatus(Status.PENDING);
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          setProgress({
            progress: (event.loaded / event.total) * 100,
            name: targetFile.name,
          });
        }
      });

      req.onload = function () {
        if (req.status === 200) {
          setProgress({
            progress: 100,
            name: targetFile.name,
          });

          setTimeout(() => {
            if (!mounted.current) {
              return;
            }
            setName(
              `${targetFile.name} - ${new Date().toLocaleTimeString(
                i18n.language,
                {
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}`
            );
            setStatus(Status.DEFAULT);
          }, 4000);
        } else {
          setStatus(Status.ERROR);
          setTimeout(() => setStatus(Status.DEFAULT), 4000);
        }
      };

      const formData = new FormData();
      formData.append("document", targetFile);
      req.open("POST", API.getUrl(UPLOAD_URL));
      req.withCredentials = true;
      req.send(formData);
    },
    [mounted, i18n.language]
  );

  const onAttachFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const targetFile = ev.target.files?.[0];

      if (!targetFile) {
        return;
      }

      setName("");
      upload(targetFile);
    },
    [upload]
  );

  return (
    <>
      <div className={cx("translations-upload", status)}>
        <div className="doc-upload-button">
          <input
            type="file"
            id={identifier.current}
            name={identifier.current}
            onChange={onAttachFile}
            ref={fileElemRef}
            multiple={false}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <label htmlFor={identifier.current}>
            <span className={cx("button", "small", status)}>
              Upload translations
              {status === Status.ERROR && <Error />}
            </span>
          </label>

          <Progress {...progress} />
        </div>
      </div>

      <i className="small text-right">
        <Visible show={!!name}>{name}</Visible>
      </i>
    </>
  );
};
