import { ReactNode } from "react";
import { atom } from "recoil";

export interface SaveState {
  message?: string | ReactNode;
  date: Date;
}

export const contractSaveState = atom<SaveState[]>({
  key: "contract-save-state",
  default: [],
});

export const contractErrorState = atom<SaveState[]>({
  key: "contract-error-state",
  default: [],
});
