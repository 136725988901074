import React, {
  FunctionComponent,
  useRef,
  useEffect,
  useMemo,
  useReducer,
  ReactNode,
} from "react";
import "./Form.scss";
import { FormContainer } from "./Form";
import { FormValidationAction, MultiFormContext } from "./MultiFormContext";
import { scrollToError } from "./utils/scrollToError";

export class MultiFormContainer {
  public forms: FormContainer[] = [];
  public forceErrors = false;

  addForm(newInput: FormContainer) {
    const existingIdx = this.forms.findIndex((form) => form.id === newInput.id);
    if (existingIdx > -1) {
      this.forms.splice(existingIdx, 1, newInput);
    } else {
      this.forms.push(newInput);
    }
  }

  removeForm(formToRemove: FormContainer) {
    const existingIdx = this.forms.findIndex(
      (form) => form.id === formToRemove.id
    );
    if (existingIdx > -1) {
      this.forms.splice(existingIdx, 1);
    }
  }

  forceValidation() {
    this.forceErrors = true;
    this.forms.forEach((form) => form.setForceErrors(true));
    const firstError = this.forms
      .find((form) => form.isInvalid)
      ?.inputs.find((input) => input.isInvalid);

    if (firstError) {
      return scrollToError(firstError.scrollToRef);
    }
  }

  resetValidation() {
    this.forceErrors = false;
    this.forms.forEach((form) => form.resetValidation());
  }

  get isValid(): boolean {
    return this.forms.every((form) => form.isValid);
  }

  get isInvalid(): boolean {
    return !this.forms.every((form) => form.isValid);
  }
}

type FormValidity = Record<string, boolean>;

function validityReducer(state: FormValidity, dispatch: FormValidationAction) {
  if (dispatch.action === "SET") {
    if (state[dispatch.form] === dispatch.isValid) {
      return state;
    }
    return { ...state, [dispatch.form]: dispatch.isValid };
  } else {
    const copy = { ...state };
    delete copy[dispatch.form];
    return copy;
  }
}

interface Props {
  multiFormContainer?: React.MutableRefObject<MultiFormContainer | undefined>;
  children: ReactNode;
}

export const MultiForm: FunctionComponent<Props> = ({
  multiFormContainer,
  children,
}) => {
  const multiForm = useRef<MultiFormContainer>(new MultiFormContainer());
  const [validity, setValidity] = useReducer(validityReducer, {});

  useEffect(() => {
    if (multiFormContainer) {
      multiFormContainer.current = multiForm.current;
    }
  }, [multiFormContainer]);

  const value = useMemo(
    () => ({
      multiForm: multiForm.current,
      validity,
      setValidity,
    }),
    [validity]
  );

  return (
    <MultiFormContext.Provider value={value}>
      {children}
    </MultiFormContext.Provider>
  );
};
