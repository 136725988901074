import { FormContainer } from "./Form";
import { VALIDATION_STATE } from "./hooks/useValidation";

export function hasRealErrors(form: FormContainer) {
  return form.inputs.some(
    (input) =>
      typeof input.value !== "undefined" &&
      typeof input.value === "string" &&
      input.value.length > 0 &&
      input.validationState === VALIDATION_STATE.FAILED
  );
}
