import React, { useMemo } from "react";
import cx from "classnames";
import {
  BankAccount,
  ContractDocument,
} from "../../../../../data/models/ContractTypes";
import { TIMELINE_EVENT } from "../Timeline";
import { Associate } from "../../../../../data/models/AssociateTypes";

interface Props {
  associates: Associate[];
  documents: ContractDocument[];
  bankAccount?: BankAccount;
}

export const EventIComplete: React.FunctionComponent<Props> = ({
  associates,
  documents,
  bankAccount,
}) => {
  const active = useMemo(() => {
    const associatesDone = associates.every(
      (associate) => associate.signatory?.signed
    );

    const docsDone = documents.every((doc) => doc.confirmed);

    return bankAccount?.signedOff && associatesDone && docsDone;
  }, [associates, documents, bankAccount]);

  return (
    <li
      className={cx({
        active,
      })}
      style={{
        height: TIMELINE_EVENT,
      }}
    >
      <div className="event">
        <div className="timeline-date" /> <b>Contract complete in Wlx</b>
      </div>
    </li>
  );
};
