import React, { FunctionComponent, useState } from "react";
import { HiVariable } from "react-icons/hi";
import { Overlay } from "../../../../../components/overlay/Overlay";
import {
  InternalEmailProperties,
  InternalTextProperties,
} from "../../../../../data/dataCommunication";
import "./VariableList.scss";

const VariableDescription: Record<
  InternalEmailProperties | InternalTextProperties,
  string
> = {
  [InternalEmailProperties.NAME]: "Customer name",
  [InternalEmailProperties.COMPANY_NAME]: "Company name",
  [InternalEmailProperties.OTP]: "One-time password",
  [InternalTextProperties.LINK]: "Link",
};

interface Props {
  variables: InternalEmailProperties[] | InternalTextProperties[];
}

export const VariableList: FunctionComponent<Props> = ({ variables }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="variable-list">
      <button
        className="variable-icon-button"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        <HiVariable />
      </button>
      <Overlay open={isOpen} onClose={() => setIsOpen(false)}>
        <h2>Variables</h2>
        <dl>
          <dd>Variable</dd>
          <dt>Code</dt>
          {variables.map((variable) => (
            <React.Fragment key={variable}>
              <dt>{VariableDescription[variable]}</dt>
              <dd>
                <code>*|{variable}|*</code>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Overlay>
    </div>
  );
};
