import React, { useMemo } from "react";
import cx from "classnames";
import {
  getTimelineAssociatesStart,
  getTimelineComplete,
  TIMELINE_ASSOCIATE,
  TIMELINE_ASSOCIATES,
} from "../Timeline";
import {
  Associate,
  AssociateRole,
} from "../../../../../data/models/AssociateTypes";
import {
  AssociateTracking,
  BankAccount,
  Contract,
  ContractDocument,
  EcomStore,
} from "../../../../../data/models/ContractTypes";

interface Props {
  associates: Associate[];
  associate: Associate;
  index: number;
  confirmedByMerchant: boolean;
  primaryTracking?: AssociateTracking;
  contract: Contract;
  bankAccount?: BankAccount;
  documents: ContractDocument[];
  ecomStore?: EcomStore;
}

export const IndicatorHAssociate: React.FunctionComponent<Props> = ({
  associate,
  associates,
  index,
  confirmedByMerchant,
  primaryTracking,
  contract,
  bankAccount,
  documents,
  ecomStore,
}) => {
  const start = getTimelineAssociatesStart(
    contract,
    associates,
    documents,
    primaryTracking,
    ecomStore,
    bankAccount
  );
  const offset = index * TIMELINE_ASSOCIATE + TIMELINE_ASSOCIATES;
  const end = getTimelineComplete(
    contract,
    documents,
    associates,
    primaryTracking,
    ecomStore,
    bankAccount
  );

  const active = useMemo(() => {
    const isSignee = associate.roles.includes(AssociateRole.SELECTED_SIGNATORY);
    const isAccountHolder = associate.roles.includes(
      AssociateRole.ACCOUNT_HOLDER
    );

    if (isSignee && isAccountHolder) {
      return !!associate.signatory?.signed && !!bankAccount?.signedOff;
    }

    if (isSignee) {
      return !!associate.signatory?.signed;
    }

    return !!bankAccount?.signedOff;
  }, [associate, bankAccount]);

  return (
    <>
      <line
        x1="10"
        x2="50"
        y1={start - 6}
        y2={start + offset}
        className={cx({ active: confirmedByMerchant })}
      />
      <line
        x1="50"
        x2="10"
        y1={start + offset}
        y2={end}
        className={cx({ active })}
      />
      <circle
        r="3"
        cx="50"
        cy={start + offset}
        className={cx({ active: confirmedByMerchant })}
      />
    </>
  );
};
