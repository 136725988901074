import { useRecoilValue } from "recoil";
import { WarningBox } from "../../../../components/boxes/WarningBox";
import { T } from "../../../../components/translation/T";
import { contractState } from "../../../../state/contractState";
import styles from "./ReadOnly.module.scss";

export const ReadOnly = () => {
  const contract = useRecoilValue(contractState);

  if (contract.readOnly) {
    return (
      <div className={styles.readOnly}>
        <WarningBox relative>
          <T>This contract is set in read only. You can not edit it.</T>
        </WarningBox>
      </div>
    );
  }

  return null;
};
