import { FC } from "react";
import { Beacon } from "../../../../../../components/beacon/Beacon";
import { NumberInput } from "../../../../../../components/form/NumberInput";
import { MaxValidator } from "../../../../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../../../../components/form/validators/MinValidator";
import { RequiredValidator } from "../../../../../../components/form/validators/RequiredValidator";
import { T } from "../../../../../../components/translation/T";
import { Validity } from "../../../../../../data/models/ContractTypes";
import { ButtonPaneCollapse } from "../../ButtonPaneCollapse/ButtonPaneCollapse";

import styles from "./Membership.module.scss";
import cx from "classnames";
import {
  SubscriptionAndMembershipData,
  MembershipOptions,
  MEMBERSHIP_OPTIONS_LABEL_MAPPING,
} from "../../../../../../data/dataBusinessRisk";
import { useTranslation } from "react-i18next";

interface Props {
  hasMembership?: boolean;
  setHasMembership: (value: boolean | undefined) => void;
  membershipData?: SubscriptionAndMembershipData;
  onChangeMembershipData: (data: SubscriptionAndMembershipData) => void;
  disabled?: boolean;
}

const calculateTotalPercentageFilled = (
  data: SubscriptionAndMembershipData | undefined
) => {
  if (!data) return 0;
  const { salesAmount, ...rest } = data;
  const total = Object.values(rest).reduce((acc, value) => {
    if (value) return acc + value;
    return acc;
  }, 0);
  return total;
};

export const Membership: FC<Props> = ({
  hasMembership,
  setHasMembership,
  membershipData,
  onChangeMembershipData,
  disabled,
}) => {
  const filled = calculateTotalPercentageFilled(membershipData);
  const leftToFill = 100 - filled;
  const periodsAreFilled = 100 - filled === 0;
  const isCompleted = periodsAreFilled && membershipData?.salesAmount;
  const { t } = useTranslation();

  const handleInputChange = (key: string, value: number | undefined) => {
    const newMembershipData = {
      ...membershipData,
      [key]: value,
    };
    onChangeMembershipData(newMembershipData);
  };

  const formatLeftToFill = () => {
    if (periodsAreFilled) return null;
    if (leftToFill > 100) return "100%";
    return `${Math.abs(leftToFill)}%`;
  };

  const getRemainingText = () => {
    if (periodsAreFilled) return "completed";
    if (leftToFill < 0) return "to remove";
    return "remaining";
  };

  const hintClass = cx(styles.hint, {
    [styles.hintCompleted]: periodsAreFilled && membershipData?.salesAmount,
  });

  const getBeaconText = () => {
    if (periodsAreFilled && !membershipData?.salesAmount)
      return <T>Please provde the sales amount</T>;
    if (isCompleted) return <T>Completed!</T>;
    if (leftToFill < 0) return <T>The sum is more than 100 %</T>;
    return <T>The sum is less than 100 %</T>;
  };

  return (
    <ButtonPaneCollapse
      title={t("Subscription or Membership") || ""}
      label="Does your company offer subscription or membership sales?"
      expanded={hasMembership}
      value={hasMembership}
      onChange={setHasMembership}
      small
      disabled={disabled}
      buttons={[
        {
          text: "Yes",
          active: hasMembership === true,
          data: true,
        },
        {
          text: "No",
          active: hasMembership === false,
          data: false,
        },
      ]}
      validators={[new RequiredValidator("Required to answer")]}
    >
      <div className={styles.body}>
        <NumberInput
          value={membershipData?.salesAmount}
          placeholder={"%"}
          label={"Part of sales (%)"}
          suffix="%"
          onChange={(value) => handleInputChange("salesAmount", value)}
          attributes={{
            decimalScale: 0,
          }}
          disabled={disabled}
          validators={[
            new RequiredValidator("Required to answer"),
            new MaxValidator(100, "Max 100 %"),
            new MinValidator(0, "Require positive value"),
          ]}
        />
        <div className={styles.divider}>
          <p className={styles.text}>
            <T>How large part of these are active in:</T>
          </p>
          <hr className={styles.hr} />
        </div>

        <div className="tablet-columns">
          {Object.values(MembershipOptions).map((option) => (
            <div key={option}>
              <NumberInput
                value={membershipData?.[option]}
                placeholder={"%"}
                suffix="%"
                label={`${MEMBERSHIP_OPTIONS_LABEL_MAPPING[option]} (%)`}
                onChange={(value) => handleInputChange(option, value)}
                attributes={{
                  decimalScale: 0,
                }}
                disabled={disabled}
                validators={
                  membershipData?.[option]
                    ? [
                        new MaxValidator(100, "Max 100%"),
                        new MinValidator(0, "Require positive value"),
                      ]
                    : []
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.beacon}>
          <Beacon validity={isCompleted ? Validity.VALID : Validity.MISSING} />
          <div className={hintClass}>{getBeaconText()}</div>
        </div>
        <div>
          <div className={styles.filled}>{filled}%</div>
          <div className={styles.remaining}>
            {formatLeftToFill()} <T>{getRemainingText()}</T>
          </div>
        </div>
      </div>
    </ButtonPaneCollapse>
  );
};
