import React from "react";
import cx from "classnames";
import {
  TIMELINE_BANK_ACCOUNT,
  TIMELINE_DOCUMENT,
  getTimelineDocsStart,
  TIMELINE_CONFIRMED_BY_MERCHANT,
} from "../Timeline";
import {
  AssociateTracking,
  BankAccount,
  Contract,
  ContractDocument,
  EcomStore,
} from "../../../../../data/models/ContractTypes";
import { Associate } from "../../../../../data/models/AssociateTypes";

interface Props {
  active: boolean;
  associates: Associate[];
  index: number;
  primaryTracking?: AssociateTracking;
  contract: Contract;
  bankAccount?: BankAccount;
  documents: ContractDocument[];
  ecomStore?: EcomStore;
}

export const IndicatorFDocument: React.FunctionComponent<Props> = ({
  active,
  index,
  primaryTracking,
  contract,
  bankAccount,
  documents,
  ecomStore,
  associates,
}) => {
  const start =
    getTimelineDocsStart(
      contract,
      associates,
      documents,
      primaryTracking,
      ecomStore,
      bankAccount
    ) - 6;
  const offset = TIMELINE_BANK_ACCOUNT + TIMELINE_CONFIRMED_BY_MERCHANT;
  return (
    <>
      <line
        x1="10"
        x2="50"
        y1={start}
        y2={start + offset + index * TIMELINE_DOCUMENT}
        className={cx({ active })}
      />
      <circle
        r="3"
        cx="50"
        cy={start + offset + index * TIMELINE_DOCUMENT}
        className={cx({ active })}
      />
    </>
  );
};
