import React from "react";
import { AnimateHeightMotion } from "../../../../../components/animate/AnimateHeightMotion";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { ButtonPaneWithLabel } from "../../../../../components/interactions/Buttons/ButtonPaneInput";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";
import { T } from "../../../../../components/translation/T";

interface Props {
  listedEntity?: boolean;
  onListedEntityChange: (value: boolean | undefined) => void;
  disabled?: boolean;
}

export const ListedCompanyFields: React.FunctionComponent<Props> = ({
  onListedEntityChange,
  listedEntity,
  disabled,
}) => {
  return (
    <div>
      <ButtonPaneWithLabel
        label="Is the company or parent company listed on a regulated market in the EU/EEA?"
        value={listedEntity}
        className="m-top-20"
        onChange={(value) => onListedEntityChange(value)}
        small
        buttons={[
          {
            text: "Yes",
            active: listedEntity === true,
            data: true,
          },
          {
            text: "No",
            active: listedEntity === false,
            data: false,
          },
        ]}
        validators={[new RequiredValidator("Required to answer")]}
        disabled={disabled}
      />

      <AnimateHeightMotion presence>
        {listedEntity && (
          <ErrorBox relative>
            <T>
              There is currently no support for companies supervised by a
              regulatory authority in Wlx. This company will have to be handled
              manually or through a different onboarding process
            </T>
          </ErrorBox>
        )}
      </AnimateHeightMotion>

      {/* <AnimateHeightMotion presence>
        {listedEntity && (
          <div className="tablet-columns">
            <div>
              <TextInput
                name="listedTickerName"
                value={listedTickerName}
                onChange={(value) => onUpdate(value, "listedTickerName")}
                label="Ticker name"
                validators={[new RequiredValidator("Ticker name is required")]}
                disabled={disabled}
              />
            </div>
            <div>
              <Select
                name="listedMarket"
                value={listedMarket}
                alternatives={[
                  {
                    value: "",
                    text: "Select market",
                    disabled: true,
                  },
                  ...marketAlternatives,
                ]}
                onChange={(value) => onUpdate(value, "listedMarket")}
                label="Market"
                validators={[new RequiredValidator("Market is required")]}
                disabled={disabled}
              />
            </div>
          </div>
        )}
      </AnimateHeightMotion> */}
    </div>
  );
};
