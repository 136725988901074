import React, { useMemo, useRef, useState } from "react";
import cx from "classnames";
import "./StoreRow.scss";
import styles from "./StoreRow.module.scss";
import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import {
  ProductType,
  Store,
  Validity,
} from "../../../../../data/models/ContractTypes";
import { Status } from "../../../../../data/types";
import { FormContainer } from "../../../../../components/form/Form";
import { isEcom } from "../../../../../data/dataContracts";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Trash as TrashIcon } from "../../../../../components/icons/Trash";
import { Expand as ExpandIcon } from "../../../../../components/icons/Expand";
import { Beacon } from "../../../../../components/beacon/Beacon";
import { Location } from "../../../../../components/icons/Location";
import { T } from "../../../../../components/translation/T";
import { StoreForm } from "../storeForm/StoreForm";
import { contractState } from "../../../../../state/contractState";
import { FormName } from "../../menus/ContractEditMenu";
import { useFormValidity } from "../../../../../components/form/MultiFormContext";
import { StoreId } from "../../../../../data/models/CommonTypes";

interface Props {
  store: Store;
  hasMultipleStores: boolean;
  onClick: () => void;
  isActive: boolean;
  status: Status;
  onDelete: (storeId: StoreId) => void;
}

export const StoreRow: React.FunctionComponent<Props> = ({
  store: originalStore,
  onClick,
  isActive = false,
  status,
  hasMultipleStores,
  onDelete,
}) => {
  const contract = useRecoilValue(contractState);
  const formContainer = useRef<FormContainer>();
  const formName = useRef<string>(`${FormName.STORE}-${crypto.randomUUID()}`);
  const validity = useFormValidity(formName.current);
  const [store, setStore] = useState<Store>(originalStore);

  const storeHeader = useMemo(() => {
    if (isEcom(contract)) {
      return contract.productType === ProductType.CHECKOUT ? (
        <T>Checkout settings</T>
      ) : (
        <T>Acceptance online</T>
      );
    } else {
      return store.commercialName || <T>No name set</T>;
    }
  }, [store, contract]);

  return (
    <div className={styles.inner}>
      <div className={styles.header} onClick={onClick}>
        <Beacon
          validity={
            contract.productType === ProductType.ACCEPTANCE_ONLINE
              ? Validity.VALID
              : validity
          }
          icon={<Location />}
        />
        <div className={styles.headerInfo}>
          <span>{storeHeader}</span>
          <span className={styles.shortLocationAddress}>
            {store.address?.street}{" "}
            {store.address?.street && store.address?.city && ","}{" "}
            {store.address?.city}
          </span>
        </div>
        <div>
          {hasMultipleStores && (
            <motion.button
              className={cx(
                "button",
                "action",
                "small",
                "danger-button",
                status
              )}
              disabled={status === Status.DISABLED}
              whileHover={{ rotate: [null, 20, -20, 0] }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(store.storeId);
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <TrashIcon />
            </motion.button>
          )}
          <Button onClick={() => {}} action ghost className={cx("small")}>
            <motion.div
              className="icon"
              animate={{ rotate: isActive ? 180 : 0 }}
            >
              <ExpandIcon />
            </motion.div>
          </Button>
        </div>
      </div>

      <div
        className={cx(styles.body, {
          [styles.active]: isActive,
        })}
      >
        <div className={styles.form}>
          <StoreForm
            formName={formName.current}
            key={store.storeId}
            setStore={setStore}
            store={store}
            formContainer={formContainer}
          />
        </div>
      </div>
    </div>
  );
};
