export const loadImage = (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = () => reject();
    img.src = url;
  });
};

export const loadPdf = (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const reader = new FileReader();
        reader.onloadend = () => {
          const arrayBuffer = reader.result as ArrayBuffer;
          if (arrayBuffer.byteLength > 0) {
            resolve();
          } else {
            reject();
          }
        };
        reader.onerror = () => reject();
        reader.readAsArrayBuffer(blob);
      } else {
        reject();
      }
    };
    xhr.onerror = () => reject();
    xhr.withCredentials = true;
    xhr.send();
  });
};
