import { useRef } from "react";
import { useScrollPosition } from "./useScrollPosition";

interface Props {
  id: string;
}

export const ScrollPositionAnchor: React.FunctionComponent<Props> = ({
  id,
}) => {
  const ref = useRef<HTMLElement>(null);
  useScrollPosition(id, ref);

  return <span id={id} ref={ref} />;
};
