import styles from "../Offering.module.scss";
import { ReactElement } from "react";
import { T } from "../../../../../components/translation/T";
import { HiOutlineGlobeAlt as GlobeIcon } from "react-icons/hi";
import { BiStoreAlt as StoreIcon } from "react-icons/bi";
import { FunctionComponent } from "react";
import { ProductCard } from "./ProductCard";
import { Offer } from "../../../../../components/icons/Offer";
import { ProductType } from "../../../../../data/models/ContractTypes";

interface Props {
  selectedProductType?: ProductType;
}

export const Products: FunctionComponent<Props> = ({ selectedProductType }) => {
  return (
    <div>
      <div className={styles.sectionTitle}>
        <T>Product</T>
      </div>
      <div className={styles.products}>
        <ProductCard
          isSelected={true}
          {...PRODUCT_OFFERINGS[selectedProductType || ProductType.BAMBORA_ONE]}
          productType={selectedProductType || ProductType.BAMBORA_ONE}
        />
      </div>
    </div>
  );
};

export interface ProductOption {
  name: string;
  productType: ProductType;
  icon: ReactElement;
  description?: string;
}

export const PRODUCT_OFFERINGS: Record<ProductType, ProductOption> = {
  [ProductType.ACCEPTANCE_ONLINE]: {
    name: "Acceptance online",
    productType: ProductType.ACCEPTANCE_ONLINE,
    icon: <GlobeIcon />,
    description: "Standalone acquiring E-Com",
  },
  [ProductType.ACCEPTANCE_INSTORE]: {
    name: "Acceptance instore",
    productType: ProductType.ACCEPTANCE_INSTORE,
    icon: <Offer />,
    description: "Standalone acquiring instore",
  },
  [ProductType.BAMBORA_DEVICE]: {
    name: "Worldline device",
    productType: ProductType.BAMBORA_DEVICE,
    icon: <Offer />,
    description: "Terminal rental",
  },
  [ProductType.BAMBORA_DEVICE_SHORT_TERM]: {
    name: "Worldline device short term",
    productType: ProductType.BAMBORA_DEVICE_SHORT_TERM,
    icon: <Offer />,
    description: "Terminal rental - Short Term",
  },
  [ProductType.BAMBORA_ONE]: {
    name: "Worldline one",
    productType: ProductType.BAMBORA_ONE,
    icon: <StoreIcon />,
    description: "Instore bundle",
  },
  [ProductType.BAMBORA_ONE_SHORT_TERM]: {
    name: "Worldline one short term",
    productType: ProductType.BAMBORA_ONE_SHORT_TERM,
    icon: <StoreIcon />,
    description: "Instore bundle - Short Term",
  },
  [ProductType.CHECKOUT]: {
    name: "Checkout",
    productType: ProductType.CHECKOUT,
    icon: <GlobeIcon />,
    description: "E-Com bundle",
  },
};
