import cx from "classnames";
import styles from "./EcomCard.module.scss";
import { ReactElement, FunctionComponent } from "react";
import { EcomType } from "../../../../../../data/models/ContractTypes";

interface EcomCardProps {
  isSelected: boolean;
  icon: ReactElement;
  name: string;
  description: string;
  onUpdate: (ecomType: EcomType) => void;
  ecomType: EcomType;
  disabled: boolean;
}

export const EcomCard: FunctionComponent<EcomCardProps> = ({
  isSelected,
  icon,
  description,
  onUpdate,
  ecomType,
  disabled,
}) => {
  const ecomCardClass = cx({
    [styles.ecomCard]: true,
    [styles.ecomCardActive]: isSelected,
    [styles.disabled]: disabled,
  });

  const iconContainerClass = cx({
    [styles.circle]: true,
    [styles.circleActive]: isSelected,
  });

  return (
    <button
      className={ecomCardClass}
      disabled={disabled}
      onClick={(ev) => {
        ev.preventDefault();
        onUpdate(ecomType);
      }}
    >
      <div className={iconContainerClass}>{icon}</div>
      <div className={styles.ecomInfo}>
        <span className={styles.productDescription}>{description}</span>
      </div>
    </button>
  );
};
