import { useEffect, useContext, RefObject } from "react";
import { ScrollPositionContext } from "./ScrollPositionContext";

export const useScrollPosition = (id: string, ref: RefObject<HTMLElement>) => {
  const context = useContext(ScrollPositionContext);
  const addScrollRef = context?.addScrollRef;
  const removeScrollRef = context?.removeScrollRef;

  useEffect(() => {
    addScrollRef?.(id, ref);

    return () => {
      removeScrollRef?.(id);
    };
  }, [addScrollRef, removeScrollRef, id, ref]);
};
