import React, { ReactNode } from "react";
import cx from "classnames";
import "./Table.scss";

interface Props {
  children: ReactNode;
  className?: string;
  sortable?: boolean;
}

export const Table: React.FunctionComponent<Props> = ({
  children,
  className,
}) => {
  return (
    <div className={cx("table-wrapper", className)}>
      <table>{children}</table>
    </div>
  );
};
