import React from "react";
import ReactCountryFlag from "react-country-flag";
import cx from "classnames";
import "./Flag.scss";

interface Props {
  country: string;
  height?: number;
  shadow?: boolean;
  rounded?: boolean;
}

export const Flag: React.FunctionComponent<Props> = ({
  country,
  height = 30,
  shadow = false,
  rounded = false,
}) => {
  if (!country) {
    return null;
  }

  return (
    <ReactCountryFlag
      className={cx("flag", {
        shadow,
        rounded,
      })}
      style={{
        width: "auto",
        height,
      }}
      svg
      countryCode={country.toUpperCase()}
    />
  );
};

export { type Props as FlagProps };
