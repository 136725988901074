import { Alternative } from "../../../../../components/interactions/InputTypes";
import { Store, TerminalType } from "../../../../../data/models/ContractTypes";
import { OverviewTerminal } from "../../../../../data/models/OverviewTerminal";
import { Kit, UniqueTerminal } from "../../../../../data/models/UniqueTerminal";
import { EMPTY_DEFAULT } from "./Settings";

export const ValidSettings: string[] = [
  "TipEnabled",
  "CashierIdentityEnabled",
  "DCCEnabled",
];

export enum CommunicationProtocol {
  BLUETOOTH = "CLIENT",
  WIFI = "PORT",
}

enum SettingsStatus {
  NotAvailable = 0, // Visa ej i UI
  Unsupported = 1, // Visa ej i UI
  Supported = 2, // Presentera
  Preselected = 3, // Presentera, med defaultValue
  Required = 4, // Presentera med defaultValue, ej editerbar.
}

export interface Setting {
  disabled: boolean;
  name: string;
  value: string;
  show: boolean;
}

export const WIRELESS_MODEL_NAME = "move5000";
export const WIRED_MODEL_NAME = "lane3000";
export const WIRELESS_ANDROID_INTEGRATED_MODEL_NAME = "dx8000";
export const WIRELESS_ANDROID_STANDALONE_MODEL_NAME = "dx8000"; // yes, they have the same name
export const WIRED_ANDROID_MODEL_NAME = "rx5000";

function isApplicableModel(
  terminalType: TerminalType,
  modelName: string
): boolean {
  switch (terminalType) {
    case TerminalType.WIRED_INTEGRATED:
      return modelName.indexOf(WIRED_MODEL_NAME) !== -1;
    case TerminalType.WIRELESS_INTEGRATED:
      return modelName.indexOf(WIRELESS_MODEL_NAME) !== -1;
    case TerminalType.WIRELESS_STANDALONE:
      return modelName.indexOf(WIRELESS_MODEL_NAME) !== -1;

    case TerminalType.WIRED_INTEGRATED_ANDROID:
      return modelName.indexOf(WIRED_ANDROID_MODEL_NAME) !== -1;
    case TerminalType.WIRELESS_INTEGRATED_ANDROID:
      return modelName.indexOf(WIRELESS_ANDROID_INTEGRATED_MODEL_NAME) !== -1;
    case TerminalType.WIRELESS_STANDALONE_ANDROID:
      return modelName.indexOf(WIRELESS_ANDROID_STANDALONE_MODEL_NAME) !== -1;

    default:
      return false;
  }
}

export function getEnabledKits(kits: Kit[]) {
  return kits.filter((kit) => {
    if (!kit.enabled) {
      return false;
    }

    return true;
  });
}

export function getKitsWithApplicableModels(
  kits: Kit[],
  terminalType: TerminalType
): Kit[] {
  const filteredKits = kits.filter((kit) => {
    if (
      !isApplicableModel(terminalType, kit.terminalModel.name.toLowerCase())
    ) {
      return false;
    }

    return true;
  });

  return filteredKits;
}

export function getKitIdentifier(kit: Kit) {
  return `${kit.name}_${kit.terminalModel.name}`;
}

export function getApplicableKits(
  terminal: UniqueTerminal,
  terminalType: TerminalType
) {
  const enabledKits = getEnabledKits(terminal.kits);
  return getKitsWithApplicableModels(enabledKits, terminalType);
}

function hasRequestedSettingsName(kit: Kit) {
  return (
    kit.interface.settingsName === CommunicationProtocol.BLUETOOTH ||
    kit.interface.settingsName === CommunicationProtocol.WIFI
  );
}

export function getDuplicates(kits: Kit[]): Kit[] {
  const terminals: Record<string, Kit> = {};
  const duplicates: Kit[] = [];

  for (let index = 0; index < kits.length; index++) {
    const kit = kits[index];
    const id = getKitIdentifier(kit);

    if (!terminals[id]) {
      terminals[id] = kit;
      if (hasRequestedSettingsName(kit)) {
        duplicates.push(kit);
      }
      continue;
    }

    if (!hasRequestedSettingsName(kit)) {
      throw new Error(
        `Duplicate unknown kit names: ${kit.name} ${kit.interface.settingsName}`
      );
    }

    duplicates.push(kit);
  }

  if (duplicates.length > 2) {
    throw new Error(
      `More than two duplicates, shouldn't be? ${JSON.stringify(duplicates)}`
    );
  }

  return duplicates;
}

export function kitsToAlternatives(kits: Kit[], t: any) {
  const options: Alternative<number>[] = kits.map((kit) => ({
    value: kit.id,
    text: kit.terminalModel.name,
  }));

  return [
    {
      value: -1,
      text: t("Select model"),
      disabled: true,
    },
    ...options,
  ];
}

export function getEcrAlternatives(
  options: OverviewTerminal[],
  terminalType: TerminalType,
  t: any
) {
  const opts: Alternative<number>[] = options
    .filter((opt) => {
      if (opt.name === EMPTY_DEFAULT) {
        return false;
      }

      const applicableModels = opt.certifiedTerminals.toLowerCase();
      return isApplicableModel(terminalType, applicableModels);
    })
    .map((opt) => ({
      value: opt.id,
      text: `${opt.name} - ${opt.integrator.name}`,
    }));

  return [
    {
      value: -1,
      text: t("Select checkout system"),
      disabled: true,
    },
    ...opts,
  ];
}

function isValidValue(value: string, validValues: string[]) {
  return validValues.includes(value);
}

export function getSettings(
  terminal: UniqueTerminal,
  terminalType: TerminalType,
  store: Store
): Setting[] {
  if (terminalType === TerminalType.WIRELESS_STANDALONE) {
    return [];
  }

  if (terminalType === TerminalType.WIRELESS_STANDALONE_ANDROID) {
    return [];
  }

  const currentSettings = store[terminalType]?.terminalOptions;
  const settingsMap: Record<string, string> = {};

  currentSettings?.reduce((acc, cur) => {
    acc[cur.name] = cur.value;
    return acc;
  }, settingsMap);

  return terminal.features.map((feature) => {
    if (feature.status === SettingsStatus.Required) {
      return {
        name: feature.name,
        disabled: true,
        value: feature.defaultValue,
        show: true,
      };
    }

    if (
      feature.status === SettingsStatus.NotAvailable ||
      feature.status === SettingsStatus.Unsupported
    ) {
      return {
        name: feature.name,
        disabled: true,
        value: feature.defaultValue,
        show: false,
      };
    }

    if (feature.name === "DCCEnabled") {
      console.warn(`For now, DCCEnabled is set to "false" and is disabled`);
      return {
        name: feature.name,
        disabled: true,
        value: isValidValue(settingsMap[feature.name], feature.validValues)
          ? settingsMap[feature.name]
          : isValidValue("false", feature.validValues)
          ? "false"
          : feature.defaultValue,
        show: true,
      };
    } else if (ValidSettings.includes(feature.name)) {
      console.warn(`For now, always set ${feature.name} to "false" if allowed`);
      return {
        name: feature.name,
        disabled: feature.validValues.length === 1,
        value: isValidValue(settingsMap[feature.name], feature.validValues)
          ? settingsMap[feature.name]
          : isValidValue("false", feature.validValues)
          ? "false"
          : feature.defaultValue,
        show: true,
      };
    }

    return {
      name: feature.name,
      disabled: feature.validValues.length === 1,
      value: isValidValue(settingsMap[feature.name], feature.validValues)
        ? settingsMap[feature.name] || feature.defaultValue
        : feature.defaultValue,
      show: true,
    };
  });
}
