import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Select } from "../../../components/form/Select";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import { Input } from "../../../components/interactions/Input/Input";
import { Link } from "../../../components/links/Link";
import { Table } from "../../../components/table/Table";
import { Status } from "../../../data/types";
import { Language } from "../../../i18n";
import { MemoMissingTranslationsRow } from "./MissingTranslationRow";
import { SuggestTranslationOverlay } from "./SuggestTranslationOverlay";
import { UploadTranslations } from "./UploadTranslations";
import cx from "classnames";
import { getLanguageOpts } from "../../../components/translation/i18nUtils";
import {
  dataTranslation,
  TranslationId,
  TranslationText,
} from "../../../components/translation/dataTranslation";
import { Page } from "../../Page";
import "./TranslationPage.scss";
import { PersistentOverlay } from "../../../components/overlay/PersistentOverlay";
import { Button } from "../../../components/interactions/Buttons/Button";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import {
  AnimateHeight,
  Speed,
} from "../../../components/animate/AnimateHeight";

export const TRANSLATIONS_PAGE = "/translations";
const DOWNLOAD_LINK =
  "/api/admin/internal/translations/excel/wlx-=NAMESPACE=-=LANGUAGE=.xlsx";

enum ROW_FILTERS {
  ALL = "ALL",
  MISSING = "MISSING",
  TRANSLATED = "TRANSLATED",
}

enum NAMESPACE_FILTERS {
  ALL = "",
  SALES = "sales",
  MERCHANT = "merchant",
  MCC = "mcc",
}

function getDownloadLink(
  namespace: string,
  language: Language,
  filter?: ROW_FILTERS
) {
  if (filter) {
    return `${process.env.REACT_APP_TARGET_BACKEND}${DOWNLOAD_LINK}?filter=${filter}`
      .replace("=NAMESPACE=", namespace || "all")
      .replace("=LANGUAGE=", language);
  }

  return `${process.env.REACT_APP_TARGET_BACKEND}${DOWNLOAD_LINK}`
    .replace("=NAMESPACE=", namespace || "all")
    .replace("=LANGUAGE=", language);
}

export const NAMESPACE_ALTERNATIVES: {
  value: NAMESPACE_FILTERS;
  text: string;
}[] = [
  {
    value: NAMESPACE_FILTERS.ALL,
    text: "All",
  },
  {
    value: NAMESPACE_FILTERS.SALES,
    text: "Sales",
  },
  {
    value: NAMESPACE_FILTERS.MERCHANT,
    text: "Merchant",
  },
  {
    value: NAMESPACE_FILTERS.MCC,
    text: "MCC",
  },
];

export const PRESENT_ALTERNATIVES = [
  {
    value: ROW_FILTERS.ALL,
    text: "All",
  },
  {
    value: ROW_FILTERS.MISSING,
    text: "Missing Translations",
  },
  {
    value: ROW_FILTERS.TRANSLATED,
    text: "Has Translation",
  },
];

export const LANGUAGE_ALTERNATIVES = getLanguageOpts(
  true,
  Language.ENGLISH
).filter((lang) => lang.value !== Language.ENGLISH);

export const TranslationsPage = () => {
  const { i18n } = useTranslation();
  const [addTranslation, setAddTranslation] = useState<boolean>(false);
  const [key, setKey] = useState<string>("");
  const [translationTexts, setTranslationTexts] = useState<TranslationText[]>(
    []
  );
  const [activeTranslation, setActiveTranslation] = useState<TranslationText>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const isManager = true;

  const [filterCountry, setFilterCountry] = useState<Language | undefined>(
    LANGUAGE_ALTERNATIVES.map(({ value }) => value).includes(
      i18n.language as Language
    )
      ? (i18n.language as Language)
      : undefined
  );

  const [filterText, setFilterText] = useState<string>();
  const [filterNamespace, setFilterNamespace] = useState<string>(
    NAMESPACE_FILTERS.SALES
  );
  const [translationFilter, setTranslationFilter] = useState(
    ROW_FILTERS.MISSING
  );

  const loadTranslations = useCallback(async () => {
    if (!filterCountry) {
      setTranslationTexts([]);
      return;
    }
    dataTranslation.loadTranslations(filterCountry).then(setTranslationTexts);
  }, [filterCountry]);

  const deleteTranslation = useCallback(
    (id: TranslationId) => {
      dataTranslation.deleteText(id).then(loadTranslations);
    },
    [loadTranslations]
  );

  useEffect(() => {
    loadTranslations();
  }, [loadTranslations]);

  const filteredTexts = useMemo(
    () =>
      translationTexts
        .filter((row) => {
          if (!filterNamespace) {
            return true;
          }
          return row.namespace === filterNamespace;
        })
        .filter((row) => {
          if (translationFilter === ROW_FILTERS.MISSING) {
            if (!row.value) {
              return true;
            }
            return false;
          }

          if (translationFilter === ROW_FILTERS.TRANSLATED) {
            return !!row.value;
          }
          return true;
        })
        .filter((row) => {
          if (!filterText) {
            return true;
          }
          const lowerCaseSearch = filterText.toLowerCase();
          const keyLowerCase = row.key.toLowerCase();
          if (keyLowerCase.includes(lowerCaseSearch)) {
            return true;
          }
          if (row.value) {
            const translationLowerCase = row.value.toLowerCase();
            if (translationLowerCase.includes(lowerCaseSearch)) {
              return true;
            }
          }
          return false;
        }),
    [filterNamespace, filterText, translationFilter, translationTexts]
  );

  const handleOnEdit = useCallback(
    (translation: TranslationText) => {
      setActiveTranslation(translation);
    },
    [setActiveTranslation]
  );

  const handleOnNextEdit = useCallback(
    async (translation: TranslationText) => {
      await loadTranslations();
      await new Promise((resolve) => setTimeout(resolve, 250));

      const index = filteredTexts.findIndex(
        (text) => text.id === translation.id
      );
      if (index === -1) {
        setActiveTranslation(undefined);
        return;
      }
      const nextIndex = index + 1;
      if (nextIndex >= filteredTexts.length) {
        setActiveTranslation(undefined);
        return;
      }
      setActiveTranslation(filteredTexts[nextIndex]);
    },
    [loadTranslations, filteredTexts]
  );

  const handleOnEditClose = useCallback(
    async (updated: boolean) => {
      if (updated) {
        await loadTranslations();
      }
      await new Promise((resolve) => setTimeout(resolve, 250));
      setActiveTranslation(undefined);
    },
    [loadTranslations]
  );

  const downloadLink = useMemo(() => {
    if (!filterCountry) {
      return;
    }
    return getDownloadLink(filterNamespace, filterCountry, translationFilter);
  }, [filterNamespace, filterCountry, translationFilter]);

  const saveTranslation = useCallback(() => {
    dataTranslation
      .addTranslationLabel({
        namespace: filterNamespace,
        key,
      })
      .then(() => {
        setStatus(Status.SUCCESS);
        setKey("");

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 1000);
      })
      .catch(() => {
        setStatus(Status.ERROR);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 1000);
      });
  }, [key, filterNamespace]);

  return (
    <Page stripped title="Translations" className="translations-page">
      <section>
        <article>
          <SuggestTranslationOverlay
            translationText={activeTranslation}
            onClose={handleOnEditClose}
            onNext={handleOnNextEdit}
          />
          <FieldSet header="Translations">
            <div className="columns">
              <div className="column">
                <Dropdown
                  onChange={(value) => {
                    setFilterCountry(value as Language);
                  }}
                  label={<span>Language</span>}
                  name="language"
                  alternatives={LANGUAGE_ALTERNATIVES}
                  value={filterCountry}
                  status={isManager ? Status.DEFAULT : Status.DISABLED}
                />
              </div>
              <div className="column">
                <Select
                  onChange={(value) => {
                    setFilterNamespace(value);
                  }}
                  label={<span>Site / namespace</span>}
                  alternatives={NAMESPACE_ALTERNATIVES}
                  value={filterNamespace}
                />
              </div>
              <div className="column">
                <Dropdown
                  className="filter-dropdown"
                  onChange={(value) => {
                    setTranslationFilter(value);
                  }}
                  label={<span>Filter</span>}
                  name="translationFilter"
                  alternatives={PRESENT_ALTERNATIVES}
                  value={translationFilter}
                />
              </div>
              <div className="column">
                <Input
                  label={<span>Search</span>}
                  value={filterText}
                  onChange={(value: string) => {
                    setFilterText(value);
                  }}
                  name="Search"
                />
              </div>
            </div>

            <div className="file-actions tablet-columns">
              <div>
                <Link
                  link={downloadLink || ""}
                  external
                  className={cx("as-button", "small", {
                    disabled: !downloadLink,
                  })}
                  download="filtered-translations"
                >
                  Download translations
                </Link>
                <i className="small">
                  as Excel file and based on the current filter
                </i>

                <div className="m-top-20">
                  <Button
                    className="small"
                    onClick={() => {
                      setAddTranslation(true);
                    }}
                  >
                    Add translation
                  </Button>
                </div>
              </div>

              <div>
                <UploadTranslations />
              </div>
            </div>

            <PersistentOverlay
              open={addTranslation}
              onClose={() => setAddTranslation(false)}
            >
              <Select
                onChange={(value) => {
                  setFilterNamespace(value);
                }}
                label={<span>Site / namespace</span>}
                alternatives={NAMESPACE_ALTERNATIVES}
                value={filterNamespace}
              />

              <Input
                label={<span>Key</span>}
                value={key}
                onChange={(value: string) => {
                  setKey(value);
                }}
                name="Key"
              />

              <AnimateHeight name={status} speed={Speed.FAST}>
                <div>
                  {status === Status.ERROR ? (
                    <ErrorBox relative>Could not save</ErrorBox>
                  ) : null}

                  {status === Status.SUCCESS ? (
                    <SuccessBox relative>Saved</SuccessBox>
                  ) : null}
                </div>
              </AnimateHeight>

              <Button className="m-top-20" block onClick={saveTranslation}>
                Save
              </Button>
            </PersistentOverlay>

            <div className="translations-table-wrapper">
              <Table className="translations-table text-small">
                <thead>
                  <tr>
                    <th className="translations-header-site">Site</th>
                    <th className="translations-header-added">Added</th>
                    <th className="translations-header-original">Original</th>
                    <th className="translations-header-translation">
                      Translation
                    </th>
                    <th className="translations-header-translated">
                      Translated
                    </th>
                    <th className="translations-header-translated-by">
                      Translated by
                    </th>
                    <th className="translations-header-button"></th>
                    <th className="translations-header-button"></th>
                  </tr>
                </thead>

                <tbody>
                  {filteredTexts.map((row) => (
                    <MemoMissingTranslationsRow
                      key={row.id}
                      translationText={row}
                      deleteTranslation={deleteTranslation}
                      language={i18n.language as Language}
                      onEdit={handleOnEdit}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </FieldSet>
        </article>
      </section>
    </Page>
  );
};
