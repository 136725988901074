import React, { RefObject, useRef } from "react";
import { Status } from "../../data/types";
import {
  RadioButtons,
  Props as RadioButtonsProps,
  Stringifiable,
} from "../interactions/Radio/RadioButtons";
import { useForm } from "./hooks/useForm";
import { useValidation, ValidationProps } from "./hooks/useValidation";
import { RequiredValidatorName } from "./validators/RequiredValidator";

interface Props<T extends Stringifiable>
  extends ValidationProps,
    Omit<RadioButtonsProps<T>, "status" | "message" | "required"> {
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
}

export function RadioGroup<T extends Stringifiable>({
  className,
  name,
  label = null,
  onChange,
  onBlur,
  hint,
  alternatives = [],
  value,
  validators = [],
  forceValidation = false,
  disabled = false,
  scrollToRef,
}: Props<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const innerValidators = disabled ? [] : validators;
  const inputId = useRef("radio_" + Math.random().toString(36).substring(2, 9));
  const [validity, errorMessages, resetValidation, status] = useValidation<
    typeof value
  >(value, innerValidators, forceValidation);
  useForm(
    inputId.current,
    validity,
    value,
    resetValidation,
    scrollToRef ?? ref
  );

  const isRequired = innerValidators.some(
    (validator) => validator.name === RequiredValidatorName
  );
  return (
    <RadioButtons
      alternatives={alternatives}
      className={className}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      hint={hint}
      status={disabled ? Status.DISABLED : status}
      required={isRequired}
      message={errorMessages.length > 0 ? errorMessages[0] : null}
      ref={ref}
    />
  );
}
