import { getDecimalSeparator } from "../../utils";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class MaxValidator extends BaseValidator {
  name = "MaxValidator";
  private max: number;
  private separator: string;

  constructor(max: number, error: string, locale?: string) {
    super(error);
    this.max = max;
    this.separator = getDecimalSeparator(locale);
  }

  validate(value: number | string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      const val =
        typeof value === "string" ? value.replace(this.separator, ".") : value;
      const num = Number(val);

      if (isNaN(num) || num <= this.max) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
