import React from "react";
import cx from "classnames";
import { Image } from "../../components/images/Image";

interface Props {
  className?: string;
}

export const Logo: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <div className={cx("logo", className)}>
      <Image
        src="/images/logo-worldline.svg"
        ratio={2862.52 / 473.06668}
        alt="Worldline logo"
      />
    </div>
  );
};
