import React from "react";

export const RadioChecked: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className="radio-checked"
  >
    <circle r="9" cx="12" cy="12" />
    <circle className="inner" r="5" cx="12" cy="12" />
  </svg>
);
