import React from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../../PdfStyles";
import { FirstCell } from "../FirstCell";
import { ComponentProps, PdfProductType } from "../../PdfPage";
import { Cell } from "../Cell";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

function getLabels(t: any) {
  return {
    header: t("Product"),
    product: t("Worldline Product or service used"),
    acquirer: t("Acquirer"),
    acquirerMid: t("Acquirer MID"),
  };
}

export const WorldlineDeviceShortTerm: React.FunctionComponent<
  ComponentProps
> = ({ language, productType, show, config }) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  if (productType !== PdfProductType.BAMBORA_DEVICE_SHORT_TERM) {
    return null;
  }

  return (
    <>
      <View style={styles.sectionHeader}>
        <Text>{labels.header}</Text>
      </View>

      <View>
        <FirstCell label={labels.product} show={show}>
          {productType}
        </FirstCell>
      </View>

      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.acquirer} show={show}>
            Elavon
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.acquirerMid} show={show}>
            ABC123 342432
          </Cell>
        </View>
      </View>
    </>
  );
};
