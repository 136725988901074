import { FunctionComponent, ReactElement } from "react";
import styles from "./EcomCard.module.scss";
// components
import { T } from "../../../../../../components/translation/T";
import { EcomCard } from "./EcomCard";
import { FiSmartphone as AppIcon } from "react-icons/fi";
import { BiDesktop as WebIcon } from "react-icons/bi";
import { EcomType } from "../../../../../../data/models/ContractTypes";

interface Props {
  selectedEcomType?: EcomType;
  onUpdate: (ecomType: EcomType) => void;
  disabled: boolean;
}

export const EcomSelection: FunctionComponent<Props> = ({
  selectedEcomType,
  onUpdate,
  disabled,
}) => {
  return (
    <div className={styles.ecomType}>
      <h5>
        <T>E-commerce type</T>
      </h5>
      <div className={styles.ecomSelection}>
        {ECOM_OFFERING.map(({ ecomType, icon, name, description }) => {
          const isSelected = ecomType === selectedEcomType ? true : false;
          return (
            <EcomCard
              key={ecomType}
              isSelected={isSelected}
              icon={icon}
              name={name}
              ecomType={ecomType}
              description={description}
              onUpdate={onUpdate}
              disabled={disabled}
            />
          );
        })}
      </div>

      {/* {selectedEcomType === EcomType.MOTO && (
        <div className={styles.descriptionOfMoto}>
          <TextArea
            value={motoMotivation}
            label={"Description of need of Moto"}
            validators={[
              new RequiredValidator(
                "Please provide a reason why the merchant needs Moto"
              ),
            ]}
            onChange={(value) => onUpdate("motoMotivation", value)}
          />
        </div>
      )} */}
    </div>
  );
};

interface EcomOption {
  name: string;
  ecomType: EcomType;
  icon: ReactElement;
  description: string;
}

const ECOM_OFFERING: EcomOption[] = [
  {
    name: "App",
    ecomType: EcomType.APP,
    icon: <AppIcon size={30} />,
    description: "Android & Iphone Apps",
  },
  {
    name: "Web",
    ecomType: EcomType.WEB,
    icon: <WebIcon size={30} />,
    description: "Websites & Web Apps",
  },
  // {
  //   name: "Moto",
  //   ecomType: EcomType.MOTO,
  //   icon: <MotoIcon />,
  //   description: "Mail & Telephone Order",
  // },
];
