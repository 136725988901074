import React from "react";
import cx from "classnames";
import styles from "./FileRow.module.scss";
import {
  ContractDocument,
  DocumentType,
  documentTypeDescription,
  documentTypeDisplay,
} from "../../data/models/ContractTypes";
import { T } from "../translation/T";
import { Status } from "../../data/types";
import { Trash } from "../icons/Trash";
import { Button } from "../interactions/Buttons/Button";
import { DocumentId } from "../../data/models/CommonTypes";
import { SupplementWithId } from "../../data/dataDocuments";

interface Props {
  document: ContractDocument | SupplementWithId;
  status?: Status;
  onRemove: (documentId: DocumentId) => void;
  allowRemove?: boolean;
  disabled?: boolean;
}

export const FileRow: React.FunctionComponent<Props> = ({
  document,
  status = Status.DEFAULT,
  onRemove,
  allowRemove,
  disabled = false,
}) => {
  const isCustomDoc =
    document.documentType === DocumentType.SALES_CUSTOM_REQUEST;

  const description = isCustomDoc
    ? document.documentDescription
    : documentTypeDescription(document.documentType);

  return (
    <div className={styles.row}>
      <div className={cx("text-small", styles.info)}>
        <ul>
          <li>
            <b>
              <T>{documentTypeDisplay(document.documentType)}</T>
            </b>
          </li>
          <li>
            <i>
              <T>{description || "No description"}</T>
            </i>
          </li>
        </ul>
      </div>
      {document.documentType === DocumentType.SALES_CUSTOM_REQUEST ||
      allowRemove ? (
        <Button
          className="small ghost"
          action
          onClick={() => onRemove(document.documentId)}
          status={disabled ? Status.DISABLED : status}
        >
          <Trash />
        </Button>
      ) : null}
    </div>
  );
};
