import React, { ReactNode } from "react";
import { ComponentProps } from "./PdfPage";
import { PDFViewer } from "@react-pdf/renderer";
import { DownloadLink } from "./DownloadLink";

interface Props extends ComponentProps {
  name: string;
  fileTag? : string;
  children: ReactNode;
}

export const Wrapper: React.FunctionComponent<Props> = (props) => (
  <>
    <PDFViewer>{props.children as any}</PDFViewer>
    <DownloadLink
      show={props.show}
      doc={props.children as any}
      language={props.language}
      name={props.name}
      fileTag={props.fileTag}
    />
  </>
);
