import React, { useCallback, useState } from "react";
import { useSetRecoilState } from "recoil";
import { dataMerchant } from "../../data/dataMerchant";
import { ContractId } from "../../data/models/CommonTypes";
import { Status } from "../../data/types";
import { contractAssociateState } from "../../state/contractAssociateState";
import { Form } from "../form/Form";
import { hasRealErrors } from "../form/FormHelpers";
import { TextInput } from "../form/TextInput";
import { EmailBackendValidator } from "../form/validators/EmailBackendValidator";
import { EmailValidator } from "../form/validators/EmailValidator";
import { MaxLengthValidator } from "../form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../form/validators/MinLengthValidator";
import { PhoneCountryCodeValidator } from "../form/validators/PhoneCountryCodeValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { Button } from "../interactions/Buttons/Button";
import { RegexValidator, PHONE_REGEX } from "../form/validators/RegexValidator";
import { Associate } from "../../data/models/AssociateTypes";
import { AnimateHeight } from "../animate/AnimateHeight";
import { GenericError } from "../boxes/GenericError";
import { SuccessBox } from "../boxes/SuccessBox";
import { T } from "../translation/T";
import { Pending } from "../icons/Pending";
import "./Reminder.scss";
import {
  MIN_MOBILE_PHONE_CHARS,
  MAX_MOBILE_PHONE_CHARS,
} from "../contact/Contact";

interface Props {
  associate: Associate;
  contractId: ContractId;
  onSent: () => void;
}

export const Reminder: React.FunctionComponent<Props> = ({
  associate,
  contractId,
  onSent,
}) => {
  const [revokeStatus, setRevokeStatus] = useState<Status>(Status.DEFAULT);
  const [reminderStatus, setReminderStatus] = useState<Status>(Status.DEFAULT);
  const setAssociates = useSetRecoilState(contractAssociateState);
  const [email, setEmail] = useState<string>(associate.contact.email || "");
  const [phoneNumber, setPhoneNumber] = useState<string>(
    associate.contact.phoneNumber || ""
  );

  const onChange = useCallback((value: string, name: string) => {
    if (name === "email") {
      setEmail(value);
    } else {
      setPhoneNumber(value);
    }
  }, []);

  const sendReminder = useCallback(() => {
    setReminderStatus(Status.PENDING);

    dataMerchant
      .resendLink(
        contractId,
        associate.associateId,
        email || undefined,
        phoneNumber || undefined
      )
      .then(() => {
        setAssociates((prevAssociates) =>
          prevAssociates.map((prevAssociate) => {
            if (associate.associateId === prevAssociate.associateId) {
              return {
                ...prevAssociate,
                email,
                phoneNumber,
              };
            }
            return prevAssociate;
          })
        );

        setRevokeStatus(Status.DISABLED);
        setReminderStatus(Status.SUCCESS);
        onSent();
      })
      .catch((err) => {
        setReminderStatus(Status.ERROR);

        setTimeout(() => {
          setReminderStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [contractId, associate, email, phoneNumber, setAssociates, onSent]);

  const hasRevokedLink = revokeStatus === Status.SUCCESS;

  return (
    <Form
      onSubmit={(_, form) => {
        const realErrors = hasRealErrors(form);
        if (!form.isValid) {
          return;
        }

        if (realErrors) {
          return;
        }
        sendReminder();
      }}
      name="confirm-reminder-form"
    >
      <div className="associate-reminder">
        <strong>
          <T>Remind person</T>
        </strong>
        <div className="tablet-columns">
          <div>
            <TextInput
              onChange={onChange}
              name="email"
              label="Email"
              value={email}
              validators={[
                new RequiredValidator("Email is required"),
                new EmailValidator("Email is not valid"),
                new MaxLengthValidator(
                  50,
                  "Email must be less than 50 characters"
                ),
                new EmailBackendValidator("Email is not valid"),
              ]}
              disabled={reminderStatus !== Status.DEFAULT}
            />
          </div>
          <div>
            <TextInput
              onChange={onChange}
              name="phoneNumber"
              label="Mobile phone"
              value={phoneNumber}
              hint={
                <span>
                  Country prefix <b>must</b> be included
                </span>
              }
              validators={[
                new PhoneCountryCodeValidator(
                  "Phone number must start with a country code e.g +61..."
                ),
                new RegexValidator(
                  PHONE_REGEX,
                  "Phone contains invalid characters"
                ),
                new MinLengthValidator(
                  MIN_MOBILE_PHONE_CHARS,
                  `Mobile phone must be at least ${MIN_MOBILE_PHONE_CHARS} characters`
                ),
                new MaxLengthValidator(
                  MAX_MOBILE_PHONE_CHARS,
                  `Mobile phone must be less than ${MAX_MOBILE_PHONE_CHARS} characters`
                ),
              ]}
              disabled={reminderStatus !== Status.DEFAULT}
            />
          </div>
        </div>

        <AnimateHeight name={reminderStatus}>
          <div>
            {reminderStatus === Status.DEFAULT ? (
              <div className="reminder-buttons">
                <Button
                  className="small"
                  ghost
                  type="submit"
                  status={reminderStatus}
                >
                  {hasRevokedLink ? "Send new link" : "Remind person"}
                </Button>
              </div>
            ) : null}

            {reminderStatus === Status.ERROR ? <GenericError /> : null}

            {reminderStatus === Status.SUCCESS ? (
              <SuccessBox relative>
                <T>Reminder has been sent ⏰!</T>
              </SuccessBox>
            ) : null}

            {reminderStatus === Status.PENDING ? <Pending /> : null}
          </div>
        </AnimateHeight>

        {/* <Visible show={reminderStatus === Status.DEFAULT}>
          <div className="reminder-buttons">
            <Button
              className="small"
              ghost
              type="submit"
              status={reminderStatus}
            >
              {hasRevokedLink ? "Send new link" : "Remind person"}
            </Button>
          </div>
        </Visible> */}
      </div>
    </Form>
  );
};
