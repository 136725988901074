import { createContext, RefObject } from "react";
import { VALIDATION_STATE } from "./hooks/useValidation";

export interface Input {
  name: string;
  isValid: boolean;
  isInvalid: boolean;
  validationState: VALIDATION_STATE;
  value: any;
  resetValidation: () => void;
  scrollToRef: RefObject<HTMLElement> | undefined;
}

export interface FormContextInterface {
  forceErrors: boolean;
  updateInput(input: Input): void;
  removeInput(inputName: string): void;
}

export const FormContext = createContext<FormContextInterface | null>(null);
