import React from "react";
import { StyleSheet, Document, Page, View } from "@react-pdf/renderer";
import { getDefaultStyles } from "../PdfStyles";
import { Header } from "../componentParts/Header";
import { Footer } from "../componentParts/Footer";
import { CompanyInformation } from "../componentParts/CompanyInformation";
import { PrimaryContact } from "../componentParts/PrimaryContact";
import { ComponentProps } from "../PdfPage";
import { Stores } from "../componentParts/Stores";
import { Signees } from "../componentParts/Signees";
import { Owners } from "../componentParts/Owners";
import { Wrapper } from "../Wrapper";
import { WorldlineDevice as Device } from "../componentParts/products/WordlineDevice";
import { WorldlineDeviceShortTerm } from "../componentParts/products/WordlineDeviceShortTerm";
import { Pricing } from "../components/Pricing";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

const Doc = (props: ComponentProps) => {
  const styles = {
    ...getDefaultStyles(props.show),
    ...getSpecificStyles(props.show),
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />
        <CompanyInformation {...props} />
        <View style={styles.divider} />
        <PrimaryContact {...props} />
        <View style={styles.divider} />
        <View style={styles.divider} />
        <Footer {...props} />
      </Page>

      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />
        <Device {...props} />
        <WorldlineDeviceShortTerm {...props} />
        <View style={styles.divider} />
        <View style={styles.divider} />
        <Footer {...props} />
      </Page>

      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />
        <Pricing {...props} />
        <View style={styles.divider} />
        <View style={styles.divider} />
        <Footer {...props} />
      </Page>

      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />
        <Stores {...props} />
        <View style={styles.divider} />
        <View style={styles.divider} />
        <Footer {...props} />
      </Page>

      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />
        <Owners {...props} />
        <View style={styles.divider} />
        <Signees {...props} />
        <View style={styles.divider} />
        <View style={styles.divider} />
        <Footer {...props} />
      </Page>
    </Document>
  );
};

export const WorldlineDevice: React.FunctionComponent<ComponentProps> = (
  props
) => {
  return (
    <Wrapper {...props} name="worldline-device">
      <Doc {...props} />
    </Wrapper>
  );
};
