import React, { useMemo } from "react";
import cx from "classnames";
import styles from "./AddressRow.module.scss";
import { Address, Validity } from "../../data/models/ContractTypes";
import { getGoogleMapsLink } from "./AddressWithSearch";
import { Beacon } from "../beacon/Beacon";
import { Location } from "../icons/Location";
import { T } from "../translation/T";
import { Link } from "../links/Link";
import { Flag } from "../flags/Flag";
import { Country } from "../../i18n";

interface Props {
  address?: Address;
}

export const AddressRow: React.FunctionComponent<Props> = ({
  address = {
    street: "",
    postalCode: "",
    city: "",
    countryCode: "",
  },
}) => {
  const googleMapsLink = useMemo(() => getGoogleMapsLink(address), [address]);

  const secondAddressRow = useMemo(() => {
    return [address.city, address.postalCode].filter(Boolean).join(", ");
  }, [address]);

  return (
    <div className={styles.row}>
      <div className={styles.icon}>
        <Beacon validity={Validity.DEFAULT} icon={<Location />} />
      </div>

      <div className={cx(styles.address, "text-small")}>
        <ul>
          <li>{address.street || <T>No address set</T>}</li>
          {secondAddressRow && (
            <li>
              <span className={styles.secondRow}>{secondAddressRow}</span>
              <Flag
                country={address.countryCode as Country}
                rounded
                shadow
                height={14}
              />
            </li>
          )}
        </ul>
      </div>

      <div className={styles.mapLink}>
        {googleMapsLink && (
          <Link link={googleMapsLink} external className="small">
            <T>View on map</T>
          </Link>
        )}
      </div>
    </div>
  );
};
