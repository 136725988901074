import { useCallback, useRef, useState } from "react";
import { Form } from "../../../../components/form/Form";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { SectionFieldSet } from "../../../../components/sectionFieldSet/SectionFieldSet";
import { LinkAnchors } from "../ContractEdit";
import { FormName } from "../menus/ContractEditMenu";
import { ListedCompanyFields } from "./components/ListedCompanyFields";
import { RegulatedCompanyFields } from "./components/RegulatedCompanyFields";
import { useRecoilValue } from "recoil";
import { disabledState } from "../../../../state/contractState";
import { LegalEntityStoreObject, STORE_KEY, Store } from "../../../../Store";
import { useContractId } from "../../../../hooks/useContractId";
import { ContractId } from "../../../../data/models/CommonTypes";

function getValueFromStore(
  contractId: ContractId,
  key: STORE_KEY.STORE_REGULATED_ENTITY | STORE_KEY.STORE_LISTED_ENTITY
) {
  return Store.getValue<LegalEntityStoreObject>(key);
}

export const FinancialData = () => {
  return (
    <SectionFieldSet
      headerTitle={LinkAnchors.FINANCIAL_DATA.name}
      formName={FormName.FINANCIAL_DATA}
      sectionNumber={3}
    >
      <FinancialDataInner />
    </SectionFieldSet>
  );
};

const FinancialDataInner = () => {
  const contractId = useContractId();
  const isDisabled = useRecoilValue(disabledState);
  const savedRegulatedEntity = useRef<LegalEntityStoreObject>(
    getValueFromStore(contractId, STORE_KEY.STORE_REGULATED_ENTITY)
  );
  const savedListedEntity = useRef<LegalEntityStoreObject>(
    getValueFromStore(contractId, STORE_KEY.STORE_LISTED_ENTITY)
  );
  const [regulatedEntity, setRegulatedEntity] = useState<boolean | undefined>(
    savedRegulatedEntity.current[contractId]
  );
  const [listedEntity, setListedEntity] = useState<boolean | undefined>(
    savedListedEntity.current[contractId]
  );

  const onRegulatedEntityChange = useCallback(
    (value: boolean | undefined) => {
      setRegulatedEntity(value);
      Store.setValue(STORE_KEY.STORE_REGULATED_ENTITY, {
        ...(savedRegulatedEntity as any).current,
        [contractId]: value,
      });
    },
    [contractId]
  );

  const onListedEntityChange = useCallback(
    (value: boolean | undefined) => {
      setListedEntity(value);
      Store.setValue(STORE_KEY.STORE_LISTED_ENTITY, {
        ...(savedListedEntity as any).current,
        [contractId]: value,
      });
    },
    [contractId]
  );

  return (
    <div>
      <ScrollPositionAnchor id={LinkAnchors.FINANCIAL_DATA.anchor} />
      <Form name={FormName.FINANCIAL_DATA}>
        <RegulatedCompanyFields
          onRegulatedEntityChange={onRegulatedEntityChange}
          regulatedEntity={regulatedEntity}
          disabled={isDisabled}
        />

        <div className="m-top-40">
          <ListedCompanyFields
            listedEntity={listedEntity}
            onListedEntityChange={onListedEntityChange}
            disabled={isDisabled}
          />
        </div>
      </Form>
    </div>
  );
};
