import { data } from "../../data/data";
import { UTCDate } from "../../data/models/CommonTypes";
import { Language } from "../../i18n";

export type TranslationId = number & { isTranslationId: true };

export enum SiteTranslation {
  merchant = "merchant",
  sales = "sales",
}

export interface TranslationText {
  id: TranslationId;
  created: UTCDate;
  updated?: UTCDate;
  updatedBy?: string;
  language: Language;
  namespace: string;
  key: string;
  value?: string;
  site: SiteTranslation;
}

export interface Count {
  count: number;
}

const BASE_URL = "/api/admin/internal/translations";

export const dataTranslation = {
  loadTranslations: (language?: Language) => {
    const lang = language || "";
    return data.get<TranslationText[]>(`${BASE_URL}?language=${lang}`);
  },

  suggestText: (id: TranslationId, text: string) => {
    return data.post<TranslationText[]>(`${BASE_URL}/${id}`, {
      text: text,
    });
  },

  updateTranslation: (id: TranslationId, text: string) => {
    return data.post(`${BASE_URL}/${id}`, {
      text,
    });
  },

  deleteText: (id: TranslationId) => {
    return data.delete<void>(`${BASE_URL}/${id}`);
  },

  countMissingTranslations: (language?: Language) => {
    const lang = language || "";
    return data.get<Count>(`${BASE_URL}/missing/count?language=${lang}`);
  },

  addTranslationLabel: ({
    // language,
    namespace,
    key,
    page,
  }: {
    // language: Language;
    namespace: string;
    key: string;
    page?: string;
  }) =>
    data.post(`/translation/${namespace}.json`, {
      key,
      page,
    }),
};
