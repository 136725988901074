import styles from "./PricePlan.module.scss";
import { FunctionComponent, useState, memo } from "react";
import { motion } from "framer-motion";
// components
import { PricePlanHeader as Header } from "./components/PricePlanHeader";
import { PricePlanBody as Table } from "./components/PricePlanBody";
import { PricePlanFooter as Footer } from "./components/PricePlanFooter";
// data & types
import { Currency, Language } from "../../i18n";
import { PricePlan } from "../../data/dataPricing";
import { Contract } from "../../data/models/ContractTypes";

const TableMemo = memo(Table);
const FooterMemo = memo(Footer);

interface PricePlanProps {
  pricePlan: PricePlan;
  contract: Contract;
  language: Language;
}

export const PricePlanTable: FunctionComponent<PricePlanProps> = ({
  pricePlan,
  language,
  contract,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <div className={styles.root}>
      <Header
        open={open}
        name={pricePlan.displayName}
        onClick={() => setOpen(!open)}
      />
      <motion.div
        className={styles.table}
        animate={{
          height: open ? "auto" : 0,
          opacity: open ? 1 : 0,
        }}
        transition={{ type: "tween", ease: "easeInOut" }}
        initial={false}
      >
        <TableMemo
          prices={pricePlan.prices}
          currency={pricePlan.currency || Currency.SWEDEN}
          language={language}
        />
        <FooterMemo productType={contract.productType} />
      </motion.div>
    </div>
  );
};
