import { API } from "../network/API";
import { data } from "./data";
import { UTCDate } from "./models/CommonTypes";
import { MccRiskLevel } from "./models/ContractTypes";

export const RISK_LEVEL_OPTIONS = [
  {
    key: MccRiskLevel.NONE,
    value: MccRiskLevel.NONE,
    text: "None",
  },
  {
    key: MccRiskLevel.RISK_APPROVAL,
    value: MccRiskLevel.RISK_APPROVAL,
    text: "Risk Approval",
  },
  {
    key: MccRiskLevel.NOT_ALLOWED,
    value: MccRiskLevel.NOT_ALLOWED,
    text: "Not Allowed",
  },
];

export interface AdminMerchantCategory {
  id: number;
  code: string;
  name: string;
  note: string;
  category: string;
  cardNotPresent: MccRiskLevel;
  faceToFace: MccRiskLevel;
  created: UTCDate;
  createdBy: string;
  updated?: UTCDate;
  updatedBy?: string;
}

export interface NewMccRequest {
  code: string;
  name: string;
  note: string;
  category: string;
  cardNotPresent: MccRiskLevel;
  faceToFace: MccRiskLevel;
}

export interface UpdateMccRequest {
  name: string;
  note: string;
  category: string;
  cardNotPresent: MccRiskLevel;
  faceToFace: MccRiskLevel;
}

export const dataMccAdministration = {
  loadAll: () => data.get<AdminMerchantCategory[]>(`/api/admin/mcc`),
  load: (id: number) => data.get<AdminMerchantCategory>(`/api/admin/mcc/${id}`),
  addNewMcc: (request: NewMccRequest) => data.post(`/api/admin/mcc`, request),
  deleteMcc: (id: number) => data.delete(`/api/admin/mcc/${id}`),
  updateMcc: (id: number, request: UpdateMccRequest) =>
    data.post<AdminMerchantCategory>(`/api/admin/mcc/${id}`, request),
  uploadExcel: (file: FormData) =>
    API.postFormData(`/api/admin/mcc/excel`, file),
};
