import { data } from "./data";
import { AssociateIdentity, AssociateRole } from "./models/AssociateTypes";
import { AssociateId, ContractId, LinkId, UTCDate } from "./models/CommonTypes";
import { Notes, ReviewStatus } from "./models/ContractTypes";

export enum InviteType {
  INVITE_TO_CONFIRM = "INVITE_TO_CONFIRM",
  INVITE_TO_SUBMIT = "INVITE_TO_SUBMIT",
  REMINDER_TO_SUBMIT = "REMINDER_TO_SUBMIT",
  RE_SUBMIT_DOCUMENT = "RE_SUBMIT_DOCUMENT",
}

export interface ViewLog {
  linkId: LinkId;
  created: UTCDate;
  ip: string;
}

export interface SalesContact {
  name: string;
  phoneNumber?: string;
  email: string;
}

export interface MerchantAssociateSignature {
  uploaded: UTCDate;
  image: string; //Base64 image, borde vi ha en bättre value class?
  ipAddress: string;
  country?: string;
  city?: string;
}

export interface RemoteIdentificationDocument {
  uploaded?: UTCDate;
  idImage?: string;
  signedOff?: UTCDate;
  signedOffBy?: string;
  ipAddress?: string;
  userAgent?: string;
  country?: string;
  city?: string;
}

export interface AssociateReview {
  name: string;
  roles: AssociateRole[];
  signedOff?: UTCDate;
  signedOffBy?: UTCDate;
  signature?: MerchantAssociateSignature;
  identificationData: AssociateIdentity;
  identityDocument: RemoteIdentificationDocument;
  email: string;
  number?: string;
}

export const BANK_STATEMENT_DOCUMENT = "";

export enum DvsStatus {
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
  TIMEOUT = "TIMEOUT",
}

const BASE_URL = "/api/sales";

export const dataMerchant = {
  getReviewStatus: (contractId: ContractId) =>
    data.get<ReviewStatus>(`/api/sales/signoff/${contractId}`),

  confirmReview: (contractId: ContractId, notes?: Notes) =>
    data.post<ReviewStatus>(`/api/sales/signoff/${contractId}`, notes),

  fetchReviewStatus: (contractId: ContractId) => ({
    queryKey: dataMerchant.getReviewKey(contractId),
    queryFn: () => data.get<ReviewStatus>(`${BASE_URL}/signoff/${contractId}`),
  }),

  getReviewKey: (contractId: ContractId) => {
    return ["review", contractId];
  },

  getAssociateReview: (contractId: ContractId, associateId: AssociateId) =>
    data.get<AssociateReview>(
      `/api/sales/signoff/${contractId}/associates/${associateId}`
    ),

  signoffOnAssociate: (
    contractId: ContractId,
    associateId: AssociateId,
    identity: AssociateIdentity
  ) => {
    return data.post<{
      dvsStatus: DvsStatus;
    }>(
      `/api/sales/signoff/${contractId}/associates/${associateId}/identity`,
      identity
    );
  },

  /* När all data är insamlad och alla bilder är bekräftade trycker sales på den här knappen för att påbörja uppskapandet */
  confirmContractComplete: (contractId: ContractId) =>
    data.post<void>(`/api/sales/signoff/${contractId}/complete`),

  //Revokes all links, removes all confirmations and sets contract back to editing mode
  rectractContract: (contractId: ContractId) =>
    data.post<void>(`/api/sales/signoff/${contractId}/revoke`),

  //Sends reminders to everyone that still hasnt uploaded the documents
  sendReminders: (contractId: ContractId) =>
    data.post(`/api/sales/signoff/${contractId}/reminders`),

  resendLink: (
    contractId: ContractId,
    associateId: AssociateId,
    email?: string,
    phoneNumber?: string
  ) =>
    data.post(
      `/api/sales/signoff/${contractId}/associate/${associateId}/remind`,
      {
        email,
        phoneNumber,
      }
    ),

  revokeLink: (contractId: ContractId, linkId: LinkId) =>
    data.delete(`/api/sales/signoff/${contractId}/links/${linkId}`),
};
