import styles from "./Prepayment.module.scss";
import { T } from "../../../../../../components/translation/T";
import { RequiredValidator } from "../../../../../../components/form/validators/RequiredValidator";
import { FC } from "react";
import { NumberInput } from "../../../../../../components/form/NumberInput";
// import { BsQuestionCircleFill } from "react-icons/bs";
import { Beacon } from "../../../../../../components/beacon/Beacon";
import { Validity } from "../../../../../../data/models/ContractTypes";
import { MaxValidator } from "../../../../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../../../../components/form/validators/MinValidator";
import { ButtonPaneCollapse } from "../../ButtonPaneCollapse/ButtonPaneCollapse";
import cx from "classnames";
import {
  PrepaidData,
  PrepaidOptions,
} from "../../../../../../data/dataBusinessRisk";
import { useTranslation } from "react-i18next";

interface Props {
  hasPrepayment: boolean | undefined;
  setHasPrepayment: (value: boolean | undefined) => void;
  prepaidData: PrepaidData | undefined;
  onChangePrepaidData: (data: PrepaidData) => void;
  disabled?: boolean;
}

const calculateTotalPercentageFilled = (data: PrepaidData | undefined) => {
  if (!data) return 0;
  const total = Object.values(data).reduce((acc, value) => {
    if (value) return acc + value;
    return acc;
  }, 0);
  return total;
};

export const Prepayment: FC<Props> = ({
  hasPrepayment,
  setHasPrepayment,
  prepaidData,
  onChangePrepaidData,
  disabled,
}) => {
  const { t } = useTranslation();
  const filled = calculateTotalPercentageFilled(prepaidData);
  const leftToFill = 100 - filled;
  const isCompleted = 100 - filled === 0;

  const handleInputChange = (
    key: PrepaidOptions,
    value: number | undefined
  ) => {
    const newPrepaidData = { ...prepaidData, [key]: Math.trunc(value || 0) };
    onChangePrepaidData(newPrepaidData);
  };

  const formatLeftToFill = () => {
    if (isCompleted) return null;
    if (leftToFill > 100) return "100%";
    return `${Math.abs(leftToFill)}%`;
  };

  const getRemainingText = () => {
    if (isCompleted) return "completed";
    if (leftToFill < 0) return "to remove";
    return "remaining";
  };
  const getBeaconText = () => {
    if (isCompleted) return <T>Completed!</T>;
    if (leftToFill < 0) return <T>The sum is more than 100 %</T>;
    return <T>The sum is less than 100 %</T>;
  };

  const hintClass = cx(styles.hint, {
    [styles.hintCompleted]: isCompleted,
  });

  return (
    <ButtonPaneCollapse
      label="Does your company accept pre-payment by credit card?"
      value={hasPrepayment}
      title={t("Pre-payment") || "Pre-payment"}
      expanded={hasPrepayment}
      className="m-top-20"
      onChange={setHasPrepayment}
      small
      disabled={disabled}
      buttons={[
        {
          text: "Yes",
          active: hasPrepayment === true,
          data: true,
        },
        {
          text: "No",
          active: hasPrepayment === false,
          data: false,
        },
      ]}
      validators={[new RequiredValidator("Required to answer")]}
    >
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.instructions}>
            <T>
              Enter the percentage for the average number of days it takes from
              payment date to delivery
            </T>
          </div>
          {/* <div className={styles.tooltip}>
            <BsQuestionCircleFill />
          </div> */}
        </div>
        <div className={styles.grid}>
          {Object.values(PrepaidOptions).map((option) => (
            <NumberInput
              className={`${prepaidData?.[option] ? "" : "color-text-passive"}`}
              value={prepaidData?.[option]}
              key={option}
              placeholder={"%"}
              suffix={"%"}
              label={`${option} days (%)`}
              attributes={{
                decimalScale: 0,
              }}
              disabled={disabled}
              onChange={(value) => handleInputChange(option, value)}
              validators={
                prepaidData?.[option]
                  ? [
                      new MaxValidator(100, "Max 100%"),
                      new MinValidator(0, "Require positive value"),
                    ]
                  : []
              }
            />
          ))}
        </div>
        <div className={styles.footer}>
          <div className={styles.beacon}>
            <Beacon
              validity={isCompleted ? Validity.VALID : Validity.MISSING}
            />
            <div className={hintClass}>{getBeaconText()}</div>
          </div>
          <div>
            <div className={styles.filled}>{filled}%</div>
            <div className={styles.remaining}>
              {formatLeftToFill()} <T>{getRemainingText()}</T>
            </div>
          </div>
        </div>
      </div>
    </ButtonPaneCollapse>
  );
};
