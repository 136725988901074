import cx from "classnames";
import styles from "./SectionFieldSet.module.scss";
import { FunctionComponent } from "react";
import { T } from "../translation/T";
import { useFormValidity } from "../form/MultiFormContext";
import { TSwitch } from "../translation/TSwitch";
import { Validity } from "../../data/models/ContractTypes";

interface Props {
  sectionNumber?: number;
  headerTitle: string;
  formName: string;
}

export const SectionFieldSetHeader: FunctionComponent<Props> = ({
  headerTitle,
  sectionNumber,
  formName,
}) => {
  const validity = useFormValidity(formName);

  return (
    <div className={styles.header}>
      <div className={cx(styles.titleWrapper, styles[validity])}>
        <h5 className={styles.index}>{`0${sectionNumber}`}</h5>
        <h4 className={styles.title}>
          <T>{headerTitle}</T>
        </h4>
      </div>
      <hr className={styles.divider} />

      <div className={cx(styles.status, styles[validity])}>
        <TSwitch>
          <T.Condition condition={validity !== Validity.VALID}>
            incomplete
          </T.Condition>
          <T.Default>completed</T.Default>
        </TSwitch>
      </div>
    </div>
  );
};
