import React, { useCallback, useEffect, useState } from "react";
import { PageStripped } from "../PageStripped";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { Status } from "../../data/types";
import {
  LookupContractResponse,
  LookupStatus,
  dataContracts,
} from "../../data/dataContracts";
import { CorrelationId } from "../../data/models/CommonTypes";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { Spinner } from "../../components/spinner/Spinner";
import { T } from "../../components/translation/T";
import { CONTRACT_ROUTE } from "../sales/Contract/ContractPage";
import { REVIEW_ROUTE } from "../sales/Review/ReviewPage";
import { COMPLETED_ROUTE } from "../sales/Completed/CompletedPage";
import { GenericError } from "../../components/boxes/GenericError";
import { Button } from "../../components/interactions/Buttons/Button";
import { CreateContract } from "./CreateContract";
import { STORE_KEY, Store } from "../../Store";
import { useSetRecoilState } from "recoil";
import { contractAuthState } from "../../state/contractAuthState";

export const ROUTING_ROUTE = "/salesforce";
export const CORRELATION_ID_KEY = "id";
export const IS_PARTNER_KEY = "ispartner";

function getRouteByStatus(lookupResponse: LookupContractResponse) {
  if (lookupResponse.status === LookupStatus.SALES) {
    return generatePath(CONTRACT_ROUTE, {
      contractId: lookupResponse.contractId || "",
    });
  }

  if (lookupResponse.status === LookupStatus.MERCHANT) {
    return generatePath(REVIEW_ROUTE, {
      contractId: lookupResponse.contractId || "",
    });
  }

  if (lookupResponse.status === LookupStatus.COMPLETE) {
    return generatePath(COMPLETED_ROUTE, {
      contractId: lookupResponse.contractId || "",
    });
  }

  throw new Error(`Unsupported status - ${lookupResponse.status}`);
}

export function RoutingPage() {
  const [params] = useSearchParams();
  const setAuthState = useSetRecoilState(contractAuthState);
  const correlationId = params.get(CORRELATION_ID_KEY);
  const isPartner = !!params.get(IS_PARTNER_KEY);

  useEffect(() => {
    const { referrer } = document;

    Store.setValue(STORE_KEY.STORE_IS_PARTNER, isPartner);
    setAuthState((prev) => ({ ...prev, isPartner }));

    if (!referrer) {
      return;
    }

    Store.setValue(STORE_KEY.STORE_REFERRER, referrer);
    setAuthState((prev) => ({ ...prev, referrer }));
  }, [setAuthState, isPartner]);

  if (!correlationId) {
    return (
      <PageStripped title="Checking contract status...">
        <section>
          <article>
            <div className="m-top-40">
              <GenericError center>
                <T>
                  The link is invalid. The url must include a correlation ID in
                  order for us to proceed.
                </T>
              </GenericError>
            </div>
          </article>
        </section>
      </PageStripped>
    );
  }

  return <RoutingPageInner correlationId={correlationId as CorrelationId} />;
}

function RoutingPageInner({ correlationId }: { correlationId: CorrelationId }) {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const navigate = useNavigate();

  const load = useCallback(() => {
    dataContracts
      .companyLookup(correlationId)
      .then((data) => {
        Store.setValue(STORE_KEY.STORE_CORRELATION_ID, correlationId);

        if (data.status === LookupStatus.NEW) {
          setStatus(Status.SUCCESS);
          return;
        }

        navigate(getRouteByStatus(data));
      })
      .catch((err) => setStatus(Status.ERROR));
  }, [navigate, correlationId]);

  useEffect(() => {
    load();
  }, [load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);

    setTimeout(() => {
      load();
    }, 500);
  }, [load]);

  return (
    <PageStripped title="Checking contract status...">
      <section>
        <article>
          <div className="m-top-40">
            <AnimateHeight name={status}>
              <div>
                {status === Status.SUCCESS /** this is a new contract */ && (
                  <CreateContract />
                )}

                {status === Status.PENDING && (
                  <div className="center">
                    <T>Lookup contract status</T>
                    <Spinner size="large" className="m-top-40" />
                  </div>
                )}

                {status === Status.ERROR && (
                  <GenericError center>
                    <div>
                      <T>Something went wrong. Check contract status again?</T>
                      <div className="m-top-30">
                        <Button block onClick={retry}>
                          <T>Retry</T>
                        </Button>
                      </div>
                    </div>
                  </GenericError>
                )}
              </div>
            </AnimateHeight>
          </div>
        </article>
      </section>
    </PageStripped>
  );
}
