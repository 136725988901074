import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Card.module.scss";

interface Props {
  className?: string;
  children: ReactNode;
}

export const Card: React.FunctionComponent<Props> = ({
  className,
  children,
}) => {
  return <div className={cx(className, styles.wrapper)}>{children}</div>;
};
