import React, { useCallback, useMemo, useRef } from "react";
import cx from "classnames";
import { Status } from "../../../data/types";
import { id } from "../../utils";
import { Checkmark } from "../../icons/Checkmark";
import { Disabled } from "../../icons/Disabled";
import { Pending } from "../../icons/Pending";
import { Error } from "../../icons/Error";
import { Alternative } from "../InputTypes";
import { Expand } from "../../icons/Expand";
import {
  createTranslation,
  createTranslationOrEmpty,
  T,
} from "../../translation/T";
import "../Input/Base.scss";
import "../Input/Input.scss";

interface AlternativeWithId<T> extends Alternative<T> {
  id: string;
}

export interface Props<T> {
  onChange: (
    value: T,
    name: string,
    ev: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  alternatives: Alternative<T>[];
  status?: Status;
  className?: string;
  value?: T;
  placeholder?: string;
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  onBlur?: (value: T | undefined, name: string) => void;
  name?: string;
  message?: string | React.ReactNode;
  required?: boolean;
}

function InnerDropdown<T>(
  {
    status = Status.DEFAULT,
    className,
    name,
    label = null,
    onChange,
    onBlur,
    message = null,
    hint = null,
    placeholder = "",
    alternatives = [],
    value,
    required,
  }: Props<T>,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  const identifier = useRef<string>(id());
  const prevValue = useRef<T | undefined>(value);
  const emptyIdentifier = useRef<string>("");

  const alternativesWithIds = useMemo(() => {
    return alternatives.map((alternative, idx): AlternativeWithId<T> => {
      const id = `radio_${idx}_${Math.random().toString(36).substring(2, 9)}`;

      if (!alternative.value) {
        emptyIdentifier.current = id;
      }

      return {
        ...alternative,
        id,
      };
    });
  }, [alternatives]);

  const change = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const currentAltWithId = alternativesWithIds.find(
        (alternative) => alternative.id === event.target.value
      );
      currentAltWithId &&
        onChange(currentAltWithId.value, name || identifier.current, event);
    },
    [alternativesWithIds, onChange, name]
  );

  const onFocus = useCallback(() => {
    prevValue.current = value;
  }, [value]);

  const blur = useCallback(() => {
    if (prevValue.current === value) {
      return;
    }

    onBlur && onBlur(value, name || identifier.current);
  }, [onBlur, value, name]);

  const opts = useMemo(() => {
    return alternativesWithIds.map((alternative) => (
      <option
        key={`${alternative.text}-${alternative.value}`}
        value={alternative.id}
        data-value={alternative.value}
        disabled={alternative.disabled}
      >
        {alternative.text}
      </option>
    ));
  }, [alternativesWithIds]);

  return (
    <label
      className={cx("input", "input-dropdown", className, status)}
      htmlFor={identifier.current}
    >
      <div className="input-label-wrapper">
        <div className="input-label">
          <div className="input-label-tag">
            {createTranslationOrEmpty(label)}
          </div>
        </div>
        {!value && required && (
          <div className="required-marker text-small">
            <T>required</T>
          </div>
        )}
      </div>

      <div className="input-frame">
        <select
          name={identifier.current}
          id={identifier.current}
          onChange={change}
          onBlur={blur}
          placeholder={placeholder}
          disabled={status === Status.DISABLED || status === Status.PENDING}
          value={
            alternativesWithIds.find(
              (alternative) => alternative.value === value
            )?.id || emptyIdentifier.current
          }
          onFocus={onFocus}
          ref={ref}
        >
          {opts}
        </select>

        <div className="input-status">
          <Expand />
          <Checkmark />
          <Disabled />
          <Pending />
          <Error />
        </div>
      </div>

      <div className="input-messages">
        <div className="input-hint">{createTranslation(hint)}</div>
        <div className="input-message">{createTranslation(message)}</div>
      </div>
    </label>
  );
}

declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export const Dropdown = React.forwardRef(InnerDropdown);
