import { AnimatePresence, motion, MotionProps } from "framer-motion";
import React from "react";
import cx from "classnames";

interface Props {
  className?: string;
  presence?: string | boolean;
  key?: string;
}

const motionProps: MotionProps = {
  initial: { height: 0, opacity: 0, overflow: "hidden" },
  animate: {
    height: "auto",
    opacity: 1,
    overflow: "visible",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    overflow: "hidden",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
      opacity: { duration: 0.15 },
    },
  },
};

export const AnimateHeightMotion: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children, className, presence }) => {
  return typeof presence !== "undefined" ? (
    <AnimatePresence initial={false}>
      {children && (
        <motion.div
          className={cx("animate-height-motion", className)}
          {...motionProps}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  ) : (
    <motion.div
      className={cx("animate-height-motion", className)}
      {...motionProps}
    >
      {children}
    </motion.div>
  );
};
