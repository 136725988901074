import { Language } from "../i18n";
import { cache } from "../network/Cache";
import { data } from "./data";

export type TemplateId = string & { isTemplateId: true };

export enum InternalEmailProperties {
  NAME = "name",
  COMPANY_NAME = "companyName",
  OTP = "otp",
}

export enum InternalTextProperties {
  NAME = "name",
  COMPANY_NAME = "companyName",
  LINK = "link",
}

export interface EmailTemplateAndTranslationsResponse {
  template: string;
  templateId: TemplateId;
  templateName: string;
  translation: {
    language: Language;
    properties: Record<string, string>;
    subject: string;
    internalProperties: InternalEmailProperties[];
  };
}

export interface EmailsResponse {
  templateId: string;
  templateName: string;
}

export interface TextTemplateAndTranslationsResponse {
  language: Language;
  templateId: TemplateId;
  templateName: string;
  text: string;
  internalProperties: InternalTextProperties[];
}

export const dataCommunication = {
  getEmails() {
    return data.get<EmailsResponse[]>(`/api/communication/email`);
  },
  getEmailTemplateAndTranslations(templateId: TemplateId, language: Language) {
    return data.get<EmailTemplateAndTranslationsResponse>(
      `/api/communication/email/${templateId}/${language}`
    );
  },
  postEmailTranslations(
    templateId: TemplateId,
    language: Language,
    subject: string,
    parameters: Record<string, string>
  ) {
    return data
      .post(`/api/communication/email/${templateId}/${language}`, {
        subject,
        parameters,
        language,
      })
      .then(() => {
        cache.delete(`/api/communication/email/${templateId}/${language}`);
      });
  },
  postEmailPreview(
    templateId: TemplateId,
    email: string,
    subject: string,
    parameters: Record<string, string>
  ) {
    return data.post(`/api/communication/email/${templateId}`, {
      email,
      subject,
      parameters,
    });
  },
  getTexts() {
    return data.get<TextTemplateAndTranslationsResponse[]>(
      `/api/communication/sms`
    );
  },
  getTextTemplateAndTranslations(templateId: TemplateId, language: Language) {
    return data.get<TextTemplateAndTranslationsResponse>(
      `/api/communication/sms/${templateId}/${language}`
    );
  },
  postTextTranslations(
    templateId: TemplateId,
    language: Language,
    text: string
  ) {
    return data
      .post(`/api/communication/sms/${templateId}/${language}`, {
        text,
        language,
      })
      .then(() => {
        cache.delete(`/api/communication/sms/${templateId}/${language}`);
      });
  },
};
