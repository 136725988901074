import i18n, { Language } from "../../i18n";
import { Alternative } from "../interactions/InputTypes";

const displayNamesCache: Record<string, Intl.DisplayNames> = {};
const displayLanguageCache: Record<string, Intl.DisplayNames> = {};

export const getDisplayNames = (language: Language) => {
  if (!displayNamesCache[language]) {
    displayNamesCache[language] = new Intl.DisplayNames([language], {
      type: "region",
    });
  }
  return displayNamesCache[language];
};

export const getDisplayLanguageNames = (language: Language) => {
  if (!displayLanguageCache[language]) {
    displayLanguageCache[language] = new Intl.DisplayNames([language], {
      type: "language",
    });
  }
  return displayLanguageCache[language];
};

export const getLanguageOpts = (
  allowEmpty: boolean = false,
  language: Language | string = i18n.language,
  languages: Language[] = Object.values(Language)
) => {
  const languageNames = new (Intl as any).DisplayNames(language, {
    type: "language",
  });

  const languageList = languages.map(
    (language): Alternative<string | undefined> => ({
      value: language,
      text: languageNames.of(language),
      disabled: false,
    })
  );

  if (allowEmpty) {
    languageList.unshift({
      value: undefined,
      text: "None",
      disabled: false,
    });
  } else {
    languageList.unshift({
      value: undefined,
      text: "Language",
      disabled: true,
    });
  }

  return languageList;
};

export const getCountryDisplayName = (
  country: string,
  currentLanguage?: Language | string
) => {
  const lang =
    currentLanguage || i18n.language
      ? i18n.language
      : Language.ENGLISH.toString();

  if (!displayNamesCache[lang]) {
    displayNamesCache[lang] = new Intl.DisplayNames(lang, {
      type: "region",
    });
  }

  return displayNamesCache[lang].of(country) || country;
};

export const getLanguageName = (
  language: Language,
  currentLanguage?: Language | string
) => {
  const lang =
    currentLanguage || i18n.language
      ? i18n.language
      : Language.ENGLISH.toString();
  if (!displayLanguageCache[lang]) {
    displayLanguageCache[lang] = new Intl.DisplayNames(lang, {
      type: "language",
    });
  }

  return displayLanguageCache[lang].of(language);
};
