import { FC } from "react";
import { NumberInput } from "../../../../../../components/form/NumberInput";
import { TextInput } from "../../../../../../components/form/TextInput";
import { MaxValidator } from "../../../../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../../../../components/form/validators/MinValidator";
import { RequiredValidator } from "../../../../../../components/form/validators/RequiredValidator";
import { ButtonPaneCollapse } from "../../ButtonPaneCollapse/ButtonPaneCollapse";
import styles from "./Giftcards.module.scss";
import { GiftCardsData } from "../../../../../../data/dataBusinessRisk";
import { useTranslation } from "react-i18next";

interface Props {
  hasGiftCards?: boolean;
  setHasGiftCards: (value: boolean | undefined) => void;
  giftCardsData?: GiftCardsData;
  onChangeGiftCardsData: (data: GiftCardsData) => void;
  disabled?: boolean;
}

export const Giftcards: FC<Props> = ({
  hasGiftCards,
  setHasGiftCards,
  giftCardsData,
  onChangeGiftCardsData,
  disabled,
}) => {
  const handleInputChange = (
    key: string,
    value: string | number | undefined
  ) => {
    const newGiftCardsData = { ...giftCardsData, [key]: value };
    onChangeGiftCardsData(newGiftCardsData);
  };

  const { t } = useTranslation();

  return (
    <ButtonPaneCollapse
      title={t("Gift cards") || "Gift cards"}
      label="Does your company sell gift cards?"
      value={hasGiftCards}
      expanded={hasGiftCards}
      className="m-top-20"
      onChange={setHasGiftCards}
      small
      buttons={[
        {
          text: "Yes",
          active: hasGiftCards === true,
          data: true,
        },
        {
          text: "No",
          active: hasGiftCards === false,
          data: false,
        },
      ]}
      disabled={disabled}
      validators={[new RequiredValidator("Required to answer")]}
    >
      <div className={styles.root}>
        <NumberInput
          value={giftCardsData?.salesAmount}
          placeholder={"%"}
          label={"Part of sales (%)"}
          onChange={(value) => handleInputChange("salesAmount", value)}
          attributes={{
            decimalScale: 0,
          }}
          disabled={disabled}
          suffix="%"
          validators={[
            new RequiredValidator("Required to answer"),
            new MaxValidator(100, "Max 100%"),
            new MinValidator(0, "Require positive value"),
          ]}
        />
        <TextInput
          value={giftCardsData?.validityPeriod}
          placeholder="1 month, 3 months, 1 year, etc."
          label="How long are these valid?"
          onChange={(value) => handleInputChange("validityPeriod", value)}
          validators={[new RequiredValidator("Required to answer")]}
          disabled={disabled}
        />
      </div>
    </ButtonPaneCollapse>
  );
};
