import React from "react";
import { StyleSheet, Document, Page, View, Text } from "@react-pdf/renderer";
import { getDefaultStyles } from "../PdfStyles";
import { Header } from "../componentParts/Header";
import { Footer } from "../componentParts/Footer";
import { CompanyInformation as Info } from "../componentParts/CompanyInformation";
import { PrimaryContact } from "../componentParts/PrimaryContact";
import { ComponentProps } from "../PdfPage";
import { Wrapper } from "../Wrapper";
import { FirstCell } from "../componentParts/FirstCell";
import { useTranslation } from "react-i18next";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

function getLabels(t: any) {
  return {
    header: t("Product"),
    product: t("Worldline Product or service used"),
  };
}

const Doc = (props: ComponentProps) => {
  const { t } = useTranslation();
  const styles = {
    ...getDefaultStyles(props.show),
    ...getSpecificStyles(props.show),
  };

  const labels = getLabels(t);

  return (
    <Document
      creator="Worldline"
      producer="Worldline"
      language={props.language.toString()}
    >
      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />
        <Info {...props} />
        <View style={styles.divider} />
        <PrimaryContact {...props} />
        <View style={styles.divider} />
        <View style={styles.sectionHeader}>
          <Text>{labels.header}</Text>
        </View>
        <View>
          <FirstCell label={labels.product} show={props.show}>
            {props.productType}
          </FirstCell>
          <FirstCell label="&nbsp;" show={props.show}>
            "Ungrateful fatherland, you will not even have my bones"
          </FirstCell>
          <FirstCell label="&nbsp;" show={props.show}>
            - Scipio Africanus
          </FirstCell>
        </View>
        <View style={styles.divider} />
        <Footer {...props} />
      </Page>
    </Document>
  );
};

export const CompanyInformation: React.FunctionComponent<ComponentProps> = (
  props
) => {
  return (
    <Wrapper {...props} name="company-information">
      <Doc {...props} />
    </Wrapper>
  );
};
