import { useEffect, useRef, useState } from "react";
import { useQueryClient, useSuspenseQueries } from "@tanstack/react-query";
import { getVatIdFromRegNumber } from "../../../../components/companyRegistrationId/FormattedCompanyRegistrationId";
import { Form } from "../../../../components/form/Form";
import { LegalEntityTypeDisplay } from "../../../../components/legalEntityType/LegalEntityTypeDisplay";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { SectionFieldSet } from "../../../../components/sectionFieldSet/SectionFieldSet";
import { FormattedIntlDate } from "../../../../components/time/FormattedIntlDate";
import { T } from "../../../../components/translation/T";
import { dataContracts } from "../../../../data/dataContracts";
import { dataDocuments } from "../../../../data/dataDocuments";
import { MainContractData } from "../../../../data/models/ContractTypes";
import { financialDataQueue } from "../../../../data/queues/dataQueues";
import { useContractEditStatus } from "../../../../hooks/useContractEditStatus";
import { useContractId } from "../../../../hooks/useContractId";
import { useCountry } from "../../../../hooks/useCountry";
import { LinkAnchors } from "../ContractEdit";
import { FormName } from "../menus/ContractEditMenu";
import { AddressRow } from "../../../../components/address/AddressRow";
import { FinancialData, dataFinancial } from "../../../../data/dataFinancial";

import "./CompanyData.scss";
import { useRecoilValue } from "recoil";
import { disabledState } from "../../../../state/contractState";
import {
  SaveProps,
  UpdateWrapper,
  isSuccessfulResponse,
} from "../../../../components/errors/UpdateWrapper";
import { MCCSelection } from "../../../../components/mcc/MCCSelection";
import { Status } from "../../../../data/types";

export const CompanyData: React.FunctionComponent = () => {
  const contractId = useContractId();
  return (
    <SectionFieldSet
      headerTitle={LinkAnchors.COMPANY_DATA.name}
      formName={FormName.MAIN_DATA}
      sectionNumber={2}
    >
      <UpdateWrapper
        contractId={contractId}
        dataQueue={financialDataQueue}
        queryKey={dataFinancial.getFinancialDataKey(contractId)}
      >
        {(trySave, isSaving, data) => (
          <CompanyDataInner trySave={trySave} isSaving={isSaving} data={data} />
        )}
      </UpdateWrapper>
    </SectionFieldSet>
  );
};

function CompanyDataInner({ trySave, data }: SaveProps<FinancialData>) {
  const contractId = useContractId();
  const country = useCountry();
  const status = useContractEditStatus();
  const formRef = useRef<HTMLFormElement>(null);

  const [{ data: contract }, { data: financialData }] = useSuspenseQueries({
    queries: [
      dataContracts.fetchContract(contractId),
      dataFinancial.fetchFinancialData(contractId),
    ],
  });

  const mccRef = useRef<string | undefined>(financialData.mcc);
  const [companyData] = useState<MainContractData>(contract.contractData);
  const [financial, setFinancial] = useState<FinancialData>(financialData);
  const isDisabled = useRecoilValue(disabledState);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isSuccessfulResponse(data)) {
      return;
    }

    if (mccRef.current === data.mcc) {
      return;
    }

    mccRef.current = data.mcc;
    queryClient.invalidateQueries({
      queryKey: dataDocuments.getDocumentsKey(contractId),
    });
  }, [data, queryClient, contractId]);

  return (
    <Form name={FormName.MAIN_DATA} ref={formRef}>
      <div className="company-data">
        <ScrollPositionAnchor id={LinkAnchors.COMPANY_DATA.anchor} />

        <div className="tablet-columns">
          <div>
            <i>
              <T>Company name</T>:
            </i>
          </div>
          <div>
            <b>{companyData.companyName}</b>
          </div>
          <div>
            <i>
              <T>Company Registration ID</T>:
            </i>
          </div>
          <div>{companyData.organizationNumber}</div>
          <div>
            <i>
              <T>VAT id</T>:
            </i>
          </div>
          <div>
            {getVatIdFromRegNumber(country, companyData.organizationNumber)}
          </div>
          <div>
            <i>
              <T>Legal form</T>:
            </i>
          </div>
          <div>
            <LegalEntityTypeDisplay
              legalEntityType={companyData.legalEntityType}
            />
          </div>
          <div>
            <i>
              <T>Date of incorperation</T>:
            </i>
          </div>
          <div>
            <FormattedIntlDate date={companyData.dateOfIncorporation} />
          </div>
          <div>
            <i>
              <T>Phone number</T>:
            </i>
          </div>
          <div>{companyData.phoneNumber || <T>Set by merchant</T>}</div>
          <div>
            <i>
              <T>Company address</T>:
            </i>
          </div>
          <div />
        </div>
        <div className="m-top-10">
          <AddressRow address={companyData.address} />
        </div>
        <div className="m-bottom-40" />

        <h4>Merchant Category Code (MCC)</h4>

        <div className="m-top-30">
          <MCCSelection
            formRef={formRef}
            onChange={(value) => {
              setFinancial({ ...financial, mcc: value });

              if (!value) {
                return;
              }

              trySave({ ...financial, mcc: value });
            }}
            mcc={financial.mcc || ""}
            name="mcc"
            active={!isDisabled && status !== Status.DISABLED}
          />
        </div>

        {/* <MCC
          onChange={(value) => {
            setFinancial({ ...financial, mcc: value });
            trySave({ ...financial, mcc: value });
          }}
          status={status}
          mcc={financial.mcc || ""}
          name="mcc"
          active={!isDisabled}
        /> */}
      </div>
    </Form>
  );
}
