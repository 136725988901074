import { useState, FunctionComponent, ReactNode } from "react";
import styles from "./SectionFieldSet.module.scss";
import { motion } from "framer-motion";
// components
import { SectionFieldSetHeader } from "./sectionFieldSetHeader";
import { Collapsible } from "../collapsible/Collapsible";
import { T } from "../translation/T";
import { Button } from "../interactions/Buttons/Button";
import { ErrorBoundary } from "../errors/ErrorBoundary";

interface Props {
  headerTitle: string;
  formName: string;
  children: ReactNode;
  sectionNumber?: number;
  className?: string;
  collapsible?: boolean;
}

export const SectionFieldSet: FunctionComponent<Props> = ({
  children,
  collapsible,
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className={styles.root}>
      <SectionFieldSetHeader {...props} />
      <div className={styles.content}>
        {collapsible ? (
          <Collapsible collapsed={isCollapsed}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Collapsible>
        ) : (
          <ErrorBoundary>{children}</ErrorBoundary>
        )}
      </div>
      {collapsible && (
        <div className={styles.expand}>
          <motion.div layout layoutId="expand-button">
            <Button
              className="small"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? <T>Show more</T> : <T>Show less</T>}
            </Button>
          </motion.div>
        </div>
      )}
    </div>
  );
};
