import { useCallback, useRef } from "react";
import { isEqual } from "../components/utils";
import { Cas } from "../data/models/CommonTypes";
import { DataWithCas } from "../data/queues/GenericDataQueue";

const mockCas = {
  cas: -1 as Cas,
};

export const useCompare = <T extends DataWithCas>(value: T | T[] | null) => {
  const ref = useRef<T | T[] | null>(value);

  const isUpdated = useCallback((data: T) => {
    return !isEqual(ref.current || mockCas, data);
  }, []);

  const setCompareRef = useCallback((data: T | null) => {
    ref.current = data;
  }, []);

  return {
    isUpdated,
    setCompareRef,
  };
};
