import styles from "./SalesData.module.scss";
import { FunctionComponent, useState, useCallback } from "react";
import { SectionFieldSet } from "../../../../components/sectionFieldSet/SectionFieldSet";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { FormName } from "../menus/ContractEditMenu";
import { LinkAnchors } from "../ContractEdit";
import { Form } from "../../../../components/form/Form";
import { Acquirer } from "./components/acquirer/Acquirer";
import { Prepayment } from "./components/prepayment/Prepayment";
import { Giftcards } from "./components/giftcards/Giftcards";
import { Membership } from "./components/membership/Membership";
import { useContractId } from "../../../../hooks/useContractId";
import { Products } from "../../../../components/products/Products";
import { ProductType } from "../../../../data/models/ContractTypes";
import { hasRealErrors } from "../../../../components/form/FormHelpers";
import { useRecoilValue } from "recoil";
import { contractState, disabledState } from "../../../../state/contractState";
import {
  BusinessRisk,
  dataBusinessRisk,
} from "../../../../data/dataBusinessRisk";
import { businessRiskDataQueue } from "../../../../data/queues/dataQueues";
import { PspAcquirer } from "./components/acquirer/PspAcquirer";
import { AcceptanceAcquirer } from "./components/acquirer/AcceptanceAcquirer";
import {
  SaveProps,
  UpdateWrapper,
} from "../../../../components/errors/UpdateWrapper";
import { useSuspenseQuery } from "@tanstack/react-query";

export const SalesData: FunctionComponent = () => {
  const contractId = useContractId();

  return (
    <SectionFieldSet
      headerTitle={LinkAnchors.SALES_DATA.name}
      formName={FormName.SALES_DATA}
      sectionNumber={4}
    >
      <UpdateWrapper
        contractId={contractId}
        dataQueue={businessRiskDataQueue}
        queryKey={dataBusinessRisk.getBusinessRiskKey(contractId)}
      >
        {(trySave, isSaving, data) => (
          <SalesDataInner trySave={trySave} isSaving={isSaving} data={data} />
        )}
      </UpdateWrapper>
    </SectionFieldSet>
  );
};

export const SalesDataInner: FunctionComponent<SaveProps<BusinessRisk>> = ({
  trySave,
}) => {
  const contractId = useContractId();
  const contract = useRecoilValue(contractState);
  const { data: businessRisk } = useSuspenseQuery(
    dataBusinessRisk.fetchBusinessRiskData(contractId)
  );

  const disabled = useRecoilValue(disabledState);
  const [salesData, setSalesData] = useState<BusinessRisk>({
    externalAcquirer: "Worldline",
    ...businessRisk,
  });

  const onUpdateOfferingData = useCallback(
    <T extends keyof BusinessRisk>(key: T, value: BusinessRisk[T]) => {
      setSalesData((prevData) => {
        const copy = {
          ...prevData,
          [key]: value,
        };

        return copy;
      });
    },
    [setSalesData]
  );

  const onChange = useCallback((value: string, name: string) => {
    setSalesData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  return (
    <div>
      <ScrollPositionAnchor id={LinkAnchors.SALES_DATA.anchor} />

      <Form
        onSaveTrigger={(_, form) => {
          const realErrors = hasRealErrors(form);
          if (!realErrors) {
            trySave({
              ...salesData,
              pspPartnerTerminalModel: salesData.pspPartnerTerminalModel || "",
            });
          }
        }}
        name={FormName.SALES_DATA}
      >
        <div className={styles.root}>
          <Products productType={contract.productType}>
            <Products.Default>
              <PspAcquirer
                onChange={onChange}
                businessRisk={salesData}
                disabled={disabled}
              />
            </Products.Default>
            <Products.Product
              products={[
                ProductType.ACCEPTANCE_INSTORE,
                ProductType.ACCEPTANCE_ONLINE,
                ProductType.BAMBORA_ONE,
                ProductType.BAMBORA_ONE_SHORT_TERM,
                ProductType.CHECKOUT,
              ]}
            >
              <Products productType={contract.productType}>
                <Products.Product
                  products={[
                    ProductType.ACCEPTANCE_INSTORE,
                    ProductType.ACCEPTANCE_ONLINE,
                  ]}
                >
                  <AcceptanceAcquirer
                    productType={contract.productType}
                    onChange={onChange}
                    businessRisk={salesData}
                    disabled={disabled}
                  />
                </Products.Product>
              </Products>

              <Acquirer
                hasPreviousAcquirer={salesData.hasFormerAcquirer}
                setHasPreviousAcquirer={(value) =>
                  onUpdateOfferingData("hasFormerAcquirer", value)
                }
                acquirer={salesData.formerAcquirer}
                onChangeAcquirer={(value) =>
                  setSalesData((prev) => ({
                    ...prev,
                    formerAcquirer: value,
                  }))
                }
                disabled={disabled}
              />

              <Prepayment
                hasPrepayment={salesData.hasPrepaid}
                setHasPrepayment={(value) =>
                  onUpdateOfferingData("hasPrepaid", value)
                }
                prepaidData={salesData.prepaidOptions}
                onChangePrepaidData={(value) =>
                  onUpdateOfferingData("prepaidOptions", value)
                }
                disabled={disabled}
              />
              <Giftcards
                hasGiftCards={salesData.hasGiftCards}
                setHasGiftCards={(value) =>
                  onUpdateOfferingData("hasGiftCards", value)
                }
                giftCardsData={salesData.giftCards}
                onChangeGiftCardsData={(value) =>
                  onUpdateOfferingData("giftCards", value)
                }
                disabled={disabled}
              />
              <Membership
                hasMembership={salesData.hasSubscriptionAndMembership}
                setHasMembership={(value) =>
                  onUpdateOfferingData("hasSubscriptionAndMembership", value)
                }
                membershipData={salesData.subscriptionAndMembership}
                onChangeMembershipData={(value) =>
                  onUpdateOfferingData("subscriptionAndMembership", value)
                }
                disabled={disabled}
              />
            </Products.Product>
          </Products>
        </div>
      </Form>
    </div>
  );
};
