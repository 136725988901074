import React, { useState, useEffect } from "react";
import { loadImage, loadPdf } from "./file";
import styles from "./ImageViewer.module.scss";

export const ImageViewer: React.FC<{ fileUrl: string }> = ({ fileUrl }) => {
  const [displayType, setDisplayType] = useState<"image" | "pdf" | "unknown">(
    "unknown"
  );

  useEffect(() => {
    const checkFileType = async () => {
      try {
        // Try to load the file as an image
        await loadImage(fileUrl);
        setDisplayType("image");
      } catch {
        // If loading as image fails, try to load it as a PDF
        try {
          await loadPdf(fileUrl);
          setDisplayType("pdf");
        } catch {
          // If both fail, set display type as unknown
          setDisplayType("unknown");
        }
      }
    };

    checkFileType();
  }, [fileUrl]);

  const renderViewer = () => {
    switch (displayType) {
      case "image":
        return <img src={fileUrl} alt="File" className={styles.height} />;
      case "pdf":
        return (
          <embed
            type="application/pdf"
            src={fileUrl}
            width="100%"
            className={styles.height}
          />
        );
      default:
        return <div>Unsupported file format</div>;
    }
  };

  return <div className={styles.viewer}>{renderViewer()}</div>;
};
