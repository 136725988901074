import cx from "classnames";
import React from "react";
import { External } from "../../../../components/icons/External";
import { Link } from "../../../../components/links/Link";
import { Status } from "../../../../data/types";
import "./IdImage.scss";
import { T } from "../../../../components/translation/T";

interface Props {
  image: IdImageInterface;
  status: Status;
  isGeneric?: boolean;
  height?: string;
  width?: string;
}

export enum FileType {
  PDF,
  IMAGE,
}

export const PDF_MIME_TYPE = "application/pdf";

export interface IdImageInterface {
  url?: string;
  type?: FileType;
}

export const IdImage: React.FunctionComponent<Props> = ({
  isGeneric,
  image,
  status,
  width = "100%",
  height = "700px",
}) => {
  if (status === Status.ERROR) {
    return (
      <div className="id-image default-error-box">
        <T>Something went wrong. We couldn't upload the image</T>
      </div>
    );
  }

  if (!image.url) {
    if (isGeneric) {
      return (
        <div className="id-image">
          <i>
            <T>No file has yet been uploaded</T>
          </i>
        </div>
      );
    }

    return (
      <div className="id-image">
        <i>
          <T>No file has yet been uploaded for this user</T>
        </i>
      </div>
    );
  }

  if (image.type === FileType.PDF) {
    return (
      <div className="id-image pdf-image">
        <object
          data={image.url}
          type={PDF_MIME_TYPE}
          width={width}
          height={height}
        >
          <T>PDF-viewer not supported.</T>{" "}
          <a
            className={cx("link", "external-link")}
            href={image.url}
            target="_blank"
            rel="noreferrer"
          >
            <T>Download here</T> <External />
          </a>
        </object>
      </div>
    );
  }

  return (
    <div className="id-image">
      <img src={image.url} alt="identification" />
      <div>
        <Link external link={image.url}>
          <T>View in new tab</T>
        </Link>
      </div>
    </div>
  );
};
