import { LegalEntityType } from "../../data/dataCompany";
import { T } from "../translation/T";

const legalEntityTypeTextMap: Record<LegalEntityType, string> = {
  [LegalEntityType.LIMITED]: "Limited company",
  [LegalEntityType.SOLE_PROPRIETARY]: "Sole proprietorship",
  [LegalEntityType.PARTNERSHIP]: "Partnership",
  [LegalEntityType.LIMITED_PARTNERSHIP]: "Limited partnership",
  [LegalEntityType.ASSOCIATION]: "Association",
  [LegalEntityType.TRUST]: "Trust",
  [LegalEntityType.MUNICIPALITY]: "Municipality",
};

export const LegalEntityTypeDisplay = ({
  legalEntityType,
}: {
  legalEntityType?: LegalEntityType;
}) => {
  if (!legalEntityType) {
    return <>-</>;
  }

  return (
    <span>
      <T>{legalEntityTypeTextMap[legalEntityType]}</T>
    </span>
  );
};

export const legalEntityTypeString = (legalEntityType: LegalEntityType) => {
  return legalEntityTypeTextMap[legalEntityType];
};
