import React from "react";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../../state/contractState";
import { T } from "../../../../components/translation/T";
import { ProductCard } from "../offering/products/ProductCard";
import { PRODUCT_OFFERINGS } from "../offering/products/Products";

export const ConfirmProduct: React.FunctionComponent = () => {
  const contract = useRecoilValue(contractState);

  return (
    <div>
      <b>
        <T>Product</T>
      </b>
      <ProductCard isSelected {...PRODUCT_OFFERINGS[contract.productType]} />
    </div>
  );
};
