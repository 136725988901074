import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../PdfStyles";
import { FirstCell } from "./FirstCell";
import { Cell } from "./Cell";
import { ComponentProps } from "../PdfPage";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({
    info: {
      margin: "4px 0 10px 0",
    },
  });
}

function getLabels(t: any) {
  return {
    giftcards: t("Giftcards"),
    lengthOfValidity: t("Length of giftcard validity"),
    partOfSales: t("Part of sales"),
  };
}

export const GiftCards: React.FunctionComponent<ComponentProps> = ({
  show = true,
}) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  return (
    <>
      <View style={styles.sectionHeader}>
        <Text>{labels.giftcards}</Text>
      </View>

      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.partOfSales} show={show}>
            10 %
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.lengthOfValidity} show={show}>
            3 months
          </Cell>
        </View>
      </View>
    </>
  );
};
