import cx from "classnames";
import {
  AssociateTracking,
  BankAccount,
  Contract,
  ContractDocument,
  EcomStore,
} from "../../../../../data/models/ContractTypes";
import { getTimelineAssociatesStart } from "../Timeline";
import { Associate } from "../../../../../data/models/AssociateTypes";

interface Props {
  active: boolean;
  associates: Associate[];
  primaryTracking?: AssociateTracking;
  contract: Contract;
  bankAccount?: BankAccount;
  documents: ContractDocument[];
  ecomStore?: EcomStore;
}

export const IndicatorGAssociates: React.FunctionComponent<Props> = ({
  active,
  primaryTracking,
  contract,
  bankAccount,
  documents,
  ecomStore,
  associates,
}) => {
  return (
    <circle
      r="4"
      cx="10"
      cy={getTimelineAssociatesStart(
        contract,
        associates,
        documents,
        primaryTracking,
        ecomStore,
        bankAccount
      )}
      className={cx({ active })}
    />
  );
};
