import React from "react";

export const CheckboxUnchecked: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className="checkbox-unchecked"
  >
    <path d="M19 3 Q21 3 21 5 L21 19 Q21 21 19 21 L5 21 Q3 21 3 19 L3 5 Q3 3 5 3 Z" />
  </svg>
);
