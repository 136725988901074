import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import "./Image.scss";

const PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

interface Props {
  active?: boolean;
  src: string;
  alt: string;
  ratio: number;
  className?: string;
}

export const Image: React.FunctionComponent<Props> = ({
  alt,
  src,
  active = true,
  ratio,
  className,
}) => {
  const [url, setUrl] = useState<string>(PLACEHOLDER);
  const [loaded, setLoaded] = useState<boolean>(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!active) {
      return;
    }

    if (!imageRef.current) {
      return;
    }

    const ref = imageRef.current;

    ref.onload = () => {
      setLoaded(true);
    };

    setUrl(src);

    return () => {
      ref.onload = null;
    };
  }, [src, active, imageRef]);

  return (
    <div
      className={cx("image", className, {
        loaded,
      })}
      style={{
        padding: `0 0 ${100 / ratio}% 0`,
      }}
    >
      <img src={url} alt={alt} ref={imageRef} />
    </div>
  );
};
