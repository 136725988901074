import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf",
      fontWeight: 100,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf",
      fontWeight: 200,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf",
      fontWeight: 300,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf",
      fontWeight: 400,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf",
      fontWeight: 500,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf",
      fontWeight: 600,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf",
      fontWeight: 700,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf",
      fontWeight: 800,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf",
      fontWeight: 900,
    },
  ],
});

export const COLOR_HIGHLIGHT = "#277777";

export function getDefaultStyles(show: boolean) {
  return StyleSheet.create({
    page: {
      padding: "20px 30px 50px 30px",
      fontFamily: "Inter",
      fontSize: 10,
      color: "#222",
      position: "relative",
      minHeight: "297mm",
      maxHeight: "297mm",
      minWidth: "210mm",
      maxWidth: "210mm",
    },
    sectionHeader: {
      fontWeight: 600,
      fontSize: 12,
      padding: "14px 0 2px 0",
      borderBottom: "2px solid #222",
    },
    divider: {
      padding: "40px 0 0 0",
    },
    split: {
      display: "flex",
      flexDirection: "row",
    },
    column: {
      flex: "0 0 50%",
    },
    largeColumn: {
      flex: "0 0 70%",
    },
    smallColumn: {
      flex: "0 0 30%",
    },
    quarterColumn: {
      flex: "0 0 25%",
    },
    fifthColumn: {
      flex: "0 0 20%",
    },
    placeholderVisibile: {
      opacity: show ? 1 : 0,
    },
    regular: {
      margin: "0 0 4px 0",
    },
    lastCellName: {
      borderLeft: "1px solid #fff",
    },
    lastCellValue: {
      borderLeft: "1px solid #222",
    },
    cellName: {
      fontSize: 8,
      padding: "5px 6px 0 6px",
    },
    cellValue: {
      margin: "5px 0 0 0",
      padding: "2px 6px 4px 6px",
      borderBottom: "1px solid #222",
    },
    pageHeader: {
      lineHeight: 1.4,
    },
    pageNumber: {
      textAlign: "right",
      fontSize: 10,
      fontWeight: 400,
    },
  });
}
