import { useRef, useCallback, useEffect, useState } from "react";

export function useDebounceFn(fn: (...args: any[]) => void, ms: number = 0) {
  const handler = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      if (handler && handler.current) {
        clearTimeout(handler.current);
      }
    };
  }, []);

  return useCallback(
    (...args: any) => {
      if (handler.current) {
        clearTimeout(handler.current);
      }

      handler.current = setTimeout(() => {
        fn(...args);
      }, ms) as any;
    },
    [fn, ms]
  );
}

export function useDebounceValue<T>(value: T, delay: number = 0): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
