import React, { ReactNode } from "react";
import styles from "./Buttons.module.scss";
import { Button } from "../../../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../../../data/types";
import { TerminalType } from "../../../../../../data/models/ContractTypes";

interface Props {
  onAdd: (data: any) => void;
  onRemove: (data: any) => void;
  value: number;
  children: ReactNode;
  disabled: boolean;
  terminalType: TerminalType;
}

export const Buttons: React.FunctionComponent<Props> = ({
  children,
  disabled,
  onAdd,
  onRemove,
  value,
  terminalType,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className="line-mini">{children}</div>
      <div className={styles.terminalButtons}>
        <Button
          data={terminalType}
          action
          onClick={onRemove}
          status={value === 0 || disabled ? Status.DISABLED : Status.DEFAULT}
        >
          &#8722;
        </Button>
        <div className={styles.value}>{value}</div>
        <Button
          action
          onClick={onAdd}
          data={terminalType}
          status={disabled ? Status.DISABLED : Status.DEFAULT}
        >
          &#43;
        </Button>
      </div>
    </div>
  );
};
