import React from "react";
import { ComponentProps, PdfProductType } from "../../PdfPage";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { getDefaultStyles } from "../../PdfStyles";
import { DisclaimerWorldlineOne } from "./DisclaimerWorldlineOne";
import { DisclaimerAcceptanceInstore } from "./DisclaimerAcceptanceInstore";
import { DisclaimerAcceptanceOnline } from "./DisclaimerAcceptanceOnline";
import { DisclaimerCheckout } from "./DisclaimerCheckout";
import { DisclaimerWorldlineOneShortTerm } from "./DisclaimerWorldlineOneShortTerm";
import { DisclaimerDeviceShortTerm } from "./DisclaimerDeviceShortTerm";
import { DisclaimerDevice } from "./DisclaimerDevice";

function getDisclaimers(type: PdfProductType) {
  switch (type) {
    case PdfProductType.ACCEPTANCE_INSTORE:
      return DisclaimerAcceptanceInstore;

    case PdfProductType.ACCEPTANCE_ONLINE:
      return DisclaimerAcceptanceOnline;

    case PdfProductType.BAMBORA_DEVICE:
      return DisclaimerDevice;

    case PdfProductType.BAMBORA_DEVICE_SHORT_TERM:
      return DisclaimerDeviceShortTerm;

    case PdfProductType.BAMBORA_ONE:
      return DisclaimerWorldlineOne;

    case PdfProductType.BAMBORA_ONE_SHORT_TERM:
      return DisclaimerWorldlineOneShortTerm;

    case PdfProductType.CHECKOUT:
      return DisclaimerCheckout;

    default:
      return [];
  }
}

function getLabels(t: any) {
  return {
    header: t("Pricing"),
    costs: t(
      "Fees are entered as percent (%) of the transaction amount and/or fixed amount per transaction."
    ),
  };
}

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({
    info: {
      margin: "4px 0 10px 0",
    },
    list: {
      margin: "15px 20px",
      padding: "0 20px",
    },
    dot: {
      position: "absolute",
      left: "-10px",
      top: "0",
      width: "14px",
      height: "14px",
    },
    listItem: {
      position: "relative",
      margin: "5px 0 0 0",
    },
  });
}

export const Disclaimer: React.FunctionComponent<ComponentProps> = ({
  productType,
}) => {
  const { t } = useTranslation();
  const labels = getLabels(t);

  const styles = {
    ...getDefaultStyles(true),
    ...getSpecificStyles(true),
  };

  return (
    <>
      <View style={styles.sectionHeader}>
        <View style={styles.split}>
          <View style={styles.largeColumn}>
            <Text>{labels.header}</Text>
          </View>
          <View style={styles.smallColumn}></View>
        </View>
      </View>
      <Text style={styles.info}>{labels.costs}</Text>
      <View style={styles.list}>
        {getDisclaimers(productType).map((disclaimer) => {
          return (
            <View key={disclaimer} style={styles.listItem}>
              <View style={styles.dot}>
                <Text>&#8226;</Text>
              </View>
              <Text wrap={true}>{t(disclaimer)}</Text>
            </View>
          );
        })}
      </View>
    </>
  );
};
