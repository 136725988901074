import { dataStores } from "./../dataStores";
import { dataCompany } from "../dataCompany";
import { dataFinancial } from "../dataFinancial";
import { GenericDataQueue } from "./GenericDataQueue";
import { Store } from "../models/ContractTypes";
import { dataBusinessRisk } from "../dataBusinessRisk";

export const financialDataQueue = new GenericDataQueue(
  dataFinancial.saveFinancialData
);

export const companyDataQueue = new GenericDataQueue(
  dataCompany.saveCompanyData
);

export const ecomStoreQueue = new GenericDataQueue(dataStores.saveEcomStore);

export const businessRiskDataQueue = new GenericDataQueue(
  dataBusinessRisk.saveBusinessRisk
);

export const storeQueue = new GenericDataQueue(
  dataStores.saveStore,
  "storeId" as keyof Store
);
