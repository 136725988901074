import {
  InternalEmailProperties,
  InternalTextProperties,
} from "../../../../data/dataCommunication";

const exampleVariable: Record<
  InternalEmailProperties | InternalTextProperties,
  string
> = {
  [InternalEmailProperties.NAME]: "Theseus Aegeuson",
  [InternalEmailProperties.OTP]: "12345678",
  [InternalEmailProperties.COMPANY_NAME]: "Theseus Shipping",
  [InternalTextProperties.LINK]: "https://merchant.wlx.pink/EXAMPLE",
};

export function replaceParameter(
  template: string,
  parameter: string,
  value: string
) {
  const pattern = new RegExp(`\\{\\{\\s?(${parameter})\\s?\\}\\}`, "g");
  const withTranslations = template.replace(pattern, value);
  return injectVariables(withTranslations);
}

export function injectVariables(
  template: string,
  variables: Record<string, string> = exampleVariable
) {
  return Object.entries(variables).reduce((acc, [key, val]) => {
    const pattern = new RegExp(`\\*\\|\\s?(${key})\\s?\\|\\*`, "g");
    return acc.replace(pattern, val);
  }, template);
}
