import { patternFormatter } from "react-number-format";
import { CompanyRegistrationId } from "../../data/models/CommonTypes";
import { Country } from "../../i18n";

interface Props {
  country: Country;
  id: CompanyRegistrationId;
}

// empty will fallback to no formatting
const companyRegistrationIdFormatMap: Record<Country, string> = {
  [Country.SWEDEN]: "######-####",
  [Country.NORWAY]: "###-###-###",
  [Country.DENMARK]: "######",
  [Country.FINLAND]: "#######-#",
};

export const FormattedCompanyRegistrationId = ({ country, id }: Props) => {
  return (
    <>
      {patternFormatter(id, {
        format: companyRegistrationIdFormatMap[country] || id,
      })}
    </>
  );
};

export const formatCompanyRegistrationId = (
  country: Country,
  id: CompanyRegistrationId
) => {
  return patternFormatter(id, {
    format: companyRegistrationIdFormatMap[country] || id,
  });
};

export function getVatIdFromRegNumber(country: Country, regNumber: string) {
  if (country === Country.SWEDEN) {
    const clean = regNumber.replace(/[^\d]/g, "");
    return `${Country.SWEDEN}${clean}01`;
  }

  return regNumber;
}
