import React, { useEffect } from "react";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  title?: string;
  className?: string;
}

export const Subpage: React.FunctionComponent<Props> = ({
  children,
  title,
  className,
}) => {
  useEffect(() => {
    if (!title) {
      document.title = "WorldlineX";
      return;
    }
    document.title = `${title} | WorldlineX`;
  }, [title]);

  return <div className={cx("subpage", className)}>{children}</div>;
};
