import React from "react";
import { StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../PdfStyles";
import { FirstCell } from "./FirstCell";
import { Cell } from "./Cell";
import { ComponentProps } from "../PdfPage";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

function getLabels(t: any) {
  return {
    street: t("Street"),
    city: t("City"),
    postalCode: t("Postal code"),
    country: t("Country"),
  };
}

export const Address: React.FunctionComponent<ComponentProps> = ({
  show = true,
}) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  return (
    <>
      <View style={styles.split}>
        <View style={styles.largeColumn}>
          <FirstCell label={labels.street} show={show}>
            Average street 45
          </FirstCell>
        </View>
        <View style={styles.smallColumn}>
          <Cell label={labels.postalCode} show={show}>
            34 564
          </Cell>
        </View>
      </View>

      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.city} show={show}>
            Average City
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.country} show={show}>
            Sweden
          </Cell>
        </View>
      </View>
    </>
  );
};
