import React from "react";
import { Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getIntlDate } from "../../../../components/utils";
import { COLOR_HIGHLIGHT, getDefaultStyles } from "../PdfStyles";
import { ComponentProps, productTypeDisplayName } from "../PdfPage";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({
    image: {
      width: 150,
    },
    info: {
      textAlign: "right",
    },
    bold: {
      fontWeight: 700,
      color: COLOR_HIGHLIGHT,
      fontSize: 15,
      margin: "0 0 4px 0",
    },
    emphasized: {
      fontWeight: 500,
    },
  });
}

function getLabels(t: any) {
  return {
    Application: t("Application"),
    Date: t("Date"),
    Id: t("Id"),
  };
}

export const Header: React.FunctionComponent<ComponentProps> = ({
  language,
  show = true,
  productType,
}) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  return (
    <View style={styles.split}>
      <View style={styles.smallColumn}>
        <Image
          style={styles.image}
          src="/images/Worldline-Mint-Horizontal.png"
        />
      </View>
      <View
        style={{ ...styles.largeColumn, ...styles.info, ...styles.pageHeader }}
      >
        <Text style={{ ...styles.bold, ...styles.placeholderVisibile }}>
          {productTypeDisplayName[productType]}
        </Text>
        <Text style={styles.regular}>
          <Text style={styles.emphasized}>{labels.Application}</Text>
        </Text>
        <Text style={styles.regular}>
          <Text style={styles.emphasized}>{labels.Date}:</Text> &nbsp;
          <Text style={styles.placeholderVisibile}>
            {getIntlDate("2002-09-27")}
          </Text>
        </Text>
        <Text style={styles.regular}>
          <Text style={styles.emphasized}>{labels.Id}:</Text> &nbsp;
          <Text style={styles.placeholderVisibile}>
            8a125802-a97d-4a29-8ae1-3e0c8dc9df32
          </Text>
        </Text>
      </View>
    </View>
  );
};
