import React, { useCallback, useMemo } from "react";
import { UniqueTerminal } from "../../../../../data/models/UniqueTerminal";
import {
  CommunicationProtocol,
  Setting,
  ValidSettings,
  getApplicableKits,
  getDuplicates,
} from "./settingsUtils";
import { Store, TerminalType } from "../../../../../data/models/ContractTypes";
import { disabledState } from "../../../../../state/contractState";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Checkboxes } from "../../../../../components/interactions/Checkboxes/Checkboxes";
import { Status } from "../../../../../data/types";

interface Props {
  terminalByECR?: UniqueTerminal;
  settings: Setting[];
  terminalType: TerminalType;
  setSettings: React.Dispatch<React.SetStateAction<Setting[]>>;
  status: Status;
  store: Store;
  saveStore: (store: Store) => void;
  selectedKitId: number;
  setSelectedKitId: React.Dispatch<React.SetStateAction<number>>;
}

function getSettingsKey(name: string, terminalType: string) {
  return `${name}${terminalType}`;
}

export const AdvancedSettings: React.FunctionComponent<Props> = ({
  terminalByECR,
  settings,
  terminalType,
  setSettings,
  status,
  store,
  saveStore,
  selectedKitId,
  setSelectedKitId,
}) => {
  const { t } = useTranslation();
  const disabled = useRecoilValue(disabledState);

  const duplicates = useMemo(() => {
    if (!terminalByECR) {
      return [];
    }

    return getDuplicates(getApplicableKits(terminalByECR, terminalType));
  }, [terminalByECR, terminalType]);

  const selectedKitAsObject = useMemo(() => {
    if (!terminalByECR) {
      return;
    }

    return terminalByECR.kits.find((kit) => kit.id === selectedKitId);
  }, [terminalByECR, selectedKitId]);

  const trySaveSettings = useCallback(
    (settings: Setting[]) => {
      saveStore({
        ...store,
        [terminalType]: {
          ...store[terminalType],
          terminalOptions: settings,
        },
      });
    },
    [store, terminalType, saveStore]
  );

  return (
    <>
      {settings
        .filter(
          (setting) => ValidSettings.includes(setting.name) && setting.show
        )
        .map((setting) => {
          const key = getSettingsKey(setting.name, terminalType);
          return (
            <div key={key}>
              <Checkboxes
                status={status}
                name={key}
                label={t(setting.name)}
                alternatives={[
                  {
                    value: "true",
                    text: t("Yes"),
                  },
                ]}
                values={setting.value === "true" ? ["true"] : []}
                onChange={(val) => {
                  setSettings((prev) => {
                    const updatedSettings = prev.map((prevSetting) => {
                      if (
                        getSettingsKey(prevSetting.name, terminalType) === key
                      ) {
                        return {
                          name: prevSetting.name,
                          value: val.length !== 0 ? "true" : "false",
                          disabled: prevSetting.disabled,
                          show: prevSetting.show,
                        };
                      }

                      return prevSetting;
                    });

                    trySaveSettings(updatedSettings);
                    return updatedSettings;
                  });
                }}
                disabled={disabled || setting.disabled}
              />
            </div>
          );
        })}
      <div>
        <Checkboxes
          status={status}
          name="communicationProtocol"
          label={t("Communication")}
          disabled={
            !terminalByECR || selectedKitId === -1 || duplicates.length !== 2
          }
          alternatives={[
            {
              value: "true",
              text: t("Bluetooth instead of Wifi"),
            },
          ]}
          values={
            selectedKitAsObject?.interface.settingsName ===
            CommunicationProtocol.BLUETOOTH
              ? ["true"]
              : []
          }
          onChange={() => {
            const alternativeKit =
              duplicates[0].id === selectedKitId
                ? duplicates[1]
                : duplicates[0];

            setSelectedKitId(alternativeKit.id);

            saveStore({
              ...store,
              [terminalType]: {
                ...store[terminalType],
                integrationKitId: alternativeKit.id,
                terminalModelId: alternativeKit.terminalModel.id,
                terminalOptions: settings,
              },
            });
          }}
        />
      </div>
    </>
  );
};
