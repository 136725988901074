import { Country } from "../../i18n";
import { LegalEntityType } from "../dataCompany";
import { IdType } from "./AssociateTypes";
import {
  AssociateId,
  Cas,
  CompanyRegistrationId,
  ContractId,
  DocumentId,
  StoreId,
  UTCDate,
} from "./CommonTypes";

export enum Validity {
  MISSING = "has-missing-information",
  PARTIAL = "has-partial-information",
  VALID = "has-required-information",
  DEFAULT = "",
}

export enum TERMINAL_TYPE {
  WIRED = "Wired",
  WIRELESS = "Wireless",
  INTEGRATED = "Integrated",
  WIRED_ANDROID = "Wired Android",
  WIRELESS_ANDROID = "Wireless Android",
  INTEGRATED_ANDROID = "Integrated Android",
}

// TODO: I think we should keep this for potential future use even if empty atm
export enum ContractFeature {}

export interface Notes {
  notes?: string;
}

export interface Contract {
  contractId: ContractId;
  readOnly: boolean;
  status: ContractStatus;
  productType: ProductType;
  salesUser: string; //namnet på användaren
  contractData: MainContractData;
  enabledFeatures: ContractFeature[];
  country: Country;
  allowedTerminals: TERMINAL_TYPE[];
  salesforceUrl: string;
}

export interface MainContractData {
  cas: Cas;
  companyName: string;
  organizationNumber: CompanyRegistrationId;
  taxRegistrationId?: string;
  legalEntityType: LegalEntityType;
  dateOfIncorporation: string; //ISO_DATE
  countryOfIncorporation?: string;
  phoneNumber?: string;
  website?: string;
  address?: Address;
  invoiceAddress: Partial<Address>;
  notes?: string;
}

export enum ContractStatus {
  SALES_INPUT = "SALES_INPUT",
  MERCHANT_INPUT = "MERCHANT_INPUT",
  MERCHANT_SIGNING = "MERCHANT_SIGNING",
  SALES_CONFIRMATION = "SALES_CONFIRMATION",
  RISK_CONFIRMATION = "RISK_CONFIRMATION",
  PROVISIONING = "PROVISIONING",
  REJECTED = "REJECTED",
  COMPLETE = "COMPLETE",
}

export interface Address {
  street: string;
  postalCode: string;
  city: string;
  countryCode: string;
}

export interface Contact {
  position?: string;
  phoneNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export type TerminalOption = {
  name: string;
  value: string;
};

export interface TerminalIntegrated {
  terminals: number;
  terminalModelId: number;
  integrationId: number;
  integrationKitId: number;
  terminalOptions: TerminalOption[];
}

export enum TerminalType {
  WIRED_INTEGRATED = "wiredIntegrated",
  WIRELESS_INTEGRATED = "wirelessIntegrated",
  WIRELESS_STANDALONE = "wirelessStandalone",
  WIRED_INTEGRATED_ANDROID = "wiredIntegratedAndroid",
  WIRELESS_INTEGRATED_ANDROID = "wirelessIntegratedAndroid",
  WIRELESS_STANDALONE_ANDROID = "wirelessStandaloneAndroid",
}

export interface Store {
  cas: Cas;
  storeId: StoreId;
  commercialName?: string;
  doingBusinessAs?: string;
  address?: Address;
  phoneNumber?: string;
  supportPhoneNumber?: string;
  [TerminalType.WIRELESS_STANDALONE]?: {
    terminals: number;
    terminalModelId: number;
  };
  [TerminalType.WIRELESS_INTEGRATED]?: TerminalIntegrated;
  [TerminalType.WIRED_INTEGRATED]?: TerminalIntegrated;
  [TerminalType.WIRELESS_STANDALONE_ANDROID]?: {
    terminals: number;
    terminalModelId: number;
  };
  [TerminalType.WIRELESS_INTEGRATED_ANDROID]?: TerminalIntegrated;
  [TerminalType.WIRED_INTEGRATED_ANDROID]?: TerminalIntegrated;
}

export interface EcomStore {
  cas: Cas;
  type?: EcomType;
  mainCountriesOfOperation?: string[];
  url: string;
  app?: {
    // screenshot here: GET /api/sales/:contractId/ecomstore/appscreen
    //Om du har objektet så finns subpropparna
    name: string;
    testName: string;
  };
  web?: {
    testUrl: string;
    checklist: WebsiteChecklist;
  };
  invoice?: {
    senderName?: string;
    contactEmail?: string;
    totalTurnover?: number;
    averageTurnover?: number;
  };
}

export interface WebsiteChecklist {
  hasPrivacyPolicy?: boolean;
  hasTermsOfService?: boolean;
  hasCookiePolicy?: boolean;
  hasDataProtectionPolicy?: boolean;
  hasDataSecurityPolicy?: boolean;
  hasDataRetentionPolicy?: boolean;
}

export interface NewPortalLogin {
  email: string;
  name: string;
}

export enum BankAccountSource {
  BANK_STATEMENT = "BANK_STATEMENT",
  OFFICIAL_REGISTRY = "OFFICIAL_REGISTRY",
  PSD_LOOKUP = "PSD_LOOKUP",
  BANKGIRO = "BANKGIRO",
}

export enum BgcResult {
  REJECTED = "REJECTED",
  OK = "OK",
}

export interface BgcStatus {
  resolved: UTCDate;
  result: BgcResult;
  sent: UTCDate;
}

export interface BankAccount {
  iban?: string;
  accountHolder?: string;
  signedOff?: UTCDate;
  signedOffBy?: string;
  source?: BankAccountSource;
  statementUploaded?: UTCDate;
  mimeType?: string;
  rejected?: UTCDate;
  bgcStatus?: BgcStatus;
}

enum AccountType {
  DEFAULT = "DEFAULT",
  SAVING = "SAVING",
  CREDITCARD = "CREDITCARD",
  DEPOT = "DEPOT",
  UNKNOWN = "UNKNOWN",
}

export enum ExternalBankAccountStatus {
  MATCH = "MATCH",
  NO_MATCH = "NO_MATCH",
  NO_HOLDER = "NO_HOLDER",
  NO_IBAN = "NO_IBAN",
  INVALID_TYPE = "INVALID_TYPE",
  INVALID_CURRENCY = "INVALID_CURRENCY",
}

export interface ExternalBankAccount {
  iban?: string;
  accountName?: string;
  accountHolder?: string;
  accountType: AccountType;
  accountStatus: ExternalBankAccountStatus;
}

export enum DocumentType {
  TAXI_LICENSE = "TAXI_LICENSE",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PROOF_OF_ACCREDITATION = "PROOF_OF_ACCREDITATION",
  FINANCIAL_STATEMENT = "FINANCIAL_STATEMENT",
  SALES_CUSTOM_REQUEST = "SALES_CUSTOM_REQUEST",
  ID_DOCUMENT = "ID_DOCUMENT",
  BANK_STATEMENT = "BANK_STATEMENT",
  COMPANY_ID_DOCUMENT = "COMPANY_ID_DOCUMENT",
  APP_SCREENSHOT = "APP_SCREENSHOT",
  CONTROL_STRUCTURE = "CONTROL_STRUCTURE",
  RECORD_OF_NOMINEE_SHAREHOLDERS = "RECORD_OF_NOMINEE_SHAREHOLDERS",
  LETTER_OF_SHAREHOLDER_CUSTODIAN = "LETTER_OF_SHAREHOLDER_CUSTODIAN",
  ECOM_APPLICATION_SCREENSHOT = "ECOM_APPLICATION_SCREENSHOT",
  SIGNED_CONTRACT = "SIGNED_CONTRACT",
}

export const contractStatusDisplay: Record<ContractStatus, string> = {
  [ContractStatus.COMPLETE]: "Completed",
  [ContractStatus.MERCHANT_INPUT]: "Providing input",
  [ContractStatus.MERCHANT_SIGNING]: "Waiting for signatures",
  [ContractStatus.PROVISIONING]: "Provisioning",
  [ContractStatus.RISK_CONFIRMATION]: "Validating information",
  [ContractStatus.SALES_CONFIRMATION]: "Confirming contract",
  [ContractStatus.SALES_INPUT]: "Creating contract",
  [ContractStatus.REJECTED]: "Rejected",
};

export const contractResponsible: Record<ContractStatus, string> = {
  [ContractStatus.COMPLETE]: "System",
  [ContractStatus.MERCHANT_INPUT]: "Merchant",
  [ContractStatus.MERCHANT_SIGNING]: "Merchant",
  [ContractStatus.PROVISIONING]: "System",
  [ContractStatus.RISK_CONFIRMATION]: "Risk",
  [ContractStatus.SALES_CONFIRMATION]: "Sales",
  [ContractStatus.SALES_INPUT]: "Sales",
  [ContractStatus.REJECTED]: "System",
};

export function documentTypeDisplay(type: DocumentType) {
  if (!type) {
    return "Missing document type";
  }

  switch (type) {
    case DocumentType.TAXI_LICENSE:
      return "Taxi License";
    case DocumentType.POWER_OF_ATTORNEY:
      return "Power of attorney";
    case DocumentType.PROOF_OF_ACCREDITATION:
      return "Proof of accreditation";
    case DocumentType.FINANCIAL_STATEMENT:
      return "Financial statement";
    case DocumentType.SALES_CUSTOM_REQUEST:
      return "A custom document request";
    case DocumentType.ID_DOCUMENT:
      return "Document of identification";
    case DocumentType.BANK_STATEMENT:
      return "Bank statement";
    case DocumentType.APP_SCREENSHOT:
      return "Screenshot of app";
    case DocumentType.CONTROL_STRUCTURE:
      return "Document of control structure";
    case DocumentType.RECORD_OF_NOMINEE_SHAREHOLDERS:
      return "Record of nominee shareholders";
    case DocumentType.LETTER_OF_SHAREHOLDER_CUSTODIAN:
      return "Letter of shareholder custodian";
    case DocumentType.ECOM_APPLICATION_SCREENSHOT:
      return "Image or screenshot of merchant application";
    case DocumentType.SIGNED_CONTRACT:
      return "The signed contract";
  }
  return "Unknown document type";
}

export function documentTypeDescription(type: DocumentType) {
  switch (type) {
    // case DocumentType.BUSINESS_CERTIFICATE:
    //   return "Excerpt from a national official business registry showing signatory power and company status";
    case DocumentType.TAXI_LICENSE:
      return "Proof of accreditation/taxi license";
    case DocumentType.POWER_OF_ATTORNEY:
      return "Copy of signed document showing the power to stand in for a representative of the company";
    case DocumentType.PROOF_OF_ACCREDITATION:
      return "Excerpt or copy or relevant accreditation documentation";
    case DocumentType.FINANCIAL_STATEMENT:
      return "The latest audited financial statement/annual report";
    case DocumentType.BANK_STATEMENT:
      return "Proof of Bank account details. Must include name of the bank account holder, bank account number, name of the bank (can also be logo, URL, or BIC), and date showing when the document was created. Must not be older than two months.";
    case DocumentType.CONTROL_STRUCTURE:
      return "Signed ownership and control structure document, Control by other Means, Partnership agreement, organizational chart or other signed (by authorized signatory) document showing the control structure of the company";
    case DocumentType.RECORD_OF_NOMINEE_SHAREHOLDERS:
      return "Official letter showing bearer or nominee shareholder (s)";

    case DocumentType.LETTER_OF_SHAREHOLDER_CUSTODIAN:
      return "Document showing the custodian shareholder (s)";

    case DocumentType.ECOM_APPLICATION_SCREENSHOT:
      return "Images or screenshot of merchant application, including contact information, products/services, checkout, privacy policy and terms and conditions";

    case DocumentType.SIGNED_CONTRACT:
      return "A copy of the signed version of the contract";
  }
  return "(Description missing for " + type.toString() + ")";
}

export interface ContractDocument {
  documentId: DocumentId;
  documentType: DocumentType;
  //Used by sales to describe a generic document that the customer needs to upload. Should be used when documentType = SALES_CUSTOM_REQUEST
  documentDescription?: string;
  created?: string; //datetime
  uploaded?: string; //datetime
  fileSize?: number;
  fileName?: string;
  mimeType?: string;
  //If its confirmed it can't be deleted
  confirmed?: UTCDate;
  confirmedBy?: string;
  fileAvailable: boolean;
}

export enum MccRiskLevel {
  NONE = "NONE",
  RISK_APPROVAL = "RISK_APPROVAL",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export interface MerchantCategory {
  code: string;
  name: string;
  translatedText?: string;
  note?: string;
  category: string;
  cardNotPresent: MccRiskLevel;
  faceToFace: MccRiskLevel;
}

export enum CommunicationEvent {
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  DELIVERY_FAILED = "DELIVERY_FAILED",
  MESSAGE_VIEWED = "MESSAGE_VIEWED",
  CONTRACT_VIEWED = "CONTRACT_VIEWED",
  REMINDER = "REMINDER",
  REVOKED = "REVOKED",
}

export interface IpAddress {
  uploaded?: UTCDate;
  ipAddress?: string;
  country?: string;
  city?: string;
}

export interface MerchantIp {
  city: string;
  countryCode: string;
  ip: IpAddress;
}

export interface CommunicationTrackingLog {
  associateId: AssociateId;
  communicationRecipient?: string;
  event: CommunicationEvent;
  merchantIp?: MerchantIp;
  //Om ett event är triggat av en sales kommer namnet stå här, tex en revoke
  salesUserName?: string;
  created: UTCDate;
}

export interface AssociateTracking {
  associateId: AssociateId;
  linkId: string;
  trackingLog: CommunicationTrackingLog[];
  lastVisit: CommunicationTrackingLog;
}

export interface ReviewStatus {
  contractStarted: UTCDate;
  startedBy: string;
  sentToMerchant?: UTCDate;
  merchantConfirmed?: UTCDate;
  merchantBankVerified?: UTCDate;
  riskSignedOff?: UTCDate;
  sentToProvisioning?: UTCDate;
  sentToRisk?: UTCDate;
  merchantComplete?: UTCDate;
  contractCompleted?: UTCDate;
  allSignedOff?: UTCDate;
  tracking: AssociateTracking[];
}

export interface CompletedContract {
  //Target email for the backoffice email
  //Should we add customer recipient?
  backofficeRecipient: string;

  //When the job was started to create the contract files
  started: UTCDate;
  //The sales admin that clicked "complete"
  startedBy: string;

  //Timestamp of when we successfully sent the email to backoffice
  backofficeEmailSent?: UTCDate;

  backofficeEmailViewed?: UTCDate;
  backofficeAttachmentDownloaded?: UTCDate;
  //Timestamp of when we successfully sent the email to the customer
  merchantEmailSent?: UTCDate;

  //Timestamp of when backoffice is done working on the contract,
  // we might not always get this, it depends on when they click the link in the email
  backofficeProvisioningCompleted?: UTCDate;
  //If the contract had softpos package, we will try to dispatch
  //the contract to softpos after backoffice is done
  softposProvisioningCompleted?: UTCDate;

  //If we have a merchantId, backoffice is done with creating
  // the customer and we might have started provisioning softpos
  merchantId?: string;
}

export interface TextBlock {
  text: string;
  width: number; //Percentage of total image, ex: 0.223 = 22.3% of the image total width
  height: number;
  left: number; // Percentage offset from the left side of the image
  top: number;
}

export interface ResolvedData {
  idType?: IdType;
  documentNumber?: string;
  nationality?: string; //2 alpha
  issuingCountry?: string; //2 alpha
  issuingAuthority?: string;
  dateOfBirth?: string; //iso date
  issueDate?: string; //iso date
  expiryDate?: string; //iso date
}

export interface IdScan {
  idImage?: TextBlock[];
  resolvedData: ResolvedData;
}

////////// NEW PRODUCTS FOR WLX-NORD

export enum ProductType {
  ACCEPTANCE_ONLINE = "ACCEPTANCE_ONLINE",
  ACCEPTANCE_INSTORE = "ACCEPTANCE_INSTORE",
  BAMBORA_DEVICE = "BAMBORA_DEVICE",
  BAMBORA_DEVICE_SHORT_TERM = "BAMBORA_DEVICE_SHORT_TERM",
  BAMBORA_ONE = "BAMBORA_ONE",
  BAMBORA_ONE_SHORT_TERM = "BAMBORA_ONE_SHORT_TERM",
  CHECKOUT = "CHECKOUT",
}

export enum EcomType {
  WEB = "WEB",
  APP = "APP",
  MOTO = "MOTO",
}
