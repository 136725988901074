import { Language } from "../../i18n";
import { AssociateId, Cas, UTCDate } from "./CommonTypes";
import { Address, Contact } from "./ContractTypes";

export enum IdType {
  DRIVING_LICENSE = "DRIVING_LICENSE",
  PASSPORT = "PASSPORT",
}

export enum AssociateRole {
  PRIMARY_CONTACT = "PRIMARY_CONTACT",
  SIGNATORY = "SIGNATORY",
  SELECTED_SIGNATORY = "SELECTED_SIGNATORY",
  ACCOUNT_HOLDER = "ACCOUNT_HOLDER",
  BENEFICIAL_OWNER = "BENEFICIAL_OWNER",
  PORTAL_LOGIN = "PORTAL_LOGIN",
}

export interface IdCard {
  idType?: IdType;
  idDocumentNo?: string;
  dateOfBirth?: string; //yyyy-mm-dd
  countryOfBirth?: string;
  nationalityCountryCode?: string;
  idIssuedIn?: string; // ???
  idIssuedByAuthority?: string;
  idIssuedByCountryCode?: string;
  idIssuedOn?: string; //yyyy-mm-dd
  idExpiry?: string; //yyyy-mm-dd
  noExpiry: boolean;
}

export interface RemoteIdStatus {
  associateId: AssociateId;
  uploadedIdDocument?: UTCDate;
  signedOffIdDocument?: UTCDate;
}

export interface AssociateIdentity {
  cas: Cas;
  idType: IdType;
  //Common types
  dateOfBirth?: string; // yyyy-MM-dd
  nationality?: string;
  idDocumentNo?: string;

  //Passport
  issuedByCountry?: string;
  idExpiry?: string; // yyyy-MM-dd

  //Drivings license
  licenceCardNumber?: string;
  stateOfIssue?: string;

  //Medicare
  individualReferenceNumber?: string;
  cardColor?: string;
}

export interface Owner {
  capitalStake?: number; //0.00 -> 100.00
  votingRightsStake?: number; //0.00 -> 100.00
  hasIndirectOwnership?: boolean;
}

export enum SigningMethod {
  BANKID = "BANKID",
  MITID = "MITID",
  FTN = "FTN", //Finnish Trust network
  REMOTE_ID = "REMOTE_ID",
}

export interface AssociateSignatory {
  signed?: UTCDate;
  signature?: string;
  signingMethod?: SigningMethod;
}

export enum PEP_POSITION {
  HEAD_OF_GOVERNMENT = "HEAD_OF_GOVERNMENT",
  MINISTER = "MINISTER",
  PARLIMENT_MEMBER = "PARLIMENT_MEMBER",
  CENTRAL_BANK_MEMBER = "CENTRAL_BANK_MEMBER",
  BOARD_MEMBER_POLITICAL_PARTY = "BOARD_MEMBER_POLITICAL_PARTY",
  JUDGE_SUPREME_COURT = "JUDGE_SUPREME_COURT",
  JUDGE_OTHER_HIGH_COURT = "JUDGE_OTHER_HIGH_COURT",
  REVISION_INSTITUTE = "REVISION_INSTITUTE",
  AMBASSADOR = "AMBASSADOR",
  MILITARY_OFFICER = "MILITARY_OFFICER",
  GOVERNMENT_COMPANY = "GOVERNMENT_COMPANY",
  EU_HIGH_RANKING_POSITION = "EU_HIGH_RANKING_POSITION",
  HIGH_RANKING_POSITION_INTERNALTIONAL_CORP = "HIGH_RANKING_POSITION_INTERNALTIONAL_CORP",
}

export enum PEP_RELATION {
  DAUGHTER = "DAUGHTER",
  DAUGHTER_IN_LAW = "DAUGHTER_IN_LAW",
  FATHER = "FATHER",
  FATHER_IN_LAW = "FATHER_IN_LAW",
  KNOWN_CO_WORKER = "KNOWN_CO_WORKER",
  MOTHER = "MOTHER",
  MOTHER_IN_LAW = "MOTHER_IN_LAW",
  PARTNER = "PARTNER",
  SON = "SON",
  SON_IN_LAW = "SON_IN_LAW",
}

export enum PEP_TYPE {
  NOT_PEP = "NOT_PEP",
  PEP = "PEP",
  RELATIVE_TO_PEP = "RELATIVE_TO_PEP",
}

export interface Id {
  nationalPersonId?: string;
  dateOfBirth?: string; // yyyy-mm-dd
  countryOfBirth?: string;
  citizenships?: string[]; // country code
  countryOfResidence?: string;
}

export interface Pep {
  type?: PEP_TYPE;
  relation?: PEP_RELATION;
  from?: string;
  to?: string;
  position?: PEP_POSITION;
  country?: string;
  firstName?: string;
  lastName?: string;
}

export interface Associate {
  cas: Cas;
  associateId: AssociateId;
  created?: UTCDate;
  roles: AssociateRole[];
  signedOff?: UTCDate;
  signedOffBy?: string;
  language?: Language;
  address: Address;
  contact: Contact;
  owner?: Owner;
  id: Id;
  pep: Pep;
  signatory?: AssociateSignatory;
  remoteIdentity?: {
    selfieUploaded?: string;
    idDocumentationUploaded?: string;
  };
}
