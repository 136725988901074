import cx from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../../i18n";
import { Select } from "../form/Select";
import { Alternative } from "../interactions/InputTypes";
import { getLanguageName } from "../translation/i18nUtils";
import { FaGlobe } from "react-icons/fa";
import "./LanguageSelect.scss";

interface Props {
  className?: string;
}

export const LanguageSelect = ({ className }: Props) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const languageAlternatives: Alternative<Language>[] = useMemo(
    () =>
      Object.values(Language).map((key) => ({
        value: key as Language,
        text: getLanguageName(key as Language, language),
      })),
    [language]
  );

  return (
    <div className={cx("language-select", className)}>
      <FaGlobe className="icon" />
      <Select
        value={language}
        alternatives={languageAlternatives}
        onChange={(newLanguage) => {
          i18n.changeLanguage(newLanguage);
        }}
      />
    </div>
  );
};
