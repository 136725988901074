import React, { useCallback, useContext } from "react";
import { useFormValidity } from "../../../../components/form/MultiFormContext";
import { ScrollPositionContext } from "../../../../components/scrollPosition/ScrollPositionContext";
import { Validity } from "../../../../data/models/ContractTypes";
import { LinkAnchors } from "../ContractEdit";
import { ContractEditMenuItem } from "./ContractEditMenuItem";
import "./ContractEditMenu.scss";

export enum FormName {
  PRIMARY_CONTACT = "primary-contact",
  MAIN_DATA = "main-data",
  SALES_DATA = "sales-data",
  FINANCIAL_DATA = "financial-data",
  OFFERING = "offering",
  STORE = "store",
  UPLOADS = "uploads",
  NOTES = "notes",
}

const scrollToElement = (element: HTMLElement, container?: HTMLDivElement) => {
  if (container) {
    const y = element.getBoundingClientRect().top + container.scrollTop - 50;
    container.scrollTo({
      top: y,
      left: 0,
      behavior: "smooth",
    });
  } else {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export const ContractEditMenu: React.FunctionComponent = () => {
  const isPrimaryValid = useFormValidity(FormName.PRIMARY_CONTACT);
  const isMainDataValid = useFormValidity(FormName.MAIN_DATA);
  const isFinancialDataValid = useFormValidity(FormName.FINANCIAL_DATA);
  const isSalesDataValid = useFormValidity(FormName.SALES_DATA);
  const isStoreValid = useFormValidity(FormName.STORE);
  const isOfferingValid = useFormValidity(FormName.OFFERING);
  // const isPricingValid = useFormValidity(FormName.PRICING);
  const scrollPositionContext = useContext(ScrollPositionContext);

  const scrollPosition = scrollPositionContext?.closestScrollEntry;

  const onClick = useCallback(
    (id: string) => {
      const element = document.getElementById(id);

      if (element) {
        scrollToElement(
          element,
          scrollPositionContext?.scrollRef?.current ?? undefined
        );
      }
    },
    [scrollPositionContext?.scrollRef]
  );

  return (
    <div className="contract-edit-vertical-menu">
      <div className="contract-edit-menu-links">
        <ul>
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.PRIMARY_CONTACT}
            validity={isPrimaryValid}
            active={
              scrollPosition?.id === LinkAnchors.PRIMARY_CONTACT.anchor ||
              typeof scrollPosition?.id === "undefined"
            }
          />
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.COMPANY_DATA}
            validity={isMainDataValid}
            active={scrollPosition?.id === LinkAnchors.COMPANY_DATA.anchor}
          />
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.FINANCIAL_DATA}
            validity={isFinancialDataValid}
            active={scrollPosition?.id === LinkAnchors.FINANCIAL_DATA.anchor}
          />{" "}
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.SALES_DATA}
            validity={isSalesDataValid}
            active={scrollPosition?.id === LinkAnchors.SALES_DATA.anchor}
          />
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.OFFERING}
            validity={isOfferingValid}
            active={scrollPosition?.id === LinkAnchors.OFFERING.anchor}
          />
          {/* <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.PRICING}
            validity={isPricingValid}
            active={scrollPosition?.id === LinkAnchors.PRICING.anchor}
          /> */}
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.STORES}
            validity={isStoreValid}
            active={scrollPosition?.id === LinkAnchors.STORES.anchor}
          />
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.UPLOADS}
            validity={Validity.VALID}
            active={scrollPosition?.id === LinkAnchors.UPLOADS.anchor}
          />
        </ul>
      </div>
    </div>
  );
};
