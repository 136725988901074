import { DateTimeFormatOptions } from "luxon";
import { UTCDate } from "../data/models/CommonTypes";
import { MerchantCategory } from "../data/models/ContractTypes";
import countries from "./countries.json";
import { Alternative } from "./interactions/InputTypes";
import { DynamicOfType } from "../data/types";
import i18n, { Language } from "../i18n";
import { default as lodashEqual } from "lodash.isequal";
import { DataWithCas } from "../data/queues/GenericDataQueue";
import { getCountryDisplayName } from "./translation/i18nUtils";

export const MISSING_DATE = " - ";

export function id() {
  return window.crypto.randomUUID();
}

export function clearCas(data: Partial<DataWithCas> | Partial<DataWithCas>[]) {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const copy = { ...item };
      delete copy.cas;
      return copy;
    });
  }

  const copy = { ...data };
  delete copy.cas;
  return copy;
}

export function isEqual(
  one: DataWithCas | DataWithCas[],
  two: DataWithCas | DataWithCas[]
) {
  try {
    const copyOne = clearCas(one);
    const copyTwo = clearCas(two);

    return lodashEqual(copyOne, copyTwo);
  } catch (err) {
    return false;
  }
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d.getTime());
}

export const REGION_NAMES = new (Intl as any).DisplayNames(["en"], {
  type: "region",
});

export function getCountryOpts(
  allowEmpty: boolean = false,
  placeholder?: string | React.ReactNode,
  language: Language | string = i18n.language,
  availableCountries?: string[]
) {
  let countryList;

  if (availableCountries) {
    countryList = availableCountries.map(
      (country): Alternative<string> => ({
        value: country,
        text: getCountryDisplayName(country, language),
        disabled: false,
      })
    );
  } else {
    countryList = Object.keys(countries).map(
      (country): Alternative<string> => ({
        value: country,
        text: getCountryDisplayName(country, language),
        disabled: false,
      })
    );
  }

  if (allowEmpty) {
    countryList.unshift({
      value: "",
      text: placeholder || "None",
      disabled: false,
    });
  } else {
    countryList.unshift({
      value: "",
      text: "Country",
      disabled: true,
    });
  }

  return countryList;
}

export function getMccOpts(categories: MerchantCategory[], t: any) {
  return categories.map((category) => ({
    value: category.code,
    text: category.translatedText || category.name,
    disabled: false,
  }));
}

export function getIntlDate(
  date?: Date | string | UTCDate,
  options?: DateTimeFormatOptions
) {
  if (!date) {
    return MISSING_DATE;
  }

  const dateObject = new Date(date);

  if (dateObject instanceof Date && !isNaN(dateObject as any)) {
    return new Intl.DateTimeFormat(
      i18n.language || Language.ENGLISH,
      options
    ).format(dateObject);
  } else {
    return MISSING_DATE;
  }
}

export function convertArrayToObjectByKey<T>(array: T[], key: string) {
  const item: DynamicOfType<T> = {};
  return array.reduce((acc, curr) => {
    acc[(curr as any)[key] as string] = curr;
    return acc;
  }, item);
}

export const cleanNonDigits = <T extends string>(value: T): T => {
  return value.replace(/\D/g, "") as T;
};

export const getDecimalSeparator = (locale?: string) =>
  Intl.NumberFormat(locale)
    .formatToParts(1.1)
    .find((part) => part.type === "decimal")?.value ?? ".";

export function trimAllButAlphaNumeric(value?: string) {
  if (!value) {
    return "";
  }

  return value.replaceAll(/[\W_]+/g, "");
}

export function getIntlCost(
  lang: string,
  number: number,
  currency: string,
  decimals: number = 0
) {
  return new Intl.NumberFormat(lang, {
    style: "currency",
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export function getIntlPercent(
  lang: string,
  number: number,
  decimals: number = 0
) {
  try {
    return new Intl.NumberFormat(lang, {
      style: "percent",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number / 100);
  } catch (err) {
    return "- %";
  }
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}
