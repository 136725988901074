import { data } from "./data";
import { ContractId, DocumentId } from "./models/CommonTypes";
import { ContractDocument, DocumentType } from "./models/ContractTypes";

const BASE_PATH = "/api/sales";

export interface Supplement {
  documentType: DocumentType;
  documentDescription?: string;
}

export interface SupplementWithId extends Supplement {
  documentId: DocumentId;
}

export const dataDocuments = {
  getDocuments: (contractId: ContractId) =>
    data.get<ContractDocument[]>(`${BASE_PATH}/${contractId}/documents`),

  fetchDocuments: (contractId: ContractId) => ({
    queryKey: dataDocuments.getDocumentsKey(contractId),
    queryFn: () =>
      data.get<ContractDocument[]>(`${BASE_PATH}/${contractId}/documents`),
  }),

  getDocumentsKey: (contractId: ContractId) => {
    return ["documents", contractId];
  },

  saveCustomDocument: (contractId: ContractId, description: string) =>
    data.post<ContractDocument[]>(
      `${BASE_PATH}/${contractId}/documents/custom`,
      {
        documentDescription: description,
      }
    ),

  removeCustomDocument: (contractId: ContractId, documentId: DocumentId) =>
    data.delete<ContractDocument[]>(
      `${BASE_PATH}/${contractId}/documents/custom/${documentId}`
    ),

  saveSupplements: (contractId: ContractId, supplements: Supplement[]) =>
    data.post(`${BASE_PATH}/${contractId}/documents/supplements`, supplements),

  confirmUploadedDocument: (contractId: ContractId, documentId: DocumentId) =>
    data.post<ContractDocument[]>(
      `${BASE_PATH}/${contractId}/documents/${documentId}`
    ),

  rejectUploadedDocument: (contractId: ContractId, documentId: DocumentId) =>
    data.delete<ContractDocument[]>(
      `${BASE_PATH}/${contractId}/documents/${documentId}`
    ),
};
