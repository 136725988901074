import { TIMELINE_CREATED, TIMELINE_START } from "../Timeline";

export function IndicatorCSentToMerchant() {
  return (
    <>
      <line
        x1="10"
        x2="10"
        y1={TIMELINE_START}
        y2={TIMELINE_START + TIMELINE_CREATED}
        className="active"
      />
      <circle
        r="4"
        cx="10"
        cy={TIMELINE_START + TIMELINE_CREATED}
        className="active"
      />
    </>
  );
}
