import React from "react";
import { Associate } from "../../data/models/AssociateTypes";

interface Props {
  associate: Associate;
}

export const Name: React.FunctionComponent<Props> = ({ associate }) => {
  if (associate.contact.firstName && associate.contact.lastName) {
    return (
      <>
        {associate.contact.lastName}, {associate.contact.firstName}
      </>
    );
  }

  if (associate.contact.firstName) {
    return <>{associate.contact.firstName}</>;
  }

  if (associate.contact.lastName) {
    return <>{associate.contact.lastName}</>;
  }

  return <>&nbsp;</>;
};
