import React from "react";
import { generatePath } from "react-router";
import { useRecoilValue } from "recoil";
import { Edit } from "../../../components/icons/Edit";
import { ListEdit } from "../../../components/icons/ListEdit";
import { LanguageSelect } from "../../../components/languageSelect/LanguageSelect";
import { Menu } from "../../../components/menu/Menu";
import { Spinner } from "../../../components/spinner/Spinner";
import { T } from "../../../components/translation/T";
import { VerticalMenu } from "../../../components/verticalMenu/VerticalMenu";
import { SupportBox } from "../../../components/verticalNav/SupportBox";
import { ContractId } from "../../../data/models/CommonTypes";
import { ContractStatus } from "../../../data/models/ContractTypes";
import { contractState } from "../../../state/contractState";
import { REVIEW_ROUTE } from "../Review/ReviewPage";
import { CONTRACT_ROUTE } from "./ContractPage";
import "./ContractMenu.scss";

interface Props {
  contractId?: ContractId;
  menuChildren?: React.ReactNode;
}

export const ContractMenu = ({ contractId, menuChildren }: Props) => {
  const contract = useRecoilValue(contractState);
  const enableMerchantReview = contract.status !== ContractStatus.SALES_INPUT;

  if (!contractId) {
    return (
      <VerticalMenu logo={true}>
        <Spinner />
      </VerticalMenu>
    );
  }

  return (
    <VerticalMenu logo={true}>
      <Menu
        menuItems={[
          {
            name: (
              <>
                <Edit />
                <T>Contract details</T>
              </>
            ),
            link: generatePath(CONTRACT_ROUTE, { contractId }),
            disabled: false,
          },
          {
            name: (
              <>
                <ListEdit />
                <T>Merchant Review</T>
              </>
            ),
            link: generatePath(REVIEW_ROUTE, { contractId }),
            disabled: !enableMerchantReview,
          },
          // {
          //   name: (
          //     <>
          //       <AssignmentCompleted />
          //       <T>Onboarding</T>
          //     </>
          //   ),
          //   link: generatePath(COMPLETED_CONTRACT_PAGE, {
          //     id: contractId,
          //   }),
          //   disabled: !enableCompleted,
          // },
        ]}
      />
      <div>
        {menuChildren}

        <LanguageSelect />
        <SupportBox />
      </div>
    </VerticalMenu>
  );
};
