import cx from "classnames";
import { ReactNode } from "react";
import { T } from "../translation/T";
import { ErrorBox } from "./ErrorBox";
import styles from "./GenericError.module.scss";

export function GenericError(props: {
  children?: ReactNode;
  center?: boolean;
}) {
  const { center, children } = props;
  return (
    <div
      className={cx(styles.error, {
        [styles.center]: center,
      })}
    >
      <ErrorBox relative>
        <b>
          <T>Oh no!</T>
        </b>{" "}
        😔
        <div className="m-top-10">
          {children || <T>Something went wrong. Try again?</T>}
        </div>
      </ErrorBox>
    </div>
  );
}
