import React, { useCallback, useMemo, useRef, useState } from "react";
import cx from "classnames";
import styles from "./Supplements.module.scss";
import { T } from "../../../../../components/translation/T";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { NewOverlay } from "../../../../../components/overlay/NewOverlay";
import {
  DocumentType,
  documentTypeDisplay,
} from "../../../../../data/models/ContractTypes";
import { Alternative } from "../../../../../components/interactions/InputTypes";
import { Select } from "../../../../../components/form/Select";
import { AnimateHeight } from "../../../../../components/animate/AnimateHeight";
import { TextArea } from "../../../../../components/form/TextArea";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Form } from "../../../../../components/form/Form";
import { Trans, useTranslation } from "react-i18next";
import { WarningBox } from "../../../../../components/boxes/WarningBox";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import {
  SupplementWithId,
  dataDocuments,
} from "../../../../../data/dataDocuments";
import { Status } from "../../../../../data/types";
import { FileRow } from "../../../../../components/fileUpload/FileRow";
import { DocumentId } from "../../../../../data/models/CommonTypes";
import { useContractId } from "../../../../../hooks/useContractId";
import { GenericError } from "../../../../../components/boxes/GenericError";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function getAlternatives(
  docs: SupplementWithId[],
  t: any,
  addedDocTypes: DocumentType[] = []
): Alternative<DocumentType>[] {
  const validDocTypes = Object.keys(DocumentType).filter((key) => {
    if (docs.find((doc) => doc.documentType === key)) {
      return false;
    }

    if (addedDocTypes.find((documentType) => documentType === key)) {
      return false;
    }

    return true;
  });

  const opts = validDocTypes.map((key) => ({
    value: key as DocumentType,
    text: t(documentTypeDisplay(key as DocumentType)),
    disabled: false,
  }));

  opts.unshift({
    value: "" as DocumentType,
    text: t("Select type of document"),
    disabled: true,
  });

  return opts;
}

export const Supplements: React.FunctionComponent<Props> = ({
  open,
  setOpen,
}) => {
  const contractId = useContractId();
  const [doc, setDoc] = useState<DocumentType>("" as DocumentType);
  const [message, setMessage] = useState<string>();
  const [docs, setDocs] = useState<SupplementWithId[]>([]);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const { t } = useTranslation();
  const id = useRef<number>(1);
  const queryClient = useQueryClient();

  const onChange = useCallback((value: DocumentType) => setDoc(value), []);

  const onMessageChange = useCallback((value: string) => setMessage(value), []);

  const onClose = useCallback(() => setOpen(false), [setOpen]);

  const onAdd = useCallback(() => {
    if (!doc) {
      return;
    }

    setMessage("");
    setDoc("" as DocumentType);
    const item: SupplementWithId = {
      documentType: doc,
      documentDescription: message,
      documentId: id.current as DocumentId,
    };
    id.current = id.current + 1;
    setDocs((prev) => [...prev, item]);
  }, [doc, message]);

  const onRemove = useCallback((id: DocumentId) => {
    setDocs((prev) => prev.filter((doc) => doc.documentId !== id));
  }, []);

  const onSave = useCallback(() => {
    setStatus(Status.PENDING);
    dataDocuments
      .saveSupplements(contractId, docs)
      .then(() => {
        setStatus(Status.SUCCESS);
        queryClient.invalidateQueries({
          queryKey: dataDocuments.getDocumentsKey(contractId),
        });
        setTimeout(() => {
          setStatus(Status.DEFAULT);
          onClose();
        }, 1000);
      })
      .catch((err) => {
        setStatus(Status.ERROR);
        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [docs, contractId, onClose, queryClient]);

  const alternatives = useMemo(() => getAlternatives(docs, t), [docs, t]);

  return (
    <div className={cx(styles.wrapper)}>
      <NewOverlay
        open={open}
        onClose={onClose}
        className="overlay-confirm-doc"
        width={800}
      >
        <div className="m-top-20">
          <h4>
            <T>Request supplements</T>
          </h4>
          <p>
            <Trans>
              Once saved, these supplements will be <b>added to the timeline</b>{" "}
              and we will notify the merchant in order for him/her to upload
              them.
            </Trans>
          </p>
          <Form
            className="m-top-40"
            onSubmit={(_, form) => {
              const realErrors = hasRealErrors(form);

              if (!form.isValid) {
                return;
              }

              if (!realErrors) {
                onAdd();
              }
            }}
          >
            <Select
              name="select-doc"
              label="Select type of supplement"
              alternatives={alternatives}
              value={doc}
              onChange={onChange}
              validators={[new RequiredValidator("Select a document type")]}
              disabled={status !== Status.DEFAULT}
            />

            <AnimateHeight
              name={doc === DocumentType.SALES_CUSTOM_REQUEST ? "up" : "down"}
            >
              {doc === DocumentType.SALES_CUSTOM_REQUEST ? (
                <>
                  <TextArea
                    name="custom-request"
                    className={styles.textarea}
                    value={message}
                    onChange={onMessageChange}
                    label="Document description"
                    disabled={status !== Status.DEFAULT}
                    validators={[
                      new RequiredValidator(
                        "Please provide a text that describes the document"
                      ),
                    ]}
                  />

                  <WarningBox relative>
                    <T>
                      Observe that the message must be in the language of the
                      merchant since we are unable to translate arbitrary texts
                    </T>
                  </WarningBox>
                </>
              ) : (
                <div />
              )}
            </AnimateHeight>

            <Button
              block
              type="submit"
              className="m-top-20"
              status={
                alternatives.length === 1 || status !== Status.DEFAULT
                  ? Status.DISABLED
                  : Status.DEFAULT
              }
            >
              <T>Add document</T>
            </Button>

            {docs.length ? (
              <div className="m-top-40">
                <b>
                  <T>Added documents</T>
                </b>
                <ul className="m-top-10">
                  {docs.map((item) => (
                    <li key={item.documentId}>
                      <FileRow
                        document={item}
                        onRemove={onRemove}
                        allowRemove
                        status={
                          status !== Status.DEFAULT ? Status.DISABLED : status
                        }
                      />
                    </li>
                  ))}
                </ul>

                <div className="m-top-40">
                  <AnimateHeight name={status}>
                    {status === Status.ERROR ? <GenericError /> : <div />}
                  </AnimateHeight>

                  <Button
                    className="m-top-10"
                    ghost
                    block
                    onClick={onSave}
                    status={status}
                  >
                    <T>Save documents</T>
                  </Button>
                </div>
              </div>
            ) : (
              <Button ghost block onClick={onClose} className="m-top-40">
                <T>Close</T>
              </Button>
            )}
          </Form>
        </div>
      </NewOverlay>
    </div>
  );
};
