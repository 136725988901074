export const APP_ID = generateUID();

export class ServerError<T> extends Error {
  public status: number;
  public statusText: string;
  public data: T;

  constructor(status: number, statusText: string, data: T) {
    super(`Server error - ${status}: ${statusText}`);
    this.status = status;
    this.statusText = statusText;
    this.data = data;
  }
}

async function handleResponse<T>(response: Response): Promise<T> {
  const contentType = response.headers.get("content-type");
  let data;

  if (!response.ok) {
    if ((response as unknown as Error).message === "Failed to fetch") {
      throw new ServerError<T>(0, "Failed to fetch", {} as any);
    }

    let resolvedData: any;
    if (contentType && contentType.indexOf("application/json") !== -1) {
      resolvedData = await response.json();
    } else {
      resolvedData = await response.text();
    }

    throw new ServerError<T>(
      response.status,
      response.statusText,
      resolvedData
    );
  }

  if (contentType && contentType.indexOf("application/json") !== -1) {
    data = response.json();
  } else {
    data = response.text();
  }

  return data;
}

export class API {
  static fetch<T>(url: string, options: RequestInit = {}): Promise<T> {
    return fetch(API.getUrl(url), {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-app-id": APP_ID,
      },
      ...options,
    }).then((response) => handleResponse<T>(response));
  }

  static get<T>(url: string, options: RequestInit = {}): Promise<T> {
    return fetch(API.getUrl(url), {
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-app-id": APP_ID,
      },
      ...options,
    }).then((response) => handleResponse<T>(response));
  }

  static postFormData<T>(
    url: string,
    data: FormData,
    options: RequestInit = {}
  ): Promise<T> {
    return fetch(API.getUrl(url), {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "x-app-id": APP_ID,
      },

      body: data,
      ...options,
    }).then((response) => handleResponse<T>(response));
  }

  static post<T>(
    url: string,
    data?: Object,
    options: RequestInit = {}
  ): Promise<T> {
    return fetch(API.getUrl(url), {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "x-app-id": APP_ID,
      },
      body: JSON.stringify(data),
      ...options,
    }).then((response) => handleResponse<T>(response));
  }

  static put<T>(
    url: string,
    data?: Object,
    options: RequestInit = {}
  ): Promise<T> {
    return fetch(API.getUrl(url), {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "x-app-id": APP_ID,
      },
      body: JSON.stringify(data),
      ...options,
    }).then((response) => handleResponse<T>(response));
  }

  static delete<T>(
    url: string,
    data?: Object,
    options: RequestInit = {}
  ): Promise<T> {
    return fetch(API.getUrl(url), {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "x-app-id": APP_ID,
      },
      body: JSON.stringify(data),
      ...options,
    }).then((response) => handleResponse<T>(response));
  }

  static getUrl(url: string) {
    if (url.indexOf("http") === 0) {
      return url;
    }

    return (process.env.REACT_APP_TARGET_BACKEND || "") + url;
  }
}

/**
 * https://stackoverflow.com/a/2117523
 */
function generateUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const ABORT_ERROR = "AbortError";
