import React, { ReactNode } from "react";
import { MenuItem as MenuItemElem } from "./MenuItem";
import cx from "classnames";
import "./Menu.scss";

export interface MenuItem {
  name: string | ReactNode;
  icon?: ReactNode;
  link: string;
  disabled?: boolean;
  active?: boolean;
}

interface Props {
  menuItems: MenuItem[];
  className?: string;
}

export const Menu: React.FunctionComponent<Props> = ({
  menuItems,
  className,
}) => {
  if (menuItems.length === 0) {
    return null;
  }

  return (
    <nav className={cx("menu", className)}>
      <ul>
        {menuItems.map((item) => (
          <MenuItemElem key={item.link} menuItem={item} />
        ))}
      </ul>
    </nav>
  );
};
