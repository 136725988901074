import cx from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { MISSING_DATE, getIntlDate } from "../../../../../components/utils";
import {
  TIMELINE_MERCHANT_LINKS,
  TIMELINE_PRIMARY_REMINDER,
  TIMELINE_SENT_TO_MERCHANT,
  allowForPrimaryReminder,
} from "../Timeline";
import {
  AssociateTracking,
  BankAccount,
  CommunicationEvent,
  Contract,
  ContractDocument,
  EcomStore,
} from "../../../../../data/models/ContractTypes";
import { MerchantViews, getMerchantLinks } from "./components/MerchantViews";
import { T } from "../../../../../components/translation/T";
import {
  Associate,
  AssociateRole,
} from "../../../../../data/models/AssociateTypes";
import styles from "./EventCSentToMerchant.module.scss";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Link } from "../../../../../components/links/Link";
import { Status } from "../../../../../data/types";
import { generatePath } from "react-router-dom";
import { ASSOCIATE_REVIEW_PAGE } from "../../AssociateReview/AssociateReviewPage";
import { dataMerchant } from "../../../../../data/dataMerchant";

interface Props {
  associates: Associate[];
  sentToMerchantDate: string;
  primaryTracking?: AssociateTracking;
  contract: Contract;
  bankAccount?: BankAccount;
  documents: ContractDocument[];
  ecomStore?: EcomStore;
}

function getSentLink(tracking?: AssociateTracking) {
  const sentEvents =
    tracking?.trackingLog.filter(
      (event) => event.event === CommunicationEvent.SENT
    ) || [];

  sentEvents.sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
  );

  const latestReminder = sentEvents[0];
  if (!latestReminder) {
    return undefined;
  }

  return new Date(latestReminder.created);
}

export const EventCSentToMerchant: React.FunctionComponent<Props> = ({
  sentToMerchantDate,
  primaryTracking,
  contract,
  bankAccount,
  documents,
  ecomStore,
  associates,
}) => {
  const merchantLinks = getMerchantLinks(
    contract,
    associates,
    documents,
    primaryTracking,
    ecomStore,
    bankAccount
  );

  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [latestReminder, setLatestReminder] = useState<Date | undefined>(
    getSentLink(primaryTracking)
  );
  const refReminder = useRef<Date | undefined>(latestReminder);

  const lastVisit = useMemo(
    () =>
      primaryTracking?.lastVisit && new Date(primaryTracking.lastVisit.created),
    [primaryTracking]
  );

  const primary = useMemo(() => {
    return associates.find((associate) =>
      associate.roles.includes(AssociateRole.PRIMARY_CONTACT)
    );
  }, [associates]);

  const sendReminder = useCallback(() => {
    if (!primary) {
      return;
    }

    setStatus(Status.PENDING);

    dataMerchant
      .resendLink(
        contract.contractId,
        primary.associateId,
        primary.contact.email,
        primary.contact.phoneNumber
      )
      .then(() => {
        setStatus(Status.SUCCESS);
        setLatestReminder(new Date());
      })
      .catch(() => {
        setStatus(Status.ERROR);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [contract, primary]);

  const primaryReminder = useMemo(() => {
    if (!primary) {
      return null;
    }

    if (!allowForPrimaryReminder(contract, associates)) {
      return null;
    }

    return (
      <div className={styles.reminder}>
        <div className={styles.info}>
          {latestReminder ? (
            <>
              <T>Sent</T>{" "}
              <b
                className={cx({
                  [styles.today]: latestReminder !== refReminder.current,
                })}
              >
                {getIntlDate(latestReminder)}
              </b>
            </>
          ) : null}
          {lastVisit ? (
            <>
              {latestReminder ? " | " : null}
              <T>Visited</T> <b>{getIntlDate(lastVisit)}</b>
            </>
          ) : (
            <>
              {latestReminder ? " | " : null}
              <T>Not visited by customer</T>
            </>
          )}
        </div>
        <strong>Send reminder to</strong>
        <dl>
          <dt>
            <T>Email</T>
          </dt>
          <dd>{primary.contact.email}</dd>
          <dt>
            <T>Phone</T>
          </dt>
          <dd>{primary.contact.phoneNumber}</dd>
        </dl>
        <div className={styles.actions}>
          <Link
            link={generatePath(ASSOCIATE_REVIEW_PAGE, {
              contractId: contract.contractId,
              associateId: primary.associateId,
            })}
            className={cx({
              [styles.disabled]: status !== Status.DEFAULT,
            })}
          >
            <T>Change</T>
          </Link>
          <Button status={status} className="mini" onClick={sendReminder}>
            <T>Send</T>
          </Button>
        </div>
      </div>
    );
  }, [
    contract,
    primary,
    status,
    sendReminder,
    lastVisit,
    latestReminder,
    associates,
  ]);

  return (
    <li
      className={cx({
        active: sentToMerchantDate !== MISSING_DATE,
      })}
      style={{
        height:
          TIMELINE_SENT_TO_MERCHANT +
          (!!primaryReminder ? TIMELINE_PRIMARY_REMINDER : 0) +
          merchantLinks.length * TIMELINE_MERCHANT_LINKS,
      }}
    >
      <div className="event">
        <div className="timeline-date">{sentToMerchantDate}</div>
        <div>
          <b>
            <T>Contract sent for confirmation</T>
          </b>
          <div className="additional-event-info text-small">
            <MerchantViews
              associates={associates}
              primaryTracking={primaryTracking}
              contract={contract}
              bankAccount={bankAccount}
              documents={documents}
              ecomStore={ecomStore}
            />
            {primaryReminder}
          </div>
        </div>
      </div>
    </li>
  );
};
