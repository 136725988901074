import { MotionValue } from "framer-motion";
import { atom } from "recoil";
import { Store, STORE_KEY, Theme } from "../Store";

export enum ViewState {
  STRIPPED = "stripped",
  DEFAULT = "default",
}

interface SidebarState {
  open: boolean;
  width: MotionValue<number>;
}

export const themeState = atom<Theme>({
  key: "theme",
  default: Store.getValue(STORE_KEY.STORE_THEME),
});

export const viewState = atom<ViewState>({
  key: "viewState",
  default: ViewState.DEFAULT,
});

export const contractSidebarState = atom<SidebarState>({
  dangerouslyAllowMutability: true,
  key: "contractSidebarState",
  default: {
    open: false,
    width: new MotionValue(),
  },
});
