import React, { useMemo } from "react";
import cx from "classnames";
import {
  Associate,
  AssociateRole,
} from "../../../../../data/models/AssociateTypes";
import {
  AssociateTracking,
  BankAccount,
  Contract,
  ContractDocument,
  EcomStore,
} from "../../../../../data/models/ContractTypes";
import { getTimelineComplete } from "../Timeline";

interface Props {
  associates: Associate[];
  primaryTracking?: AssociateTracking;
  contract: Contract;
  bankAccount?: BankAccount;
  documents: ContractDocument[];
  ecomStore?: EcomStore;
}

export const IndicatorIComplete: React.FunctionComponent<Props> = ({
  associates,
  primaryTracking,
  contract,
  bankAccount,
  documents,
  ecomStore,
}) => {
  const offset = getTimelineComplete(
    contract,
    documents,
    associates,
    primaryTracking,
    ecomStore,
    bankAccount
  );

  const active = useMemo(() => {
    const associatesDone = associates.every((associate) => {
      // might be a single account holder
      if (!associate.roles.includes(AssociateRole.SELECTED_SIGNATORY)) {
        return true;
      }

      return !!associate.signatory?.signed;
    });

    const docsDone = documents.every((doc) => doc.confirmed);

    return bankAccount?.signedOff && associatesDone && docsDone;
  }, [associates, documents, bankAccount]);

  return (
    <circle
      r="6"
      cx="10"
      cy={offset}
      className={cx({
        active,
      })}
    />
  );
};
