import React from "react";
import { Status } from "../../../../../data/types";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../../../components/boxes/SuccessBox";
import { getIntlDate } from "../../../../../components/utils";
import { ContractDocument } from "../../../../../data/models/ContractTypes";
import { IdImage, IdImageInterface } from "../../../Contract/uploads/IdImage";

interface Props {
  originalImage: IdImageInterface;
  confirmStatus: Status;
  rejectStatus: Status;
  document: ContractDocument;
}

export const ConfirmDocImageAndStatuses: React.FunctionComponent<Props> = ({
  originalImage,
  rejectStatus,
  confirmStatus,
  document,
}) => {
  return (
    <div className="relative">
      <IdImage status={Status.DEFAULT} image={originalImage} isGeneric={true} />

      <div className="confirm-doc-statuses">
        {confirmStatus === Status.ERROR && (
          <ErrorBox relative>
            Something went wrong. We couldn't confirm this document.
          </ErrorBox>
        )}

        {confirmStatus === Status.SUCCESS && (
          <SuccessBox relative>
            <i>{getIntlDate(document.confirmed)}</i> - Thanks! We have confirmed
            this document
          </SuccessBox>
        )}

        {rejectStatus === Status.ERROR && (
          <ErrorBox relative>
            Something went wrong. We couldn't reject this document.
          </ErrorBox>
        )}

        {rejectStatus === Status.SUCCESS && (
          <SuccessBox relative>Ok! We have rejected this document.</SuccessBox>
        )}
      </div>
    </div>
  );
};
