import React from "react";
import cx from "classnames";
import "./Icon.scss";

interface Props {
  className?: string;
}

export const Close: React.FunctionComponent<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={cx("icon", "close", className)}
  >
    <line x1="0" x2="20" y1="0" y2="20" />
    <line x1="0" x2="20" y1="20" y2="0" />
  </svg>
);
