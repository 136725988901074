import React, { VoidFunctionComponent } from "react";
import { Edit } from "../../../components/icons/Edit";
import { Trash } from "../../../components/icons/Trash";
import { Button } from "../../../components/interactions/Buttons/Button";
import {
  TranslationText,
  TranslationId,
} from "../../../components/translation/dataTranslation";
import { getIntlDate } from "../../../components/utils";
import { Language } from "../../../i18n";

interface Props {
  translationText: TranslationText;
  deleteTranslation: (id: TranslationId) => void;
  language: Language;
  onEdit: (translationText: TranslationText) => void;
}

export const MissingTranslationsRow: VoidFunctionComponent<Props> = ({
  translationText,
  deleteTranslation,
  language,
  onEdit,
}) => {
  return (
    <tr key={translationText.id}>
      <td className="translations-header-site">{translationText.namespace}</td>
      <td className="translations-header-added">
        {getIntlDate(new Date(translationText.created))}
      </td>
      <td className="translations-header-original">{translationText.key}</td>
      <td className="translations-header-translation">
        {translationText.value ?? "-"}
      </td>
      <td className="translations-header-translated">
        {translationText.updated
          ? getIntlDate(new Date(translationText.updated))
          : "-"}
      </td>
      <td className="truncated translations-header-translated-by">
        {translationText.updatedBy ?? ""}
      </td>
      <td className="translations-header-button">
        <Button action className="mini" onClick={() => onEdit(translationText)}>
          <Edit />
        </Button>
      </td>
      <td className="translations-header-button">
        <Button
          action
          className="has-error mini"
          onClick={() => {
            const confirmDelete = window.confirm(
              "Are you sure you want to delete text?"
            );
            if (confirmDelete) {
              deleteTranslation(translationText.id);
            }
          }}
        >
          <Trash />
        </Button>
      </td>
    </tr>
  );
};

export const MemoMissingTranslationsRow = React.memo(MissingTranslationsRow);
