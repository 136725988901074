import React from "react";
import { MISSING_DATE } from "../../../../../components/utils";
import { TIMELINE_CONFIRMED_BY_MERCHANT } from "../Timeline";

interface Props {
  confirmedByMerchant: string;
  hasMultipleAssociates: boolean;
}

export const EventDConfirmedByMerchant: React.FunctionComponent<Props> = ({
  confirmedByMerchant,
  hasMultipleAssociates,
}) => {
  const active = confirmedByMerchant !== MISSING_DATE;

  return (
    <li
      className="active"
      style={{
        height: TIMELINE_CONFIRMED_BY_MERCHANT,
      }}
    >
      <div className="event">
        <div className="timeline-date">{confirmedByMerchant}</div>
        <div>
          <b>
            {active
              ? "Confirmed by merchant"
              : "Waiting for merchant to confirm"}
          </b>
          <br />
          <div className="additional-event-info text-small text-passive">
            {active &&
              hasMultipleAssociates &&
              "Invites sent to all signatories"}
          </div>
        </div>
      </div>
    </li>
  );
};
