import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import cx from "classnames";
import styles from "./Settings.module.scss";
import { Props } from "./Terminals";
import {
  Store,
  TerminalIntegrated,
  TerminalType,
} from "../../../../../data/models/ContractTypes";
import { AnimateHeight } from "../../../../../components/animate/AnimateHeight";
import { Alternative } from "../../../../../components/interactions/InputTypes";
import { T } from "../../../../../components/translation/T";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { useTranslation } from "react-i18next";
import { disabledState } from "../../../../../state/contractState";
import { useRecoilValue } from "recoil";
import { InfoBox } from "../../../../../components/boxes/InfoBox";
import { UniqueTerminal } from "../../../../../data/models/UniqueTerminal";
import { Status } from "../../../../../data/types";
import { dataStores } from "../../../../../data/dataStores";
import { useContractId } from "../../../../../hooks/useContractId";
import { HiddenInput } from "../../../../../components/form/HiddenInput";
import { Dropdown } from "../../../../../components/interactions/Dropdown/Dropdown";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import {
  CommunicationProtocol,
  Setting,
  getApplicableKits,
  getDuplicates,
  getEcrAlternatives,
  getKitIdentifier,
  getSettings,
  kitsToAlternatives,
} from "./settingsUtils";
import { AdvancedSettings } from "./AdvancedSettings";

export const EMPTY_DEFAULT = "-";

export const Settings: React.FunctionComponent<Props> = (props) => {
  if (props.terminalType === TerminalType.WIRELESS_STANDALONE) {
    return null;
  }

  if (props.terminalType === TerminalType.WIRELESS_STANDALONE_ANDROID) {
    return null;
  }

  return <SettingsInner {...props} />;
};

function getIdOfECR(store: Store, terminalType: TerminalType) {
  if (terminalType === TerminalType.WIRELESS_STANDALONE) {
    return -1;
  }

  if (terminalType === TerminalType.WIRELESS_STANDALONE_ANDROID) {
    return -1;
  }

  return store[terminalType]?.integrationId ?? -1;
}

function getKitId(store: Store, terminalType: TerminalType) {
  if (terminalType === TerminalType.WIRELESS_STANDALONE) {
    return -1;
  }

  if (terminalType === TerminalType.WIRELESS_STANDALONE_ANDROID) {
    return -1;
  }

  return store[terminalType]?.integrationKitId ?? -1;
}

export const SettingsInner: React.FunctionComponent<Props> = ({
  store,
  saveStore,
  terminalType,
  formRef,
  terminalOptions,
  isError,
}) => {
  const contractId = useContractId();
  const disabled = useRecoilValue(disabledState);
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [kits, setKits] = useState<Alternative<number>[]>([]);
  const [settings, setSettings] = useState<Setting[]>([]);
  const [terminalByECR, setTerminalByECR] = useState<UniqueTerminal>();

  const [selectedKitId, setSelectedKitId] = useState<number>(
    getKitId(store, terminalType)
  );

  const [idOfECR, setIdOfECR] = useState<number>(
    getIdOfECR(store, terminalType)
  );
  const [showAdvancedSettings, setShowAdvancedSettings] =
    useState<boolean>(false);

  const hasFetchedInitialModel = useRef<boolean>(false);
  const prevNumberOfTerminals = useRef<number>(
    store[terminalType]?.terminals ?? 0
  );

  useEffect(() => {
    if (!isError) {
      return;
    }

    const integrated = store[terminalType] as TerminalIntegrated;

    if (!integrated) {
      return;
    }
    setSelectedKitId(integrated.integrationKitId || -1);
    setIdOfECR(integrated.integrationId || -1);
    setTerminalByECR(undefined);
  }, [isError, terminalType, store]);

  useEffect(() => {
    const currentNumberOfTerminals = store[terminalType]?.terminals ?? 0;
    if (prevNumberOfTerminals.current > 0 && currentNumberOfTerminals === 0) {
      setKits([]);
      setSelectedKitId(-1);
      setIdOfECR(-1);
      setTerminalByECR(undefined);
      setSettings([]);
    }

    prevNumberOfTerminals.current = currentNumberOfTerminals;
  }, [store, terminalType]);

  const onKitChange = useCallback(
    (val: number) => {
      if (val === -1) {
        return;
      }

      if (!terminalByECR) {
        return;
      }

      const kit = terminalByECR.kits.find((kit) => kit.id === val);

      if (!kit) {
        return;
      }

      const modelId = kit.terminalModel.id;

      setSelectedKitId(val);
      saveStore({
        ...store,
        [terminalType]: {
          ...store[terminalType],
          integrationId: idOfECR,
          integrationKitId: kit.id,
          terminalModelId: modelId,
          terminalOptions: settings,
        },
      });
    },
    [idOfECR, saveStore, store, terminalByECR, terminalType, settings]
  );

  const getTerminalFromApi = useCallback(
    (val: number) => {
      setStatus(Status.PENDING);
      dataStores
        .getTerminalById(contractId, val)
        .then((uniqueTerminal) => {
          setStatus(Status.SUCCESS);
          setTerminalByECR(uniqueTerminal);
          setShowAdvancedSettings(false);
          const savedKit = uniqueTerminal.kits.find(
            (kit) => kit.id === selectedKitId
          );
          let applicableKits = getApplicableKits(uniqueTerminal, terminalType);

          if (savedKit) {
            const identifier = getKitIdentifier(savedKit);
            applicableKits = applicableKits.filter(
              (kit) =>
                identifier !== getKitIdentifier(kit) || kit.id === savedKit.id
            );

            setKits(kitsToAlternatives(applicableKits, t));
            setSettings(getSettings(uniqueTerminal, terminalType, store));
            return;
          }

          const duplicates = getDuplicates(applicableKits);
          if (duplicates.length === 2) {
            const duplicateId =
              duplicates[0].interface.settingsName ===
              CommunicationProtocol.WIFI
                ? duplicates[1].id
                : duplicates[0].id;

            applicableKits = applicableKits.filter(
              (kit) => kit.id !== duplicateId
            );
          }

          const alternatives = kitsToAlternatives(applicableKits, t);
          setKits(alternatives);
          setSelectedKitId(
            alternatives.length === 2 ? alternatives[1].value : -1
          );
          setSettings(getSettings(uniqueTerminal, terminalType, store));

          if (alternatives.length === 2) {
            const kit = uniqueTerminal.kits.find(
              (kit) => kit.id === alternatives[1].value
            );

            if (!kit) {
              return;
            }

            const modelId = kit.terminalModel.id;

            saveStore({
              ...store,
              [terminalType]: {
                ...store[terminalType],
                integrationId: val,
                integrationKitId: alternatives[1].value,
                terminalModelId: modelId,
                terminalOptions: getSettings(
                  uniqueTerminal,
                  terminalType,
                  store
                ),
              },
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setStatus(Status.ERROR);
        });
    },
    [contractId, t, terminalType, saveStore, selectedKitId, store]
  );

  useEffect(() => {
    if (hasFetchedInitialModel.current) {
      return;
    }

    if (!store) {
      return;
    }

    const localStore = store[terminalType] as TerminalIntegrated;
    hasFetchedInitialModel.current = true;

    if (!localStore) {
      return;
    }

    if (!localStore.integrationId) {
      return;
    }

    if (localStore.integrationId === -1) {
      return;
    }

    getTerminalFromApi(localStore.integrationId);
  }, [getTerminalFromApi, store, terminalType, idOfECR]);

  const onIntegrationChange = useCallback(
    (val: number) => {
      if (val === -1) {
        return;
      }

      setIdOfECR(val);
      setSelectedKitId(-1);
      setTerminalByECR(undefined);
      getTerminalFromApi(val);
    },
    [getTerminalFromApi]
  );

  const hasTerminals = useMemo(
    () => (store[terminalType]?.terminals ?? 0) > 0,
    [store, terminalType]
  );

  const ecrOptions = useMemo(
    () => getEcrAlternatives(terminalOptions, terminalType, t),
    [t, terminalOptions, terminalType]
  );

  return (
    <AnimateHeight name={hasTerminals ? "yes" : "no"}>
      <div>
        {hasTerminals ? (
          <div className={styles.dropdowns}>
            <Dropdown
              alternatives={ecrOptions}
              label={<T>Checkout system</T>}
              name="provider"
              onChange={onIntegrationChange}
              value={idOfECR}
              status={disabled ? Status.DISABLED : status}
            />

            <Dropdown
              alternatives={kits}
              label={<T>Terminal model</T>}
              name="kits"
              onChange={onKitChange}
              value={selectedKitId}
              status={disabled || idOfECR === -1 ? Status.DISABLED : status}
            />

            <HiddenInput
              label=""
              value={hasTerminals && selectedKitId === -1 ? undefined : true}
              scrollToRef={formRef}
              validators={[
                new RequiredValidator("You must select a terminal model"),
              ]}
            />

            <div className={cx(styles.advanced)}>
              <Button
                className="as-link"
                status={selectedKitId === -1 ? Status.DISABLED : Status.DEFAULT}
                onClick={() => setShowAdvancedSettings((prev) => !prev)}
              >
                {t("Toggle advanced settings")}
              </Button>
            </div>
            <div className="m-top-10">
              <AnimateHeight name={showAdvancedSettings ? "true" : "false"}>
                <div className="tablet-columns">
                  {showAdvancedSettings ? (
                    <AdvancedSettings
                      terminalByECR={terminalByECR}
                      terminalType={terminalType}
                      settings={settings}
                      setSettings={setSettings}
                      status={status}
                      selectedKitId={selectedKitId}
                      setSelectedKitId={setSelectedKitId}
                      store={store}
                      saveStore={saveStore}
                    />
                  ) : (
                    <div />
                  )}
                </div>
              </AnimateHeight>
            </div>
            <InfoBox relative>
              <T>Self checkout is not available at this moment</T>
            </InfoBox>
          </div>
        ) : null}
      </div>
    </AnimateHeight>
  );
};
