import React from "react";
import { CasError } from "../../../components/errors/CasError";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Overlay } from "../../../components/overlay/Overlay";
import { Status } from "../../../data/types";
import { ContractSaveError } from "./ContractPage";
import styles from "./ContractEditError.module.scss";
import { DangerBox } from "../../../components/boxes/DangerBox";

interface Props {
  error: ContractSaveError | null;
  retry?: () => void;
  onClose: () => void;
  displayableError?: string;
}

const ContractEditErrorInner: React.FunctionComponent<Props> = ({
  error,
  retry,
  onClose,
  displayableError,
}) => {
  if (error === ContractSaveError.CAS_ERROR) {
    return <CasError />;
  }

  const errorElem = displayableError ? (
    <DangerBox>
      <b className={styles.errorText}>{displayableError}</b>
    </DangerBox>
  ) : null;

  if (error === ContractSaveError.SERVER_ERROR) {
    if (retry) {
      return (
        <>
          <strong>Ooh noo! </strong> 😔
          <p className="line-small">Something bad happened. Try again?</p>
          {errorElem}
          <Button onClick={retry} block>
            Try again?
          </Button>
        </>
      );
    }

    return (
      <>
        <strong>Ooh noo! </strong> 😔
        <p className="line-small">
          Something bad happened. But we are unable to retry save?
        </p>
        {errorElem}
        <Button onClick={onClose} block>
          Close
        </Button>
      </>
    );
  }

  return (
    <>
      <strong>Ooh noo! </strong> 😔
      <p className="line-small">
        We couldn't save the data. It likely that that we tried to save
        incorrect information.
      </p>
      {errorElem}
      <Button ghost onClick={onClose} block>
        Close
      </Button>
    </>
  );
};

export const ContractEditError: React.FunctionComponent<Props> = ({
  error,
  retry,
  onClose,
  displayableError,
}) => {
  return (
    <div className="contract-edit-error">
      <Overlay open={error !== null} onClose={onClose} status={Status.ERROR}>
        <ContractEditErrorInner
          error={error}
          retry={retry}
          onClose={onClose}
          displayableError={displayableError}
        />
      </Overlay>
    </div>
  );
};
