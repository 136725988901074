import React from "react";
import cx from "classnames";
import styles from "./TableHeaderElem.module.scss";
import { SortOrder } from "./ContractsPage";
import { Button } from "../../../components/interactions/Buttons/Button";
import { T } from "../../../components/translation/T";
import { MdOutlineChevronRight } from "react-icons/md";

interface Props {
  active: SortOrder;
  sortOrder: SortOrder;
  ascending: boolean;
  setAscending: React.Dispatch<React.SetStateAction<boolean>>;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
  className?: string;
}

export const TableHeaderElem: React.FunctionComponent<Props> = ({
  setAscending,
  setSortOrder,
  sortOrder,
  active,
  ascending,
  className,
}) => {
  return (
    <div
      className={cx(className, styles.header, {
        [styles.activeHeader]: sortOrder === active,
        [styles.ascending]: ascending,
      })}
    >
      <Button
        className="as-link"
        onClick={() => {
          if (sortOrder === active) {
            setAscending((prev) => !prev);
          } else {
            setSortOrder(sortOrder);
          }
        }}
      >
        <div>
          <T>{sortOrder}</T>
        </div>
        <MdOutlineChevronRight className={styles.chevron} />
      </Button>
    </div>
  );
};
