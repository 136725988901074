import { data } from "./data";
import { ContractId, DEFAULT_CAS, StoreId } from "./models/CommonTypes";
import { EcomStore, Store } from "./models/ContractTypes";
import { OverviewTerminal } from "./models/OverviewTerminal";
import { UniqueTerminal } from "./models/UniqueTerminal";

const BASE_PATH = "/api/sales";

export const dataStores = {
  getStores: (contractId: ContractId) =>
    data.get<Store[]>(`${BASE_PATH}/${contractId}/stores`),

  getEcomStore: (contractId: ContractId) =>
    data.get<EcomStore>(`${BASE_PATH}/${contractId}/ecomstore`),

  fetchStores: (contractId: ContractId) => ({
    queryKey: dataStores.getStoresKey(contractId),
    queryFn: () => dataStores.getStores(contractId),
  }),

  fetchEcomStore: (contractId: ContractId) => ({
    queryKey: dataStores.getEcomStoresKey(contractId),
    queryFn: () => dataStores.getEcomStore(contractId),
  }),

  getStoresKey: (contractId: ContractId) => {
    return ["stores", contractId];
  },

  getEcomStoresKey: (contractId: ContractId) => {
    return ["ecom", contractId];
  },

  saveStore: (contractId: ContractId, store: Store) => {
    if (store.cas === DEFAULT_CAS) {
      return data.post<Store>(`${BASE_PATH}/${contractId}/stores`, store);
    }

    return data.post<Store>(
      `${BASE_PATH}/${contractId}/stores/${store.storeId}`,
      store
    );
  },

  saveEcomStore: (contractId: ContractId, store: EcomStore) => {
    return data.post<EcomStore>(`${BASE_PATH}/${contractId}/ecomstore`, store);
  },

  deleteStore: (contractId: ContractId, storeId: StoreId) => {
    return data.delete(`/api/sales/${contractId}/store/${storeId}`);
  },

  fetchTerminalOptions: (contractId: ContractId) => ({
    queryKey: dataStores.getTerminalOptionsKey(contractId),
    queryFn: () => dataStores.getTerminalOptions(contractId),
  }),

  getTerminalOptions: (contractId: ContractId) =>
    data.get<OverviewTerminal[]>(`${BASE_PATH}/${contractId}/terminals`),

  getTerminalById(contractId: ContractId, id: number) {
    return data.get<UniqueTerminal>(
      `${BASE_PATH}/${contractId}/terminals/${id}`
    );
  },

  getTerminalOptionsKey: (contractId: ContractId) => {
    return ["options", contractId];
  },

  // saveTerminal: (
  //   contractId: ContractId,
  //   terminal: IntegratedTerminal | StandaloneTerminal
  // ) => {
  //   if (terminal.cas === DEFAULT_CAS) {
  //     return data.post<IntegratedTerminal | StandaloneTerminal>(
  //       `${BASE_PATH}/${contractId}/terminals`,
  //       terminal
  //     );
  //   }

  //   return data.post<IntegratedTerminal | StandaloneTerminal>(
  //     `${BASE_PATH}/${contractId}/terminals/${terminal.id}`,
  //     terminal
  //   );
  // },

  // removeTerminal: (contractId: ContractId, terminalId: TerminalId) =>
  //   data.delete<IntegratedTerminal | StandaloneTerminal>(
  //     `${BASE_PATH}/${contractId}/terminals/${terminalId}`
  //   ),

  // addStoreSettings: (
  //   contractId: ContractId,
  //   storeId: StoreId,
  //   settings: StandaloneTerminal | IntegratedTerminal
  // ) =>
  //   data.post<Store>(
  //     `${BASE_PATH}/${contractId}/stores/${storeId}/settings`,
  //     settings
  //   ),
};
