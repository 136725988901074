import { ReactNode } from "react";
import { ValidationResponse } from "../hooks/useValidation";

export abstract class BaseValidator {
  protected error: string | ReactNode;
  public abstract name: string;

  constructor(error: string | ReactNode) {
    this.error = error;
  }

  public getId() {
    return this.error + this.name;
  }

  abstract validate(value: any): Promise<ValidationResponse>;
}
