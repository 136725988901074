import { contractState } from "./../state/contractState";
import { useRecoilValue } from "recoil";
import { Country } from "../i18n";

export const useCountry = () => {
  const { country } = useRecoilValue(contractState);

  // fallback to sweden here, should never be unset if we're logged in
  return country || Country.SWEDEN;
};
