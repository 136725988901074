import React, { useState, useCallback, ReactNode } from "react";
import cx from "classnames";
import { Button } from "./Button";
import { Status } from "../../../data/types";
import "./ConfirmButton.scss";
import { T } from "../../translation/T";

export enum ConfirmType {
  ERROR = "danger-button",
  SUCCESS = "success-button",
}

interface Props {
  children: string | ReactNode;
  onClick: (data?: any) => void;
  data?: any;
  className?: string;
  removeButtonText?: string;
  regretButtonText?: string;
  status: Status;
  confirmType?: ConfirmType;
  ghost?: boolean;
  small?: boolean;
}

export const ConfirmButton: React.FunctionComponent<Props> = ({
  children,
  onClick,
  data,
  className,
  removeButtonText,
  regretButtonText,
  status,
  confirmType = ConfirmType.ERROR,
  ghost = false,
  small = true,
}) => {
  const [confirm, setConfirm] = useState<boolean>(false);

  const tryRemove = useCallback(() => setConfirm(true), []);

  const onRegret = useCallback(() => setConfirm(false), []);

  const onChange = useCallback(() => {
    setConfirm(false);
    onClick(data);
  }, [onClick, data]);

  return (
    <div
      className={cx("confirm-button", {
        "show-confirm": confirm,
      })}
    >
      <div className="initial">
        <Button
          block
          ghost={ghost}
          className={cx(className, {
            small,
          })}
          status={status}
          onClick={tryRemove}
          tabIndex={confirm ? -1 : 0}
        >
          {children}
        </Button>
      </div>

      <div className="columns confirm">
        <div>
          <Button
            ghost
            block
            className={cx(className, {
              small,
            })}
            onClick={onRegret}
            tabIndex={confirm ? 0 : -1}
          >
            <T>{regretButtonText || "No, wait..."}</T>
          </Button>
        </div>

        <div>
          <Button
            block
            className={cx(className, confirmType, {
              small,
            })}
            onClick={onChange}
            tabIndex={confirm ? 0 : -1}
          >
            <T>{removeButtonText || "Yes, remove"}</T>
          </Button>
        </div>
      </div>
    </div>
  );
};
