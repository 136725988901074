import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  RefObject,
} from "react";
import cx from "classnames";
import styles from "./MCCSelection.module.scss";
import { Alternative } from "../interactions/InputTypes";
import { TextInput } from "../form/TextInput";
import { Or } from "../or/Or";
import { Dropdown } from "../interactions/Dropdown/Dropdown";
import { Dynamic, Speed } from "../animate/Dynamic";
import { Button } from "../interactions/Buttons/Button";
import { useTranslation } from "react-i18next";
import { Status } from "../../data/types";
import { MCC, dataContracts } from "../../data/dataContracts";
import { Pending } from "../icons/Pending";
import { GenericError } from "../boxes/GenericError";
import { T } from "../translation/T";
import { HiddenInput } from "../form/HiddenInput";
import { InListValidator } from "../form/validators/InListValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { TRANSLATION_NAMESPACE } from "../../i18n";

interface Props {
  onChange: (value: string) => void;
  mcc: string;
  name: string;
  active: boolean;
  formRef: RefObject<HTMLFormElement>;
}

const shortcutCategories = [
  "Hotels and accomodation",
  "Food, drinks and restaurants",
  "Health, medical and beauty",
];

function getCategoryOptions(
  mccs: MCC[],
  t: (val: string) => ReturnType<typeof String>
) {
  const categories: string[] = [];

  const options = mccs
    .reduce((acc, cur) => {
      if (acc.indexOf(cur.salesCategory) === -1) {
        return acc.concat(cur.salesCategory);
      }

      return acc;
    }, categories)
    .map((category) => ({
      value: category,
      text: t(category),
    }));

  return [
    {
      value: undefined,
      text: t("Show all MCCs"),
    } as unknown as Alternative<string>,
  ].concat(options);
}

function getCodeFromLabel(label: string) {
  const key = label.split("-")[0];
  return key ? key.trim() : "";
}

function getMccFromLabel(label: string, mccs: MCC[]) {
  const key = getCodeFromLabel(label);
  return mccs.find((mcc) => mcc.code === key);
}

function getLabelFromMCC(mcc: MCC) {
  return `${mcc.code} - ${mcc.tag || mcc.label}`;
}

export const MCCSelection: React.FunctionComponent<Props> = ({
  onChange,
  mcc,
  name,
  active,
  formRef,
}) => {
  const [categories, setCategories] = useState<Alternative<string>[]>([]);
  const [category, setCategory] = useState<string>();
  const [mccs, setMccs] = useState<MCC[]>([]);
  const [filteredMccs, setFilteredMccs] = useState<MCC[]>([]);
  const [value, setValue] = useState<string>();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [validCodes, setValidCodes] = useState<Set<string>>(new Set());
  const { t } = useTranslation();

  const datalistIdentifier = useRef<string>(window.crypto.randomUUID());

  const disabled = !active || Status.SUCCESS !== status;
  const inputStatus = disabled ? Status.DISABLED : Status.DEFAULT;

  useEffect(() => {
    if (!mccs) {
      return;
    }

    if (!category) {
      setFilteredMccs(mccs);
      return;
    }

    setFilteredMccs(mccs.filter((mcc) => mcc.salesCategory === category));
  }, [category, mccs]);

  const load = useCallback(
    (mcc: string) => {
      dataContracts
        .loadMccCodes()
        .then((rows) => {
          setStatus(Status.SUCCESS);
          setCategories(getCategoryOptions(rows, t));
          setMccs(rows);
          setValidCodes(new Set(rows.map((row) => row.code)));

          if (!mcc) {
            return;
          }

          const savedMcc = getMccFromLabel(mcc, rows);

          if (!savedMcc) {
            return;
          }

          setCategory(savedMcc.salesCategory);
          setValue(getLabelFromMCC(savedMcc));
        })
        .catch((err) => {
          setStatus(Status.ERROR);
          console.log("err", err);
        });
    },
    [t]
  );

  useEffect(() => {
    if (status !== Status.PENDING) {
      return;
    }

    load(mcc);
  }, [mcc, load, status]);

  const internalChange = useCallback(
    (value: string) => {
      setValue(value);
      const mcc = getMccFromLabel(value, mccs);

      if (!mcc) {
        setCategory("");
        onChange("");
        return;
      }

      setCategory(mcc.salesCategory);
      onChange(mcc.code);
    },
    [mccs, onChange]
  );

  const filteredMccOptions: Alternative<string>[] = useMemo(() => {
    const opts: Alternative<string>[] = filteredMccs.map((mcc) => {
      const label = getLabelFromMCC(mcc);
      return {
        text: `${t(label, {
          ns: TRANSLATION_NAMESPACE.MCC,
        })}`,
        value: label,
      };
    });

    return [
      {
        value: undefined,
        text: t("Select MCC"),
        disabled: true,
      } as unknown as Alternative<string>,
    ].concat(opts);
  }, [filteredMccs, t]);

  const activeMCC = useMemo(() => {
    return mccs.find((entry) => entry.code === mcc);
  }, [mccs, mcc]);

  const dropdownMccLabel = useMemo(() => {
    if (!activeMCC) {
      return;
    }

    return getLabelFromMCC(activeMCC);
  }, [activeMCC]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
  }, []);

  return (
    <div>
      <Or className="white-bg">
        <div>
          <Dropdown
            label="Select category"
            name="category_dropdown"
            className="compact"
            alternatives={categories}
            value={category}
            status={inputStatus}
            onChange={(value) => {
              setCategory(value);
              setValue("");
              onChange("");
            }}
          />

          <ul className={styles.shortcuts}>
            {shortcutCategories
              .filter(
                (shortcutCategory) =>
                  !!categories.find(
                    (category) => category.value === shortcutCategory
                  )
              )
              .map((shortcutCategory) => (
                <li key={shortcutCategory} className={styles.shortcut}>
                  <Button
                    className={cx("mini")}
                    block
                    status={inputStatus}
                    onClick={() => {
                      if (shortcutCategory === category) {
                        return;
                      }

                      setValue("");
                      onChange("");
                      setCategory(shortcutCategory);
                    }}
                  >
                    {t(shortcutCategory)}
                  </Button>
                </li>
              ))}
          </ul>

          <Dropdown
            label="MCCs"
            className="compact"
            alternatives={filteredMccOptions}
            value={dropdownMccLabel}
            status={inputStatus}
            onChange={(value) => {
              const code = getCodeFromLabel(value);
              if (!code) {
                return;
              }

              onChange(code);
              setValue(value);
            }}
          />
        </div>
        <div>
          <TextInput
            onChange={internalChange}
            className="compact"
            label="Search all MCCs"
            value={value}
            name={name}
            list={datalistIdentifier.current}
            disabled={disabled}
          />
          <datalist id={datalistIdentifier.current}>
            {mccs.map((opt) => {
              const key = getLabelFromMCC(opt);
              return (
                <option value={key} key={key}>
                  {key}
                </option>
              );
            })}
          </datalist>
        </div>
      </Or>

      <Dynamic name={status}>
        {status === Status.PENDING ? <Pending /> : null}
        {status === Status.ERROR ? (
          <div>
            <GenericError />
            <Button onClick={retry} block>
              <T>Retry</T>
            </Button>
          </div>
        ) : null}
      </Dynamic>

      <Dynamic name={activeMCC?.code ?? "empty"} speed={Speed.SLOW}>
        {activeMCC ? (
          <div className={cx(styles.info, "small")}>
            <div>{`${t(activeMCC.description, {
              ns: TRANSLATION_NAMESPACE.MCC,
            })}`}</div>
          </div>
        ) : null}
      </Dynamic>

      {validCodes.size === 0 ? (
        <HiddenInput
          label="mcc empty validation"
          value={undefined}
          scrollToRef={formRef}
          validators={[new RequiredValidator("MCC is required")]}
        />
      ) : (
        <HiddenInput
          label="mcc validation"
          value={mcc || "no-code"}
          scrollToRef={formRef}
          validators={[new InListValidator(validCodes, "MCC is not valid")]}
        />
      )}
    </div>
  );
};
