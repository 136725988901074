import styles from "../PricePlan.module.scss";
import { T } from "../../translation/T";
import { Button } from "../../interactions/Buttons/Button";
import { motion } from "framer-motion";
import { Expand } from "../../icons/Expand";
import cx from "classnames";

interface Props {
  open: boolean;
  onClick(): void;
  name: string;
}

export const PricePlanHeader: React.FunctionComponent<Props> = ({
  open,
  name,
  onClick,
}) => {
  return (
    <div className={styles.outerHeader} onClick={onClick}>
      <div>
        <div className={styles.headerLabel}>
          <T>Price Plan</T>
        </div>
        <strong className={styles.headerTitle}>{name}</strong>
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={() => {}} action ghost className={cx("small")}>
          <motion.div className="icon" animate={{ rotate: open ? 180 : 0 }}>
            <Expand />
          </motion.div>
        </Button>
      </div>
    </div>
  );
};
