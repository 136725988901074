import { data } from "./data";
import { Cas, ContractId } from "./models/CommonTypes";

const BASE_PATH = "/api/sales";

export interface FinancialData {
  cas: Cas;
  mcc?: string;
}

export const dataFinancial = {
  getFinancialData: (contractId: ContractId) =>
    data.get<FinancialData>(`${BASE_PATH}/${contractId}/financial`),

  fetchFinancialData: (contractId: ContractId) => ({
    queryKey: dataFinancial.getFinancialDataKey(contractId),
    queryFn: () =>
      data.get<FinancialData>(`${BASE_PATH}/${contractId}/financial`),
  }),

  getFinancialDataKey: (contractId: ContractId) => {
    return ["financial", contractId];
  },

  saveFinancialData: (contractId: ContractId, financialData: FinancialData) =>
    data.post<FinancialData>(
      `${BASE_PATH}/${contractId}/financial`,
      financialData
    ),
};
