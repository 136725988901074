import cx from "classnames";
import React, { useMemo } from "react";
import { generatePath } from "react-router";
import { Beacon } from "../../../../../components/beacon/Beacon";
import { Visibility } from "../../../../../components/icons/Visibility";
import { Link } from "../../../../../components/links/Link";
import {
  Associate,
  SigningMethod,
} from "../../../../../data/models/AssociateTypes";
import { ContractId } from "../../../../../data/models/CommonTypes";
import {
  BankAccount,
  AssociateTracking,
  CommunicationEvent,
  Validity,
  Contract,
  ContractStatus,
  BankAccountSource,
} from "../../../../../data/models/ContractTypes";
import {
  isAccountHolder as isAccountHolderFn,
  isOwner as isOwnerFn,
  isSignee as isSigneeFn,
} from "../../../../../state/contractAssociateState";
import { ASSOCIATE_REVIEW_PAGE } from "../../AssociateReview/AssociateReviewPage";
import { TIMELINE_ASSOCIATE } from "../Timeline";
import { T } from "../../../../../components/translation/T";
import { getIntlDate } from "../../../../../components/utils";
import "./EventHAssociate.scss";

interface Props {
  contract: Contract;
  contractId: ContractId;
  associate: Associate;
  bankAccount?: BankAccount;
  associateTracking?: AssociateTracking;
}

function getSigningMethod(method: SigningMethod) {
  switch (method) {
    case SigningMethod.BANKID:
      return "BankId";
    case SigningMethod.MITID:
      return "MitID";
    case SigningMethod.FTN:
      return "Finnish Trust Network";
    case SigningMethod.REMOTE_ID:
      return "Remote ID";

    default:
      return "-";
  }
}

function buildHeader(associate: Associate) {
  var header = "";
  if (isSigneeFn(associate)) {
    header += "Legal representative";
  }
  if (isOwnerFn(associate)) {
    if (header.length > 0) {
      header += ", ";
    }
    header += "Beneficial Owner";
  }
  if (isAccountHolderFn(associate)) {
    if (header.length > 0) {
      header += ", ";
    }
    header += "Bank Account Verifier";
  }
  return header;
}

export const EventHAssociate: React.FunctionComponent<Props> = ({
  contractId,
  contract,
  associate,
  bankAccount,
  associateTracking,
}) => {
  const isAccountHolder = isAccountHolderFn(associate);
  const isSignee = isSigneeFn(associate);
  const isSingleAccountHolder = isAccountHolder && !isSignee;

  const logCopy = structuredClone(associateTracking?.trackingLog) || [];
  const hasViewedContract = logCopy
    .filter((item) => item.event === CommunicationEvent.CONTRACT_VIEWED)
    .sort(
      (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
    )[0];

  const contractStatusAllowsConfirmation =
    contract.status !== ContractStatus.SALES_INPUT &&
    contract.status !== ContractStatus.MERCHANT_INPUT;

  const button = useMemo(() => {
    let enableConfirmDetails = true;

    if (contract.readOnly) {
      enableConfirmDetails = false;
    }

    if (!contractStatusAllowsConfirmation) {
      enableConfirmDetails = false;
    }

    if (associate.signedOff) {
      return null;
    }

    return (
      <Link
        className={cx("event-button", "small", "as-button", {
          "is-disabled": !enableConfirmDetails,
        })}
        link={generatePath(ASSOCIATE_REVIEW_PAGE, {
          contractId: contractId,
          associateId: associate.associateId,
        })}
      >
        <Visibility />{" "}
        {!!associate.signatory?.signed ? <T>View</T> : <T>Remind</T>}
      </Link>
    );
  }, [associate, contractId, contract, contractStatusAllowsConfirmation]);

  if (isSingleAccountHolder) {
    return (
      <li
        className={cx("associate-timeline-item", "text-small", {
          active: contractStatusAllowsConfirmation,
        })}
        style={{
          height: TIMELINE_ASSOCIATE,
        }}
      >
        {!contract.readOnly &&
        contract.status === ContractStatus.MERCHANT_SIGNING &&
        !bankAccount?.signedOff &&
        !bankAccount?.iban ? (
          <>{button}</>
        ) : null}
        <div className="relative">
          <div className="associate-item-name">
            {`${associate.contact.firstName} ${associate.contact.lastName}`},{" "}
            <span className="timeline-item-type">Account verifier</span>
          </div>

          <div className="associate-item-signature">
            <Beacon
              className="aligned"
              mini
              validity={
                !!bankAccount?.signedOff || bankAccount?.iban
                  ? Validity.VALID
                  : Validity.MISSING
              }
            />{" "}
            <span>Verified bank account</span>
          </div>

          {!!bankAccount?.iban ? (
            <div className="associate-item-signature">
              <Beacon className="aligned" mini validity={Validity.VALID} />{" "}
              <span>IBAN: {bankAccount.iban || "-"}</span>
            </div>
          ) : null}
        </div>
      </li>
    );
  }

  return (
    <li
      className={cx("associate-timeline-item", "text-small", {
        active: contractStatusAllowsConfirmation && !contract.readOnly,
      })}
      style={{
        height: TIMELINE_ASSOCIATE,
      }}
    >
      {button}
      <div className="relative">
        <div className="associate-item-name">
          {`${associate.contact.firstName} ${associate.contact.lastName}`},{" "}
          <span className="timeline-item-type">{buildHeader(associate)}</span>
        </div>

        <div className="associate-item-signature">
          <Beacon
            className="aligned"
            mini
            validity={
              associateTracking === undefined
                ? Validity.MISSING
                : hasViewedContract
                ? Validity.VALID
                : //Message sent, not viewed
                  Validity.DEFAULT
            }
          />{" "}
          <span>
            <T>Viewed contract</T> -{" "}
            {hasViewedContract ? getIntlDate(hasViewedContract.created) : null}
          </span>
        </div>

        {isAccountHolder && (
          <div className="associate-item-signature">
            <Beacon
              className="aligned"
              mini
              validity={
                bankAccount?.iban ||
                bankAccount?.source === BankAccountSource.BANKGIRO ||
                (bankAccount?.source === BankAccountSource.BANK_STATEMENT &&
                  bankAccount.statementUploaded)
                  ? Validity.VALID
                  : Validity.MISSING
              }
            />{" "}
            <span>
              <T>Added bank account</T>
            </span>
          </div>
        )}

        <div className="associate-item-signature">
          <Beacon
            className="aligned"
            mini
            validity={
              associate.signatory?.signed ? Validity.VALID : Validity.MISSING
            }
          />{" "}
          <span>
            {associate.signatory?.signed ? (
              <>
                <T>Signed with</T>{" "}
                {getSigningMethod(
                  associate.signatory.signingMethod || SigningMethod.BANKID
                )}
              </>
            ) : (
              <T>Added signature</T>
            )}
          </span>
        </div>
      </div>
    </li>
  );
};
