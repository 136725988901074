import React from "react";
import {
  BankAccountSource,
  Contract,
  ContractStatus,
} from "../../../../../data/models/ContractTypes";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Associate } from "../../../../../data/models/AssociateTypes";
import { BankConfirmed } from "./BankConfirmed";
import { BankStatementConfirmed } from "./BankStatementConfirmed";
import { T } from "../../../../../components/translation/T";
import { ContractId } from "../../../../../data/models/CommonTypes";
import "./ConfirmBank.scss";
import { useRecoilValue } from "recoil";
import { bankState } from "../../../../../state/contractBankState";
import { AddBankgiroAccount } from "../AddBankgiroAccount/AddBankgiroAccount";
import { Country } from "../../../../../i18n";

interface Props {
  contract: Contract;
  onClose: () => void;
  contractId: ContractId;
}

export function getName(associate: Associate) {
  if (!associate.contact.firstName && !associate.contact.lastName) {
    return "";
  }

  if (associate.contact.firstName && associate.contact.lastName) {
    return `${associate.contact.firstName} ${associate.contact.lastName}`;
  }

  if (associate.contact.firstName) {
    return associate.contact.firstName;
  }

  return associate.contact.lastName;
}

export const ConfirmBank: React.FunctionComponent<Props> = ({
  onClose,
  contractId,
  contract,
}) => {
  const bankAccount = useRecoilValue(bankState);

  // Official registry and Psd lookup will always be confirmed
  if (bankAccount.signedOff) {
    return (
      <BankConfirmed
        onClose={onClose}
        bankAccount={bankAccount}
        contractId={contractId}
      />
    );
  }

  if (bankAccount.source === BankAccountSource.BANKGIRO) {
    if (contract.country !== Country.SWEDEN) {
      return (
        <>
          {" "}
          <h3>
            <T>Add bank account</T>
          </h3>
          <p>
            <T>
              This is not a Swedish contract. Bankgiro is not applicable in this
              case?!
            </T>
          </p>
        </>
      );
    }

    if (
      contract.status !== ContractStatus.MERCHANT_SIGNING &&
      contract.status !== ContractStatus.SALES_CONFIRMATION
    ) {
      return (
        <>
          {" "}
          <h3>
            <T>Add bank account</T>
          </h3>
          <p>
            <T>
              This contract is not in correct state. We're unable to do
              anything.
            </T>
          </p>
        </>
      );
    }

    return <AddBankgiroAccount onClose={onClose} contractId={contractId} />;

    // TODO - re-add the customer facing alternatives

    // if (bankAccount.bgcStatus?.result === BgcResult.REJECTED) {
    //   return (
    //     <BankgiroReject
    //       onClose={onClose}
    //       bankAccount={bankAccount}
    //       contractId={contractId}
    //     />
    //   );
    // }

    // return (
    //   <div className="confirm-bank">
    //     <strong>
    //       <T>Bank account</T>
    //     </strong>

    //     <p>
    //       <T>We are awaiting the Bankgiro confirmation.</T>
    //     </p>

    //     <Button block ghost onClick={onClose}>
    //       <T>Close</T>
    //     </Button>
    //   </div>
    // );
  }

  if (bankAccount.statementUploaded) {
    return <BankStatementConfirmed onClose={onClose} contractId={contractId} />;
  }

  return (
    <div className="confirm-bank">
      <strong>
        <T>Bank account</T>
      </strong>

      <p>
        <T>The bank account has not yet been added.</T>
      </p>

      <Button block ghost onClick={onClose}>
        <T>Close</T>
      </Button>
    </div>
  );
};
