import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { getDefaultStyles } from "../PdfStyles";
import { Header } from "../componentParts/Header";
import { Footer } from "../componentParts/Footer";
import { ComponentProps } from "../PdfPage";
import { Wrapper } from "../Wrapper";
import {
  Signees,
  getLabels,
  getSpecificStyles,
} from "../componentParts/Signees";
import { useTranslation } from "react-i18next";

const Doc = (props: ComponentProps) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(props.show),
    ...getSpecificStyles(props.show),
  };

  const len = props.config.numberOfSignees || 0;

  const signeeElems = [];
  for (let idx = 0; idx < len; idx++) {
    signeeElems.push(
      <React.Fragment key={idx}>
        <Signees {...props} isFirst={idx === 0} multiple={len > 1} />
      </React.Fragment>
    );
  }

  return (
    <Document
      creator="Worldline"
      producer="Worldline"
      language={props.language.toString()}
    >
      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />

        {len > 1 ? (
          <>
            <View style={styles.sectionHeader}>
              <Text>{labels.headerMultiple}</Text>
            </View>
            <Text style={styles.info}>{labels.info}</Text>
            <View style={styles.divider} />
          </>
        ) : null}
        {signeeElems}
        <Footer {...props} />
      </Page>
    </Document>
  );
};

export const Signatory: React.FunctionComponent<ComponentProps> = (props) => (
  <Wrapper
    {...props}
    fileTag={"" + (props.config.numberOfSignees || 0)}
    name="signatory"
  >
    <Doc {...props} />
  </Wrapper>
);
