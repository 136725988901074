import React, { ReactNode } from "react";
import cx from "classnames";
import "./Visible.scss";

interface Props {
  show: boolean;
  children: ReactNode;
  className?: string;
}

export const Visible: React.FunctionComponent<Props> = ({
  children,
  show,
  className,
}) => {
  return (
    <div className={cx("visible-component", className, { show })}>
      {children}
    </div>
  );
};
