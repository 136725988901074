import React from "react";
import { ZoomableImage } from "../../../../../components/images/ZoomableImage";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import {
  BankAccount,
  BankAccountSource,
} from "../../../../../data/models/ContractTypes";
import { Status } from "../../../../../data/types";
import {
  FileType,
  IdImage,
  PDF_MIME_TYPE,
} from "../../../Contract/uploads/IdImage";
import { ContractId } from "../../../../../data/models/CommonTypes";
import { T } from "../../../../../components/translation/T";
import { dataBank } from "../../../../../data/dataBank";

interface Props {
  onClose: () => void;
  bankAccount: BankAccount;
  contractId: ContractId;
}

function getText(bankAccount: BankAccount) {
  if (bankAccount.source === BankAccountSource.OFFICIAL_REGISTRY) {
    return "This bank account was provided from the registry";
  }

  if (bankAccount.source === BankAccountSource.PSD_LOOKUP) {
    return "This bank account was provided by the merchant using Klarna";
  }

  if (bankAccount.source === BankAccountSource.BANKGIRO) {
    return "This bank account was verified by Bankgiro";
  }

  return "This bank account was verified by a provided bank statement";
}

export const BankConfirmed: React.FunctionComponent<Props> = ({
  onClose,
  bankAccount,
  contractId,
}) => {
  const url = dataBank.getBankAccountStatementUrl(contractId);

  let fileElem = null;

  if (!!bankAccount.statementUploaded) {
    if (bankAccount.mimeType === PDF_MIME_TYPE) {
      fileElem = (
        <IdImage
          status={Status.DEFAULT}
          image={{
            url: url,
            type: FileType.PDF,
          }}
          isGeneric={true}
        />
      );
    } else {
      fileElem = <ZoomableImage alt="Bank Statement" image={url} />;
    }
  }

  return (
    <div className="confirm-bank">
      <strong>
        <T>Bank account</T>
      </strong>

      <p>{getText(bankAccount)}</p>

      <dl className="text-small m-bottom-40">
        <dt>IBAN:</dt>
        <dd>{bankAccount.iban}</dd>
        <dt>
          <T>Account holder</T>:
        </dt>
        <dd>{bankAccount.accountHolder}</dd>
      </dl>

      {fileElem}

      <Button block ghost onClick={onClose} className="m-top-30">
        <T>Close</T>
      </Button>
    </div>
  );
};
