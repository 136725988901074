import { useEffect, useContext, RefObject } from "react";
import { FormContext } from "../FormContext";
import { VALIDATION_STATE } from "./useValidation";

export function useForm(
  inputName: string,
  validity: VALIDATION_STATE,
  value: unknown,
  resetValidation: () => void,
  scrollToRef: RefObject<HTMLElement>
) {
  const context = useContext(FormContext);
  const updateInput = context?.updateInput;
  const removeInput = context?.removeInput;

  useEffect(() => {
    const name = inputName;
    updateInput?.({
      name,
      isValid: validity === VALIDATION_STATE.SUCCESS,
      isInvalid: validity !== VALIDATION_STATE.SUCCESS,
      validationState: validity,
      value,
      resetValidation,
      scrollToRef,
    });

    return () => {
      removeInput?.(name);
    };
  }, [
    updateInput,
    removeInput,
    inputName,
    validity,
    value,
    resetValidation,
    scrollToRef,
  ]);
}
