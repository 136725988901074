import React, { ReactNode } from "react";
import styles from "./DangerBox.module.scss";

interface Props {
  children: ReactNode;
}

export const DangerBox: React.FunctionComponent<Props> = ({ children }) => {
  return <div className={styles.box}>{children}</div>;
};
