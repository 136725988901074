import { data } from "./data";
import { UTCDate } from "./models/CommonTypes";

interface LoginResponse {
  url: string;
}

export interface SessionResponse {
  expiry: UTCDate;
  name: string;
}

export const dataAuth = {
  /**
   * Returns an Oauth2 URI to send the user to
   */
  startLogin: (isPartner: boolean) => {
    if (isPartner) {
      return data.post<LoginResponse>("/api/login/partner");
    }

    return data.post<LoginResponse>("/api/login");
  },

  postCallback: (code: string) =>
    data.post("/api/login/callback", {
      code,
    }),

  getCurrentSession: () => data.get<SessionResponse>("/api/whoami"),
};
