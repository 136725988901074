import React from "react";
import ReactPDF, { PDFDownloadLink } from "@react-pdf/renderer";
import { Language } from "../../../i18n";

interface Props {
  doc: ReactPDF.Document;
  language: Language;
  name: string;
  show: boolean;
  fileTag?: string;
}

export const DownloadLink: React.FunctionComponent<Props> = ({
  doc,
  name,
  language,
  show,
  fileTag,
}) => {
  const filename =
    //Det här är inte vackert, men nu är det bara pricing som har olika vyer beroende på produkt typ
    fileTag
      ? `${language}-${fileTag}-${name}${show ? "-prefilled" : ""}.pdf`
      : `${language}-${name}${show ? "-prefilled" : ""}.pdf`;
  return (
    <PDFDownloadLink document={doc as any} fileName={filename}>
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download"
      }
    </PDFDownloadLink>
  );
};
