import React from "react";
import { Button } from "../interactions/Buttons/Button";

export const PermissionError = () => {
  return (
    <div className="retry-error">
      <p>
        You don't have permission to load this contract
      </p>
      <Button
        onClick={() => {
          window.location.pathname = "/";
        }}
      >
        Back to dashboard
      </Button>
    </div>
  );
};
