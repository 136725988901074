import React from "react";
import { StyleSheet, Document, Page, View } from "@react-pdf/renderer";
import { getDefaultStyles } from "../PdfStyles";
import { Header } from "../componentParts/Header";
import { Footer } from "../componentParts/Footer";
import { ComponentProps } from "../PdfPage";
import { Wrapper } from "../Wrapper";
import { Pricing as PricingComponent } from "../componentParts/pricing/Pricing";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

const Doc = (props: ComponentProps) => {
  const styles = {
    ...getDefaultStyles(props.show),
    ...getSpecificStyles(props.show),
  };

  return (
    <Document
      creator="Worldline"
      producer="Worldline"
      language={props.language.toString()}
    >
      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />
        <PricingComponent {...props} />
        <View style={styles.divider} />
        <Footer {...props} />
      </Page>
    </Document>
  );
};

export const Pricing: React.FunctionComponent<ComponentProps> = (props) => (
  <Wrapper {...props} fileTag={props.productType.toString()} name="pricing">
    <Doc {...props} />
  </Wrapper>
);
