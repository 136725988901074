export enum Theme {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum STORE_KEY {
  STORE_THEME = "store.ui.theme",
  STORE_URL_AT_SESSION_EXPIRED = "store.auth.url",
  STORE_FEATURES = "store.features",
  STORE_FEE_TEMPLATES = "store.fee.templates",
  STORE_CORRELATION_ID = "store.correlationId",
  STORE_REGULATED_ENTITY = "store.regulated.entity",
  STORE_LISTED_ENTITY = "store.listed.entity",
  STORE_REFERRER = "store.referrer",
  STORE_IS_PARTNER = "store.isPartner",
  STORE_CONFIRMED_CONTRACTS = "store.confirmedContracts",
}

export interface LegalEntityStoreObject {
  [key: string]: boolean | undefined;
}

export interface ConfirmedContract {
  contractId: string;
  confirmed: Date;
}

interface StoredData {
  [STORE_KEY.STORE_THEME]: Theme;
  [STORE_KEY.STORE_URL_AT_SESSION_EXPIRED]: string;
  [STORE_KEY.STORE_FEATURES]: boolean;
  [STORE_KEY.STORE_CORRELATION_ID]: string;
  [STORE_KEY.STORE_REGULATED_ENTITY]: LegalEntityStoreObject;
  [STORE_KEY.STORE_LISTED_ENTITY]: LegalEntityStoreObject;
  [STORE_KEY.STORE_REFERRER]: string;
  [STORE_KEY.STORE_IS_PARTNER]: boolean;
  [STORE_KEY.STORE_CONFIRMED_CONTRACTS]: ConfirmedContract[];
}

const NAME = "wlx-nord";

const defaultValues = {
  [STORE_KEY.STORE_THEME]: Theme.LIGHT,
  [STORE_KEY.STORE_URL_AT_SESSION_EXPIRED]: "",
  [STORE_KEY.STORE_FEATURES]: false,
  [STORE_KEY.STORE_CORRELATION_ID]: "",
  [STORE_KEY.STORE_REGULATED_ENTITY]: {},
  [STORE_KEY.STORE_LISTED_ENTITY]: {},
  [STORE_KEY.STORE_REFERRER]: "",
  [STORE_KEY.STORE_CONFIRMED_CONTRACTS]: [],
  [STORE_KEY.STORE_IS_PARTNER]: false,
};

let storedData: StoredData = { ...defaultValues };
const storedStringData = window.localStorage.getItem(NAME);
if (storedStringData) {
  try {
    storedData = { ...defaultValues, ...JSON.parse(storedStringData) };
  } catch (err) {}
}

export const Store = {
  getValue<T>(key: keyof StoredData) {
    return storedData[key] as unknown as T;
  },

  setValue<Key extends keyof StoredData>(key: Key, value: StoredData[Key]) {
    storedData[key] = value;
    try {
      window.localStorage.setItem(NAME, JSON.stringify(storedData));
    } catch (err) {
      //Do not log
    }
  },

  getStorage() {
    return storedData;
  },
};
