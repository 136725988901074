import React from "react";
import { StyleSheet, Document, Page, View } from "@react-pdf/renderer";
import { getDefaultStyles } from "../PdfStyles";
import { Header } from "../componentParts/Header";
import { Footer } from "../componentParts/Footer";
import { ComponentProps } from "../PdfPage";
import { Wrapper } from "../Wrapper";
import { Stores } from "../componentParts/Stores";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

const Doc = (props: ComponentProps) => {
  const styles = {
    ...getDefaultStyles(props.show),
    ...getSpecificStyles(props.show),
  };

  const len = props.config.numberOfStores || 0;

  const storeElems = [];
  for (let idx = 0; idx < len; idx++) {
    storeElems.push(
      <React.Fragment key={idx}>
        <Stores {...props} />
      </React.Fragment>
    );
  }

  return (
    <Document
      creator="Worldline"
      producer="Worldline"
      language={props.language.toString()}
    >
      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />
        {storeElems}
        <View style={styles.divider} />
        <View style={styles.divider} />
        <Footer {...props} />
      </Page>
    </Document>
  );
};

export const Store: React.FunctionComponent<ComponentProps> = (props) => (
  <Wrapper
    {...props}
    fileTag={"" + (props.config.numberOfStores || 0)}
    name="store"
  >
    <Doc {...props} />
  </Wrapper>
);
