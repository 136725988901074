import cx from "classnames";
import React, { useCallback, useRef, useState } from "react";
import { AnimateHeight } from "../../../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../../../components/boxes/SuccessBox";
import { Form, FormContainer } from "../../../../../components/form/Form";
import { TextInput } from "../../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import {
  ConfirmButton,
  ConfirmType,
} from "../../../../../components/interactions/Buttons/ConfirmButton";
import { getIntlDate } from "../../../../../components/utils";
import { dataBank } from "../../../../../data/dataBank";
import {
  DocumentType,
  documentTypeDescription,
} from "../../../../../data/models/ContractTypes";
import { Status } from "../../../../../data/types";
import { T } from "../../../../../components/translation/T";
import { ContractId, UTCDate } from "../../../../../data/models/CommonTypes";
import { useQueryClient } from "@tanstack/react-query";
import { ImageViewer } from "../../../../../components/images/ImageViewer";
import { useRecoilState } from "recoil";
import { bankState } from "../../../../../state/contractBankState";

interface Props {
  onClose: () => void;
  contractId: ContractId;
}

// function getOpts(mainData: MainContractData, associates: Associate[]) {
//   const options = [
//     {
//       text: "Select account holder",
//       value: "",
//       disabled: true,
//     },
//     {
//       text: `Company: ${mainData.companyName}`,
//       value: "COMPANY",
//     },
//   ];

//   associates.forEach((item) => {
//     const name = getName(item);

//     if (!name) {
//       return;
//     }

//     if (!item.roles.includes(AssociateRole.BENEFICIAL_OWNER)) {
//       return false;
//     }

//     options.push({
//       text: name || "No name",
//       value: item.associateId,
//     });
//   });

//   return options;
// }

export const BankStatementConfirmed: React.FunctionComponent<Props> = ({
  onClose,
  contractId,
}) => {
  const [bankAccount, setBankAccount] = useRecoilState(bankState);
  const [rejectStatus, setRejectStatus] = useState<Status>(Status.DEFAULT);
  const [confirmStatus, setConfirmStatus] = useState<Status>(Status.DEFAULT);
  const formContainer = useRef<FormContainer>();
  const queryClient = useQueryClient();
  // const opts = useMemo(
  //   () => getOpts(contract.contractData, associates),
  //   [contract.contractData, associates]
  // );

  const onReject = useCallback(() => {
    setRejectStatus(Status.PENDING);
    setConfirmStatus(Status.DISABLED);

    dataBank
      .rejectBankAccount(contractId)
      .then(() => {
        setRejectStatus(Status.SUCCESS);
        setBankAccount({});
        onClose();
        queryClient.invalidateQueries({
          queryKey: dataBank.getBankAccountKey(contractId),
        });
      })
      .catch(() => {
        setRejectStatus(Status.ERROR);
        setTimeout(() => {
          setRejectStatus(Status.DEFAULT);
          setConfirmStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [contractId, onClose, setBankAccount, queryClient]);

  const onConfirm = useCallback(() => {
    if (formContainer.current?.isInvalid) {
      formContainer.current?.setForceErrors(true);
      return;
    }

    setConfirmStatus(Status.PENDING);
    setRejectStatus(Status.DISABLED);

    dataBank
      .addBankAccount(contractId, {
        ...bankAccount,
      })
      .then(() => {
        setConfirmStatus(Status.SUCCESS);
        setBankAccount({
          ...bankAccount,
          signedOff: new Date().toISOString() as UTCDate,
        });
        queryClient.invalidateQueries({
          queryKey: dataBank.getBankAccountKey(contractId),
        });
      })
      .catch(() => {
        setConfirmStatus(Status.ERROR);
        setTimeout(() => {
          setConfirmStatus(Status.DEFAULT);
          setRejectStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [bankAccount, setBankAccount, queryClient, contractId]);

  const wasSuccessfulOrConfirmed =
    confirmStatus === Status.SUCCESS ||
    rejectStatus === Status.SUCCESS ||
    bankAccount.signedOff;

  const url = dataBank.getBankAccountStatementUrl(contractId);

  return (
    <div
      className={cx("confirm-bank", {
        "show-status":
          confirmStatus !== Status.DEFAULT || rejectStatus !== Status.DEFAULT,
      })}
    >
      <strong>
        <T>Bank account</T>
      </strong>

      <p>
        <T>This bank statement has to be validated and confirmed.</T>
      </p>

      <div className="m-top-30 relative">
        <ImageViewer fileUrl={url} />

        {/* {bankAccount.mimeType === PDF_MIME_TYPE ? (
          <IdImage
            status={Status.DEFAULT}
            image={{
              url: url,
              type: FileType.PDF,
            }}
            isGeneric={true}
          />
        ) : (
          <ZoomableImage alt="Bank Statement" image={url} />
        )} */}

        <div className="confirm-bank-statuses">
          {confirmStatus === Status.ERROR && (
            <ErrorBox relative>
              <T>Something went wrong. We couldn't confirm this document.</T>
            </ErrorBox>
          )}

          {confirmStatus === Status.SUCCESS && (
            <SuccessBox relative>
              <i>{getIntlDate(new Date())}</i> -{" "}
              <T>Thanks! We have confirmed this document.</T>
            </SuccessBox>
          )}

          {rejectStatus === Status.ERROR && (
            <ErrorBox relative>
              <T>Something went wrong. We couldn't reject this document.</T>
            </ErrorBox>
          )}

          {rejectStatus === Status.SUCCESS && (
            <SuccessBox relative>
              <T>Ok! We have rejected this document.</T>
            </SuccessBox>
          )}
        </div>
      </div>

      <AnimateHeight name={wasSuccessfulOrConfirmed ? "done" : "pending"}>
        {wasSuccessfulOrConfirmed ? (
          <div>
            <SuccessBox relative>
              {confirmStatus === Status.SUCCESS ? (
                <>
                  <strong>
                    <T>Perfect!</T>{" "}
                  </strong>
                  <T>We have confirmed this bank statement</T>
                </>
              ) : (
                <>
                  <strong>
                    <T>Got it!</T>{" "}
                  </strong>
                  <T>
                    We have rejected this bank statement. We will notify the
                    merchant to upload another one.
                  </T>
                </>
              )}
            </SuccessBox>

            <Button block ghost onClick={onClose} className="m-top-20">
              Close
            </Button>
          </div>
        ) : (
          <Form
            formContainer={formContainer}
            onSubmit={(event, form) => {
              if (form.isValid) {
                onConfirm();
              }
            }}
          >
            <div className="tablet-columns m-top-40">
              <div>
                <TextInput
                  onChange={(val, name) => {
                    setBankAccount((prevBank) => ({
                      ...prevBank,
                      [name]: val,
                    }));
                  }}
                  label="IBAN"
                  value={bankAccount.iban}
                  name="iban"
                  validators={[new RequiredValidator("IBAN is required")]}
                  disabled={
                    rejectStatus !== Status.DEFAULT ||
                    confirmStatus !== Status.DEFAULT
                  }
                />
              </div>
              <div>
                {/* <Select
                  onChange={(val) =>
                    setAccount((prevBank) => ({
                      ...prevBank,
                      accountHolder: val,
                    }))
                  }
                  label="Account Holder"
                  alternatives={opts}
                  value={account.accountHolder}
                  hint="Owner of the account"
                  validators={[
                    new RequiredValidator("Account owner is required"),
                  ]}
                /> */}

                <TextInput
                  onChange={(val, name) => {
                    setBankAccount((prevBank) => ({
                      ...prevBank,
                      [name]: val,
                    }));
                  }}
                  label="Account Verifier"
                  value={bankAccount.accountHolder}
                  hint="Owner of the account"
                  name="accountHolder"
                  validators={[
                    new RequiredValidator("Account owner is required"),
                  ]}
                  disabled={
                    rejectStatus !== Status.DEFAULT ||
                    confirmStatus !== Status.DEFAULT
                  }
                />
              </div>

              {/* <div>
                <TextInput
                  onChange={(val, name) => {
                    setAccount((prevBank) => ({
                      ...prevBank,
                      [name]: val,
                    }));
                  }}
                  label="Bank"
                  value={account.bank}
                  name="bank"
                  validators={[new RequiredValidator("Bank is required")]}
                  disabled
                />
              </div>
              <div>
                <TextInput
                  onChange={(val, name) => {
                    setAccount((prevBank) => ({
                      ...prevBank,
                      [name]: val,
                    }));
                  }}
                  label="BSB"
                  validators={[new RequiredValidator("BSB is required")]}
                  value={account.bsb}
                  name="bsb"
                />
              </div> */}
            </div>

            <div className="m-bottom-30">
              <T>{documentTypeDescription(DocumentType.BANK_STATEMENT)}</T>
            </div>

            <div className="tablet-columns">
              <div>
                <ConfirmButton
                  ghost
                  onClick={onReject}
                  removeButtonText="Yes, reject"
                  status={rejectStatus}
                >
                  Reject document
                </ConfirmButton>
              </div>
              <div>
                <ConfirmButton
                  confirmType={ConfirmType.SUCCESS}
                  className="small confirm-button"
                  removeButtonText="Yes, confirm"
                  onClick={onConfirm}
                  status={confirmStatus}
                >
                  Confirm document
                </ConfirmButton>
              </div>
            </div>
          </Form>
        )}
      </AnimateHeight>
    </div>
  );
};
