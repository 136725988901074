import React, { ReactNode } from "react";
import { Text } from "@react-pdf/renderer";
import { getDefaultStyles } from "../PdfStyles";

interface Props {
  label: string;
  children?: ReactNode;
  show: boolean;
}

export const FirstCell: React.FunctionComponent<Props> = ({
  label,
  children = "&nbsp;",
  show,
}) => {
  const styles = getDefaultStyles(show);
  return (
    <>
      <Text style={styles.cellName}>{label}</Text>
      <Text style={styles.cellValue}>
        <Text style={styles.placeholderVisibile}>{children}</Text>
      </Text>
    </>
  );
};
