import styles from "../Offering.module.scss";
import cx from "classnames";
import { FunctionComponent } from "react";
import { ProductOption } from "./Products";

interface Props extends ProductOption {
  isSelected: boolean;
}

export const ProductCard: FunctionComponent<Props> = ({
  isSelected,
  name,
  description,
  icon,
}) => {
  const productCardClass = cx({
    [styles.productCard]: true,
    [styles.productCardActive]: isSelected,
  });

  const iconContainerClass = cx({
    [styles.circle]: true,
    [styles.circleActive]: isSelected,
  });

  return (
    <div className={productCardClass}>
      <div className={iconContainerClass}>{icon}</div>
      <div className={styles.productInfo}>
        <span className={styles.productName}>{name}</span>
        <span className={styles.productDescription}>{description}</span>
      </div>
    </div>
  );
};
