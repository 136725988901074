import { useCallback, useState } from "react";
import styles from "./Stores.module.scss";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { SectionFieldSet } from "../../../../components/sectionFieldSet/SectionFieldSet";
import { LinkAnchors } from "../ContractEdit";
import { FormName } from "../menus/ContractEditMenu";
import { StoreId } from "../../../../data/models/CommonTypes";
import { StoreRow } from "./storeRow/StoreRow";
import { useContractId } from "../../../../hooks/useContractId";
import { dataStores } from "../../../../data/dataStores";
import { useRecoilValue } from "recoil";
import { contractState, disabledState } from "../../../../state/contractState";
import { isEcom } from "../../../../data/dataContracts";
import { EcomStore } from "./storeForm/EcomStore";
import { Status } from "../../../../data/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Store } from "../../../../data/models/ContractTypes";
import { ContractSaveError, handleError } from "../ContractPage";
import { ContractEditError } from "../ContractEditError";

// function getDefaultStore(id: number): Store {
//   return {
//     cas: DEFAULT_CAS as Cas,
//     storeId: id as StoreId,
//   };
// }

export const Stores: React.FunctionComponent = () => {
  const contract = useRecoilValue(contractState);

  return (
    <SectionFieldSet
      headerTitle={
        isEcom(contract) ? LinkAnchors.STORES.ecomName : LinkAnchors.STORES.name
      }
      formName={FormName.STORE}
      sectionNumber={6}
    >
      {isEcom(contract) ? <EcomStore /> : <StoresInner />}
    </SectionFieldSet>
  );
};

function StoresInner() {
  const contractId = useContractId();
  const disabled = useRecoilValue(disabledState);
  const { data: stores } = useSuspenseQuery(dataStores.fetchStores(contractId));
  // const newId = useRef<number>(-1);
  const [renderedStores, setRenderedStores] = useState<Store[]>(stores);
  const [removeError, setRemoveError] = useState<ContractSaveError | null>(
    null
  );

  const [expandedStore, setExpandedStore] = useState<StoreId | undefined>(
    stores.length === 1 ? stores[0]?.storeId : undefined
  );

  // const addStore = useCallback(() => {
  //   setRenderedStores((prev) => [...prev, getDefaultStore(newId.current)]);
  //   setExpandedStore(newId.current as StoreId);
  //   newId.current = newId.current - 1;
  // }, []);

  const onDelete = useCallback(
    async (storeId: StoreId) => {
      let promise;
      if (storeId < 0) {
        promise = Promise.resolve();
      } else {
        promise = dataStores.deleteStore(contractId, storeId);
      }

      promise
        .then(() => {
          setRenderedStores((stores) =>
            stores.filter((store) => store.storeId !== storeId)
          );
        })
        .catch((err) => {
          handleError(err, setRemoveError);
        });
    },
    [contractId]
  );

  const retryRemove = useCallback(() => {
    setRemoveError(null);
    setTimeout(onDelete, 500);
  }, [onDelete]);

  return (
    <div>
      <ContractEditError
        error={removeError}
        retry={retryRemove}
        onClose={() => setRemoveError(null)}
      />

      <ScrollPositionAnchor id={LinkAnchors.STORES.anchor} />

      <div className={styles.content}>
        {renderedStores.map((store) => (
          <StoreRow
            key={store.storeId}
            store={store}
            hasMultipleStores={renderedStores.length > 1}
            status={disabled ? Status.DISABLED : Status.DEFAULT}
            isActive={expandedStore === store.storeId}
            onDelete={onDelete}
            onClick={() =>
              setExpandedStore(
                expandedStore !== store.storeId ? store.storeId : undefined
              )
            }
          />
        ))}
      </div>
      {/* <Button
        className="m-top-40"
        block
        ghost
        status={disabled ? Status.DISABLED : Status.DEFAULT}
        onClick={addStore}
      >
        <T>Add additional location</T>
      </Button> */}
    </div>
  );
}
