import { data } from "./data";
import { Associate, AssociateRole } from "./models/AssociateTypes";
import { AssociateId, ContractId } from "./models/CommonTypes";

export type Combination = {
  associateIds: AssociateId[];
};

export function getRoleAsText(role: AssociateRole) {
  switch (role) {
    case AssociateRole.BENEFICIAL_OWNER:
      return "Beneficial owner";
    case AssociateRole.SIGNATORY:
    case AssociateRole.SELECTED_SIGNATORY:
      return "Signatory";
    case AssociateRole.PRIMARY_CONTACT:
      return "Primary contact";
    case AssociateRole.ACCOUNT_HOLDER:
      return "Account verifier";

    default:
      return "-";
  }
}

const BASE_URL = "/api/sales";

export const dataAssociates = {
  getAssociates: (contractId: ContractId) =>
    data.get<Associate[]>(`${BASE_URL}/${contractId}/associates`),

  fetchAssociates: (contractId: ContractId) => ({
    queryKey: dataAssociates.getAssociatesKey(contractId),
    queryFn: () =>
      data.get<Associate[]>(`${BASE_URL}/${contractId}/associates`),
  }),

  saveAssociate: (contractId: ContractId, associate: Associate) => {
    return data.post<Associate[]>(
      `${BASE_URL}/${contractId}/associates/${associate.associateId}`,
      associate
    );
  },

  removeAssociate: (contractId: ContractId, associateId: AssociateId) =>
    data.delete<void>(`${BASE_URL}/${contractId}/associates/${associateId}`),

  fetchSigningCombinations: (contractId: ContractId) => ({
    queryKey: dataAssociates.getCombinationsKey(contractId),
    queryFn: () =>
      data.get<Combination[]>(`${BASE_URL}/${contractId}/signing-combinations`),
  }),

  getAssociatesKey: (contractId: ContractId) => {
    return ["associates", contractId];
  },

  getCombinationsKey: (contractId: ContractId) => {
    return ["combinations", contractId];
  },

  // removeAssociate: (linkId: LinkId, associate: Associate) =>
  //   data.delete<void>(
  //     `/api/merchant/${linkId}/associates/${associate.associateId}`
  //   ),

  // getAssociatesKey: (linkId: LinkId) => {
  //   return ["associates", linkId];
  // },

  // deleteAssociate: (contractId: ContractId, associateId: AssociateId) =>
  //   data.delete<Associate[]>(
  //     `${BASE_URL}/${contractId}/associates/${associateId}`
  //   ),

  // updateAssociate: (
  //   contractId: ContractId,
  //   associate: SaveAssociate,
  //   cas: Cas
  // ) =>
  //   data.post<Associate>(`${BASE_URL}/${contractId}/associates`, {
  //     ...associate,
  //     cas,
  //   }),
  // updateAssociateRoles: (
  //   contractId: ContractId,
  //   associate: SaveAssociate,
  //   cas: Cas
  // ) =>
  //   data.post<Associate>(
  //     `${BASE_URL}/${contractId}/associates/${associate.associateId}/roles`,
  //     {
  //       roles: associate.roles,
  //       cas,
  //     }
  //   ),
  // updateAssociateOwner: (
  //   contractId: ContractId,
  //   associate: SaveAssociate,
  //   cas: Cas
  // ) =>
  //   data.post<Associate>(
  //     `${BASE_URL}/${contractId}/associates/${associate.associateId}/owner`,
  //     {
  //       ...associate.owner,
  //       cas,
  //     }
  //   ),
  // updateAssociateIdentity: (
  //   contractId: ContractId,
  //   associate: SaveAssociate,
  //   cas: Cas
  // ) =>
  //   data.post<Associate>(
  //     `${BASE_URL}/${contractId}/associates/${associate.associateId}/owner`,
  //     {
  //       ...associate.identity,
  //       cas,
  //     }
  //   ),
};

export function filterLegalAssociates(associate: Associate[]) {
  return associate.filter(
    (associate) =>
      associate.roles.includes(AssociateRole.BENEFICIAL_OWNER) ||
      associate.roles.includes(AssociateRole.SIGNATORY)
  );
}

export function filterSelectedSignees(associate: Associate[]) {
  return associate.filter((associate) =>
    associate.roles.includes(AssociateRole.SELECTED_SIGNATORY)
  );
}
