import cx from "classnames";
import React, { useCallback, useRef, useState } from "react";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import {
  ConfirmButton,
  ConfirmType,
} from "../../../../../components/interactions/Buttons/ConfirmButton";
import { Visible } from "../../../../../components/visible/Visible";
import {
  ContractDocument,
  documentTypeDescription,
  documentTypeDisplay,
} from "../../../../../data/models/ContractTypes";
import { Status } from "../../../../../data/types";
import { ConfirmDocImageAndStatuses } from "./ConfirmDocImageAndStatuses";
import {
  FileType,
  IdImageInterface,
  PDF_MIME_TYPE,
} from "../../../Contract/uploads/IdImage";
import { API } from "../../../../../network/API";
import "./ConfirmDoc.scss";
import { ContractId, UTCDate } from "../../../../../data/models/CommonTypes";
import { useQueryClient } from "@tanstack/react-query";
import { dataDocuments } from "../../../../../data/dataDocuments";
import { AnimateHeight } from "../../../../../components/animate/AnimateHeight";
import { T } from "../../../../../components/translation/T";

interface Props {
  document: ContractDocument | null;
  onClose: () => void;
  contractId: ContractId;
}

export const ConfirmDoc: React.FunctionComponent<Props> = ({
  document,
  onClose,
  contractId,
}) => {
  if (!document) {
    return null;
  }

  return (
    <ConfirmDocInner
      contractId={contractId}
      document={document}
      onClose={onClose}
    />
  );
};

function getFileUrl(contractId: ContractId, document: ContractDocument) {
  if (document.fileAvailable) {
    return `${API.getUrl(
      `/api/sales/${contractId}/documents/${document.documentId}`
    )}?q=${document.uploaded}`;
  }
}

function getFileAttributes(contractId: ContractId, document: ContractDocument) {
  return {
    url: getFileUrl(contractId, document),
    type: document.mimeType === PDF_MIME_TYPE ? FileType.PDF : FileType.IMAGE,
  };
}

export const ConfirmDocInner: React.FunctionComponent<{
  document: ContractDocument;
  onClose: () => void;
  contractId: ContractId;
}> = ({ document: doc, onClose, contractId }) => {
  const queryClient = useQueryClient();
  const [document, setDocument] = useState<ContractDocument>(doc);
  const [rejectStatus, setRejectStatus] = useState<Status>(Status.DEFAULT);
  const [confirmStatus, setConfirmStatus] = useState<Status>(
    document.confirmed ? Status.SUCCESS : Status.DEFAULT
  );

  const originalImage = useRef<IdImageInterface>(
    getFileAttributes(contractId, document)
  );

  const onReject = useCallback(() => {
    setRejectStatus(Status.PENDING);
    setConfirmStatus(Status.DISABLED);

    dataDocuments
      .rejectUploadedDocument(contractId, document.documentId)
      .then((response) => {
        setRejectStatus(Status.SUCCESS);
        queryClient.invalidateQueries({
          queryKey: dataDocuments.getDocumentsKey(contractId),
        });
      })
      .catch((err) => {
        setRejectStatus(Status.ERROR);
        setTimeout(() => {
          setRejectStatus(Status.DEFAULT);
          setConfirmStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [contractId, queryClient, document.documentId]);

  const onConfirm = useCallback(() => {
    setConfirmStatus(Status.PENDING);
    setRejectStatus(Status.DISABLED);

    dataDocuments
      .confirmUploadedDocument(contractId, document.documentId)
      .then((response) => {
        setConfirmStatus(Status.SUCCESS);
        queryClient.invalidateQueries({
          queryKey: dataDocuments.getDocumentsKey(contractId),
        });
        setDocument((doc) => ({
          ...doc,
          confirmed: new Date().toISOString() as UTCDate,
        }));
      })
      .catch((err) => {
        setConfirmStatus(Status.ERROR);
        setTimeout(() => {
          setConfirmStatus(Status.DEFAULT);
          setRejectStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [contractId, queryClient, document.documentId, setDocument]);

  return (
    <div
      className={cx("confirm-doc", {
        "is-confirmed": !!document.confirmed,
        "show-status":
          confirmStatus !== Status.DEFAULT || rejectStatus !== Status.DEFAULT,
      })}
    >
      <strong>
        <T>{documentTypeDisplay(document.documentType)}</T>
      </strong>
      <T>
        {document.documentDescription ||
          documentTypeDescription(document.documentType)}
      </T>

      <div className="m-top-30">
        <ConfirmDocImageAndStatuses
          originalImage={originalImage.current}
          rejectStatus={rejectStatus}
          confirmStatus={confirmStatus}
          document={document}
        />

        <div className="m-bottom-20">
          <AnimateHeight
            name={
              !!(document.fileAvailable && !document.confirmed) ? "one" : "two"
            }
          >
            <div>
              {!!(
                document.fileAvailable &&
                !document.confirmed &&
                rejectStatus !== Status.SUCCESS
              ) ? (
                <div className="tablet-columns">
                  <div>
                    <ConfirmButton
                      ghost
                      onClick={onReject}
                      removeButtonText="Yes, reject"
                      status={rejectStatus}
                    >
                      Reject document
                    </ConfirmButton>
                  </div>
                  <div>
                    <ConfirmButton
                      onClick={onConfirm}
                      removeButtonText="Yes, confirm"
                      confirmType={ConfirmType.SUCCESS}
                      status={confirmStatus}
                    >
                      Confirm document
                    </ConfirmButton>
                  </div>
                </div>
              ) : (
                <div>
                  {rejectStatus === Status.SUCCESS ? (
                    <T>
                      We have rejected this document. We will notify the
                      merchant in order to upload it again.
                    </T>
                  ) : (
                    <>
                      <T>We have confirmed this document</T>
                    </>
                  )}
                </div>
              )}
            </div>
            {/* <Visible show={!!(document.fileAvailable && !document.confirmed)}>
            <div className="tablet-columns">
              <div>
                <ConfirmButton
                  ghost
                  onClick={onReject}
                  removeButtonText="Yes, reject"
                  status={rejectStatus}
                >
                  Reject document
                </ConfirmButton>
              </div>
              <div>
                <ConfirmButton
                  onClick={onConfirm}
                  removeButtonText="Yes, confirm"
                  confirmType={ConfirmType.SUCCESS}
                  status={confirmStatus}
                >
                  Confirm document
                </ConfirmButton>
              </div>
            </div>
          </Visible> */}
          </AnimateHeight>
        </div>

        <Visible show={!!document.confirmed || rejectStatus === Status.SUCCESS}>
          <Button ghost onClick={onClose} block className="small close-button">
            Close
          </Button>
        </Visible>
      </div>
    </div>
  );
};
