import React, { useState, useCallback } from "react";
import { ContractEditError } from "../../pages/sales/Contract/ContractEditError";
import { useSetRecoilState } from "recoil";
import {
  contractErrorState,
  contractSaveState,
} from "../../state/contractSaveState";
import {
  ContractSaveError,
  handleError,
} from "../../pages/sales/Contract/ContractPage";
import { ContractId, ONGOING_RESPONSE } from "../../data/models/CommonTypes";
import {
  DataWithCas,
  GenericDataQueue,
} from "../../data/queues/GenericDataQueue";
import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useCompare } from "../../hooks/useCompare";
import { ServerError } from "../../network/API";
import { UNAUTHORIZED_STATUS_CODE } from "../../data/data";

export function isSuccessfulResponse<T>(
  data: T | "ONGOING_RESPONSE" | ServerError<T> | undefined
): data is T {
  if (!data) {
    return false;
  }

  if (data === "ONGOING_RESPONSE") {
    return false;
  }

  if (
    typeof (data as ServerError<T>).status === "number" &&
    (data as ServerError<T>).status > 299
  ) {
    return false;
  }

  return true;
}

export type SaveRequest<T> = UseMutateFunction<
  typeof ONGOING_RESPONSE | T | ServerError<T>,
  unknown,
  T,
  { previousData: T | undefined }
>;

export interface SaveProps<T> {
  trySave: SaveRequest<T>;
  isSaving: boolean;
  data: T | typeof ONGOING_RESPONSE | ServerError<T> | undefined;
}

interface Props<T extends DataWithCas> {
  contractId: ContractId;
  dataQueue: GenericDataQueue<T>;
  children: (
    trySave: SaveRequest<T>,
    isSaving: boolean,
    data: T | typeof ONGOING_RESPONSE | ServerError<T> | undefined,
    isError: boolean
  ) => React.ReactNode;
  queryKey: string[];
}

export function UpdateWrapper<T extends DataWithCas>({
  contractId,
  dataQueue,
  children,
  queryKey,
}: Props<T>) {
  const [error, setError] = useState<ContractSaveError | null>(null);
  const { isUpdated, setCompareRef } = useCompare(null);
  const setDataError = useSetRecoilState(contractErrorState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const onClose = useCallback(() => setError(null), []);
  const queryClient = useQueryClient();

  const {
    mutate: trySave,
    isPending: isSaving,
    isError,
    data,
  } = useMutation({
    mutationFn: (data: T) => {
      if (!isUpdated(data)) {
        return Promise.resolve(data);
      }

      return dataQueue.saveData(contractId, data);
    },
    onMutate: async (data: T) => {
      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData<T>(queryKey);

      if (Array.isArray(previousData) && !dataQueue.isKeyed()) {
        throw new Error("Array items are missing the key identifier");
      }

      if (Array.isArray(previousData)) {
        const identifier = dataQueue.getKey() as keyof T;
        const key = data[identifier];
        const updated: T[] = previousData.map((prev) => {
          if (key === prev[identifier]) {
            return data;
          }
          return prev;
        });

        queryClient.setQueryData<T>(queryKey, updated as any);
      } else {
        queryClient.setQueryData<T>(queryKey, data);
      }

      return { previousData };
    },
    onSuccess: (response) => {
      setCompareRef(response as T);
      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );
    },
    onError: (err: unknown, updated: T, context) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      if ((err as ServerError<T>).status === UNAUTHORIZED_STATUS_CODE) {
        return;
      }

      context && queryClient.setQueryData(queryKey, context.previousData);
      queryClient.invalidateQueries({ queryKey });
      setCompareRef(null);
      handleError(err as ServerError<T>, setError);
      setDataError((dataErrors) =>
        dataErrors.concat({
          date: new Date(),
        })
      );
    },
  });

  const retrySave = useCallback(() => {
    setError(null);
    setTimeout(trySave, 500);
  }, [trySave]);

  return (
    <>
      <ContractEditError error={error} retry={retrySave} onClose={onClose} />
      {children(trySave, isSaving, data, isError)}
    </>
  );
}
