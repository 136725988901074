import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { SectionFieldSet } from "../../../../components/sectionFieldSet/SectionFieldSet";
import { T } from "../../../../components/translation/T";
import { dataAssociates } from "../../../../data/dataAssociates";
import { Associate } from "../../../../data/models/AssociateTypes";
import { ONGOING_RESPONSE } from "../../../../data/models/CommonTypes";
import { useContractId } from "../../../../hooks/useContractId";
import { usePrimaryContact } from "../../../../hooks/usePrimaryContact";
import {
  contractErrorState,
  contractSaveState,
} from "../../../../state/contractSaveState";
import { LinkAnchors } from "../ContractEdit";
import { ContractEditError } from "../ContractEditError";
import { ContractSaveError, handleError } from "../ContractPage";
import { FormName } from "../menus/ContractEditMenu";
import { ServerError } from "../../../../network/API";
import { UNAUTHORIZED_STATUS_CODE } from "../../../../data/data";
import { Name } from "../../../../components/name/Name";
import { Select } from "../../../../components/form/Select";
import { ACTIVATED_LANGUAGES, Language } from "../../../../i18n";
import { associateQueue } from "../../../../data/queues/AssociateQueue";
import { getLanguageName } from "../../../../components/translation/i18nUtils";
import { useTranslation } from "react-i18next";
import { contractState, disabledState } from "../../../../state/contractState";
import { TextInput } from "../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { hasRealErrors } from "../../../../components/form/FormHelpers";
import { Form } from "../../../../components/form/Form";
import {
  getCountryFromPhoneNumber,
  replacePhoneNumberBeginning,
  sanitizePhoneNumber,
} from "../../../../components/form/validators/PhoneToCountryCode";
import { PhoneNumberCountry } from "../../../../components/contact/PhoneNumberCountry";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { Status } from "../../../../data/types";
import { MinLengthValidator } from "../../../../components/form/validators/MinLengthValidator";
import {
  MAX_MOBILE_PHONE_CHARS,
  MIN_MOBILE_PHONE_CHARS,
} from "../../../../components/contact/Contact";
import { MaxLengthValidator } from "../../../../components/form/validators/MaxLengthValidator";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { PhoneCountryCodeValidator } from "../../../../components/form/validators/PhoneCountryCodeValidator";
import {
  PHONE_REGEX,
  RegexValidator,
} from "../../../../components/form/validators/RegexValidator";

export const PrimaryContact: React.FunctionComponent = () => {
  return (
    <SectionFieldSet
      headerTitle={LinkAnchors.PRIMARY_CONTACT.name}
      formName={FormName.PRIMARY_CONTACT}
      sectionNumber={1}
    >
      <PrimaryContactInner />
    </SectionFieldSet>
  );
};

const PrimaryContactInner: React.FunctionComponent = () => {
  const contractId = useContractId();
  const { i18n, t } = useTranslation();
  const { data: associates } = useSuspenseQuery(
    dataAssociates.fetchAssociates(contractId)
  );

  const { country } = useRecoilValue(contractState);
  const queryClient = useQueryClient();
  const originalPrimaryContact = usePrimaryContact(associates);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const isDisabled = useRecoilValue(disabledState);
  const [primary, setPrimary] = useState<Associate>(originalPrimaryContact);
  const queryKey = dataAssociates.getAssociatesKey(contractId);
  const [phoneStatus, setPhoneStatus] = useState<Status>(Status.DEFAULT);
  const lastEditedField = useRef<string>("");

  const { mutate: trySave } = useMutation({
    mutationFn: (updated: Associate) =>
      associateQueue.saveAssociate(contractId, updated),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });
      const previousAssociates =
        queryClient.getQueryData<Associate[]>(queryKey) || [];
      queryClient.setQueryData<Associate[]>(queryKey, (prev) => [
        ...(prev || []),
        primary,
      ]);
      return { previousAssociates };
    },
    onSuccess: () => {
      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );
    },
    onError: (
      err: ServerError<Associate> | typeof ONGOING_RESPONSE,
      updatedPrimary: Associate,
      context
    ) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      if ((err as ServerError<Associate>).status === UNAUTHORIZED_STATUS_CODE) {
        return;
      }

      context && queryClient.setQueryData(queryKey, context.previousAssociates);
      handleError(err, setError);
      setDataError((dataErrors) =>
        dataErrors.concat({
          date: new Date(),
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey,
      });
    },
  });

  const validPhone = useMemo(() => {
    return getCountryFromPhoneNumber(primary.contact.phoneNumber);
  }, [primary]);

  useEffect(() => {
    if (!primary) {
      return;
    }

    if (phoneStatus !== Status.DEFAULT) {
      return;
    }

    setPhoneStatus(Status.PENDING);

    const primaryPhone = primary.contact.phoneNumber;
    let sanitizedNumber = sanitizePhoneNumber(primary.contact.phoneNumber);
    sanitizedNumber = replacePhoneNumberBeginning(
      country,
      sanitizedNumber
    ).replace(/\s/g, "");

    if (
      getCountryFromPhoneNumber(primary.contact.phoneNumber).valid &&
      sanitizedNumber === primaryPhone
    ) {
      setPhoneStatus(Status.SUCCESS);
      return;
    }

    if (!sanitizedNumber.startsWith("+")) {
      setPhoneStatus(Status.ERROR);
      return;
    }

    const updatedPrimary: Associate = {
      ...primary,
      contact: {
        ...primary.contact,
        phoneNumber: sanitizedNumber,
      },
    };

    setPrimary(updatedPrimary);
    trySave(updatedPrimary);
  }, [primary, phoneStatus, country, trySave]);

  const onClose = useCallback(() => {
    setError(null);
  }, []);

  const retry = useCallback(() => {
    setError(null);
    setTimeout(() => trySave(primary), 500);
  }, [trySave, primary]);

  const onLanguageChange = useCallback(
    (language: Language, name: string) => {
      lastEditedField.current = name;
      const updatedPrimary = {
        ...primary,
        language,
      };
      setPrimary(updatedPrimary);
      trySave(updatedPrimary);
    },
    [primary, trySave]
  );

  const onChange = useCallback(
    (position: string, name: string) => {
      lastEditedField.current = name;

      const updatedPrimary = {
        ...primary,
        contact: {
          ...primary.contact,
          position,
        },
      };
      setPrimary(updatedPrimary);
    },
    [primary]
  );

  const onPhoneChange = useCallback(
    (phoneNumber: string, name: string) => {
      lastEditedField.current = name;
      const updatedPrimary = {
        ...primary,
        contact: {
          ...primary.contact,
          phoneNumber,
        },
      };
      setPrimary(updatedPrimary);
    },
    [primary]
  );

  const opts = useMemo(() => {
    const alternatives = Object.values(ACTIVATED_LANGUAGES).map((value) => {
      return {
        value: value,
        text: getLanguageName(value, i18n.language),
      };
    });

    return [
      {
        value: "" as Language,
        text: t("Preferred communication language"),
        disabled: true,
      },
      ...alternatives,
    ];
  }, [i18n.language, t]);

  const validators = useMemo(() => {
    return [
      new RequiredValidator("Phone number is required"),
      new PhoneCountryCodeValidator("Please include a country code e.g +61..."),
      new RegexValidator(PHONE_REGEX, "Phone contains invalid characters"),
      new MinLengthValidator(
        MIN_MOBILE_PHONE_CHARS,
        `Mobile phone must be at least ${MIN_MOBILE_PHONE_CHARS} characters`
      ),
      new MaxLengthValidator(
        MAX_MOBILE_PHONE_CHARS,
        `Mobile phone must be less than ${MAX_MOBILE_PHONE_CHARS} characters`
      ),
    ];
  }, []);

  const onPaste = useCallback(
    (value: string) => {
      let phoneNumber = sanitizePhoneNumber(value);
      phoneNumber = replacePhoneNumberBeginning(country, phoneNumber);
      onPhoneChange(phoneNumber, "phoneNumber");
    },
    [country, onPhoneChange]
  );

  if (!primary) {
    throw new Error("Missing primary contact");
  }

  return (
    <div className="primary">
      <Form
        name={FormName.PRIMARY_CONTACT}
        onSaveTrigger={(_, form) => {
          const realErrors = hasRealErrors(form);

          let { phoneNumber } = primary.contact;
          if (lastEditedField.current === "phoneNumber") {
            let phone = sanitizePhoneNumber(phoneNumber);
            phone = replacePhoneNumberBeginning(country, phone).replace(
              /\s/g,
              ""
            );
            phoneNumber = phone;
            onPhoneChange(phone, "phoneNumber");
          }

          if (realErrors) {
            return;
          }

          trySave({
            ...primary,
            contact: {
              ...primary.contact,
              phoneNumber,
            },
          });
        }}
      >
        <ScrollPositionAnchor id={LinkAnchors.PRIMARY_CONTACT.anchor} />

        <ContractEditError error={error} retry={retry} onClose={onClose} />

        <div className="m-top-10">
          <h4>
            <Name associate={primary} />
          </h4>

          <div className="m-top-30">
            <div>
              <TextInput
                onChange={() => {}}
                name="email"
                label="Email"
                value={primary.contact.email}
                disabled={true}
              />
            </div>
            <div>
              <AnimateHeight name={validPhone.valid ? "one" : "two"}>
                {validPhone.valid ? (
                  <div />
                ) : (
                  <ErrorBox relative>
                    <T>
                      This phone number looks fishy. It lacks a proper country
                      code. Please change it.
                    </T>
                  </ErrorBox>
                )}
              </AnimateHeight>
              <div className="m-top-10">
                <TextInput
                  onChange={onPhoneChange}
                  name="phoneNumber"
                  label="Phone number"
                  value={primary.contact.phoneNumber}
                  hint="Must include country code"
                  validators={validators}
                  message={
                    <PhoneNumberCountry
                      phoneNumber={primary.contact.phoneNumber}
                    />
                  }
                  disabled={isDisabled || phoneStatus === Status.SUCCESS}
                  onPaste={onPaste}
                />
              </div>
            </div>
          </div>
          <div className="tablet-columns">
            <div className="m-top-40">
              <TextInput
                onChange={onChange}
                name="position"
                label="Position"
                value={primary.contact.position}
                hint="Title or position of primary contact"
                validators={[new RequiredValidator("Title is required")]}
                disabled={isDisabled}
              />
            </div>
            <div className="m-top-40">
              <Select
                onChange={onLanguageChange}
                name="language"
                label="Communication language"
                value={primary.language}
                disabled={isDisabled}
                alternatives={opts}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
