import React, { useMemo, useState } from "react";
import cx from "classnames";
import styles from "./ContractsPage.module.scss";
import { Page } from "../../Page";
import { ListContract, dataContracts } from "../../../data/dataContracts";
import { useSuspenseQuery } from "@tanstack/react-query";
import { ErrorBoundary } from "../../../components/errors/ErrorBoundary";
import { Card } from "../../../components/card/Card";
import { T } from "../../../components/translation/T";
import { Country, TI } from "../../../i18n";
import { Flag } from "../../../components/flags/Flag";
import { getIntlDate } from "../../../components/utils";
import { generatePath, Link } from "react-router-dom";
import { REVIEW_ROUTE } from "../Review/ReviewPage";
import {
  ContractStatus,
  contractResponsible,
  contractStatusDisplay,
} from "../../../data/models/ContractTypes";
import { CONTRACT_ROUTE } from "../Contract/ContractPage";
import { External } from "../../../components/icons/External";
import { legalEntityTypeString } from "../../../components/legalEntityType/LegalEntityTypeDisplay";
import { Trans, useTranslation } from "react-i18next";
import { PdfProductType } from "../../manager/pdfContract/PdfPage";
import { Link as ComponentLink } from "../../../components/links/Link";
import { TableHeaderElem } from "./TableHeaderElem";

const DANGER_DAY = 5;
const WARNING_DAY = 3;

const DURATION_ONE_DAY = 1000 * 60 * 60 * 24;
const DURATION_DANGER = DURATION_ONE_DAY * DANGER_DAY;
const DURATION_WARNING = DURATION_ONE_DAY * WARNING_DAY;

export enum SortOrder {
  CREATED = "Created",
  STAGE = "Stage",
  STATUS = "Status",
  COMPANY_NAME = "Company Name",
  ENTITY_TYPE = "Entity type",
  PRODUCT = "Product",
  USER = "User",
  COUNTRY = "Country",
  ORG_NO = "Org.no",
}

function getIndicatorClass(today: Date, contract: ListContract) {
  const diff = today.getTime() - new Date(contract.created).getTime();
  if (diff > DURATION_DANGER) {
    return styles.danger;
  }

  if (diff > DURATION_WARNING) {
    return styles.warning;
  }

  return styles.success;
}

export const ContractsPage: React.FunctionComponent = () => {
  return (
    <div className={styles.page}>
      <Page stripped title="Contracts page">
        <section>
          <article>
            <h1>
              <T>Ongoing contracts</T>
            </h1>
            <ErrorBoundary>
              <ContractsPageInner />
            </ErrorBoundary>
          </article>
        </section>
      </Page>
    </div>
  );
};

function filter(
  contracts: ListContract[],
  sortOrder: SortOrder,
  ascending: boolean
): ListContract[] {
  switch (sortOrder) {
    case SortOrder.CREATED: {
      if (ascending) {
        return contracts.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );
      }

      return contracts.sort(
        (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
      );
    }

    case SortOrder.COMPANY_NAME: {
      return contracts.sort((a, b) => {
        if (ascending) {
          return a.companyName.localeCompare(b.companyName);
        }

        return b.companyName.localeCompare(a.companyName);
      });
    }

    case SortOrder.ENTITY_TYPE: {
      return contracts.sort((a, b) => {
        if (ascending) {
          return a.legalEntityType.localeCompare(b.legalEntityType);
        }

        return b.legalEntityType.localeCompare(a.legalEntityType);
      });
    }

    case SortOrder.PRODUCT: {
      return contracts.sort((a, b) => {
        if (ascending) {
          return a.productType.localeCompare(b.productType);
        }

        return b.productType.localeCompare(a.productType);
      });
    }

    case SortOrder.USER: {
      return contracts.sort((a, b) => {
        if (ascending) {
          return a.salesUser.localeCompare(b.salesUser);
        }

        return b.salesUser.localeCompare(a.salesUser);
      });
    }

    case SortOrder.COUNTRY: {
      return contracts.sort((a, b) => {
        if (ascending) {
          return a.country.localeCompare(b.country);
        }

        return b.country.localeCompare(a.country);
      });
    }

    case SortOrder.STATUS: {
      return contracts.sort((a, b) => {
        if (ascending) {
          return a.status.localeCompare(b.status);
        }

        return b.status.localeCompare(a.status);
      });
    }

    case SortOrder.STAGE: {
      return contracts.sort((a, b) => {
        if (ascending) {
          return a.status.localeCompare(b.status);
        }

        return b.status.localeCompare(a.status);
      });
    }

    default:
      break;
  }

  return contracts;
}

export const ContractsPageInner: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { data: contracts } = useSuspenseQuery(dataContracts.fetchContracts());
  const today = new Date();
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.CREATED);
  const [ascending, setAscending] = useState<boolean>(true);

  const sortedContracts = useMemo(
    () => filter(contracts, sortOrder, ascending),
    [contracts, sortOrder, ascending]
  );

  if (contracts && !contracts.length) {
    return (
      <Card>
        <T>No active contracts at the moment</T>
      </Card>
    );
  }

  return (
    <div>
      <ul className={styles.indicators}>
        <li>
          <span className={styles.dangerIndicator} />{" "}
          <Trans>
            Contract creation exceeds <b>{{ DANGER_DAY } as TI}</b> days
          </Trans>
        </li>
        <li>
          <span className={styles.warningIndicator} />{" "}
          <Trans>
            Contract creation exceeds <b>{{ WARNING_DAY } as TI}</b> days
          </Trans>
        </li>
        <li>
          <span className={styles.ongoingIndicator} />{" "}
          <Trans>Contract creation is ongoing</Trans>
        </li>
      </ul>
      <Card className={styles.card}>
        <ul className={cx(styles.list, "text-small")}>
          <li className={cx(styles.headers)}>
            <div className={styles["header-indicator-cell"]} />
            <TableHeaderElem
              sortOrder={SortOrder.COUNTRY}
              setSortOrder={setSortOrder}
              active={sortOrder}
              ascending={ascending}
              setAscending={setAscending}
              className={cx(styles["header-flag"])}
            />
            <TableHeaderElem
              sortOrder={SortOrder.CREATED}
              setSortOrder={setSortOrder}
              active={sortOrder}
              ascending={ascending}
              setAscending={setAscending}
              className={styles["header-created"]}
            />
            <TableHeaderElem
              sortOrder={SortOrder.STAGE}
              setSortOrder={setSortOrder}
              active={sortOrder}
              ascending={ascending}
              setAscending={setAscending}
              className={cx(styles["header-phase"], styles.padded)}
            />
            <TableHeaderElem
              sortOrder={SortOrder.STATUS}
              setSortOrder={setSortOrder}
              active={sortOrder}
              ascending={ascending}
              setAscending={setAscending}
              className={cx(styles["header-status"])}
            />
            <TableHeaderElem
              sortOrder={SortOrder.COMPANY_NAME}
              setSortOrder={setSortOrder}
              active={sortOrder}
              ascending={ascending}
              setAscending={setAscending}
              className={cx(styles["header-name"])}
            />
            <div className={cx(styles["header-sf"], "center")}>
              <T>Wlx</T>
            </div>
            <div className={cx(styles["header-contractId"], "center")}>
              <T>SF</T>
            </div>
            <TableHeaderElem
              sortOrder={SortOrder.ENTITY_TYPE}
              setSortOrder={setSortOrder}
              active={sortOrder}
              ascending={ascending}
              setAscending={setAscending}
              className={cx(styles["header-legalEntityType"], styles.padded)}
            />
            <TableHeaderElem
              sortOrder={SortOrder.PRODUCT}
              setSortOrder={setSortOrder}
              active={sortOrder}
              ascending={ascending}
              setAscending={setAscending}
              className={cx(styles["header-productType"])}
            />
            <TableHeaderElem
              sortOrder={SortOrder.ORG_NO}
              setSortOrder={setSortOrder}
              active={sortOrder}
              ascending={ascending}
              setAscending={setAscending}
              className={cx(styles["header-orgno"])}
            />
            <TableHeaderElem
              sortOrder={SortOrder.USER}
              setSortOrder={setSortOrder}
              active={sortOrder}
              ascending={ascending}
              setAscending={setAscending}
              className={cx(styles["header-user"], styles.padded)}
            />
          </li>

          {sortedContracts.map((contract, idx) => {
            return (
              <li
                className={cx({
                  [styles.striped]: idx % 2 === 0,
                })}
                key={contract.contractId}
              >
                <div
                  className={cx(
                    styles["indicator-cell"],
                    getIndicatorClass(today, contract)
                  )}
                >
                  <div />
                </div>

                <div className={cx(styles.flag, styles.padded)}>
                  <Flag
                    rounded
                    shadow
                    height={20}
                    country={contract.country as Country}
                  />
                </div>
                <div className={cx(styles.created)}>
                  {getIntlDate(contract.created)}
                </div>

                <div className={cx(styles.phase, styles.padded)}>
                  <Tag status={contract.status} />
                </div>

                <div className={cx(styles.status)}>
                  {shouldTakeAction(contract.status) ? (
                    <b>{contractStatusDisplay[contract.status]}</b>
                  ) : (
                    contractStatusDisplay[contract.status]
                  )}
                </div>
                <div className={styles.name}>{contract.companyName}</div>
                <div className={cx(styles.contractId)}>
                  <Link
                    className="as-button action ghost small"
                    to={
                      contract.status === ContractStatus.SALES_INPUT
                        ? generatePath(CONTRACT_ROUTE, {
                            contractId: contract.contractId,
                          })
                        : generatePath(REVIEW_ROUTE, {
                            contractId: contract.contractId,
                          })
                    }
                  >
                    <External />
                  </Link>
                </div>
                <div className={cx(styles.sf)}>
                  {contract.salesforceUrl ? (
                    <ComponentLink
                      className="as-button action ghost small"
                      link={contract.salesforceUrl}
                      external
                    >
                      {null}
                    </ComponentLink>
                  ) : null}
                </div>
                <div className={cx(styles.legalEntityType, styles.padded)}>
                  {t(legalEntityTypeString(contract.legalEntityType))}
                </div>
                <div className={cx(styles.productType)}>
                  {PdfProductType[contract.productType]}
                </div>
                <div className={cx(styles.orgno)}>
                  {contract.organizationNumber}
                </div>
                <div className={cx(styles.user, styles.padded)}>
                  {contract.salesUser}
                </div>
              </li>
            );
          })}
        </ul>
      </Card>
    </div>
  );
};

function shouldTakeAction(status: ContractStatus) {
  if (
    status === ContractStatus.COMPLETE ||
    status === ContractStatus.PROVISIONING ||
    status === ContractStatus.REJECTED ||
    status === ContractStatus.MERCHANT_INPUT ||
    status === ContractStatus.MERCHANT_SIGNING
  ) {
    return false;
  }

  return true;
}

export const Tag: React.FunctionComponent<{ status: ContractStatus }> = ({
  status,
}) => {
  if (
    status === ContractStatus.COMPLETE ||
    status === ContractStatus.PROVISIONING ||
    status === ContractStatus.REJECTED
  ) {
    return (
      <div className={cx(styles.tag, styles.system)}>
        {contractResponsible[status]}
      </div>
    );
  }

  if (status === ContractStatus.RISK_CONFIRMATION) {
    return (
      <div className={cx(styles.tag, styles.risk)}>
        {contractResponsible[status]}
      </div>
    );
  }

  if (
    status === ContractStatus.MERCHANT_INPUT ||
    status === ContractStatus.MERCHANT_SIGNING
  ) {
    return (
      <div className={cx(styles.tag, styles.merchant)}>
        {contractResponsible[status]}
      </div>
    );
  }

  return (
    <div className={cx(styles.tag, styles.sales)}>
      <b>{contractResponsible[status]}</b>
    </div>
  );
};
