import React, { Suspense } from "react";
import Skeleton from "react-loading-skeleton";
import { ErrorBoundary as Boundary } from "@sentry/react";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { Button } from "../interactions/Buttons/Button";
import { T } from "../translation/T";

interface Props {
  children: React.ReactNode;
}

export const ErrorBoundary: React.FunctionComponent<Props> = ({ children }) => {
  const location = useLocation();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <>
          <Boundary
            key={location.pathname}
            onReset={reset}
            fallback={({ error, resetError }) => (
              <>
                <h2>
                  <T>Oops, something went wrong</T>
                </h2>
                <p>
                  <T>Please try again...</T>
                </p>
                {/* if development */}
                {process.env.NODE_ENV === "development" && (
                  <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {error.stack}
                  </pre>
                )}

                <Button onClick={resetError}>
                  <T>Try again</T>
                </Button>
              </>
            )}
          >
            <Suspense
              fallback={
                <>
                  <h1>
                    <Skeleton />
                  </h1>
                  <div>
                    <Skeleton count={1} />
                    <Skeleton height={120} className="m-top-10" />
                  </div>
                </>
              }
            >
              {children}
            </Suspense>
          </Boundary>
        </>
      )}
    </QueryErrorResetBoundary>
  );
};
