import { BaseValidator } from "../components/form/validators/BaseValidator";
import { MaxLengthValidator } from "../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../components/form/validators/MinLengthValidator";
import { PhoneCountryCodeValidator } from "../components/form/validators/PhoneCountryCodeValidator";
import { RequiredValidator } from "../components/form/validators/RequiredValidator";
import { data } from "./data";
import { ContractId } from "./models/CommonTypes";
import { TFunction } from "i18next";
import { MainContractData } from "./models/ContractTypes";

const BASE_PATH = "/api/sales";

export enum LegalEntityType {
  LIMITED = "LIMITED",
  SOLE_PROPRIETARY = "SOLE_PROPRIETARY",
  PARTNERSHIP = "PARTNERSHIP",
  LIMITED_PARTNERSHIP = "LIMITED_PARTNERSHIP",
  ASSOCIATION = "ASSOCIATION",
  TRUST = "TRUST",
  MUNICIPALITY = "MUNICIPALTY",
}

interface ValidationProps {
  name: string;
  min?: number;
  max?: number;
  minDate?: Date;
  maxDate?: Date;
}

const validators: Record<
  keyof Omit<
    MainContractData,
    | "cas"
    | "organizationNumber"
    | "taxRegistrationId"
    | "dateOfIncorporation"
    | "countryOfIncorporation"
    | "address"
    | "invoiceAddress"
    | "notes"
  >,
  ValidationProps
> = {
  companyName: {
    min: 2,
    max: 60,
    name: "Company name",
  },
  legalEntityType: {
    name: "Legal form",
  },
  phoneNumber: {
    min: 8,
    max: 14,
    name: "Phone number",
  },
  website: {
    min: 4,
    max: 50,
    name: "Website",
  },
};

const validatorCache: {
  [key: string]: BaseValidator[];
} = {};

function getValidators(
  t: TFunction<"translation">,
  key: keyof MainContractData
): BaseValidator[] {
  if (validatorCache[key]) {
    return validatorCache[key];
  }

  switch (key) {
    case "companyName":
    case "website": {
      validatorCache[key] = [
        new RequiredValidator(t(`${validators[key].name} is required`)),
        new MinLengthValidator(
          validators[key].min || 0,
          t(
            `${validators[key].name} must be at least ${validators[key].min} characters`
          )
        ),
        new MaxLengthValidator(
          validators[key].max || 0,
          t(
            `${validators[key].name} must not exceed ${validators[key].max} characters`
          )
        ),
      ];

      return validatorCache[key];
    }

    case "phoneNumber": {
      validatorCache[key] = [
        new RequiredValidator(t(`${validators[key].name} is required`)),
        new PhoneCountryCodeValidator(
          t(`${validators[key].name} must start with a country code e.g +46...`)
        ),
        new MinLengthValidator(
          validators[key].min || 0,
          t(
            `${validators[key].name} must be at least ${validators[key].min} characters`
          )
        ),
        new MaxLengthValidator(
          validators[key].max || 0,
          t(
            `${validators[key].name} must not exceed ${validators[key].max} characters`
          )
        ),
      ];

      return validatorCache[key];
    }
  }

  return [];
}

export const getCompanyValidators = getValidators;

export enum VatStatus {
  NOT_CHECKED = "NOT_CHECKED",
  NOT_VALID = "NOT_VALID",
  VALID = "VALID",
  VIES_ERROR = "VIES_ERROR",
}

export const dataCompany = {
  getCompanyData: (contractId: ContractId) =>
    data.get<MainContractData>(`${BASE_PATH}/${contractId}/contract-data`),

  saveCompanyData: (contractId: ContractId, companyData: MainContractData) =>
    data.post<MainContractData>(
      `${BASE_PATH}/${contractId}/company`,
      companyData
    ),

  triggerReprovisioning: (contractId: ContractId) =>
    data.get(`${BASE_PATH}/${contractId}/provisioning`),

  byPassRisk: (contractId: ContractId) =>
    data.post(`${BASE_PATH}/signoff/${contractId}/test/bypass-risk`),

  // Todo, update url?
  validateVat: (contractId: ContractId, vatNumber: string) =>
    data.post<{ valid: boolean; result: VatStatus }>(
      `/api/sales/externa/vies/${contractId}`,
      {
        vatNumber,
      }
    ),
};
