import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  contractSaveState,
  contractErrorState,
} from "../../state/contractSaveState";
import "./Notifier.scss";
import { T } from "../translation/T";

export function Notifier() {
  const [dataSaved, setDataSaved] = useRecoilState(contractSaveState);
  const dataError = useRecoilValue(contractErrorState);
  const [internalSaveTime, setInternalSaveTime] = useState<string>();

  useEffect(() => {
    if (dataError.length) {
      setInternalSaveTime("");
    }
  }, [dataError]);

  useEffect(() => {
    if (dataSaved.length) {
      const item = dataSaved[dataSaved.length - 1];
      const time = DateTime.fromJSDate(item.date).toFormat("HH:mm");
      setInternalSaveTime(time);
      setDataSaved([]);
    }
  }, [dataSaved, setDataSaved]);

  const saveTag = internalSaveTime ? (
    <div className="notifier-saved">
      <i>
        <T
          id="Saved at {{ timestamp }}"
          options={{ timestamp: internalSaveTime }}
        />
      </i>
    </div>
  ) : null;

  return <div className="notifier">{saveTag}</div>;
}
