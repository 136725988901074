import React from "react";
import { useTranslation } from "react-i18next";
import { ComponentProps } from "../../PdfPage";
import { getDefaultStyles } from "../../PdfStyles";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { Cell } from "../Cell";
import { FirstCell } from "../FirstCell";
import { getCardLabels } from "./labels";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({
    info: {
      margin: "4px 0 10px 0",
    },
    list: {
      margin: "15px 20px",
      padding: "0 20px",
    },
    dot: {
      position: "absolute",
      left: "-10px",
      top: "0",
      width: "14px",
      height: "14px",
    },
    listItem: {
      position: "relative",
      margin: "5px 0 0 0",
    },
  });
}

function getLabels(t: any) {
  return {
    header: t("Pricing"),
    costs: t(
      "Fees are entered as percent (%) of the transaction amount and/or fixed amount per transaction."
    ),
    desc: t("Description"),
    price: t("Price"),
    inAdvance: t("The monthly cost is invoiced monthly in advance."),
    deducted: t(
      "The cost of Visa, MasterCard, Maestro, DinersClub, UnionPay and JCB is deducted from the payout you receive."
    ),
    bankDay: t(
      "Payment of redeemed credit card transactions takes place in local currency the banking day after the credit card transaction has been completed in the payment terminal."
    ),
    commitment: t(
      "The binding period is 24 months and the agreement then continues with 3 months' notice"
    ),
  };
}

export const Pricing: React.FunctionComponent<ComponentProps> = ({
  show,
  productType,
  config,
}) => {
  const { t } = useTranslation();
  const labels = { ...getLabels(t), ...getCardLabels(t) };
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  const elems = [];
  for (let i = 0; i < 14; i++) {
    elems.push(
      <View key={i} style={styles.split}>
        <View style={styles.largeColumn}>
          <FirstCell label={labels.desc} show={false}>
            {labels.debitEEA}
          </FirstCell>
        </View>
        <View style={styles.smallColumn}>
          <Cell label={labels.price} show={false}>
            1,50%
          </Cell>
        </View>
      </View>
    );
  }

  return (
    <>
      <View style={styles.sectionHeader}>
        <View style={styles.split}>
          <View style={styles.largeColumn}>
            <Text>{labels.header}</Text>
          </View>
          <View style={styles.smallColumn}>
            {config.pageNumber && config.numberOfPages ? (
              <Text style={styles.pageNumber}>
                {config.pageNumber} / {config.numberOfPages}
              </Text>
            ) : null}
          </View>
        </View>
      </View>
      <Text style={styles.info}>{labels.costs}</Text>

      {show ? (
        <>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.debitDomestic}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                1,50 %
              </Cell>
            </View>
          </View>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.creditDomestic}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                1,50 %
              </Cell>
            </View>
          </View>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.commercialDomestic}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                1,50 %
              </Cell>
            </View>
          </View>
          {/** EEA */}
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.debitEEA}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                1,50 %
              </Cell>
            </View>
          </View>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.creditEEA}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                1,50 %
              </Cell>
            </View>
          </View>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.commercialEEA}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                1,50 %
              </Cell>
            </View>
          </View>
          {/** Outside EEA */}
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.debitOutsideEEA}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                1,50 %
              </Cell>
            </View>
          </View>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.creditOutsideEEA}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                1,50 %
              </Cell>
            </View>
          </View>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.commercialOutsideEEA}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                1,50 %
              </Cell>
            </View>
          </View>
          {/** Terminal costs */}
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.monthlyFeeWireless}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                249,00 SEK
              </Cell>
            </View>
          </View>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.monthlyFeeWiredIntegrated}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                199,00 SEK
              </Cell>
            </View>
          </View>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.monthlyFeeWirelessIntegrated}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                249,00 SEK
              </Cell>
            </View>
          </View>
          <View style={styles.split}>
            <View style={styles.largeColumn}>
              <FirstCell label={labels.desc} show={show}>
                {labels.shippingPerTerminal}
              </FirstCell>
            </View>
            <View style={styles.smallColumn}>
              <Cell label={labels.price} show={show}>
                199,00 SEK
              </Cell>
            </View>
          </View>
        </>
      ) : (
        <>{elems}</>
      )}
    </>
  );
};
