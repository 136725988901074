import React from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../../PdfStyles";
import { FirstCell } from "../FirstCell";
import { ComponentProps, PdfProductType } from "../../PdfPage";
import { EcomType } from "../../../../../data/models/ContractTypes";
import { Cell } from "../Cell";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

function getLabels(t: any) {
  return {
    header: t("Product"),
    product: t("Worldline Product or service used"),
    typeOfAgreement: t("Type of agreement"),
    url: t("Url"),
    application: t("Application"),
    former: t("Former acquirer"),
    acquirer: t("Acquirer"),
  };
}

export const AcceptanceOnline: React.FunctionComponent<ComponentProps> = ({
  language,
  productType,
  show,
  config,
}) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  if (productType !== PdfProductType.ACCEPTANCE_ONLINE) {
    return null;
  }

  return (
    <>
      <View style={styles.sectionHeader}>
        <Text>{labels.header}</Text>
      </View>

      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.product} show={show}>
            {productType}
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.typeOfAgreement} show={show}>
            {config.ecomType === EcomType.APP
              ? "Application"
              : "Website solution"}
          </Cell>
        </View>
      </View>

      <View>
        {config.ecomType === EcomType.APP ? (
          <FirstCell label={labels.application} show={show}>
            An app on iStore
          </FirstCell>
        ) : (
          <FirstCell label={labels.url} show={show}>
            https://someurl.com
          </FirstCell>
        )}
      </View>

      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.acquirer} show={show}>
            Elavon
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.former} show={show}>
            Swedbank/Babs
          </Cell>
        </View>
      </View>
    </>
  );
};
