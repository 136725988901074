import React, { useMemo } from "react";
import cx from "classnames";
import styles from "./ConfirmPrimary.module.scss";
import { useContractId } from "../../../../hooks/useContractId";
import { useSuspenseQuery } from "@tanstack/react-query";
import { ErrorBoundary } from "@sentry/react";
import { dataAssociates } from "../../../../data/dataAssociates";
import { AssociateRole } from "../../../../data/models/AssociateTypes";
import { T } from "../../../../components/translation/T";
import {
  PhoneValidatorResponseCountry,
  getCountryFromPhoneNumber,
} from "../../../../components/form/validators/PhoneToCountryCode";
import { Flag } from "../../../../components/flags/Flag";

export const ConfirmPrimary: React.FunctionComponent = () => {
  const contractId = useContractId();
  const { data: associates } = useSuspenseQuery(
    dataAssociates.fetchAssociates(contractId)
  );

  const primary = useMemo(
    () =>
      associates.find((associate) =>
        associate.roles.includes(AssociateRole.PRIMARY_CONTACT)
      ),
    [associates]
  );

  const countryOfPhone = useMemo(() => {
    const item = getCountryFromPhoneNumber(primary?.contact.phoneNumber);
    if (item.valid) {
      return (
        <Flag
          country={(item as PhoneValidatorResponseCountry).country}
          height={16}
          rounded
          shadow
        />
      );
    }

    return null;
  }, [primary]);

  if (!primary) {
    throw new Error("Missing mandatory primary contact");
  }

  return (
    <div className={cx("m-top-30")}>
      <ErrorBoundary>
        <b>
          <T>Primary contact</T>
        </b>
        <ul className="small">
          <li>
            <span className={styles.infoName}>
              <T>Name</T>
            </span>
            <span>
              {primary.contact.firstName} {primary.contact.lastName}
            </span>
          </li>
          <li>
            <span className={styles.infoName}>
              <T>Email</T>
            </span>
            <span>{primary.contact.email}</span>
          </li>

          <li>
            <span className={styles.infoName}>
              <T>Phone number</T>
            </span>
            <span>
              {countryOfPhone} &nbsp;
              {primary.contact.phoneNumber}
            </span>
          </li>
        </ul>
      </ErrorBoundary>
    </div>
  );
};
