import React, { useCallback } from "react";
import cx from "classnames";
import { Status } from "../../../../../data/types";
import {
  ContractDocument,
  documentTypeDisplay,
  Validity,
} from "../../../../../data/models/ContractTypes";
import { Beacon } from "../../../../../components/beacon/Beacon";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Edit } from "../../../../../components/icons/Edit";
import { Visibility } from "../../../../../components/icons/Visibility";
import { TIMELINE_DOCUMENT } from "../Timeline";
import { T } from "../../../../../components/translation/T";

interface Props {
  document: ContractDocument;
  active: boolean;
  onConfirmDoc: (doc: ContractDocument) => void;
}

export const EventFDocument: React.FunctionComponent<Props> = ({
  active,
  document,
  onConfirmDoc,
}) => {
  const onConfirm = useCallback(
    () => onConfirmDoc(document),
    [onConfirmDoc, document]
  );

  let status = Status.DEFAULT;

  if (!active) {
    status = Status.DISABLED;
  } else if (!document.fileAvailable) {
    status = Status.DISABLED;
  } else {
    status = Status.DEFAULT;
  }

  return (
    <li
      className={cx("timeline-event-document", { active })}
      style={{
        height: TIMELINE_DOCUMENT,
      }}
    >
      <Button
        className="event-button small"
        onClick={onConfirm}
        status={status}
      >
        {document.confirmed ? (
          <>
            <Visibility /> <T>View</T>
          </>
        ) : (
          <>
            <Edit /> <T>Confirm</T>
          </>
        )}
      </Button>
      <div>
        <div className="truncated">
          <b>
            <T>{documentTypeDisplay(document.documentType)}</T>,
          </b>{" "}
          <span className="timeline-item-type">Document</span>
        </div>
        <div className={cx("additional-event-info", "text-small")}>
          <Beacon
            mini
            className="aligned"
            validity={document.uploaded ? Validity.VALID : Validity.MISSING}
          />
          <span className="additional-event-passive-text">
            <T>Merchant upload</T>
          </span>
        </div>
        <div className={cx("additional-event-info", "text-small")}>
          <Beacon
            mini
            className="aligned"
            validity={document.confirmed ? Validity.VALID : Validity.MISSING}
          />
          <span className="additional-event-passive-text">
            <T>Sales confirmation</T>
          </span>
        </div>
      </div>
    </li>
  );
};
