import { Country } from "../../../i18n";

export interface PhoneValidatorResponseInvalid {
  valid: boolean;
}

export interface PhoneValidatorResponseCountry {
  valid: boolean;
  country: Country;
}

export interface PhoneValidatorResponseName {
  valid: boolean;
  name: string;
}

export const PHONE_TO_KNOWN_COUNTRY_CODE: Record<string, Country> = {
  "+46": Country.SWEDEN,
  "+45": Country.DENMARK,
  "+358": Country.FINLAND,
  "+47": Country.NORWAY,
};

export const PHONE_TO_UNKNOWN_COUNTRY_CODE: Record<string, string> = {
  "+48": "PL",
  "+385": "HR",
  "+32": "BE",
  "+359": "BG",
  "+49": "DE",
  "+372": "EE",
  "+353": "IE",
  "+30": "EL",
  "+34": "ES",
  "+33": "FR",
  "+39": "IT",
  "+357": "CY",
  "+371": "LV",
  "+370": "LT",
  "+352": "LU",
  "+36": "HU",
  "+356": "MT",
  "+31": "NL",
  "+43": "AT",
  "+351": "PT",
  "+40": "RO",
  "+386": "SI",
  "+421": "SK",
};

const inList = (
  list: Record<string, string | Country>,
  phoneNumber: string
) => {
  for (let i = 5; i >= 1; i--) {
    const code = phoneNumber.substring(0, i);
    const country = list[code];
    if (country) {
      return country;
    }
  }
};

export function sanitizePhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) {
    return "";
  }

  const sanitizedNumber = phoneNumber
    .replace(/[--]/g, " ")
    .replace(/[^0-9\s+]/g, "")
    .replace(/ {2,}/g, " ")
    .trim();

  return sanitizedNumber;
}

export function replacePhoneNumberBeginning(
  country: Country,
  phoneNumber?: string
) {
  if (!phoneNumber) {
    return "";
  }

  let prefix;
  for (const key in PHONE_TO_KNOWN_COUNTRY_CODE) {
    if (country === PHONE_TO_KNOWN_COUNTRY_CODE[key]) {
      prefix = key;
      break;
    }
  }

  // Found no prefix for this country
  if (!prefix) {
    return phoneNumber;
  }

  const zeroedPrefix = prefix.replace("+", "00");

  if (phoneNumber.startsWith(zeroedPrefix)) {
    return phoneNumber.replace("00", "+");
  } else if (phoneNumber.startsWith("00")) {
    return phoneNumber.replace("00", `${prefix} `);
  } else if (phoneNumber.startsWith("0")) {
    return phoneNumber.replace("0", `${prefix} `);
  }

  return phoneNumber;
}

export function getCountryFromPhoneNumber(
  phoneNumber?: string
):
  | PhoneValidatorResponseInvalid
  | PhoneValidatorResponseCountry
  | PhoneValidatorResponseName {
  if (!phoneNumber) {
    return {
      valid: false,
    };
  }

  if (phoneNumber.length <= 4) {
    return {
      valid: false,
    };
  }

  const country = inList(PHONE_TO_KNOWN_COUNTRY_CODE, phoneNumber) as Country;

  if (country) {
    return {
      valid: true,
      country,
    };
  }

  const name = inList(PHONE_TO_UNKNOWN_COUNTRY_CODE, phoneNumber);

  if (name) {
    return {
      valid: true,
      name,
    };
  }

  return {
    valid: false,
  };
}
