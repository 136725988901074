import { ServerError } from "../../network/API";
import { Cas, ContractId, ONGOING_RESPONSE } from "../models/CommonTypes";

interface DataWithCas {
  cas: Cas;
}

class CasStorage<T extends DataWithCas> {
  cas?: Cas;
  key?: string;
  storage = new Map<string, Cas>();

  getCas(data: T) {
    if (this.key) {
      const key = data[this.key as keyof T] as string;
      return this.storage.get(key) || data.cas || 0;
    } else {
      return this.storage.get("cas") || data.cas || 0;
    }
  }

  setCas(data: T) {
    if (this.key) {
      const key = data[this.key as keyof T] as string;
      this.storage.set(key, data.cas);
    } else {
      this.storage.set("cas", data.cas);
    }
  }

  setKey(key?: keyof T) {
    if (key) {
      this.key = String(key);
    }
  }
}

interface QueueProps<T extends DataWithCas> {
  contractId: ContractId;
  data: T;
  resolve: (data: T) => void;
  reject: (error: ServerError<T> | typeof ONGOING_RESPONSE) => void;
}

class GenericDataQueue<T extends DataWithCas> {
  queue: QueueProps<T>[] = [];
  casStorage = new CasStorage<T>();
  isRequesting: boolean = false;
  saveFunction: (contractId: ContractId, data: T) => Promise<T>;
  key?: keyof T;

  constructor(
    saveFunction: (contractId: ContractId, data: T) => Promise<T>,
    key?: keyof T
  ) {
    this.key = key;
    this.casStorage.setKey(key);
    this.saveFunction = saveFunction;
  }

  getKey() {
    return this.key;
  }

  isKeyed() {
    return !!this.key;
  }

  private isInQueue(data: T) {
    if (!!this.key) {
      return !!this.queue.length;
    }

    return !!this.queue.find(
      (queueItem) =>
        queueItem.data[this.key as keyof T] !== data[this.key as keyof T]
    );
  }

  saveData(contractId: ContractId, data: T) {
    return new Promise<T | ServerError<T> | typeof ONGOING_RESPONSE>(
      (resolve, reject) => {
        if (this.isRequesting) {
          if (!!this.key) {
            // If there are additional saves for this item in queue,
            // just remove them since we have a newer update
            this.queue = this.queue.filter(
              (queueItem) =>
                queueItem.data[this.key as keyof T] !==
                data[this.key as keyof T]
            );
          }

          this.queue.push({
            data,
            contractId,
            resolve,
            reject,
          });
          return;
        }

        // Nothing in queue. Request immediately
        if (!this.queue.length) {
          this.postData(contractId, data, resolve, reject);
          return;
        }

        const next = this.queue.pop();
        if (next) {
          this.postData(next.contractId, next.data, resolve, reject);
        }
      }
    );
  }

  postData(
    contractId: ContractId,
    data: T,
    resolve: (data: T) => void,
    reject: (error: ServerError<T> | typeof ONGOING_RESPONSE) => void
  ) {
    this.isRequesting = true;
    let usedCas = this.casStorage.getCas(data) as Cas;
    this.saveFunction(contractId, {
      ...data,
      cas: usedCas,
    })
      .then((response) => {
        let update: T;
        // If this is an array, at some point...
        if (Array.isArray(response)) {
          update = response.find(
            (element) => element[this.key] === data[this.key as keyof T]
          );
        } else {
          update = response;
        }

        this.casStorage.setCas({
          ...update,
          cas: update.cas || (usedCas || 0) + 1,
        });
        const hasRecentUpdate = this.isInQueue(data);
        this.isRequesting = false;

        // Are there unsaved store updates in queue?
        const next = this.queue.pop();
        if (next) {
          this.postData(next.contractId, next.data, next.resolve, next.reject);
        }

        // If this store is buffered, resolve with placeholder
        // for now and wait for more recent promises to resolve
        hasRecentUpdate ? reject(ONGOING_RESPONSE) : resolve(update);
      })
      .catch((err) => {
        const hasRecentUpdate = this.isInQueue(data);
        this.isRequesting = false;

        // Are there unsaved store updates in queue?
        const next = this.queue.pop();
        if (next) {
          this.postData(next.contractId, next.data, next.resolve, next.reject);
        }

        // If this store is buffered, disregard error and
        // resolve with placeholder for now and wait for
        // more recent promises to resolve.
        hasRecentUpdate ? reject(ONGOING_RESPONSE) : reject(err);
      });
  }
}

export { GenericDataQueue, type DataWithCas };
