import { PropsWithChildren } from "react";
import { ButtonPaneWithLabel } from "../../../../../components/interactions/Buttons/ButtonPaneInput";
import styles from "./ButtonPaneCollapse.module.scss";
import { Props as ButtonPaneWithLabelProps } from "../../../../../components/interactions/Buttons/ButtonPaneInput";
import cx from "classnames";
import { AnimateHeightMotion } from "../../../../../components/animate/AnimateHeightMotion";

interface Props<T> extends ButtonPaneWithLabelProps<T> {
  title?: string;
  children: React.ReactNode;
  expanded?: boolean;
}

export function ButtonPaneCollapse<ObjectType>({
  title,
  children,
  expanded = false,
  ...childProps
}: PropsWithChildren<Props<ObjectType>>) {
  const rootClass = cx(styles.root, {
    [styles.rootOpen]: expanded,
  });

  const headerClass = cx(styles.header, {
    [styles.headerOpen]: expanded,
  });

  const bodyClass = cx(styles.body, {
    [styles.bodyOpen]: expanded,
  });

  return (
    <div className={rootClass}>
      <div className={headerClass}>
        {title && <div className={styles.title}>{title}</div>}
        <ButtonPaneWithLabel {...childProps} />
      </div>
      <AnimateHeightMotion presence>
        {expanded && <div className={bodyClass}>{children}</div>}
      </AnimateHeightMotion>
    </div>
  );
}
