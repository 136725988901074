import { ReactNode } from "react";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class BankValidatorMaxLength extends BaseValidator {
  name = "Bank validator max length";

  constructor(private max: number, error: string | ReactNode) {
    super(error);
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      const digitsOnly = value.replace(/\D/g, "");

      if (digitsOnly.length === 0 || digitsOnly.length <= this.max) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
