import React from "react";
import cx from "classnames";
import "./FieldSet.scss";
import { ReactNode } from "react";
import { T } from "../translation/T";

interface Props {
  header: string | ReactNode;
  children: ReactNode;
  className?: string;
}

export const FieldSet: React.FunctionComponent<Props> = ({
  header,
  children,
  className,
}) => {
  return (
    <div className={cx("fieldset", className)}>
      <h5 className="fieldset-header">
        {typeof header === "string" ? <T>{header}</T> : header}
      </h5>
      <div className="fieldset-inner">{children}</div>
    </div>
  );
};
