import cx from "classnames";
import React, { ReactNode } from "react";
import "./Pagination.scss";

interface Props {
  activePage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  className?: string;
  alwaysShow?: boolean;
}

export const Pagination: React.FunctionComponent<Props> = ({
  activePage,
  totalPages,
  onPageChange,
  className,
  alwaysShow = false,
}) => {
  if (!alwaysShow && totalPages < 2) {
    return null;
  }

  let buttons: ReactNode[] = [];
  if (totalPages < 8) {
    for (let i = 0; i < totalPages; i++) {
      buttons.push(
        <li key={`pagination-key-${i}`}>
          <button
            className={cx("pagination-button", {
              "is-active": i === activePage,
            })}
            data-page={i}
            onClick={() => onPageChange(i)}
          >
            {i + 1}
          </button>
        </li>
      );
    }
  } else if (activePage === 0) {
    buttons = [
      <li key="pagination-key-0">
        <button
          className="pagination-button is-active"
          data-page={0}
          onClick={() => onPageChange(0)}
        >
          1
        </button>
      </li>,
      <li key="pagination-key-1">
        <button
          className="pagination-button"
          data-page={1}
          onClick={() => onPageChange(1)}
        >
          2
        </button>
      </li>,
      <li key="pagination-key-2">
        <button
          className="pagination-button"
          data-page={1}
          onClick={() => onPageChange(1)}
        >
          &gt;
        </button>
      </li>,
      <li key="pagination-key-3">
        <button
          className="pagination-button"
          data-page={totalPages - 1}
          onClick={() => onPageChange(totalPages - 1)}
        >
          &gt;&gt;
        </button>
      </li>,
    ];
  } else if (activePage === 1) {
    buttons = [
      <li key="pagination-key-0">
        <button
          className="pagination-button"
          data-page={0}
          onClick={() => onPageChange(0)}
        >
          1
        </button>
      </li>,
      <li key="pagination-key-1">
        <button
          className="pagination-button is-active"
          data-page={1}
          onClick={() => onPageChange(1)}
        >
          2
        </button>
      </li>,
      <li key="pagination-key-2">
        <button
          className="pagination-button"
          data-page={2}
          onClick={() => onPageChange(2)}
        >
          3
        </button>
      </li>,
      <li key="pagination-key-3">
        <button
          className="pagination-button"
          data-page={2}
          onClick={() => onPageChange(2)}
        >
          &gt;
        </button>
      </li>,
      <li key="pagination-key-4">
        <button
          className="pagination-button"
          data-page={totalPages - 1}
          onClick={() => onPageChange(totalPages - 1)}
        >
          &gt;&gt;
        </button>
      </li>,
    ];
  } else if (activePage === totalPages - 1) {
    buttons = [
      <li key="pagination-key-0">
        <button
          className="pagination-button"
          data-page={0}
          onClick={() => onPageChange(0)}
        >
          &lt;&lt;
        </button>
      </li>,
      <li key="pagination-key-1">
        <button
          className="pagination-button"
          data-page={totalPages - 2}
          onClick={() => onPageChange(totalPages - 2)}
        >
          &lt;
        </button>
      </li>,
      <li key="pagination-key-2">
        <button
          className="pagination-button"
          data-page={totalPages - 2}
          onClick={() => onPageChange(totalPages - 2)}
        >
          {totalPages - 1}
        </button>
      </li>,
      <li key="pagination-key-3">
        <button
          className="pagination-button is-active"
          data-page={totalPages - 1}
          onClick={() => onPageChange(totalPages - 1)}
        >
          {totalPages}
        </button>
      </li>,
    ];
  } else if (activePage === totalPages - 2) {
    buttons = [
      <li key="pagination-key-0">
        <button className="pagination-button" data-page={0} onClick={() => onPageChange(0)}>
          &lt;&lt;
        </button>
      </li>,
      <li key="pagination-key-1">
        <button
          className="pagination-button"
          data-page={totalPages - 3}
          onClick={() => onPageChange(totalPages - 3)}
        >
          &lt;
        </button>
      </li>,
      <li key="pagination-key-2">
        <button
          className="pagination-button"
          data-page={totalPages - 3}
          onClick={() => onPageChange(totalPages - 3)}
        >
          {totalPages - 2}
        </button>
      </li>,
      <li key="pagination-key-3">
        <button
          className="pagination-button is-active"
          data-page={totalPages - 2}
          onClick={() => onPageChange(totalPages - 2)}
        >
          {totalPages - 1}
        </button>
      </li>,
      <li key="pagination-key-4">
        <button
          className="pagination-button"
          data-page={totalPages - 1}
          onClick={() => onPageChange(totalPages - 1)}
        >
          {totalPages}
        </button>
      </li>,
    ];
  } else {
    buttons = [
      <li key="pagination-key-0">
        <button className="pagination-button" data-page={0} onClick={() => onPageChange(0)}>
          &lt;&lt;
        </button>
      </li>,
      <li key="pagination-key-1">
        <button
          className="pagination-button"
          data-page={activePage - 1}
          onClick={() => onPageChange(activePage - 1)}
        >
          &lt;
        </button>
      </li>,

      <li key="pagination-key-2">
        <button
          className="pagination-button"
          data-page={activePage - 1}
          onClick={() => onPageChange(activePage - 1)}
        >
          {activePage}
        </button>
      </li>,

      <li key="pagination-key-3">
        <button
          className="pagination-button is-active"
          data-page={activePage}
          onClick={() => onPageChange(activePage)}
        >
          {activePage + 1}
        </button>
      </li>,
      <li key="pagination-key-4">
        <button
          className="pagination-button"
          data-page={activePage + 1}
          onClick={() => onPageChange(activePage + 1)}
        >
          {activePage + 2}
        </button>
      </li>,

      <li key="pagination-key-5">
        <button
          className="pagination-button "
          data-page={activePage + 1}
          onClick={() => onPageChange(activePage + 1)}
        >
          &gt;
        </button>
      </li>,
      <li key="pagination-key-6">
        <button
          className="pagination-button"
          data-page={totalPages - 1}
          onClick={() => onPageChange(totalPages - 1)}
        >
          &gt;&gt;
        </button>
      </li>,
    ];
  }

  return <ul className={cx("pagination", className)}>{buttons}</ul>;
};
