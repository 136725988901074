import React, { useMemo, useRef } from "react";
import { BusinessRisk } from "../../../../../../data/dataBusinessRisk";
import { TextInput } from "../../../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../../../components/form/validators/RequiredValidator";
import { T } from "../../../../../../components/translation/T";
import { ProductType } from "../../../../../../data/models/ContractTypes";
import { id } from "../../../../../../components/utils";

interface Props {
  businessRisk: BusinessRisk;
  onChange: (value: string, name: string) => void;
  disabled?: boolean;
  productType: ProductType;
}

const EcomOpts = [
  "ACI / Payon",
  "Adyen",
  "Amadeus",
  "AsiaBill",
  "ePay",
  "Bizzon",
  "Cardstream",
  "Checkout.com",
  "Dalenys (Be2Bill)",
  "Dintero",
  "MeaPay",
  "Modulsystem",
  "Mondido",
  "MyMoid",
  "NetAxept",
  "Novatum",
  "Ogone",
  "OP Checkout Payment Highway",
  "Payair",
  "PayByrd",
  "Paymark",
  "Payment IQ",
  "Paynova",
  "Reepay",
  "Seitatech",
  "Smart Refill",
  "Swedbank Pay (Payex)",
  "Synthesis",
  "TNSI (Advam)",
  "Worldpay",
  "Quickpay",
];

const StoreOpts = [
  "Worldline Device (Samport)",
  "Nets (Nets viking terminals)",
  "Verifone SE",
  "Verifone Greenbox",
  "Westpay",
  "MPP (tap on phone via Tietoevry)",
  "Seita (via Tietoevry)",
  "Poplapay (via GA and Tietoevry)",
  "Payworks (Cybersource)",
  "Planet/3C",
  "Seita Technologies",
];

export const AcceptanceAcquirer: React.FunctionComponent<Props> = ({
  businessRisk,
  onChange,
  disabled,
  productType,
}) => {
  const identifier = useRef<string>(id());
  const list = useMemo(
    () =>
      (productType === ProductType.ACCEPTANCE_INSTORE
        ? StoreOpts
        : EcomOpts
      ).map((opt) => (
        <option value={opt} key={opt}>
          {opt}
        </option>
      )),
    [productType]
  );

  return (
    <div>
      <TextInput
        label={<T>Approved PSP</T>}
        onChange={onChange}
        name="pspPartner"
        disabled={disabled}
        value={businessRisk.pspPartner}
        list={identifier.current}
        validators={[new RequiredValidator("Required to answer")]}
      />
      <datalist id={identifier.current}>{list}</datalist>

      {productType === ProductType.ACCEPTANCE_INSTORE ? (
        <TextInput
          label={<T>Terminal model</T>}
          onChange={onChange}
          name="pspPartnerTerminalModel"
          disabled={disabled}
          value={businessRisk.pspPartnerTerminalModel}
          validators={[new RequiredValidator("Required to answer")]}
        />
      ) : null}
    </div>
  );
};
