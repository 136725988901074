import React from "react";
import cx from "classnames";
import { Logo } from "../images/Logo";
import { SfLink } from "../links/SfLink";
import "./VerticalMenu.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
  logo?: boolean;
}

export const VerticalMenu: React.FunctionComponent<Props> = ({
  className,
  children,
  logo = true,
}) => {
  return (
    <div className={cx("vertical-menu", className)}>
      {logo && (
        <SfLink logo={true}>
          <Logo />
        </SfLink>
      )}
      {children}
    </div>
  );
};
