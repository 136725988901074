import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { MenuWrapper } from "../components/menuWrapper/MenuWrapper";
import { ContractMenu } from "./sales/Contract/ContractMenu";
import { ContractEditMenu } from "./sales/Contract/menus/ContractEditMenu";
import { useContractId } from "../hooks/useContractId";
import { Overlay } from "../components/overlay/Overlay";
import { T } from "../components/translation/T";
import { Status } from "../data/types";
import { dataAuth } from "../data/dataAuth";
import { Button } from "../components/interactions/Buttons/Button";
import { AnimateHeight } from "../components/animate/AnimateHeight";
import { GenericError } from "../components/boxes/GenericError";
import "./Page.scss";
import { useTranslation } from "react-i18next";
import { STORE_KEY, Store } from "../Store";

export interface PageProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  stripped?: boolean;
  padded?: boolean;
  centered?: boolean;
  background?: boolean;
  withSectionMenu?: boolean;
}

export const AuthOverlay: React.FunctionComponent<{
  requireAuth: boolean;
  isPartner: boolean;
}> = ({ requireAuth, isPartner }) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const onClick = useCallback(() => {
    setStatus(Status.DEFAULT);

    Store.setValue(
      STORE_KEY.STORE_URL_AT_SESSION_EXPIRED,
      window.location.href
    );

    dataAuth
      .startLogin(isPartner)
      .then((data) => (window.location.href = data.url))
      .catch(() => setStatus(Status.ERROR));
  }, [isPartner]);

  return (
    <Overlay open={requireAuth} disableClose={true} onClose={() => {}}>
      <strong>
        <T>You have been logged out</T>
      </strong>{" "}
      <p>
        <T>Press the login button to become logged in again</T> 🙂
      </p>
      <AnimateHeight name={status}>
        {status === Status.ERROR ? <GenericError /> : <div />}
      </AnimateHeight>
      <div className="m-top-30">
        <Button block onClick={onClick}>
          To login
        </Button>
      </div>
    </Overlay>
  );
};

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  className,
  title,
  stripped = false,
  padded = false,
  centered = false,
  background = false,
  withSectionMenu = false,
}) => {
  const [menuOpen, toggleMenu] = useState<boolean>(false);
  const id = useContractId();
  const { t } = useTranslation();

  useEffect(() => {
    if (!title) {
      document.title = "WorldlineX";
      return;
    }
    document.title = `${t(title)} | WorldlineX`;
  }, [title, t]);

  if (stripped) {
    return (
      <div
        className={cx("page", "stripped", className, {
          background,
          centered,
        })}
      >
        <div className="accent-bar" />
        {children}
      </div>
    );
  }

  return (
    <div
      className={cx("page", className, {
        background,
        centered,
      })}
    >
      <div className="accent-bar" />
      <MenuWrapper
        menuComponent={
          <ContractMenu
            contractId={id}
            menuChildren={withSectionMenu && <ContractEditMenu />}
          />
        }
        {...{ padded, toggleMenu, menuOpen }}
      >
        {children}
      </MenuWrapper>
    </div>
  );
};
