import { atom } from "recoil";
import { STORE_KEY, Store } from "../Store";

type Auth = {
  authorized: boolean;
  referrer?: string;
  isPartner?: boolean;
};

export const contractAuthState = atom<Auth>({
  key: "contract-auth-state",
  default: {
    authorized: true,
    referrer: Store.getValue(STORE_KEY.STORE_REFERRER),
    isPartner: Store.getValue(STORE_KEY.STORE_IS_PARTNER),
  },
});
