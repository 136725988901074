import React, { useEffect, useState } from "react";
import { useNavigate, generatePath } from "react-router";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { dataMerchant } from "../../../../data/dataMerchant";
import { ContractId } from "../../../../data/models/CommonTypes";
import {
  AssociateTracking,
  BankAccount,
} from "../../../../data/models/ContractTypes";
import { REVIEW_ROUTE } from "../ReviewPage";
import { Associate } from "../../../../data/models/AssociateTypes";
import { TrackingAssociate } from "../../../../components/tracking/TrackingAssociate";
import { T } from "../../../../components/translation/T";
import "./AssociateTraceView.scss";
import { useSuspenseQuery } from "@tanstack/react-query";
import { dataBank } from "../../../../data/dataBank";

export interface Props {
  contractId: ContractId;
  associate: Associate;
  trackingLog: AssociateTracking[];
}

export const AssociateTraceView: React.FunctionComponent<Props> = ({
  contractId,
  associate,
}) => {
  const navigate = useNavigate();
  const [bankAccount, setBankAccount] = useState<BankAccount>({});
  const { data: reviewStatus } = useSuspenseQuery(
    dataMerchant.fetchReviewStatus(contractId)
  );

  useEffect(() => {
    dataBank
      .getBankAccount(contractId)
      .then((resp) => setBankAccount(resp))
      .catch(() => {}); // do nothing
  }, [contractId]);

  const onClose = () => {
    navigate(generatePath(REVIEW_ROUTE, { contractId }), {
      replace: true,
    });
  };

  const tracking = reviewStatus.tracking.find(
    (track) => track.associateId === associate.associateId
  );

  return (
    <div className="associate-review">
      {tracking ? (
        <TrackingAssociate
          tracking={tracking}
          associate={associate}
          bankAccount={bankAccount}
        />
      ) : (
        <T>Mm, we didn't find any tracking for this person. That's weird</T>
      )}

      <div className="associate-review-actions m-top-30">
        <div className="tablet-columns">
          <div></div>
          <div>
            <Button onClick={onClose} block className="close-button">
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
