import { ContractId } from "./models/CommonTypes";
import { data } from "./data";
import { Currency } from "../i18n";

export enum PricingModel {
  BLENDED = "Blended",
  MONTHLY_FEE_STEP = "MonthlyFeeStep",
  IC_PLUS_PLUS = "IC++",
}

export interface PriceData {
  label: string;
  displayLabel: string;
  unitType: UnitType;
  value: number;
}

export interface PricePlan {
  pricingModel: PricingModel;
  currency: Currency;
  displayName: string;
  prices: PriceData[];
}

export enum UnitType {
  CURRENCY = "Currency",
  PERCENT = "Percent",
}

const BASE_PATH = "/api/sales";

export const dataPricing = {
  getPricing: (contractId: ContractId) =>
    data.get<PricePlan>(`${BASE_PATH}/${contractId}/pricing`),

  fetchPricing: (contractId: ContractId) => ({
    queryKey: dataPricing.getPricingKey(contractId),
    queryFn: () => dataPricing.getPricing(contractId),
  }),

  getPricingKey: (contractId: ContractId) => {
    return ["pricing", contractId];
  },
};
