import React from "react";
import cx from "classnames";
import { CheckboxChecked } from "./CheckboxChecked";
import { CheckboxUnchecked } from "./CheckboxUnchecked";
import "./Checkbox.scss";

interface Props {
  className?: string;
  checked: boolean;
}

export const Checkbox: React.FunctionComponent<Props> = ({
  className,
  checked,
}) => {
  return (
    <div
      className={cx("checkbox", className, {
        checked,
      })}
    >
      <CheckboxUnchecked />
      <CheckboxChecked />
    </div>
  );
};
