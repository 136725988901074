import { useCallback, useEffect, useState } from "react";
import { Retry } from "../../../../components/retry/Retry";
import { dataMerchant } from "../../../../data/dataMerchant";
import { Associate, IdType } from "../../../../data/models/AssociateTypes";
import { AssociateId, ContractId } from "../../../../data/models/CommonTypes";
import { Status } from "../../../../data/types";
import { AssociateTraceView } from "./AssociateTraceView";
import { Page } from "../../../Page";
import { useAssociateId } from "../../../../hooks/useAssociateId";
import { useContractId } from "../../../../hooks/useContractId";
import { T } from "../../../../components/translation/T";
import { ReviewStatus } from "../../../../data/models/ContractTypes";
import { dataAssociates } from "../../../../data/dataAssociates";
import "./AssociateReview.scss";

export const ASSOCIATE_REVIEW_PAGE =
  "/review/:contractId/associate/:associateId";

interface Props {
  contractId: ContractId;
  reviewStatus?: ReviewStatus;
  associate?: Associate;
}

export function getIdTypeName(idType: IdType) {
  if (idType === IdType.PASSPORT) {
    return "Passport";
  }

  if (idType === IdType.DRIVING_LICENSE) {
    return "Driver's license";
  }

  return "";
}

const AssociateReviewPageInner: React.FunctionComponent<Props> = ({
  contractId,
  reviewStatus,
  associate,
}) => {
  if (!reviewStatus || !associate) {
    return null;
  }

  return (
    <AssociateTraceView
      contractId={contractId}
      associate={associate}
      trackingLog={[]}
    />
  );
};

//Om associate är färdig-reviewad så visar vi information
//bara med filer att ladda ner

//Review ID-kort. Visa ID-kortet och ställ kontrollfrågor
//När man godkänner så sparar vi hur lång tid man tog på sig att kontrollera
//TODO ska man fråga om signaturens matchning?

//Lägg till information om ID-kortet. Visa ID-kortet och signaturen
//Visa input-fält. Om vi har stöd för det så visa information som vi kunna extrahera
//automatiskt. Säljaren behöver då bara godkänna
export const AssociateReviewPage = () => {
  const contractId = useContractId();
  const associateId = useAssociateId();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [reviewStatus, setReviewStatus] = useState<ReviewStatus>();
  const [associate, setAssociate] = useState<Associate>();

  const load = useCallback(
    (contractId: ContractId, associateId: AssociateId) => {
      setStatus(Status.PENDING);

      Promise.all([
        dataMerchant.getReviewStatus(contractId),
        dataAssociates.getAssociates(contractId),
      ])
        .then((response) => {
          const [reviewStatus, associates] = response;
          setReviewStatus(reviewStatus);
          setAssociate(
            associates.find((item) => item.associateId === associateId)
          );
          setStatus(Status.SUCCESS);
        })
        .catch((error) => {
          setStatus(Status.ERROR);
        });
    },
    []
  );

  useEffect(() => {
    if (!contractId || !associateId) {
      return;
    }

    load(contractId, associateId);
  }, [load, contractId, associateId]);

  if (!contractId || !associateId) {
    return (
      <div>
        <T>Broken Link! Can't open contract information</T>
      </div>
    );
  }

  return (
    <Page title="Associate Review" className="merchant-status-page">
      <section>
        <article>
          <Retry
            retry={() => {
              setStatus(Status.PENDING);
              setTimeout(() => {
                load(contractId, associateId);
              }, 300);
            }}
            status={status}
          >
            <AssociateReviewPageInner
              associate={associate}
              contractId={contractId}
              reviewStatus={reviewStatus}
            />
          </Retry>
        </article>
      </section>
    </Page>
  );
};
