import React from "react";
import { ComponentProps } from "../PdfPage";
// import { Text, StyleSheet, View } from "@react-pdf/renderer";
// import { useTranslation } from "react-i18next";
// import { getDefaultStyles } from "../PdfStyles";
// import { Cell } from "./Cell";
// import { FirstCell } from "./FirstCell";

// function getSpecificStyles(show: boolean) {
//   return StyleSheet.create({
//     owner: {
//       margin: "10px 0 0 0",
//     },
//   });
// }

// function getLabels(t: any) {
//   return {
//     header: t("Owners"),
//     name: t("Name"),
//     nationalPersonId: t("Social security number"),
//     ownershipShares: t("Ownership shares"),
//   };
// }

// const Owner: React.FunctionComponent<ComponentProps> = ({
//   show,
//   language,
//   productType,
//   config,
// }) => {
//   const { t } = useTranslation();
//   const labels = getLabels(t);
//   const styles = {
//     ...getDefaultStyles(show),
//     ...getSpecificStyles(show),
//   };

//   return (
//     <View style={styles.owner}>
//       <View style={styles.split}>
//         <View style={styles.column}>
//           <FirstCell label={labels.name} show={show}>
//             Hannibal Barca
//           </FirstCell>
//         </View>
//         <View style={styles.quarterColumn}>
//           <Cell label={labels.nationalPersonId} show={show}>
//             19990101-1111
//           </Cell>
//         </View>
//         <View style={styles.quarterColumn}>
//           <Cell label={labels.ownershipShares} show={show}>
//             45 %
//           </Cell>
//         </View>
//       </View>
//     </View>
//   );
// };

export const Owners: React.FunctionComponent<ComponentProps> = (props) => {
  return null;
  // const { t } = useTranslation();
  // const labels = getLabels(t);
  // const styles = {
  //   ...getDefaultStyles(props.show),
  //   ...getSpecificStyles(props.show),
  // };

  // const items: React.FunctionComponent<ComponentProps>[] = [];
  // for (let idx = 0; idx < props.config.numberOfSignees; idx++) {
  //   items.push((<Owner {...props} key={idx} />) as any);
  // }

  // return (
  //   <>
  //     <View style={styles.sectionHeader}>
  //       <Text>{labels.header}</Text>
  //     </View>
  //     {items}
  //   </>
  // );
};
