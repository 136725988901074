import React from "react";
import { Language } from "../../i18n";
import { DateTimeFormatOptions } from "luxon";
import { UTCDate } from "../../data/models/CommonTypes";
import { MISSING_DATE } from "../utils";
import { useTranslation } from "react-i18next";

interface Props {
  date?: Date | string | UTCDate;
  options?: DateTimeFormatOptions;
}

export const FormattedIntlDate: React.FunctionComponent<Props> = ({
  date,
  options = { year: "numeric", month: "numeric", day: "numeric" },
}) => {
  const { i18n } = useTranslation();

  if (!date) {
    return <>{MISSING_DATE}</>;
  }

  const dateObject = new Date(date);

  if (dateObject instanceof Date && !isNaN(dateObject as any)) {
    return (
      <>
        {new Intl.DateTimeFormat(
          i18n.language || Language.ENGLISH,
          options
        ).format(dateObject)}
      </>
    );
  } else {
    return <>{MISSING_DATE}</>;
  }
};
