import { createContext, RefObject } from "react";

export interface ScrollPositionEntry {
  id: string;
  ref: RefObject<HTMLElement>;
}

export interface ScrollPositionContextInterface {
  addScrollRef(id: string, ref: RefObject<HTMLElement>): void;
  removeScrollRef(id: string): void;
  closestScrollEntry?: ScrollPositionEntry;
  scrollRef?: RefObject<HTMLDivElement>;
}

export const ScrollPositionContext =
  createContext<ScrollPositionContextInterface | null>(null);
