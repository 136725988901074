import { useEffect, useState } from "react";
import { generatePath } from "react-router";
import { NavLink } from "react-router-dom";
import {
  EmailsResponse,
  TextTemplateAndTranslationsResponse,
  dataCommunication,
} from "../../../../data/dataCommunication";
import { EMAIL_PREVIEW_URL } from "../emailPreview/EmailPreview";
import { TEXT_PREVIEW_URL } from "../textPreview/TextPreview";
import { Page } from "../../../Page";
import styles from "./CommunicationPage.module.scss";

export const COMMUNICATION_PAGE_URL = "/manager/communication";

export const CommunicationPage = () => {
  const [email, setEmail] = useState<EmailsResponse[]>();
  const [texts, setTexts] = useState<TextTemplateAndTranslationsResponse[]>();

  useEffect(() => {
    dataCommunication.getEmails().then(setEmail);
    dataCommunication.getTexts().then(setTexts);
  }, []);

  return (
    <Page stripped title="Communication">
      <section>
        <article>
          <div className={styles.wrapper}>
            <div>
              <h2>Customer communication</h2>
            </div>
            <div className="tablet-columns">
              <div>
                <h3>Email translations</h3>

                <ul>
                  {email?.map((email) => (
                    <li key={email.templateId}>
                      <NavLink
                        to={generatePath(EMAIL_PREVIEW_URL, {
                          templateId: email.templateId,
                        })}
                      >
                        {email.templateName}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3>SMS translations</h3>
                <ul>
                  {texts?.map((text) => (
                    <li key={text.templateId}>
                      <NavLink
                        to={generatePath(TEXT_PREVIEW_URL, {
                          templateId: text.templateId,
                        })}
                      >
                        {text.templateName}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </article>
      </section>
    </Page>
  );
};
