import debounce from "lodash.debounce";
import { useCallback, useState } from "react";
import {
  dataLocationLookup,
  LocationLookupResult,
} from "../../data/dataLocationLookup";
import { ValidationProps } from "../form/hooks/useValidation";
import { Searchable } from "../interactions/Searchable/Searchable";
import { Alternative } from "../interactions/InputTypes";
import { useTranslation } from "react-i18next";
import { Address } from "../../data/models/ContractTypes";
import { Country } from "../../i18n";

export interface AddressLocationChange {
  street?: string;
  city?: string;
  postalCode?: string;
  countryCode?: string;
}

interface Props extends ValidationProps {
  onChange: ({ street, city, postalCode, countryCode }: Address) => void;
  disabled?: boolean;
  label?: string;
}

function getAddress(lookup: LocationLookupResult): Address {
  return {
    street:
      getStreetRow({
        street: lookup.street,
        streetNumber: lookup.streetNumber,
      }) || "",
    postalCode: lookup.postalCode || "",
    city: lookup.city || "",
    countryCode: lookup.countryCode || ("" as Country),
  };
}

const getStreetRow = ({
  street,
  streetNumber,
}: {
  street?: string;
  streetNumber?: string;
}) => {
  if (street && streetNumber) {
    return `${street} ${streetNumber}`;
  } else if (street) {
    return street;
  } else {
    return undefined;
  }
};

export const AddressSearch: React.FunctionComponent<Props> = ({
  onChange,
  label = "Search address",
  ...props
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Alternative<string>[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce(async (value: string) => {
      if (value.length < 3) {
        return;
      }

      dataLocationLookup.getSuggestions(value).then((suggestions) => {
        setSuggestions(suggestions.map(({ text }) => ({ text, value: text })));
      });
    }, 250),
    []
  );

  const select = useCallback(
    (value: string) => {
      dataLocationLookup.getLocation(value).then((location) => {
        onChange(getAddress(location));
        setSearchValue("");
        setSuggestions([]);
      });
    },
    [onChange]
  );

  return (
    <div className="address-search">
      <Searchable
        {...props}
        onChange={(value) => {
          setSearchValue(value);
          search(value);
        }}
        onSelect={select}
        alternatives={suggestions}
        value={searchValue}
        autocomplete="off"
        placeholder={t("Search address...")}
        {...{ label }}
      />
    </div>
  );
};
